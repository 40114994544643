import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 100px 0px 200px 0px;
    @media ${props => props.theme.mobile}{
        overflow: hidden;
        margin: 0px 0px;
        padding: 50px 0px 79px 0px;
        border-top: 20px solid #f8f8f8;
        border-bottom: 1px solid #ebebeb;
    }
`;

export const Container = styled.div`
    @media ${props => props.theme.mobile}{
        margin: 0px 20px;
    }
`;



export const DeliveryTitle = styled.div`
    margin-bottom: 30px;
    h3{
        font-size: 27px;
        font-weight: bold;
        line-height: normal;
        color: #111111;
    }
`;

export const ListWrapper = styled.div`
    margin: 0px -10px;
`;

export const DeliveryList = styled.div`
    
`;

export const ListBox = styled.div`
    padding: 0px 10px;
    width: 50%;
    display: inline-block;
    margin-bottom: 30px;
    @media ${props => props.theme.mobile}{
        padding: 0px 10px;
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const Content = styled.div`
    width: auto;
    border-radius: 2px;
    border: solid 1px #e3e5e8;
    background-color: #fbfbfb;
    padding: 40px 30px;
    .flex{
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: flex-end;
        margin-bottom: 30px;
        p{
            font-size: 17px;
            font-weight: 500;
            line-height: normal;
            color: #111111;
        }
        span{
            font-size: 15px;
            font-weight: normal;
            line-height: normal;
            color: #111111;
        }
    }
    @media ${props => props.theme.mobile}{
        padding: 20px;
        .flex{
            p{
                font-size: 15px;
            }
            span{
                font-size: 13px;
            }
        }
        a{
            font-size: 14px;
            height: 46px;
            line-height: 46px;
        }
    }
`;