import axios from './defaultClient';

const nameSpace = '/ep/v1/product';

export const ProductGetPosts = async (args) => {
    return await axios.get( nameSpace + '/',{
        params:{
            category_ID: args.category_ID,
            keyword: args.keyword,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}

export const ProductGetBest = async (args) => {
    return await axios.get( nameSpace + '/best',{
        params:{
            category_ID: args.category_ID,
            posts_per_page: args.posts_per_page,
            paged: args.paged
        }
    })
}

export const ProductGetPost = async (args) => {
    return await axios.get( nameSpace + '/' + args.product_ID,{

    })
}

export const ProductGetRelated = async (args) => {
    return await axios.get( nameSpace + '/related/' + args.product_ID ,{

    })
}

export const ProductGetPoint = async (args) => {
    return await axios.get( nameSpace + '/point',{
        params:{
            category_ID: args.category_ID,
            paged: args.paged,
            posts_per_page: args.posts_per_page
        }
    })
}
