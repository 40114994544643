import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Popup from '../../components/Popup';
import {toast} from 'react-toastify';
import Joi from 'joi-browser';
import { ChatbotRoleup } from '../../api/chatbot';
import {
    Wrapper,
    BackButton,
    CloseButton,
    InquireBox,
    TopSection,
    Title,
    PageNum,
    InquireTextarea,
    InquireInput,
    Submit
} from './styled';

const RoleupPopup = (props) => {

    const history = useHistory();
    const[content, set_content] = useState('');
    const[buy_product, set_buy_product] = useState('');

    const DoRoleUp = (e) => { 
        const schema ={
            content: Joi.string()
                .required()
                .error(() => ({message: '내용을 입력해주세요.'})),
            buy_product: Joi.string()
                .required()
                .error(() => ({message: '구입 상품을 입력해주세요.'})),
        }
        const {error, value} = Joi.validate({
            content: content,
            buy_product: buy_product,
        }, schema);

        if(error){
            toast.error(error.details[0].message);
        } else { 
            ChatbotRoleup({
                buy_product: buy_product,
                content: content,
            })
            .then((response) => {
                console.log(response.data.code)
                if(response.data.code == '200') {
                    toast.success('등업이 완료되었습니다.');
                    props.submit_success(0);
                } else {
                    alert(response.data.body.message)
                }
            })
            .catch((error) => console.log(error))
        }
    }

    return (
        <Popup show={props.show} onClose={props.onClose} full={true}>
            <Wrapper>
                <CloseButton onClick={props.onClose} src={process.env.PUBLIC_URL + "/img/close-button.svg"} />
                <InquireBox>
                    <TopSection>
                        <Title>
                            등업신청
                        </Title>
                    </TopSection>
                    <InquireInput type="text" name="user_email" onChange={(e) => set_buy_product(e.target.value)} placeholder="무엇을 사러 오셨습니까?" />
                    <InquireTextarea onChange={(e) => set_content(e.target.value)} name="content" placeholder="내용을 입력해주세요." />
                    <Submit onClick={DoRoleUp} spellcheck="false">
                        Enter
                        <img src={process.env.PUBLIC_URL + "/img/arrow-enter.svg"} />
                    </Submit>
                </InquireBox>
            </Wrapper>
        </Popup>
    );
};

export default RoleupPopup;