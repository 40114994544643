import styled from 'styled-components';

export const PopupWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9990;
    ${(props) => {
        if (props.show) {
            return `
                display: block;
            `;
        } else {
            return `
                display: none;
            `;
        }
    }}
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    ${(props) => {
        if (props.show) {
            return `
                display: block;
            `;
        } else {
            return `
                display: none;
            `;
        }
    }}
`;

export const PopupInner = styled.div`
    max-width: 1024px;
    width: ${(props) => (props.width ? props.width : `100%`)};
    height: ${(props) => (props.height ? props.height : `636px`)};
    position: relative;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 50px;
    overflow-y: auto;
    @media ${(props) => props.theme.mobile} {
        width: calc(100% - 40px);
        height: calc(100% - 100px);
        img {
            width: 100%;
            height: auto;
        }
        ${(props) =>
            props.full
                ? `
            max-width: 100vw;
            width: 100vw;
            height: 100%;
            border-radius: 0;
        `
                : ``}
    }
    ${(props) =>
        props.full
            ? `
        max-width: 100vw;
        width: 100vw;
        height: 100%;
        border-radius: 0;
    `
            : ``}
`;

export const PopupClose = styled.div`
    position: relative;
    z-index: 100;
    cursor: pointer;
    .icon-close {
        position: absolute;
        top: 37px;
        right: 37px;
        color: #111;
    }
    ${(props) =>
        props.full
            ? `
        display: none;
    `
            : ``}
    @media ${(props) => props.theme.mobile} {
        display: none;
    }
`;
