import React, { useState, useEffect } from 'react';
import MainContainer from '../../components/MainContainer';
import { Row, Col, MediaQuery, mobileQuery } from '../../components/Grid';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import Input from '../../components/SelectBox';
import OrderListPopup from './Single/Popup/OrderList';
import styled from 'styled-components';
import Pagination from '../../components/Pagination';
import { SktonReview } from '../../components/Skeleton';
import { ReviewGetPosts, ReviewGetBest } from '../../api/review';
import {
    Wrapper,
    Container,
    ReviewTitle,
    UseWrapper,
    UseReview,
    User,
    ReviewImg,
    ReviewSlider,
    ReviewBadge,
    Written,
    WrittenDate,
    SeemoreButton,
    UserItem,
    UsersWrapper,
    UsersReviewTitle,
    InputWrapper,
    UsersReviewContent,
    UsersReviewBox,
    UsersReview,
    ReviewUserImg,
    ReviewWriting,
    ReviewUserItem,
    ReviewMoreButton,
    WrittenButton,
    ReviewUserPrice,
    ReviewProductDetails,
    ReviewUserImage,
    IncludeUrl,
    IsFinest,
} from './styled';

export const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
    autoplay: false,
    arrows: true,
    dots: false,
    responsive: [
        {
            breakpoint: 770,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const SlickWrap = styled.div`
    .slick-slide {
        display: inline-block;
        position: relative;
    }
    .slick-prev {
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
        z-index: 10;
        width: 31px;
        height: 31px;
        border: none;
        background-color: transparent;
        font-size: 0;
        color: transparent;
        :before {
            content: '';
            background-image: url('../img/slider-left.svg');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 31px;
            height: 31px;
        }
        @media ${(props) => props.theme.mobile} {
            top: 72%;
            left: 10px;
        }
    }
    .slick-next {
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        z-index: 10;
        width: 31px;
        height: 31px;
        border: none;
        background-color: transparent;
        font-size: 0;
        color: transparent;
        :before {
            content: '';
            background-image: url('../img/slider-right.svg');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 31px;
            height: 31px;
        }
        @media ${(props) => props.theme.mobile} {
            top: 72%;
            right: 10px;
        }
    }
`;

const Review = () => {
    const isMobile = MediaQuery(mobileQuery);
    const get_params = new URLSearchParams(useLocation().search);
    const [OrderPopup_isshow, show_OrderPopup] = useState(0);
    const History = useHistory();
    const showOrderPopup = (e) => {
        e.preventDefault();
        if (OrderPopup_isshow) {
            show_OrderPopup(0);
        } else {
            show_OrderPopup(1);
        }
    };

    const [current_paged, set_current_paged] = useState(0);
    const [total, set_total] = useState(0);
    const [paged, set_paged] = useState(get_params.get('paged') ? get_params.get('paged') : 1);
    const [orders, set_orders] = useState('');
    const posts_per_page = 12;

    const [review, set_review] = useState('');
    const [best_review, set_best_review] = useState('');

    const onPaging = (page) => {
        set_paged(page);
        History.push('/review/?paged=' + page);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        if (current_paged != paged) {
            set_current_paged(paged);
            set_review('');
            ReviewGetPosts({
                paged: paged,
                posts_per_page: posts_per_page,
                category_ID: '',
            })
                .then((response) => {
                    if (response.data.code == 200) {
                        set_total(response.data.body.total);
                        set_review(response.data.body.posts);
                        console.log(response.data.body.posts);
                    }
                })
                .catch((error) => console.log(error));
        }
        // if(!best_review) {
        //     ReviewGetBest()
        //     .then(response => {
        //         if(response.data.code == 200){
        //             set_best_review(response.data.body.posts);
        //             console.log(response.data.body.posts);
        //         }
        //     })
        //     .catch(error => console.log(error));
        // }
    });

    return (
        <>
            <MainContainer>
                <Wrapper>
                    <Container>
                        <ReviewTitle>
                            <h2>
                                이용후기
                                <WrittenButton onClick={showOrderPopup}>리뷰 작성하기</WrittenButton>
                            </h2>
                        </ReviewTitle>
                        {/* <UseWrapper>
                            <ReviewSlider>
                                <SlickWrap>
                                    <Slider {...settings}>
                                        {best_review ? best_review.map((item, key) => {
                                                return (
                                                    <Row>
                                                        <Col>
                                                            <UseReview>
                                                                <ReviewImg url="/img/review_01.png" />
                                                            </UseReview>
                                                        </Col>
                                                        <Col>
                                                            <User>
                                                                <ReviewBadge>Best Review</ReviewBadge>
                                                                <Written>
                                                                    <p>
                                                                        {item.post_title ? item.post_title : ''}
                                                                    </p>
                                                                </Written>
                                                                <WrittenDate>
                                                                    <p>{item.author_display_name ? item.author_display_name : ''}ㅣ{item.post_date ? item.post_date : ''}</p>
                                                                </WrittenDate>
                                                                <SeemoreButton>
                                                                    <Link to="/review/single">
                                                                        <p>자세히 보기</p>
                                                                    </Link>
                                                                </SeemoreButton>
                                                                <UserItem>
                                                                    <p>
                                                                    {item.product_cate_name ? item.product_cate_name : ''}<br />
                                                                    {item.product_name ? item.product_name : ''}
                                                                    </p>
                                                                    <p>{item.product_price ? item.product_price : ''}Z</p>
                                                                </UserItem>
                                                            </User>
                                                        </Col>
                                                    </Row>
                                                )
                                            }) : <>
                                                스켈레통
                                        </> }
                                        <Row>
                                            <Col>
                                                <UseReview>
                                                    <ReviewImg url="/img/review_01.png" />
                                                </UseReview>
                                            </Col>
                                            <Col>
                                                <User>
                                                    <ReviewBadge>Best Review</ReviewBadge>
                                                    <Written>
                                                        <p>
                                                            배송지 변경도 친절하게 해주시고<br />
                                                            배송도 빠르고 포장도 깔끔하고 만족합니다!(프론트샘플)
                                                        </p>
                                                    </Written>
                                                    <WrittenDate>
                                                        <p>ava***ㅣ2021-01-14</p>
                                                    </WrittenDate>
                                                    <SeemoreButton>
                                                        <Link to="/review/single">
                                                            <p>자세히 보기</p>
                                                        </Link>
                                                    </SeemoreButton>
                                                    <UserItem>
                                                        <p>
                                                        득모+불끈<br />
                                                        비달리스타 10mg 600정(프론트샘플)
                                                        </p>
                                                        <p>120,000원</p>
                                                    </UserItem>
                                                </User>
                                            </Col>
                                        </Row>
                                    </Slider>
                                </SlickWrap>
                            </ReviewSlider>
                        </UseWrapper> */}
                        <UsersWrapper>
                            <UsersReviewTitle>
                                <Row>
                                    <Col>
                                        {/* <h2>총 <strong>{total}개</strong> 리뷰가 작성되었습니다.</h2> */}
                                        {/* <WrittenButton onClick={showOrderPopup}>
											리뷰 작성하기
										</WrittenButton> */}
                                    </Col>
                                    <Col>
                                        <InputWrapper>
                                            {/* {isMobile ? (
                                            <>
                                                <Input type="select" width="90px">
                                                <option value="">카테고리</option>
                                                <option value="매매">두통</option>
                                                <option value="월세">비염</option>
                                                </Input>
                                                <Input type="select" width="90px">
                                                    <option value="">상품</option>
                                                    <option value="매매">두사트</option>
                                                    <option value="월세">핀페시아</option>
                                                </Input>
                                            </>
                                        ) :
                                            <>
                                                <Input type="select" width="130px">
                                                    <option value="">카테고리</option>
                                                    <option value="매매">두통</option>
                                                    <option value="월세">비염</option>
                                                </Input>
                                                <Input type="select" width="130px">
                                                    <option value="">상품</option>
                                                    <option value="매매">두사트</option>
                                                    <option value="월세">핀페시아</option>
                                                </Input>
                                            </>
                                        } */}
                                        </InputWrapper>
                                    </Col>
                                </Row>
                            </UsersReviewTitle>
                            <UsersReviewContent>
                                <UsersReviewBox>
                                    {review ? (
                                        review.map((item, key) => {
                                            if (item.thumbnail)
                                                return (
                                                    <UsersReview>
                                                        <Link to={'/review/' + (item.ID ? item.ID : '')}>
                                                            <ReviewUserImg url={item.thumbnail ? item.thumbnail : ''} />
                                                        </Link>
                                                        <ReviewWriting>
                                                            <p>
                                                                {item.is_administrator ? (
                                                                    item.finest ? (
                                                                        <IsFinest>프리미엄 후기</IsFinest>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {item.post_title ? item.post_title : ''}
                                                            </p>
                                                            <small>
                                                                <span>
                                                                    {item.author_display_name
                                                                        ? item.author_display_name
                                                                        : ''}
                                                                </span>
                                                                {item.post_date ? item.post_date : ''}
                                                            </small>
                                                        </ReviewWriting>
                                                        {item.product_ID ? (
                                                            <ReviewProductDetails
                                                                onClick={(e) =>
                                                                    History.push(`/product/${item.product_ID}`)
                                                                }
                                                            >
                                                                <ReviewUserImage
                                                                    url={item.product_image ? item.product_image : ''}
                                                                />
                                                                <ReviewUserItem>
                                                                    <p>
                                                                        {item.product_cate_name
                                                                            ? item.product_cate_name
                                                                            : ''}
                                                                    </p>
                                                                    <span>
                                                                        {item.product_name ? item.product_name : ''}
                                                                    </span>
                                                                </ReviewUserItem>
                                                                <ReviewUserPrice>
                                                                    <p>
                                                                        {item.product_price ? item.product_price : ''}
                                                                    </p>
                                                                </ReviewUserPrice>
                                                            </ReviewProductDetails>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {item.is_administrator ? (
                                                            item.is_finest ? (
                                                                <IncludeUrl>url 포함 글</IncludeUrl>
                                                            ) : (
                                                                ''
                                                            )
                                                        ) : (
                                                            ''
                                                        )}
                                                    </UsersReview>
                                                );
                                        })
                                    ) : (
                                        <>
                                            <SktonReview />
                                            <SktonReview />
                                            <SktonReview />
                                            <SktonReview />
                                            <SktonReview />
                                            <SktonReview />
                                            <SktonReview />
                                            <SktonReview />
                                        </>
                                    )}
                                </UsersReviewBox>
                            </UsersReviewContent>
                        </UsersWrapper>
                        <Pagination
                            onPaging={onPaging}
                            total={total}
                            current={current_paged}
                            posts_per_page={posts_per_page}
                            max={total >= posts_per_page ? parseInt(total / posts_per_page) + 1 : 1}
                        />
                    </Container>
                </Wrapper>
            </MainContainer>
            <OrderListPopup show={OrderPopup_isshow} onClose={showOrderPopup} />
        </>
    );
};

export default Review;
