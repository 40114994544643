import React from 'react';
import {
    LoadingRoundStyle,
} from './styled'


const LoadingRound = () => {
    return (
        <LoadingRoundStyle />
    );
};

export default LoadingRound;