import React, { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import { SetLoading, ToggleChatbot } from '../../../store/global';
import {toast} from 'react-toastify';
import Joi from 'joi-browser';
import { ChatbotPost } from '../../../api/chatbot';
import {
    Content,
    Submit,
    OpenChatLink
} from '../styled';

const ContentTemplate = (props) => {
    const Dispatch = useDispatch();

    return (
        <>
        
            <Content  dangerouslySetInnerHTML={ {__html: props.actions.content}}/>
            {props.back ? (<Submit onClick={e=>props.DoAction(props.back,[])}>뒤로가기</Submit>) : ''}
            {props.openchat ? (<></>
                // <OpenChatLink href="https://open.kakao.com/o/sDvmaGhb" target="_blank" >카카오톡 오픈채팅</OpenChatLink>
            ) : ''}
            <Submit type="default" onClick={e=>{props.DoAction('qna_start',[]);}}>1:1 문의</Submit>
        </>
    );
};

export default ContentTemplate;