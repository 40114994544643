import React, {useState, useEffect} from 'react';
import Popup from '../../../components/Popup';
import {Link} from 'react-router-dom';
import {
    Wrapper,
    Container,
    ButtonWrapper

} from './styled';

const MainPopup = ({closeToday, index, children}) => {
    const [show, set_show] = useState(1);
    const onClosee = (e) => {
        set_show(0);
    }
    var today = new Date();
    const date = today.getDate();

    const dnjdlek = (e) => {
        set_show(0);
        localStorage.setItem('popup_date', date);
        if(localStorage.getItem('load')){
            var prev_load = JSON.parse(localStorage.getItem('load'));
            var current_load = prev_load.push(e.currentTarget.dataset.id);
            localStorage.setItem('load', JSON.stringify(prev_load));
        } else {
            localStorage.setItem('load', JSON.stringify([e.currentTarget.dataset.id]));
        }
    }

    useEffect(()=> {
        const load = localStorage.getItem('load');
    
        if(!tmp) {
            if(localStorage.getItem('popup_date') != date){
                localStorage.removeItem('load');
            }
            if(load && load.includes(index)) {
                onClosee();
            } 
            set_tmp(1);
        }
    })

    const [tmp, set_tmp] = useState(0);

    return (
        <>
            <Popup show={show} onClose={onClosee} width="600px">
                <Wrapper>
                    <Container>
                        {children}
                        {/* <strong>입금자를 찾습니다.</strong>
                        <span>하기 리스트에 입금자분은 고객센터 <Link to="/mypage/inquire">1:1문의</Link>로 문의 부탁드립니다.</span>
                        <div>
                            <p>- 2021-08-01 06:57 - <b>함석춘</b>님의 100,000원 이체
                            <br/>- 2021-08-01 18:41 - <b>차종운</b>님의 46,000원 이체
                            <br/>- 2021-08-07 13:35 - <b>이현기</b>님의 70,000원 이체
                            <br/>- 2021-08-08 11:16 - <b>이정숙</b>님의 110,000원 이체
                            <br/>- 2021-08-08 15:02 - <b>이용호</b>님의 130,000원 이체
                            <br/>- 2021-08-08 22:49 - <b>이혜자</b>님의 100,000원 이체
                            <br/>- 2021-08-09 09:16 - <b>김민숙</b>님의 115,000원 이체</p>
                        </div>
                        <strong>
                            배송기간
                        </strong>
                        <p>
                            신규 주문건은 평균 6주 ~ 8주 사이에 수령들 하고 계십니다.<br />
                            코로나 영향으로 예기치 못한 상황 발생으로 배송기간은 여유롭게 6주 ~ 12주 사이<br />
                            안내해드리고 있습니다.
                        </p>
                        <span>
                            결제 전 안내를 해드리고 있으며, 확인 후 동의 부탁드립니다.
                        </span> */}
                    </Container>
                    <ButtonWrapper className="button">
                        <button onClick={dnjdlek}>오늘은 그만 보기</button>
                        <button onClick={onClosee}>닫기</button>
                    </ButtonWrapper>
                </Wrapper>
            </Popup>
            
        </>
    );
};

export default MainPopup;