import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import MainContainer from '../../components/MainContainer';
import { MediaQuery, mobileQuery } from '../../components/Grid';
import { category } from '../../theme';
import Pagination from '../../components/Pagination';
import { SktonBest } from '../../components/Skeleton';
import { ProductGetBest } from '../../api/product';
import {
	Wrapper,
	Title,
	CategoryWrapper,
	Category,
	CategoryLogo,
	CategoryName,
	ProductsWrapper,
	ProductWrapper,
	Product,
	ProductImage,
	Label,
	ProductDetail,
	ProductType,
	ProductName,
	ProductPrice,
	MoreButtonWrapper,
	MoreButton,
	MobileBasket,
} from './styled';

const Best = () => {
	const get_params = new URLSearchParams(useLocation().search);
	const History = useHistory();

	const params = useParams();
	const pathname = window.location.pathname;
	const isMobile = MediaQuery(mobileQuery);

	const [cate, set_cate] = useState('');
	const [category_ID, set_category_ID] = useState(params.ID ? params.ID : 785);
	const [best_products, set_best_products] = useState('');
	const [test, set_test] = useState(0);

	const [current_paged, set_current_paged] = useState(0);
	const [total, set_total] = useState(0);
	const [paged, set_paged] = useState(
		get_params.get('paged') ? get_params.get('paged') : 1
	);
	const posts_per_page = 12;

	const onPaging = (page) => {
		console.log(page);
		History.push(
			'/best/' + (params.ID ? params.ID + '/' : '') + '?paged=' + page
		);
		set_best_products('');
		set_paged(page);
		window.scrollTo(0, 0);
	};

	const selectCate = (e) => {
		set_best_products('');
		History.push('/best/' + e.target.dataset.id);
		set_category_ID(e.target.dataset.id);
	};

	//   useEffect(() => {
	//     if (test !== category_ID || current_paged != paged) {
	//       set_current_paged(paged);
	//       if (test !== category_ID) {
	//         set_paged(get_params.get('paged') ? get_params.get('paged') : 1);
	//       }
	//       set_test(category_ID);
	//       ProductGetBest({
	//         category_ID: category_ID ? category_ID : 785,
	//         posts_per_page: posts_per_page,
	//         paged: paged,
	//       })
	//         .then(response => {
	//           if (response.data.code == 200) {
	//             set_best_products(response.data.body.products);
	//             set_total(response.data.body.total);
	//             console.log(response.data.body.products);
	//           }
	//         })
	//         .catch(error => console.log(error));
	//     }
	//   });

	useEffect(() => {
		ProductGetBest({
			category_ID: category_ID ? category_ID : 785,
			posts_per_page: posts_per_page,
			paged: paged,
		})
			.then((response) => {
				if (response.data.code == 200) {
					set_best_products(response.data.body.products);
					set_total(response.data.body.total);
					console.log(response.data.body.products);
				}
			})
			.catch((error) => console.log(error));
	}, [category_ID, paged]);

	return (
		<MainContainer>
			<Wrapper>
				<Title>BEST</Title>
				<CategoryWrapper>
					<Category>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id=""
								className={category_ID == '' ? 'active' : ''}
							>
								{category.all}
								<CategoryName>전체상품</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="20"
								className={category_ID == 20 ? 'active' : ''}
							>
								{category.hair}
								<CategoryName>득모상품</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="433"
								className={category_ID == 433 ? 'active' : ''}
							>
								{category.health}
								<CategoryName>건강상품</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="22"
								className={category_ID == 22 ? 'active' : ''}
							>
								{category.skin}
								<CategoryName>여드름상품</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="384"
								className={category_ID == 384 ? 'active' : ''}
							>
								{category.beauty}
								<CategoryName>미용상품</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="526"
								className={category_ID == 526 ? 'active' : ''}
							>
								{category.sleep}
								<CategoryName>꿀잠상품</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="692"
								className={category_ID == 692 ? 'active' : ''}
							>
								{category.concent}
								<CategoryName>집중력상품</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="457"
								className={category_ID == 457 ? 'active' : ''}
							>
								{category.various}
								<CategoryName>숙취 금연 금주 우울</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="456"
								className={category_ID == 456 ? 'active' : ''}
							>
								{category.weight}
								<CategoryName>다이어트식품</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="535"
								className={category_ID == 535 ? 'active' : ''}
							>
								{category.mars}
								<CategoryName>남성호르몬</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="633"
								className={category_ID == 633 ? 'active' : ''}
							>
								{category.venus}
								<CategoryName>여성호르몬</CategoryName>
							</CategoryLogo>
						</li>
						<li>
							<CategoryLogo
								onClick={selectCate}
								data-id="440"
								className={category_ID == 440 ? 'active' : ''}
							>
								{category.pet}
								<CategoryName>반려동물</CategoryName>
							</CategoryLogo>
						</li>
						<li className="last-category">
							<CategoryLogo
								onClick={selectCate}
								data-id="840"
								className={category_ID == 840 ? 'active' : ''}
							>
								{category.point}
								<CategoryName>FC2포인트</CategoryName>
							</CategoryLogo>
						</li>
					</Category>
				</CategoryWrapper>
				<ProductsWrapper>
					{best_products ? (
						best_products.map((item, key) => {
							return (
								<ProductWrapper>
									<Product>
										<ProductImage
											to={'/product/' + item.ID}
											url={item.thumbnail ? item.thumbnail : ''}
										>
											{key + 1 + (paged - 1) * posts_per_page <= 20 ? (
												<Label>
													<span>{key + 1 + (paged - 1) * posts_per_page}</span>
												</Label>
											) : (
												''
											)}
											{item?.is_sold_out ? (
												<div className="sold-out">임시품절</div>
											) : null}
										</ProductImage>
										<ProductDetail>
											<ProductType>
												{item.cate_name ? item.cate_name : ''}
											</ProductType>
											<ProductName>{item.title ? item.title : ''}</ProductName>
											<ProductPrice>
												{item.price ? item.price : ''}
											</ProductPrice>
										</ProductDetail>
									</Product>
								</ProductWrapper>
							);
						})
					) : (
						<>
							<SktonBest />
							<SktonBest />
							<SktonBest />
							<SktonBest />
							<SktonBest />
							<SktonBest />
							<SktonBest />
							<SktonBest />
						</>
					)}
				</ProductsWrapper>
				<Pagination
					onPaging={onPaging}
					total={total}
					current={paged}
					posts_per_page={posts_per_page}
					max={
						total >= posts_per_page ? parseInt(total / posts_per_page) + 1 : 1
					}
				/>
			</Wrapper>
		</MainContainer>
	);
};

export default Best;
