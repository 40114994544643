import styled from 'styled-components';

export const SidePopupWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    z-index: 999;
    transition: 0.6s;
    width:auto;
    height:100%;
    ${props => {
        if(props.show) {
            return `
                right: 0px;
            `
        } else {
            return `
                right: -500px;
            `
        }
    }}
    @media ${props => props.theme.mobile}{
        ${props => {
            if(props.show) {
                return `
                    right: 0px;
                `
            } else {
                return `
                    right: -100vw;
                `
            }
        }}
    }
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 990;
    position: fixed;
    top: 0;
    left: 0;
    ${props => {
        if(props.show) {
            return `
                display: block;
            `
        } else {
            return `
                display: none;
            `
        }
    }}
`;

export const SidePopupInner = styled.div`
    position: relative;
    width: 500px;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
    overflow-y: scroll;
    @media ${props => props.theme.mobile}{
        width: 100vw;
        height:100%;
    }
`;

export const SidePopupClose = styled.img`
    position: absolute;
    top: 50px;
    right: 50px;
    width: 28px;
    height: 28px;
    z-index: 100;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        top: 29px;
        right: 14px;
        width: 34px;
        height: 34px;
    }
`;
