import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../../../components/Popup';
import LoadingRound from '../../../../../components/LoadingRound';
import { MediaQuery, mobileQuery } from '../../../../../components/Grid';
import { OrderGetProducts } from '../../../../../api/order';
import WriteReviewPopup from '../WriteReivew';
import {
  Container,
  Wrapper,
  OrderTitle,
  Order,
  OrderImg,
  OrderItem,
  WriteButton,
  ButtonWrapper,
  OrderCheck,
  WriteButtonGroup,
} from './styled';

const OrderListPopup = ({ show, onClose }) => {
  const check_icon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <rect
        width='19'
        height='19'
        x='.5'
        y='.5'
        fill='#fff'
        stroke='#F5F5F5'
        rx='1.5'
      />
      <path
        fill='#9A9A9E'
        fill-rule='evenodd'
        d='M12.97 6.97c.14-.138.329-.217.526-.217s.386.076.527.214c.14.138.221.326.225.523.003.197-.071.388-.207.53l-3.992 4.99c-.069.074-.152.133-.244.175-.092.04-.191.063-.292.065-.1.002-.201-.017-.295-.055-.093-.037-.178-.093-.25-.165l-2.644-2.646c-.074-.068-.133-.151-.174-.243-.041-.092-.063-.191-.065-.292-.002-.1.017-.2.055-.294.037-.094.093-.179.165-.25.07-.071.156-.127.25-.165.092-.038.192-.056.293-.054.1.001.2.024.292.064.092.041.175.1.244.174l2.094 2.093 3.473-4.425c.006-.007.012-.015.02-.022h-.001z'
        clip-rule='evenodd'
      />
    </svg>
  );

  const isMobile = MediaQuery(mobileQuery);
  const [WritePopup_isshow, show_WritePopup] = useState(0);
  const [orders, set_orders] = useState('');
  const [not_founds, set_not_founds] = useState(0);
  const [checked, set_checked] = useState(0);
  const [checked_product_ID, set_checked_product_ID] = useState(0);
  const [checked_product_name, set_checked_product_name] = useState('');

  const showWritePopup = e => {
    e.preventDefault();
    if (WritePopup_isshow) {
      show_WritePopup(0);
    } else {
      show_WritePopup(1);
    }
  };

  const ProductCheck = e => {
    e.preventDefault();
    const order_ID = e.currentTarget.dataset.id;
    const product_ID = e.currentTarget.dataset.product_id;
    const product_name = e.currentTarget.dataset.product_name;

    set_checked(order_ID);
    set_checked_product_ID(product_ID);
    set_checked_product_name(product_name);

    console.log(order_ID);
    console.log(product_ID);
  };

  useEffect(() => {
    if (show) {
      if (!orders && !not_founds) {
        OrderGetProducts({
          paged: 1,
          posts_per_page: 15,
        })
          .then(response => {
            if (response.data.code == '200') {
              set_orders(response.data.body.orders);
            } else {
              toast.error(response.data.body.message);
              set_not_founds(1);
            }
          })
          .catch(err => {
            console.log(err);
            toast.error('서버요청 오류!');
          });
      }
    }
  });
  return (
    <>
      <Popup show={show} onClose={onClose} width='500px;' height='auto'>
        <OrderTitle>
          <h2>주문내역</h2>
        </OrderTitle>
        <Container>
          {/* {order_item.is_write_reivew ? order_item.is_write_reivew : '' } */}
          {orders ? (
            orders.map((order_item, key) => (
              <Wrapper
                onClick={!order_item.is_write_review && ProductCheck}
                data-id={order_item.order_ID}
                data-product_id={order_item.product_ID}
                data-product_name={order_item.name}
              >
                <Order
                  is_review={
                    order_item.is_write_review ? order_item.is_write_review : ''
                  }
                >
                  <OrderImg
                    is_review={
                      order_item.is_write_review
                        ? order_item.is_write_review
                        : ''
                    }
                    url={order_item.thumbnail}
                  />
                  <OrderItem
                    is_review={
                      order_item.is_write_review
                        ? order_item.is_write_review
                        : ''
                    }
                  >
                    <span>주문일: {order_item.order_date}</span>
                    <p class='product_name'>{order_item.name}</p>
                    {order_item.is_write_review ? (
                      <p class='write_complete'>후기작성완료</p>
                    ) : (
                      <p> 수량: {order_item.qty}개</p>
                    )}
                  </OrderItem>
                  <OrderCheck
                    checked={
                      checked == order_item.order_ID &&
                      checked_product_ID == order_item.product_ID
                        ? 'checked'
                        : ''
                    }
                  >
                    {order_item.is_write_review ? (
                      ''
                    ) : (
                      <label for='img_checkbox'>{check_icon}</label>
                    )}
                  </OrderCheck>
                </Order>
              </Wrapper>
            ))
          ) : not_founds ? (
            <Wrapper>
              <Order>
                <OrderItem>
                  <span>아직 주문내역이 없습니다.</span>
                </OrderItem>
              </Order>
            </Wrapper>
          ) : (
            <LoadingRound />
          )}
        </Container>
        {checked ? (
          <WriteButton onClick={showWritePopup}>작성하기</WriteButton>
        ) : (
          ''
        )}
      </Popup>
      <WriteReviewPopup
        show={WritePopup_isshow}
        product_ID={checked_product_ID}
        product_name={checked_product_name}
        order_ID={checked}
        onClose={showWritePopup}
      />
    </>
  );
};

export default OrderListPopup;
