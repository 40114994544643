import styled from 'styled-components';
import { RowWrap, ColWrap } from '../Grid/styled';

export const FooterWrapper = styled.div`
	width: 100%;
	height: auto;
	background-color: #f8f8f8;
	padding-top: 100px;
	@media ${(props) => props.theme.mobile} {
		padding-top: 50px;
		overflow-x: hidden;
		background-color: #fff;
		border-top: 1px solid #ebebeb;
	}
`;

export const FooterContainer = styled.div`
	max-width: 1502px;
	margin: 0 auto;
`;

export const FooterTop = styled.div`
	padding-bottom: 149px;
	border-bottom: 1px solid #eee;
	@media ${(props) => props.theme.mobile} {
		padding-bottom: 50px;
		${RowWrap} {
			display: block;
		}
		${ColWrap} {
			-webkit-flex: 0;
			flex: 0;
		}
	}
`;

export const OpenChat = styled.div`
	font-size: 15px;
	line-height: 1.87;
	text-align: left;
	color: #111111;
	a {
		margin-top: 30px;
		font-size: 13px;
	}
	@media ${(props) => props.theme.mobile} {
		display: block;
		flex: 0;
		text-align: center;
		font-size: 16px;
		line-height: 1.67;
		font-weight: normal;
		color: #111111;
		padding: 0px 20px;
		a {
			width: 300px;
			height: 60px;
			position: relative;
			margin: 0 auto;
			margin-top: 26px;
			font-size: 16px;
			line-height: 58px;
			color: #111;
			background-color: #fee000;
			:before {
				content: '';
				background-image: url('../img/kakaoimg1.png');
				background-repeat: no-repeat;
				background-size: cover;
				position: absolute;
				display: inline-block;
				top: 50%;
				left: 20%;
				transform: translateY(-50%) translateX(-20%);
				width: 27px;
				height: 27px;
			}
		}
	}
	@media (max-width: 330px) {
		a {
			width: 280px;
		}
	}
`;

export const FooterMenu = styled.ul`
	display: flex;
	justify-content: space-between;
	> li {
		float: left;
		width: 100%;
		span {
			display: inline-block;
			font-family: 'Roboto';
			font-size: 17px;
			line-height: 20px;
			color: #111111;
			margin-bottom: 20px;
		}
	}
	.sub_menu {
		li {
			font-size: 13px;
			line-height: 19px;
			color: #666666;
			a {
				font-size: 13px;
				line-height: 19px;
				color: #666666;
			}
		}
		li:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	.service {
		width: 140%;
	}
	.contact {
		width: 160%;
		min-width: 270px;
		li:last-child {
			line-height: 1.85;
		}
	}

	@media ${(props) => props.theme.mobile} {
		display: none;
		flex: 0;
		-webkit-flex: 0;
	}
`;

export const FooterBottom = styled.div`
	width: 100%;
	height: auto;
	padding-top: 50px;
	padding-bottom: 100px;
	font-size: 13px;
	line-height: 1.85;
	color: #9a9a9e;
	background-color: #f8f8f8;
	font-weight: 300;
	li {
		display: block;
		position: relative;
		float: left;
		margin-right: 17px;
	}
	li:after {
		display: block;
		position: absolute;
		content: '';
		top: 50%;
		right: -8px;
		transform: translateY(-50%);
		width: 1px;
		height: 10px;
		background: #9a9a9e;
	}
	li:after:last-child {
		display: none;
	}
	span {
		float: right;
	}
	@media ${(props) => props.theme.mobile} {
		background-color: #f8f8f8;
		padding: 50px 20px 53px 20px;
		font-size: 12px;
		ul {
			display: inline-block;
		}
		li {
			margin-right: 9px;
			line-height: 30px;
		}
		li:after {
			right: -5px;
		}
		.t {
			margin-bottom: 20px;
			font-size: 14px;
			font-weight: normal;
			line-height: 1.67;
			text-align: left;
			color: #666;
		}
		span {
			display: block;
			float: left;
		}
	}
	@media (max-width: 330px) {
		padding: 50px 15px 53px 15px;
		font-size: 11px;
		li {
			margin-right: 7px;
		}
		li:after {
			right: -4px;
		}
		p {
			font-size: 12px;
		}
	}
`;

export const OpenChatLink = styled.a`
	display: block;
	width: 200px;
	height: 50px;
	line-height: 50px;
	border-radius: 200px;
	background-color: #444;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	transition: 0.5s;
	text-align: center;
	text-decoration: none;
`;

export const ChatbotIcon = styled.a`
	display: block;
	position: fixed;
	bottom: 70px;
	right: 70px;
	.icon {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 12px 28px rgba(0, 0, 0, 0.2);
		transition: 0.3s;
		width: 64px;
		height: 64px;
		background-color: #ff5c75;
		border-radius: 50%;
		text-align: center;
		position: relative;
		> div {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 26px;
			height: 24px;
			background-image: url(/img/chatbot.png);
			background-size: 26px 24px;
			> i {
				display: inline-block;
				position: relative;
				top: 9px;
				vertical-align: top;
				line-height: 24px;
				width: 2px;
				height: 2px;
				margin: 0px 1px;
				background: #fff;
				border-radius: 50%;
				animation: circlelight 1.5s infinite 0s alternate;
				:nth-child(2) {
					animation: circlelight 1.5s infinite 0.5s alternate;
				}
				:nth-child(3) {
					animation: circlelight 1.5s infinite 1.5s alternate;
				}
			}
		}
		:hover {
			opacity: 0.8;
		}
	}
	span {
		display: block;
		color: #ff5c75;
		font-weight: bold;
		width: 100%;
		margin-top: 3px;
		font-size: 12px;
		text-align: center;
	}
	@keyframes circlelight {
		from {
			opacity: 1;
		}
		to {
			opacity: 0.2;
		}
	}
	@media ${(props) => props.theme.mobile} {
		right: 20px;
		bottom: 20px;
		span {
			display: none;
		}
	}
`;
