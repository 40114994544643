import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 50px;
    @media ${props => props.theme.mobile}{
        padding: 0 20px;
    }
`;

export const StorySingleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media ${props => props.theme.mobile}{
        flex-direction: column-reverse;
    }
`;

export const StoryImageSection = styled.div`
    width: 66.577896138%;
    margin-right: 20px;
    @media ${props => props.theme.mobile}{
        width: 100%;
        margin: 0 auto;
    }
`;

export const StoryImage = styled.div`
    width: 100%;
    height: auto;
    background-image: url(${props => props.url ? props.url : ''});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    p {
        font-size: 16px;
        color:#000;
        line-height:1.8;
    }
    img {
        width: 100%;
        max-width: 100%;
    }
`;

export const StoryDetail = styled.div`
    position: sticky;
    right: 0px;
    top: 170px;
    width: 452px;
    height: 100%;
    border-top: 1px solid #ebebeb;
    padding-top: 50px;
    @media ${props => props.theme.mobile}{
        width: 100%;
        margin: 0 auto;
        padding-top:20px;
        border-top:0px;
        position:static;
    }
`;

export const StoryBadge = styled.div`
    display: inline-block;
    width: auto;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #E55F74;
    box-sizing: border-box;
    border-radius: 300px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #E55F74;
    text-align: center;
    padding: 0 12px;
    transition: 0.2s;
    margin-bottom: 7px;
    :hover {
        background: #E55F74;
        color: #fff;
    }
    @media ${props => props.theme.mobile}{
        height: 30px;
        font-size: 14px;
        line-height: 28px;
    }
`;

export const StoryDate = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
    width: auto;
    font-size: 13px;
    line-height: 19px;
    color: #9A9A9E;
    @media ${props => props.theme.mobile}{
        font-size: 14px;
        line-height: 20px;
        right:20px;
    }
`;

export const StoryTitle = styled.div`
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    color: #111;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 24px;
    @media ${props => props.theme.mobile}{
        font-size: 24px;
        line-height: 35px;
        margin-top:10px;
        margin-bottom:20px;
    }
`;

export const StorySubTitle = styled.div`
    font-size: 15px;
    line-height: 22px;
    color: #111;
    margin-bottom: 50px;
    @media ${props => props.theme.mobile}{
        font-size: 16px;
        line-height: 23px;
    }
`;

export const Profile = styled.div`
    position: relative;
    padding: 20px 0;
    border-top: 1px solid #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
    margin-bottom: 25px;
    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const Avartar = styled.div`
    display: inline-block;
    width: 60px;
    height: 60px;
    background-image: url(/img/avatar.svg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
`;

export const Relative = styled.div`
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 30px;
    margin-bottom: 50px;
`;

export const RelativeTitle = styled.div`
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    color: #111;
    padding-bottom: 5px;
`;

export const ProductList = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-top: 10px;
`;

export const ProductImage = styled.div`
    width: 80px;
    height: 80px;
    background-image: url(/img/avatar.svg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle;
`;

export const ProductDetail = styled.div`
    display: table;
    width: 55%;
    height: 80px;
    text-align: left;
    span {
        display :table-cell;
        vertical-align: middle;  
        font-size: 13px;
        line-height: 19px;
        color: #111;    
    }
`;

export const ProductPrice = styled.div`
    display: table;
    height: 80px;
    span {
        display :table-cell;
        vertical-align: middle;      
        font-weight: bold;
        font-size: 13px;
        line-height: 19px;
        color: #111111;     
    }
`;

export const StoryButton = styled(Link)`
    display: inline-block;
    width: 100%;
    height: 56px;
    text-align: center;
    line-height: 56px;
    background: #F5F5F5;
    border-radius: 300px;
    font-size: 15px;
    color: #111;
    @media ${props=>props.theme.mobile} {
        margin-bottom:50px;
    }
`;