import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 200px;
	height: 108px;
	position: relative;
	margin: 0 auto;
	.button {
		position: absolute;
		width: 50px;
		height: 50px;
		background-color: #ffffff;
		border-radius: 50%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 24px auto;
		cursor: pointer;
	}
	.disable {
		border: 1px solid #dfdfdf;
		cursor: default;
	}
	@media ${(props) => props.theme.mobile} {
		transform: scale(0.9, 0.9);
	}
`;

export const Prev = styled.div`
	left: 40px;
	border: 1px solid #484848;
	background-image: url(/img/arrow-prev.svg);
`;

export const Next = styled.div`
	right: 40px;
	border: 1px solid #484848;
	background-image: url('/img/arrow-next.svg');
`;

export const Bar = styled.div`
	position: absolute;
	top: 80px;
	width: 100%;
	height: 2px;
	background: #dfdfdf;
	border-radius: 10px;
	:after {
		display: block;
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: ${(props) => (props.barWidth ? props.barWidth + '%' : '100%')};
		height: 2px;
		background: #969696;
		border-radius: 10px;
	}
`;

export const PageNum = styled.div`
	position: absolute;
	display: flex;
	justify-content: space-between;
	top: 92px;
	left: 50%;
	transform: translateX(-50%);
	width: 60px;
	text-align: center;
	font-size: 11px;
	line-height: 16px;
	color: #111;
	div {
		width: 20px;
	}
`;
