import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    @media ${props => props.theme.mobile}{
        padding: 0 20px;
    }
`;

export const NoticeTable = styled.ul`
    margin-bottom: 50px; 
`;

export const NoticeList = styled.li`
    display: flex;
    padding-top: 31px;
    padding-bottom: 32px;
    border-bottom: 1px solid #ebebeb;
    @media ${props => props.theme.mobile}{
        padding: 24px 0;
    }
`;

export const LeftArea = styled.div`
    width: 63px;
`;

export const Emphasis = styled.div`
    background-color: #fff;
    border: 1px solid #E55F74;
    border-radius: 2px;
    font-size: 13px;
    height: 25px;
    line-height: 23px;
    color: #E55F74;
    padding: 0px 8px;
    margin-right: 20px;
    text-align: center;
    @media ${props => props.theme.mobile}{
        min-width:50px;
        font-size: 12px;
        line-height: 22px;
        font-weight: 500;
        color: #e55f74;
    }
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #111;
    @media ${props => props.theme.mobile}{
        font-size: 16px;
        line-height: 23px;  
    }
`;

export const Date = styled.div`
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #9A9A9E;
    margin-left: auto;
    @media ${props => props.theme.mobile}{
        display: none;
    }
`;

export const MoreButtonWrapper = styled.div`
    padding: 0 20px;
    padding-bottom: 79px;
    border-bottom: 1px solid #ebebeb;
`;

export const MoreButton = styled.div`
    width: 100%;
    height: 60px;
    font-size: 18px;
    font-weight: 500;
    line-height: 58px;
    border-radius: 500px;
    border: solid 1px #999;
    text-align: center;
`;

export const LoadingRoundWrapper = styled.div`
    padding: 24px 0 0 10px;
`;