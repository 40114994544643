import React from 'react';
import { 
    SidePopupWrapper,
    Overlay,
    SidePopupInner,
    SidePopupClose,
 } from './styled';

const closeIcon = (<svg xmlns="close.svg" width="21" height="21" viewBox="0 0 18 18"><path d="M13 5c.364 0 .659.295.659.659l-.001 7.682h7.683c.364 0 .659.295.659.659 0 .364-.295.659-.659.659l-7.683-.001v7.683c0 .364-.294.659-.658.659-.364 0-.659-.295-.659-.659v-7.683H4.659C4.295 14.659 4 14.365 4 14c0-.364.295-.659.659-.659h7.682V5.659c0-.364.295-.659.659-.659z" transform="translate(-29 -18) translate(23 11) rotate(45 13 14)"/></svg>)

const SidePopup = (props) => {
    return (
        <SidePopupWrapper show={props.show}>
            <Overlay show={props.show} onClick={props.onClose} />
            <SidePopupInner show={props.show}>
                <SidePopupClose onClick={props.onClose} src={process.env.PUBLIC_URL + "/img/close_button.svg"} />
                {props.children}
            </SidePopupInner>
        </SidePopupWrapper>
    );
};

export default SidePopup;