const SET_LOADING = "global/SET_LOADING";
const TOGGLE_QNA = "global/TOGGLE_QNA";
const TOGGLE_CHATBOT = "global/TOGGLE_CHATBOT";
const SET_RAF = "global/SET_RAF";

export const SetLoading = (loading) => ({
    type:SET_LOADING,
    payload:{
        loading
    }
})
export const ToggleQna = (toggle) => ({
    type:TOGGLE_QNA,
    payload:{
        toggle
    }
})
export const ToggleChatbot = (toggle) => ({
    type:TOGGLE_CHATBOT,
    payload:{
        toggle
    }
})
export const SetRaf = (raf) => ({
    type:SET_RAF,
    payload:{
        raf
    }
})


const initState = {
    loading: 0,
    qna_popup: 0,
    chatbot_popup: 0,
    raf:0 // 추천코드
}

const GlobalReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case TOGGLE_QNA:
            return {
                ...state,
                qna_popup: action.payload.toggle
            }
        case TOGGLE_CHATBOT:
            return {
                ...state,
                chatbot_popup: !state.chatbot_popup
            }
        case SET_RAF:
            return {
                ...state,
                raf: action.payload.raf
            }
        default:
            return state;
    }
}
export default GlobalReducer;