import axios from './defaultClient';

const nameSpace = '/ep/v1/auth';

export const isLogined = async () =>{
    return await axios.post('/jwt-auth/v1/token/validate');
}

export const SignIn = async (args) => {
    return await axios.post( nameSpace + '/',{
        user_login: args.user_login,
        user_pw: args.user_pw
    })
}
export const DeleteUser = async (args) => {
    return await axios.delete( nameSpace + '/',{
        params: {password: args.password}
    })
}

export const SignUp = async (args) => {
    return await axios.post( nameSpace + '/register',{
        user_login: args.user_login,
        user_name: args.user_name,
        user_phone: args.user_phone,
        user_pw: args.user_pw,
        raf: args.raf
    })
}

export const RePassord = async (args) => {
    return await axios.post( nameSpace + '/forgot',{
        user_login: args.user_login
    })
}