import React from 'react';
import { StyledButton } from './styeld'

const Button = ({type, color, width, height, to, href, target, onClick, children, className}) => {
    return (
        <StyledButton type={type} className={className} color={color} onClick={onClick} width={width} height={height} to={to} href={href} target={target}>
            {children}
        </StyledButton>
    )
}

export default Button;

// type 입력 안하면 동그란 버튼, type="normal" 하면 네모난 버튼 
// color 입력 안하면 분홍색배경, color="secondary" 하면 배경 회색, color="black" 하면 배경 #444 색상

Button.defaultProps = {
    backgroundColor: null,
    type: 'primary',
    color: 'primary',
};