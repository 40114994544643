import React, { useState, useEffect } from 'react';
import MainContainer from '../../../components/MainContainer';
import { MediaQuery, mobileQuery } from '../../../components/Grid';
import MainList from '../MainList';
import { Getme, GetPoint } from '../../../api/me';
import {
    Wrapper,
    Container,
} from '../styled';
import {
    PointWrapper,
    PointUse,
    PointItem,
    ItemTitle,
    PointButton,
} from './styled';

const Point = () => {

    const isMobile = MediaQuery(mobileQuery);
    const [me, set_me] = useState("");
    const [point, set_point] = useState("");
    const [empty, set_empty] = useState(0);

	const replaceAt = function(input){
		if (input.length >= 23) {
			return input.substr(0, 14) + '**' + input.substr(16);
		} else {
			return input.substr(0, 14) + '*' + input.substr(15);
		}
   }
   
   
    useEffect(() => {
        if(!me){
            Getme({
            })
            .then(response => {
                if(response.data.code == 200){
                    set_me(response.data.body);
                }
            })
            .catch(error => console.log(error));
        } 
        if(!point){
            GetPoint({
                paged: 1,
                posts_per_page: 5
            })
            .then(response => {
                if(response.data.code == 200){
                    set_point(response.data.body.logs);
                }
            })
            .catch(error => console.log(error));
        } 
    });

    return (
        <>
            <MainContainer type="small">
                <Wrapper>
                    <Container>
                        <MainList />
                        <PointWrapper>
                            <PointUse>
                                <p>
                                    사용 가능한 포인트
                                    <span>최종 적립일: {me.last_earn_point ? me.last_earn_point : ''}</span>
                                </p>
                                <p>{me.point ? parseInt(me.point).toLocaleString('en') : '0'}P</p>
                            </PointUse>
                            <PointItem>
                                <ItemTitle>
                                    <h3>포인트 내역</h3>
                                </ItemTitle>
                                <table>
                                    {isMobile ? '' : (
                                        <thead>
                                            <tr>
                                                <th>날짜</th>
                                                <th>상세 내역</th>
                                                <th>포인트</th>
                                                <th>잔액</th>
                                            </tr>
                                        </thead>
                                    )}
                                    <tbody>
                                        {point ? point.map((item, key) => {
                                            return (
                                                <tr>
                                                    <td className="date">{item.date ? item.date : ''}</td>
                                                    <td><p dangerouslySetInnerHTML={{
                                                        __html: item.memo ? (item.memo.includes('친구 초대 포인트 지급') ? replaceAt(item.memo) : item.memo) : ''
                                                    }}/></td>
                                                    <td className={item.type && item.type == 'deduct' ? 'minus' : 'plus'}>
                                                        {item.point ? item.point : ''}P
                                                    </td>
                                                    <td>
                                                        {item.balance ? item.balance : ''}P
                                                    </td>
                                                </tr>
                                            )
                                        }) : <>
                                                {empty ? '포인트 사용내역이 없습니다.' : ''}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </PointItem>
                        </PointWrapper>

                        <PointButton>
                            <button>더보기</button>
                        </PointButton>
                    </Container>
                </Wrapper>
            </MainContainer>
        </>
    );
};

export default Point;