import React, { useState, useEffect }  from 'react';
import {useSelector} from 'react-redux';
import MainContainer from '../../../components/MainContainer';
import Joi from 'joi-browser';
import {toast} from 'react-toastify';
import MainList from '../MainList';
import { Getme, Postme } from '../../../api/me';
import Button from '../../../components/Button';
import {
    AccountWrapper,
    IdAccount,
    IdTitle,
    IdInput,
    PwTitle,
    PwInput,
} from './styled';
import {
    Wrapper,
    Container,
} from '../styled';

const Account = () => {
    const me = useSelector(state=>state.me.user_data);

    const [user_name, set_user_name] = useState(me.user_name);
    const [user_email, set_user_email] = useState(me.user_email);
    const [user_phone, set_user_phone] = useState(me.user_phone);
    const [current_password, set_current_password] = useState('');
    const [new_password, set_new_password] = useState('');
    const [check_password, set_check_password] = useState('');

    const Submit = () => {
        const schema ={
            user_name: Joi.string()
                .required()
                .error(() => ({message: '이름을 입력해주세요.'})),
            user_phone: Joi.number()
                .required()
                .error(() => ({message: '휴대폰번호를 입력해주세요.'})),
        }
        const {error, value} = Joi.validate({
            user_name: user_name,
            user_phone: user_phone,
        }, schema);

        if(error){
            toast.error(error.details[0].message);
        } else {
            Postme({
                user_name: user_name,
                user_phone: user_phone,
                current_password: current_password,
                user_password: new_password,
                check_password: check_password
            })
            .then(response => {
                if(response.data.code == 200){
                    toast.success('계정정보가 변경되었습니다.')
                } else {
                    toast.error(response.data.body.message);
                }
            })
            .catch(error => console.log(error));
        }
    }

    return (
        <>
            <MainContainer type="small">
                <Wrapper>
                    <Container>
                        <MainList />
                        <AccountWrapper>
                            <IdTitle>
                                <h3>계정정보</h3>
                            </IdTitle>
                            <IdAccount>
                                <p>이름</p>
                                <IdInput type="text" name="user_name" value={user_name  ? user_name : ''} onChange={(e) => set_user_name(e.target.value)} />
                            </IdAccount>
                            <IdAccount>
                                <p>이메일주소</p>
                                <IdInput type="text" name="user_email" value={user_email ? user_email : ''} readOnly />
                            </IdAccount>
                            <IdAccount>
                                <p>휴대폰번호</p>
                                <IdInput type="text" name="user_phone" value={user_phone ? user_phone : ''} onChange={(e) => set_user_phone(e.target.value)} />
                            </IdAccount>
                            <PwTitle>
                                <h3>비밀번호 변경</h3>
                            </PwTitle>
                            <PwInput type="password" name="current_password" onChange={(e) => set_current_password(e.target.value)} placeholder="현재 비밀번호" />
                            <PwInput type="password" name="new_password" placeholder="새 비밀번호" onChange={(e) => set_new_password(e.target.value)} />
                            <PwInput type="password" name="check_password" placeholder="새 비밀번호 확인" onChange={(e) => set_check_password(e.target.value)} />
                            <Button onClick={Submit} color="black">저장하기</Button>
                        </AccountWrapper>
                    </Container>
                </Wrapper>
            </MainContainer>
        </>
    );
};

export default Account;