import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;
	padding-bottom: 200px;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		padding-bottom: 50px;
	}
`;

export const CategoryTitle = styled.h3`
	margin-top: 100px;
	font-weight: bold;
	font-size: 30px;
	line-height: 43px;
	color: #111;
	margin-bottom: 30px;
	@media ${(props) => props.theme.mobile} {
		margin-top: 30px;
		margin-bottom: 20px;
		font-size: 24px;
		font-weight: bold;
		color: #111;
	}
`;

export const CategorySection = styled.div`
	margin-bottom: -20px;

	@media ${(props) => props.theme.mobile} {
		margin-bottom: 30px;
		a {
			div {
				width: 50%;
			}
		}
		label {
			font-size: 12px;
			font-weight: 500;
			text-align: left;
			padding-left: 10px;
		}
		input[type='radio']:checked + label {
			:before {
				width: 20px;
				height: 20px;
				right: 10px;
			}
		}
	}
`;
export const ChildCategorySection = styled.div`
	/* margin-bottom: -20px; */
	margin-top: 70px;
	a {
		display: inline-block;
		> div {
			width: auto !important;
		}
		padding: 10px 20px;
		margin-right: 10px;
		background: #f5f5f5;
		border-radius: 50px;
		transition: 0.6s;
		font-size: 14px;
		line-height: 14px;
		:hover {
			opacity: 0.6;
		}
	}
`;

export const ProductsWrapper = styled.div`
	width: calc(100% + 30px);
	height: 100%;
	margin: 0 -15px;
	margin-bottom: 50px;
	@media (max-width: 1200px) {
		width: 100%;
		margin: 0 0;
		margin-bottom: 50px;
	}
`;

export const ProductWrapper = styled.div`
	display: inline-block;
	width: 25%;
	height: 0;
	padding: 0 15px;
	padding-bottom: 36%;
	vertical-align: top;
	margin-top: 6.528%;
	border-bottom: 1px solid #ebebeb;
	@media (max-width: 1200px) {
		width: 33.3%;
		margin-top: 5.695%;
		padding-bottom: 53%;
	}
	@media ${(props) => props.theme.mobile} {
		padding: 0 10px;
		width: 50%;
		margin-top: 7.695%;
		padding-bottom: 64%;
	}
	@media (max-width: 400px) {
		border: none;
	}
	/* @media (max-width: 1200px) {
		border-bottom: none;
	} */
`;

export const Product = styled.div`
	width: 100%;
	height: 100%;
`;

export const ProductImage = styled(Link)`
	position: relative;
	display: block;
	width: 100%;
	max-width: 353px;
	height: 0;
	padding-bottom: 100%;
	background-color: #fff;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 70% auto;
	border: 1px solid #eee;
	margin: 0 auto;
	border-radius: 2px;

	.sold-out {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 69px;
		/* transform: translateY(-100%); */
		background-color: rgba(0, 0, 0, 0.3);
		color: #fff;
		font-family: 'Noto Sans KR';
		font-size: 18px;
		font-weight: 700;
		line-height: 67px;
		text-align: center;
	}

	@media ${(props) => props.theme.mobile} {
		padding-bottom: 84%;
		.sold-out {
			height: 40px;
			font-size: 13px;
			line-height: 38px;
		}
	}
`;

export const ProductDetail = styled.div`
	width: 100%;
	max-width: 353px;
	height: 0;
	padding: 20px 0;
	padding-bottom: 59.206798866%;
	margin: 0 auto;
	@media ${(props) => props.theme.mobile} {
		padding-top: 13px;
	}
`;

export const ProductType = styled.div`
	font-size: 13px;
	line-height: 19px;
	font-weight: 500;
	color: #111;
	margin-bottom: 5px;
	@media ${(props) => props.theme.mobile} {
		font-size: 12px;
		line-height: 15px;
	}
`;

export const ProductName = styled.div`
	font-size: 13px;
	line-height: 19px;
	color: #111;
	margin-bottom: 15px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media ${(props) => props.theme.mobile} {
		font-size: 13px;
		line-height: 16px;
		margin-bottom: 10px;
		height: auto;
	}
`;

export const ProductPrice = styled.div`
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #111;
	@media ${(props) => props.theme.mobile} {
		font-size: 14px;
		line-height: 18px;
		word-break: keep-all;
	}
	@media (max-width: 400px) {
		font-size: 13px;
	}
	@media (max-width: 370px) {
		font-size: 12px;
	}
`;
