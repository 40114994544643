import React from 'react';
import MainContainer from '../../../../components/MainContainer';
import {Link} from 'react-router-dom';
import {
    Wrapper,
    Container,
    DeliveryTitle,
    ListWrapper,
    DeliveryList,
    ListBox,
    Content,
} from './styled';
import Button from '../../../../components/Button';

const OrderDelivery = () => {
    return (
        <>
            <MainContainer type="small">
                <Wrapper>
                    <Container>
                        <DeliveryTitle>
                            <h3>배송조회</h3>
                        </DeliveryTitle>
                        <ListWrapper>
                            <DeliveryList>
                                <ListBox>
                                    <Content>
                                        <div className="flex">
                                            <p>배송조회</p>
                                            <span>우체국 EMS - LP000798007IN</span>
                                        </div>
                                        <Button>조회하기</Button>
                                    </Content>
                                </ListBox>
                                <ListBox>
                                    <Content>
                                        <div className="flex">
                                            <p>배송조회</p>
                                            <span>우체국 EMS - LP000798007IN</span>
                                        </div>
                                        <Button>조회하기</Button>
                                    </Content>
                                </ListBox>
                                <ListBox>
                                    <Content>
                                        <div className="flex">
                                            <p>배송조회</p>
                                            <span>우체국 EMS - LP000798007IN</span>
                                        </div>
                                        <Button>조회하기</Button>
                                    </Content>
                                </ListBox>
                                <ListBox>
                                    <Content>
                                        <div className="flex">
                                            <p>배송조회</p>
                                            <span>우체국 EMS - LP000798007IN</span>
                                        </div>
                                        <Button>조회하기</Button>
                                    </Content>
                                </ListBox>
                                <ListBox>
                                    <Content>
                                        <div className="flex">
                                            <p>배송조회</p>
                                            <span>우체국 EMS - LP000798007IN</span>
                                        </div>
                                        <Button>조회하기</Button>
                                    </Content>
                                </ListBox>
                                <ListBox>
                                    <Content>
                                        <div className="flex">
                                            <p>배송조회</p>
                                            <span>우체국 EMS - LP000798007IN</span>
                                        </div>
                                        <Button>조회하기</Button>
                                    </Content>
                                </ListBox>
                            </DeliveryList>
                        </ListWrapper>
                    </Container>
                </Wrapper>
            </MainContainer>
        </>
    );
};

export default OrderDelivery;