import React, { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import { SetLoading, ToggleChatbot } from '../../../store/global';
import {toast} from 'react-toastify';
import Joi from 'joi-browser';
import { ChatbotPost } from '../../../api/chatbot';
import {
    List,
    Submit,
    ShippingInfo,
    Notice
} from '../styled';

const OrderViewListTemplate = (props) => {
    const Dispatch = useDispatch();

    return (
        <>
            <ShippingInfo>
                <img src="/img/carbon_delivery-parcel.png" alt="" width="40px" height="auto"/>
                <span>{props.shipping_days ? '대략 '+props.shipping_days+' 도착 예정입니다!' : '아직 배송정보가 등록되지 않았습니다.' }</span>
            </ShippingInfo>
            <Notice>
                코로나 영향으로 실제 배송일과 다를 수 있습니다.
            </Notice>
            <List type={'list'}>
                {props.actions ? props.actions.map((action, key) => (
                    <li onClick={e=>{props.DoAction(action.action_ID,[]); props.set_subtitle(action.label);}}>
                        {action.icon ? (<img src={action.icon}/>) : ''}
                        {action.label}
                    </li>
                )) :''}
            </List>
            {props.back ? (<Submit onClick={e=>props.DoAction(props.back,[])}>뒤로가기</Submit>) : ''}
        </>
    );
};

export default OrderViewListTemplate;