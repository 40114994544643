import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 0 50px;
    padding-top: 100px;
    @media ${props=>props.theme.mobile} {
        padding: 0 20px;
        padding-top: 80px;
    }
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #111111;
    margin-bottom: 30px;
`;

export const Summary = styled.div`
    font-size: 13px;
    line-height: 26px;
    letter-spacing: -0.03em;
    color: #111111;
    margin-bottom: 93px;
    h2 {
        margin-top: 50px;
        margin-bottom: 10px;
        font-size: 16px;
    }
    h1 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 16px;
    }
    strong {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 16px;
        display: block;
    }
    img {
        max-width: 100%;
        height: auto;
    }
`;

export const DescriptionMenu = styled.ul`
    li {
        position: relative;
        padding: 30px 0;
        border-bottom: 1px solid #ebebeb;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        color: #111111;
        img {
            position: absolute;
            right: 0;
        }
    }
    li:first-child {
        border-top: 1px solid #ebebeb;
    }
`;

export const ArrowDown = styled.img`
    ${props => props.isopen ? `
        transform: rotate(180deg);
    ` : ``};
`;

export const Explain = styled.div`
    ${props => props.isopen ? `display: block` : `display: none`};
    padding-top: 30px;
    font-size: 13px;
    line-height: 2;
    font-weight: normal;
    letter-spacing: -0.39px;
    color: #111;
`;