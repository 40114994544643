import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainContainer from '../../components/MainContainer';
import RadioButton from '../../components/CheckBox';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import { SktonPoint } from '../../components/Skeleton';
import { ProductGetPoint } from '../../api/product';
import { Link } from 'react-router-dom';
import {
	Wrapper,
	TitleSection,
	Title,
	HavePoint,
	CategorySection,
	ProductsSection,
	ProductsWrapper,
	Product,
	ProductImage,
	ProductDetail,
	ProductType,
	ProductName,
	ProductPrice,
	BasketBtn,
} from './styled';

const PointShop = () => {
	const me = useSelector((state) => state.me.user_data);
	const [point_product, set_point_product] = useState();
	const [category_ID, set_category_ID] = useState(791);
	const [tmp, set_tmp] = useState(0);

	const [current_paged, set_current_paged] = useState(0);
	const [total, set_total] = useState(0);
	const [paged, set_paged] = useState(1);
	const posts_per_page = 8;

	const selectCate = (e) => {
		set_category_ID(e.target.dataset.id);
	};

	const onPaging = (page) => {
		console.log(page);
		set_paged(page);
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		if (tmp !== category_ID || current_paged != paged) {
			if (tmp !== category_ID) {
				set_paged(1);
			}
			set_point_product('');
			set_tmp(category_ID);
			ProductGetPoint({
				category_ID: category_ID,
				paged: paged,
				posts_per_page: posts_per_page,
			})
				.then((response) => {
					if (response.data.code == 200) {
						set_point_product(response.data.body.products);
						set_current_paged(response.data.body.paged);
						set_total(response.data.body.total);
						console.log(response.data.body.products);
					}
				})
				.catch((error) => console.log(error));
		}
	});
	return (
		<MainContainer>
			<Wrapper>
				<TitleSection>
					<Title>포인트상점</Title>
					<HavePoint>
						<Link className="btn" to="/reward">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="22"
								height="22"
								viewBox="0 0 22 22"
								fill="none"
							>
								<g clip-path="url(#clip0_2531_4082)">
									<path
										d="M11 5L15 11L20 7L18 17H4L2 7L7 11L11 5Z"
										stroke="#E55F74"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</g>
								<defs>
									<clipPath id="clip0_2531_4082">
										<rect width="22" height="22" fill="white" />
									</clipPath>
								</defs>
							</svg>
							리워드
						</Link>
						{me ? me.user_name : ''}님이 보유 중인 포인트
						<span> {me ? parseInt(me.point).toLocaleString('en') : ''}P</span>
					</HavePoint>
				</TitleSection>
				<CategorySection>
					<RadioButton
						value="전체보기"
						name="category"
						id="791"
						dataID="791"
						checked={category_ID == 791 && 1}
						onClick={selectCate}
					>
						전체보기
					</RadioButton>
					<RadioButton
						value="담배"
						name="category"
						id="795"
						dataID="795"
						checked={category_ID == 795 && 1}
						onClick={selectCate}
					>
						담배
					</RadioButton>
					<RadioButton
						value="캐쉬백+미녹시딜"
						name="category"
						id="798"
						dataID="798"
						checked={category_ID == 798 && 1}
						onClick={selectCate}
					>
						캐쉬백
					</RadioButton>
					<RadioButton
						value="사케"
						name="category"
						id="792"
						dataID="792"
						checked={category_ID == 792 && 1}
						onClick={selectCate}
					>
						사케
					</RadioButton>
					<RadioButton
						value="식품"
						name="category"
						id="797"
						dataID="797"
						checked={category_ID == 797 && 1}
						onClick={selectCate}
					>
						식품
					</RadioButton>
					<RadioButton
						value="건강보조"
						name="category"
						id="794"
						dataID="794"
						checked={category_ID == 794 && 1}
						onClick={selectCate}
					>
						건강보조
					</RadioButton>
					<RadioButton
						value="브랜드"
						name="category"
						id="796"
						dataID="796"
						checked={category_ID == 796 && 1}
						onClick={selectCate}
					>
						브랜드
					</RadioButton>
				</CategorySection>
				<ProductsSection>
					<ProductsWrapper className="claerfix">
						{point_product ? (
							point_product.map((item, key) => {
								return (
									<Product is_sold_out={item.is_sold_out}>
										<ProductImage
											to={
												item.is_sold_out
													? false
													: '/pointshop/' + (item.ID ? item.ID : '')
											}
											url={item.thumbnail ? item.thumbnail : ''}
										/>
										<ProductDetail>
											<ProductType>
												{item.cate_name ? item.cate_name : ''}
											</ProductType>
											<ProductName
												to={
													item.is_sold_out
														? false
														: '/pointshop/' + (item.ID ? item.ID : '')
												}
											>
												{item.title ? item.title : ''}
											</ProductName>
											<ProductPrice>
												{item.price ? item.price : ''}
											</ProductPrice>
											{item.is_sold_out ? (
												<Button height="40px">품절</Button>
											) : (
												''
											)}
										</ProductDetail>
									</Product>
								);
							})
						) : (
							<>
								<SktonPoint />
								<SktonPoint />
								<SktonPoint />
								<SktonPoint />
								<SktonPoint />
								<SktonPoint />
								<SktonPoint />
								<SktonPoint />
							</>
						)}
					</ProductsWrapper>
				</ProductsSection>
				<Pagination
					onPaging={onPaging}
					total={total}
					current={current_paged}
					posts_per_page={posts_per_page}
					max={
						total >= posts_per_page ? parseInt(total / posts_per_page) + 1 : 1
					}
				/>
			</Wrapper>
		</MainContainer>
	);
};

export default PointShop;
