import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 100px 40px 0px 40px;
    @media ${props => props.theme.mobile}{
        padding: 94px 20px 50px 20px;
    }
`;

export const Title = styled.div`
    h2{
        font-size: 20px;
        font-weight: bold;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        h2{
            font-size: 18px;
        }
    }
`;

export const SubTitle = styled.div`
    margin-top: 20px;
    h3{
        font-size: 15px;
        font-weight: normal;
        line-height: 1.87;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        h3{
            font-size: 13px;
        }
    }
`;

export const Information = styled.div`
    border-bottom: 1px solid #ebebeb;
    margin-top: 20px;
    padding-bottom: 29px;
    font-size: 15px;
    line-height: 1.87;
    color: #111111;
    ul{
        font-weight: normal;
    }
    p{
        margin-top: 20px;
    }
    a{
        text-decoration: underline;
    }
    @media ${props => props.theme.mobile}{
        font-size: 13px;
    }
`;

export const PwTitle = styled.div`
    margin-top: 30px;
    font-size: 15px;
    line-height: 1.73;
    color: #111111;
    h3{
        font-weight: 500;
    }
    @media ${props => props.theme.mobile}{
        font-size: 13px;
    }
`;

export const PassWord = styled.div`
    position: relative;
    img{
        position: absolute;
        top: 0;
        right: 0px;
        width: 20px;
        height: 20px;
    }
    @media ${props => props.theme.mobile}{
    }
`;

export const PwInput = styled.input`
    position: relative;
    width: 100%;
    display: block;
    margin: 30px 0px 50px 0px;
    padding-bottom: 9px;
    border: none;
    border-bottom: 1px solid #999;
    font-size: 15px;
    color: #111;
    ::placeholder{
        color: #9a9a9e;
    }
    @media ${props => props.theme.mobile}{
        font-size: 13px;
    }
`;

export const ConfirmButton = styled.div`
    @media ${props => props.theme.mobile}{
        a{
            line-height: 46px;
            height: 46px; 
            font-size: 14px;
        }
    }
`;