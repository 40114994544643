import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	padding-top: 100px;
	padding-bottom: 100px;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
`;

export const Title = styled.div`
	font-family: 'Roboto';
	font-weight: bold;
	font-size: 30px;
	line-height: 35px;
	color: #111;
	margin-bottom: 30px;
	@media ${(props) => props.theme.mobile} {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 29px;
	}
`;

export const CategoryWrapper = styled.div`
	width: 100vw;
	height: 130px;
	max-width: 1502px;
	overflow-x: auto;
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	@media ${(props) => props.theme.mobile} {
		height: 80px;
		margin-bottom: 10px;
	}
`;

export const Category = styled.ul`
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	li {
		position: relative;
		width: 100%;
		min-width: 112px;
		height: 100%;
	}
	a:hover,
	.active {
		path {
			fill: #e55f74;
		}
		span {
			color: #e55f74;
		}
		.all {
			path {
				fill: none;
				stroke: #e55f74;
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		li {
			min-width: 90px;
		}
	}
`;

export const CategoryLogo = styled.a`
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 50%;
	height: 55%;
	transition: 0.2s linear;
	cursor: pointer;
	svg {
		position: absolute;
		top: 13px;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
		path {
			transition: 0.2s linear;
		}
	}
	@media ${(props) => props.theme.mobile} {
		top: 15px;
		width: 50%;
		height: 63%;
		svg {
			top: 0px;
			width: 30px;
			height: 30px;
		}
	}
`;

export const CategoryName = styled.span`
	position: absolute;
	top: 60px;
	left: 50%;
	transform: translateX(-50%);
	width: max-content;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #111;
	transition: 0.2s linear;
	@media ${(props) => props.theme.mobile} {
		font-size: 12px;
		line-height: 14px;
		font-weight: 500;
		top: auto;
		bottom: 0;
	}
`;

export const ProductsWrapper = styled.div`
	width: calc(100% + 30px);
	height: 100%;
	margin: 0 -15px;
	margin-bottom: 50px;
	@media (max-width: 1200px) {
		width: 100%;
		margin: 0 0;
		margin-bottom: 50px;
	}
`;

export const ProductWrapper = styled.div`
	display: inline-block;
	width: 25%;
	height: 0;
	padding: 0 15px;
	padding-bottom: 36%;
	vertical-align: top;
	margin-top: 6.528%;
	border-bottom: 1px solid #ebebeb;
	@media (max-width: 1200px) {
		width: 33.3%;
		margin-top: 5.695%;
		padding-bottom: 53%;
	}
	@media ${(props) => props.theme.mobile} {
		padding: 0 10px;
		width: 50%;
		margin-top: 7.695%;
		padding-bottom: 64%;
	}
`;

export const Product = styled.div`
	width: 100%;
	height: 100%;
`;

export const ProductImage = styled(Link)`
	position: relative;
	display: block;
	width: 100%;
	max-width: 353px;
	height: 0;
	padding-bottom: 100%;
	background-color: #fff;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 70% auto;
	border: 1px solid #eee;
	margin: 0 auto;
	border-radius: 2px;
	.sold-out {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 69px;
		/* transform: translateY(-100%); */
		background-color: rgba(0, 0, 0, 0.3);
		color: #fff;
		font-family: 'Noto Sans KR';
		font-size: 18px;
		font-weight: 700;
		line-height: 67px;
		text-align: center;
	}
	@media ${(props) => props.theme.mobile} {
		padding-bottom: 84%;
		.sold-out {
			height: 40px;
			font-size: 13px;
			line-height: 38px;
		}
	}
`;

export const ProductDetail = styled.div`
	width: 100%;
	max-width: 353px;
	height: 0;
	padding: 20px 0;
	padding-bottom: 59.206798866%;
	margin: 0 auto;
	@media ${(props) => props.theme.mobile} {
		padding-top: 13px;
	}
`;

export const ProductType = styled.div`
	font-size: 13px;
	line-height: 19px;
	font-weight: 500;
	color: #111;
	margin-bottom: 5px;
	@media ${(props) => props.theme.mobile} {
		font-size: 12px;
		line-height: 15px;
	}
`;

export const ProductName = styled.div`
	font-size: 13px;
	line-height: 19px;
	color: #111;
	margin-bottom: 15px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media ${(props) => props.theme.mobile} {
		font-size: 13px;
		line-height: 16px;
		margin-bottom: 10px;
		height: auto;
	}
`;

export const ProductPrice = styled.div`
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #111;
	@media ${(props) => props.theme.mobile} {
		font-size: 15px;
		line-height: 18px;
	}
`;

export const Label = styled.div`
	position: relative;
	left: 20px;
	width: 25px;
	height: 35px;
	background-image: url(/img/label.png);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	letter-spacing: -0.03em;
	color: #fff;
	span {
		position: absolute;
		top: 4px;
		left: 50%;
		transform: translateX(-47%);
	}
	@media ${(props) => props.theme.mobile} {
		left: 10px;
		width: 20px;
		span {
			top: 2px;
		}
	}
`;

export const MobileBasket = styled.div`
	position: absolute;
	top: 55px;
	right: 0;
	width: 48px;
	height: 48px;
	background-color: #e55f74;
	border-radius: 50%;
	background-image: url(/img/basket_white.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 26px 26px;
	font-weight: bold;
`;
