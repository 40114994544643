import React from 'react';
import {Link} from 'react-router-dom';
import { 
    MainProductWrapper,
    MainProductDiv1,
    MainProductDiv2,
    MainProductDiv3,
    MainProductDiv4,
    MainReviewWrapper,
    MainReviewDiv1,
    MainReviewDiv2,
    MainReviewDiv3,
    MainReviewDiv4,
    MainReviewDiv5,
    MainReviewDiv6,
    SkeletonEventContent,
    ContentImg,
    Product,
    ProductDetails,
    ProductButton,
    SeeMore,
    OrderCancellation,
    SkeletonSearchDiv,
    SkeletonSearchImage,
    SkeletonSearchDetail,
    SkeletonSearchType,
    SkeletonSearchName,
    SkeletonSearchPrice,
    SktonHomeStoryTipCard,
    SktonHomeStoryCardImage,
    SktonHomeStoryCardContent,
    SktonHomeStoryCardArrow,
    SktonProductsProductWrapper,
    SktonProductsProduct,
    SktonProductsProductImage,
    SktonProductsProductDetail,
    SktonProductsProductType,
    SktonProductsProductName,
    SktonProductsProductPrice,
    SktonProductsLabel,
    SktonStoryCard,
    SktonCardImage,
    SktonCardBadge,
    SktonCardTitle,
    SktonCardTag,
    SktonStoryCardTag,
    SktonMoreEventWrapper,
    SktonEventMoreEventContent,
    SktonEventMoreContentImg,
    SktonUsersReview,
    SktonReviewUserImg,
    SktonReviewWriting,
    SktonReviewProductDetails,
    SktonReviewUserImage,
    SktonReviewUserItem,
    SktonReviewUserPrice,
    SktonPointProduct,
    SktonPointProductImage,
    SktonPointProductDetail,
    SktonPointProductType,
    SktonPointProductName,
    SktonPointProductPrice,
    SktonReviewImgStyle,
    SktonReviewBoxImgStyle
} from './styled';

// 메인페이지 지금 뜨는 인기상품, 메인페이지 구매자들이 뽑은 추천상품, 제품 싱글페이지 관련상품 스켈레톤 //

export const SkeletonMainProduct = () => {
    return (
        <MainProductWrapper>
            <MainProductDiv1 />
            <MainProductDiv2 />
            <MainProductDiv3 />
            <MainProductDiv4 />
        </MainProductWrapper>
    );
};

// 메인페이지 고객 리뷰 스켈리톤 //

export const SkeletonMainReview = () => {
    return (
        <MainReviewWrapper>
            <MainReviewDiv1 />
            <MainReviewDiv2>
                <div />
                <div />
            </MainReviewDiv2>
            <MainReviewDiv3>
                <MainReviewDiv4 />
                <MainReviewDiv5>
                    <div />
                    <div />
                </MainReviewDiv5>
                <MainReviewDiv6 />
            </MainReviewDiv3>
        </MainReviewWrapper>
    );
};


// 이벤트, 스토리 리스트 스켈레톤 (두개 모양이 같음)//

export const SkeletonEvent = () => {
    return (
        <>
            <SkeletonEventContent>
                <ContentImg url="../img/W_01.png" />
                <p>가</p>
                <p>나</p>
                <span>다</span>
            </SkeletonEventContent>
        </>
    );
};

// 주문 내역 스켈레톤 //

export const SkeletonOrder = () => {
    return (
        <>
            <Product url="/img/order_01.png" />
            <ProductDetails>
                <p>2021년 8월 20일</p>
                <strong>#21312 주문완료</strong>
                <small>핀페시아 300정+미녹시딜 5mg 300정</small>
            </ProductDetails>
            <ProductButton>
                <SeeMore>
                    <Link to="/mypage/orderlist/single">
                        <button>자세히보기</button>
                    </Link>
                </SeeMore>
                <OrderCancellation>
                    <Link to="/mypage/orderlist/Delivery">
                        <button>배송조회</button>
                    </Link>
                </OrderCancellation>
            </ProductButton>
        </>
    )
}

export const SkeletonSearch = () => {
    return (
        <SkeletonSearchDiv>
            <SkeletonSearchImage/>
            <SkeletonSearchDetail>
                <SkeletonSearchType />
                <SkeletonSearchName />
                <SkeletonSearchPrice />      
            </SkeletonSearchDetail>
        </SkeletonSearchDiv>
    )
}

//홈에 스토리
export const SktonHomeStory = () => {
    return(
        <SktonHomeStoryTipCard>
            <SktonHomeStoryCardImage />
            <SktonHomeStoryCardContent>
            </SktonHomeStoryCardContent>
            <SktonHomeStoryCardArrow />
        </SktonHomeStoryTipCard>
    )
}

//상품리스트
export const SktonProducts = () => {
    return(
        <SktonProductsProductWrapper>
            <SktonProductsProduct>
                <SktonProductsProductImage />
                <SktonProductsProductDetail>
                    <SktonProductsProductType />
                    <SktonProductsProductName />
                    <SktonProductsProductPrice />      
                </SktonProductsProductDetail>
            </SktonProductsProduct>
        </SktonProductsProductWrapper>
    )
}

export const SktonBest = () => {
    return(
    <   SktonProductsProductWrapper>
            <SktonProductsProduct>
                <SktonProductsProductImage>
                    <SktonProductsLabel />
                </SktonProductsProductImage>
                <SktonProductsProductDetail>
                    <SktonProductsProductType />
                    <SktonProductsProductName />
                    <SktonProductsProductPrice />      
                </SktonProductsProductDetail>
            </SktonProductsProduct>
        </SktonProductsProductWrapper>
    )
}

export const SktonEvent = () => {
    return(
        <SktonStoryCard>
            <SktonCardImage/>
            <SktonCardBadge />
            <SktonCardTitle />
            <SktonCardTag />
        </SktonStoryCard>
    )
}

export const SktonStory = () => {
    return(
        <SktonStoryCard>
            <SktonCardImage/>
            <SktonCardBadge />
            <SktonCardTitle />
            <SktonStoryCardTag />
        </SktonStoryCard>
    )
}

export const SktonMoreEvent = () => {
    return(
        <SktonMoreEventWrapper>
            <SktonEventMoreEventContent>
                <SktonEventMoreContentImg/>
                <p></p>
                <p></p>
                <span></span>
            </SktonEventMoreEventContent>
        </SktonMoreEventWrapper>
    )
}

export const SktonReview = () => {
    return(
        <SktonUsersReview>       
            <a>
                <SktonReviewUserImg/>
            </a>
            <SktonReviewWriting>
                <p/>
                <small/>
            </SktonReviewWriting>
                <SktonReviewProductDetails>
                    <SktonReviewUserImage />
                    <SktonReviewUserItem>
                        <p/>
                        <span/>
                    </SktonReviewUserItem>
                    <SktonReviewUserPrice>
                        <p/>
                    </SktonReviewUserPrice>
                </SktonReviewProductDetails>
        </SktonUsersReview>
    )
}

export const SktonPoint = () => {
    return(
        <SktonPointProduct>
            <SktonPointProductImage />
            <SktonPointProductDetail>
                <SktonPointProductType />
                <SktonPointProductName />
                <SktonPointProductPrice />
            </SktonPointProductDetail>
        </SktonPointProduct> 
    )
}

export const SktonReviewImg = () => {
    return(
        <SktonReviewImgStyle />
    )
}

export const SktonReviewBoxImg = () => {
    return(
        <SktonReviewBoxImgStyle />
    )
}