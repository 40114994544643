import React, { useState, useEffect } from 'react';
import { Link, useLocation, BrowserRouter as Router } from 'react-router-dom';
import MainContariner from '../../components/MainContainer';
import { MediaQuery, mobileQuery } from '../../components/Grid';
import Pagination from '../../components/Pagination';
import { EventGetPosts } from '../../api/event';
import { SktonEvent } from '../../components/Skeleton';
import {
  Wrapper,
  TopSection,
  Title,
  TabWrapper,
  Tab,
  EventCardWrapper,
  EventCard,
  CardImage,
  CardBadge,
  CardTitle,
  CardTag,
} from './styled';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Event = () => {
  let query = useQuery();
  const isMobile = MediaQuery(mobileQuery);
  const [not_found, set_not_found] = useState(0);
  const [event, set_event] = useState(0);
  const [type, set_type] = useState(
    query.get('type') ? query.get('type') : 'inprogress'
  );
  const [tmp, set_tmp] = useState('');
  const [current_paged, set_current_paged] = useState(0);
  const [total, set_total] = useState(0);
  const [paged, set_paged] = useState(1);
  const posts_per_page = 12;

  const onPaging = page => {
    console.log(page);
    set_event('');
    set_paged(page);
    window.scrollTo(0, 0);
  };

  // useEffect(() => {
  //     if(tmp !== type || current_paged != paged) {
  //         set_tmp(type)
  //         set_event('');
  //         set_not_found(0);
  //         if(tmp !== type) {
  //             set_paged(1);
  //         }
  //         EventGetPosts({
  //             paged: paged,
  //             posts_per_page: posts_per_page,
  //             type: type
  //         })
  //         .then(response => {
  //             if(response.data.code == 200){
  //                 set_event(response.data.body.posts);
  //                 set_current_paged(response.data.body.paged);
  //                 set_total(response.data.body.total);
  //                 console.log(response.data.body.posts);
  //             } else {
  //                 set_event(0);
  //                 set_not_found(1);
  //             }
  //         })
  //         .catch(error => console.log(error));
  //     }
  // })

  useEffect(() => {
    set_event(0);
    EventGetPosts({
      paged: paged,
      posts_per_page: posts_per_page,
      type: type,
    })
      .then(response => {
        if (response.data.code == 200) {
          set_event(response.data.body.posts);
          set_current_paged(response.data.body.paged);
          set_total(response.data.body.total);
          console.log(response.data.body.posts);
        } else {
          set_event(0);
          set_not_found(1);
        }
      })
      .catch(error => console.log(error));
  }, [paged, type]);

  return (
    <MainContariner>
      <Wrapper>
        <TopSection>
          <Title>EVENT</Title>
          <TabWrapper>
            <Tab
              onClick={e => {
                set_type('inprogress');
                set_event('');
              }}
              className={type === 'inprogress' ? 'active' : ''}
            >
              <a>진행중인 이벤트</a>
            </Tab>
            <Tab
              onClick={e => {
                set_type('choice');
                set_event('');
              }}
              className={type === 'choice' ? 'active' : ''}
            >
              <a>당첨자 발표</a>
            </Tab>
            <Tab
              onClick={e => {
                set_type('end');
                set_event('');
              }}
              className={type === 'end' ? 'active' : ''}
            >
              <a>종료된 이벤트</a>
            </Tab>
          </TabWrapper>
        </TopSection>
        <EventCardWrapper>
          {event ? (
            event.map((item, key) => {
              return (
                <>
                  <EventCard>
                    <CardImage
                      url={item.thumbnail ? item.thumbnail : ''}
                      to={'/event/' + (item.ID ? item.ID : '')}
                    />
                    <CardBadge>
                      {item.cate_name ? item.cate_name : ''}
                    </CardBadge>
                    <CardTitle>
                      {item.post_title ? item.post_title : ''}
                    </CardTitle>
                    <CardTag>
                      {item.event_date_range ? item.event_date_range : ''}
                    </CardTag>
                  </EventCard>
                </>
              );
            })
          ) : (
            <>
              {not_found ? (
                <p>해당 조건의 이벤트를 찾을 수 없습니다.</p>
              ) : (
                <>
                  <SktonEvent />
                  <SktonEvent />
                  <SktonEvent />
                </>
              )}
            </>
          )}
        </EventCardWrapper>
        <Pagination
          onPaging={onPaging}
          total={total}
          current={current_paged}
          posts_per_page={posts_per_page}
          max={
            total >= posts_per_page ? parseInt(total / posts_per_page) + 1 : 1
          }
        />
      </Wrapper>
    </MainContariner>
  );
};

export default Event;
