import axios from './defaultClient';

const nameSpace = '/ep/v1/story';

export const StoryGetPosts = async (args) => {
    return await axios.get( nameSpace + '/',{
        params:{
            paged: args.paged,
            posts_per_page: args.posts_per_page,
            category_ID: args.category_ID
        }
    })
}

export const StoryGetPost = async (args) => {
    return await axios.get( nameSpace + '/' + args.story_ID,{

    })
}

export const Story = async (args) => {
    return await axios.get( nameSpace + '/' + args.story_ID + '/related' ,{

    })
}

