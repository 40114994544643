import axios from './defaultClient';

const nameSpace = '/ep/v1/global';

export const Global = async args => {
  return await axios.get(nameSpace + '/', {});
};

export const GlobalCategory = async args => {
  return await axios.get(nameSpace + '/category', {
    params: {
      category_ID: args.category_ID,
    },
  });
};

export const GlobalPopup = async args => {
  return await axios.get(nameSpace + '/popup', {});
};

export const GlobalSystem = async args => {
  return await axios.get(nameSpace + '/system', {});
};
