import React, { useEffect, useState } from 'react';
// IE11의 경우
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import Loading from './components/Loading';
import AuthContainer from './components/AuthContainer';
import ScrollToTop from './components/ScrollTop/ScrollToTop';
import Home from './views/Home';
import Login from './views/Auth/Login';
import Register from './views/Auth/Register';
import ForggotPw from './views/Auth/ForggotPw';
import Best from './views/Best/';
import Event from './views/Event';
import EventSingle from './views/Event/Single/';
import Review from './views/Review';
import ReviewSingle from './views/Review/Single';
import Story from './views/Story';
import StorySingle from './views/Story/Single';
import Service from './views/Service/index';
import Products from './views/Products';
import ProductSingle from './views/Products/Single';
import Search from './views/Search';
import Mypage from './views/Mypage';
import Account from './views/Mypage/Account';
import Inquire from './views/Mypage/Inquire';
import OrderList from './views/Mypage/OrderList';
import OrderSingle from './views/Mypage/OrderList/Single';
import OrderDelivery from './views/Mypage/OrderList/Delivery';
import Point from './views/Mypage/Point';
import Withdraw from './views/Mypage/Withdraw';
import Order from './views/Order/';
import OrderView from './views/Order/OrderView';
import Basket from './views/Order/Basket';
import Page from './views/Page';
// import Guide from './views/Page/Guide';
// import Privacy from './views/Page/Privacy';
// import Terms from './views/Page/Terms';
// import TS from './views/Page/TS';
import PointShop from './views/PointShop';
import PointShopSingle from './views/PointShop/Sinlge';
import Chatbot from './views/Chatbot';
import { useLocation } from 'react-router-dom';

import Parking from './views/Parking';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reward from './views/Reward';

function App() {
	const isLoading = useSelector((state) => state.global.loading);
	const isChatbot = useSelector((state) => state.global.chatbot_popup);
	const isLogined = useSelector((state) => state.me.user_data);

	const location = useLocation();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.CallMtm =
				window.CallMtm ||
				function () {
					(window.CallMtm.q = window.CallMtm.q || []).push(arguments);
				};

			window.CallMtm(); // 초기 호출
		}

		// CallMtm을 호출하는 함수
		const handleRouteChange = () => {
			window.CallMtm(); // 라우터가 변경될 때 CallMtm 함수 호출
		};

		// 위치가 변경될 때마다 CallMtm 실행
		handleRouteChange();

		// 컴포넌트가 언마운트 될 때 cleanup
		return () => {
			// 추가 cleanup 작업이 필요할 경우 이곳에 작성
		};
	}, [location]); // location이 변경될 때마다 실행

	// return (<Parking/>);
	return (
		<>
			<Helmet>
				<title>
					탈모인 성지 사쿠라허브 | 의약품 직구대행 | 핀페시아 | 타다주브 |
					실주브 | 핀주브 | 두사트
				</title>
				<link rel="og:url" href="https://sakuraherbs.com" />
				<link rel="canonical" href="https://sakuraherbs.com" />
				<meta
					name="naver-site-verification"
					content="acbf8d92a19b592f91681304776860d94b7c5d2c"
				></meta>
				<script
					async
					src="https://cdn.onetag.co.kr/0/tcs.js?eid=qxg0zv5ifgiqqxg0zv5ifg"
				></script>
			</Helmet>
			<ScrollToTop />
			{/* 토스트 얼럿 */}
			<ToastContainer />
			<Switch>
				<Route exact={true} path="/" component={Home} />

				{/* 로그인관련 */}
				<Route exact={true} path="/login" component={Login} />
				<Route exact={true} path="/register" component={Register} />
				<Route exact={true} path="/find-pw" component={ForggotPw} />

				{/* 베스트 */}
				<Route exact={true} path="/best" component={Best} />
				<Route exact={true} path="/best/:ID" component={Best} />

				{/* 이벤트 */}
				<Route exact={true} path="/event" component={Event} />
				<Route exact={true} path="/event/:event_ID" component={EventSingle} />

				{/* 이용후기 */}
				<Route exact={true} path="/review" component={Review} />
				<Route
					exact={true}
					path="/review/:review_ID"
					component={ReviewSingle}
				/>
				{/* 리워드 */}
				<Route exact={true} path="/reward" component={Reward} />
				{/* 스토리 */}
				<Route exact={true} path="/story/:category" component={Story} />
				<Route exact={true} path="/story/single/:ID" component={StorySingle} />

				{/* 고객센터 */}
				<Route exact={true} path="/service/faq" component={Service} />
				<Route exact={true} path="/service/notice" component={Service} />
				<Route exact={true} path="/service/notice/:ID" component={Service} />
				<Route exact={true} path="/service/OneOn" component={Service} />

				{/* 제품 */}
				<Route exact={true} path="/products/:ID" component={Products} />
				<Route exact={true} path="/product/:ID" component={ProductSingle} />

				{/* 검색결과페이지 */}
				<Route exact={true} path="/search/:keyword" component={Search} />

				{/* 페이지 */}
				<Route exact={true} path="/page/:page_ID" component={Page} />

				{/* 포인트상점 */}
				<Route exact={true} path="/pointshop" component={PointShop} />
				<Route exact={true} path="/pointshop/:ID" component={PointShopSingle} />

				<AuthContainer>
					{/* 마이페이지 로직 */}
					<Route exact={true} path="/mypage" component={Mypage} />
					<Route exact={true} path="/mypage/account" component={Account} />
					<Route exact={true} path="/mypage/inquire" component={Inquire} />
					<Route exact={true} path="/mypage/orderlist" component={OrderList} />
					<Route
						exact={true}
						path="/mypage/order/:order_ID"
						component={OrderSingle}
					/>
					<Route
						exact={true}
						path="/mypage/order/:order_ID/delivery"
						component={OrderDelivery}
					/>
					<Route exact={true} path="/mypage/point" component={Point} />
					<Route exact={true} path="/mypage/withdraw" component={Withdraw} />

					{/* 주문쪽 로직 */}
					<Route exact={true} path="/basket" component={Basket} />
					<Route exact={true} path="/order" component={Order} />
					<Route
						exact={true}
						path="/order/view/:order_ID"
						component={OrderSingle}
					/>
				</AuthContainer>
				{/* 마이페이지 */}
				{/* 장바구니 아직안됨 */}
				{/* 1대1문의 페이지 아직 안됨 */}
			</Switch>
			{isLoading ? <Loading /> : ''}
			{isLogined ? <Chatbot show={isChatbot} /> : ''}
		</>
	);
}

export default App;
