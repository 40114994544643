import styled from 'styled-components';

export const Container = styled.div`
	overflow-y: scroll;
	height: 400px;
	@media ${(props) => props.theme.mobile} {
		height: 400px;
	}
	@media (max-width: 330px) {
		height: 300px;
	}
`;

export const Wrapper = styled.div`
	padding: 20px;
	border-bottom: 1px solid #f7f7f7;
	@media ${(props) => props.theme.mobile} {
		padding: 15px;
	}
`;

export const OrderTitle = styled.div`
	padding-bottom: 19px;
	border: 1px solid #ebebeb;
	height: 69px;
	padding: 25px 0px 19px 30px;
	h2 {
		font-size: 17px;
		font-weight: bold;
		line-height: normal;
		color: #111111;
	}
	@media ${(props) => props.theme.mobile} {
		h2 {
			font-size: 15px;
		}
		height: 64px;
		padding: 20px 0px 0px 20px;
	}
`;

export const Order = styled.div`
	display: flex;
	justify-content: space-between;
	position: relative;
	align-items: flex-end;
	${(props) => (!props.is_review ? `cursor: pointer;` : ``)}
`;

export const OrderImg = styled.div`
	${(props) => (props.url ? `background:url("${props.url}");` : '')}
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100px auto;
	width: 30%;
	height: 97px;
	background-color: #fbfbfb;
	@media ${(props) => props.theme.mobile} {
		background-size: 70px auto;
		height: 75px;
	}
	${(props) => (props.is_review ? `opacity:0.7;` : ``)}
`;

export const OrderItem = styled.div`
	width: 70%;
	height: 97px;
	vertical-align: top;
	padding: 15px 0px 20px 15px;
	span {
		font-size: 13px;
		font-weight: normal;
		font-style: normal;
		line-height: normal;
		color: #9a9a9e;
	}
	p {
		margin-top: 5px;
		font-size: 13px;
		font-weight: 500;
		line-height: normal;
		color: #111111;
	}
	p:nth-of-type(2) {
		font-weight: normal;
	}
	${(props) =>
		props.is_review
			? `
        .product_name{
            color: #909090;
        }
        .write_complete{
            color: #ff3b5a;
        }
    `
			: ''}
	@media ${(props) => props.theme.mobile} {
		padding: 0px 0px 0px 20px;
		height: 80px;
		span {
			font-size: 12px;
		}
		p {
			font-size: 12px;
		}
		p:nth-of-type(1) {
			width: 148px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;

export const OrderCheck = styled.div`
	position: relative;
	box-sizing: border-box;
	transform: translateY(-200%);
	svg {
		cursor: pointer;
	}
	${(props) =>
		props.checked
			? `
        svg{
            path {
                fill: #e55f74;
            }
            rect {
                stroke: #e55f74;
                fill: #fff;
            }
        }
    `
			: ''}
`;

export const WriteButtonGroup = styled.div`
	display: none;
	@media ${(props) => props.theme.mobile} {
		position: absolute;
		display: block;
		bottom: 0;
		width: 100%;
	}
`;

export const WriteButton = styled.div`
	width: auto;
	height: auto;
	padding: 17px 0px;
	margin: 0 20px;
	margin-top: 30px;
	margin-bottom: 30px;
	display: block;
	position: relative;
	border-radius: 200px;
	background-color: #e55f74;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	color: #fff;
	cursor: pointer;

	@media ${(props) => props.theme.mobile} {
		/* display: none; */
		padding: 17px 0px;
		margin-top: 15px;
		margin-bottom: 15px;
	}
`;

export const ButtonWrapper = styled.div`
	display: none;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		display: block;
		position: relative;
		margin: 30px 0px 0px 0px;
		button {
			width: 50%;
			height: 50px;
			background-color: #eeeeee;
			font-weight: 500;
			font-size: 15px;
			line-height: 22px;
			color: #111111;
			border: none;
		}
		button:nth-of-type(2) {
			box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
			background-color: #e55f74;
			color: #fff;
		}
	}
`;
