import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {Row, MediaQuery, mobileQuery} from '../../../components/Grid/';
import {Link, useHistory} from 'react-router-dom';
import Joi from 'joi-browser';
import Button from '../../../components/Button';
import {useDispatch, useSelector} from 'react-redux';
import {SetLoading} from '../../../store/global';
import {SetUserData} from '../../../store/me';
import {Getme} from '../../../api/me';
import { SignUp } from '../../../api/auth';
import {
    RegisterWrapper,
    RegisterImg,
    Wrapper,
    RegisterContent,
    RegisterTitle,
    RegisterInfor,
    IdInput,
    PassWord,
    PwInput,
    RegisterButton,
    Mobile,
    BackButton,
} from './styled';

const Register = (props) => {
    const Dispatch = useDispatch();
    const raf = useSelector(state=>state.global.raf);
    const isMobile = MediaQuery(mobileQuery);
    const history = useHistory();
    const icon_back =
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none" viewBox="0 0 34 34">
        <path fill="#111" d="M26.916 15.583H10.116l5.142-6.176c.24-.29.357-.663.322-1.037-.035-.375-.216-.72-.506-.96-.29-.241-.662-.357-1.037-.323-.374.035-.72.217-.96.506l-7.084 8.5c-.047.068-.09.139-.127.213 0 .07 0 .113-.1.184-.064.162-.097.335-.098.51 0 .175.034.348.099.51 0 .07 0 .113.099.184.037.074.08.145.127.213l7.084 8.5c.133.16.3.288.488.376.189.088.394.134.602.134.332 0 .652-.115.907-.326.144-.12.262-.265.35-.43.086-.165.14-.345.157-.53.017-.186-.003-.373-.058-.551-.056-.178-.145-.344-.265-.487l-5.142-6.176h16.801c.376 0 .737-.15 1.002-.415.266-.266.415-.626.415-1.002s-.15-.736-.415-1.002c-.265-.265-.626-.415-1.002-.415z"/>
    </svg>

    const [user_login, set_user_login] = useState('');
    const [user_name, set_user_name] = useState('');
    const [user_phone, set_user_phone] = useState('');
    const [user_pw, set_user_pw] = useState('');
    const [user_pw_eye, set_user_pw_eye] = useState(0);
    const [check_pw, set_check_pw] = useState('');
    const [check_pw_eye, set_check_pw_eye] = useState(0);
    const [refCode, setRefCode] = useState(raf ? raf : '');

    const Submit = (e) => {
        const schema ={
            user_name: Joi.string()
                .required()
                .error(() => ({message: '이름을 입력해주세요.'})),
            user_phone: Joi.number()
                .required()
                .error(() => ({message: '휴대폰 번호를 입력해주세요.'})),
            user_login: Joi.string().email()
                .required()
                .error(() => ({message: '이메일을 입력해주세요.'})),
            user_pw: Joi.string()
                .required()
                .error(() => ({message: '비밀번호를 입력해주세요.'})),
            check_pw: Joi.string()
                .required()
                .valid(Joi.ref('user_pw'))
                .error(() => ({message: '비밀번호가 일치하지 않습니다.'}))
        }
        const {error, value} = Joi.validate({
            user_login: user_login,
            user_name: user_name,
            user_phone: user_phone,
            user_pw: user_pw,
            check_pw: check_pw
        }, schema);

        if(error){
            toast.error(error.details[0].message);
            console.log(error);
        } else { 
            Dispatch(SetLoading(1));
            SignUp ({
                user_login: user_login,
                user_name: user_name,
                user_phone: user_phone,
                user_pw: user_pw,
                raf: refCode
            })
            .then((response) => {
                console.log(response.data.code)

                if(response.data.code == '200') {
                    Getme().then(res=>{
                        if(res.data.code == 200) {
                            Dispatch(SetUserData(res.data.body));
                        }
                        toast.success('환영합니다!');
                        localStorage.setItem('user_token', response.data.body.user_token);
                        Dispatch(SetLoading(0));
                        window.location.href='/';
                    })
                    .catch(error => {
                        toast.error('서버 요청 오류');
                        Dispatch(SetLoading(0));
                    });
                } else {
                    toast.error(response.data.body.message);
                    Dispatch(SetLoading(0));
                }
            })
            .catch((error) => console.log(error))
        }
    }

    // useEffect(() => {
    //     if (user_phone.length === 10) {
    //         var t_user_phone = user_phone;
    //         set_user_phone(t_user_phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    //     }
    //     if (user_phone.length === 13) {
    //         var t_user_phone = user_phone;
    //         set_user_phone(t_user_phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    //     }
    // },[user_phone])
    
    // const Submit = (e) => {
    //     e.preventDefault();
    //     SignUp ({
    //         user_login: user_login,
    //         user_name: user_name,
    //         user_phone: user_phone,
    //         user_pw: user_pw       
    //     })
    //     .then((response) => {
    //         console.log(response.data.code)
    //         if(response.data.code == '200') {
    //             console.log('회원가입 완료')
    //             localStorage.setItem('user_token', response.data.body.user_token);
    //             history.push('/')
    //         } else {
    //             alert(response.data.body.message)
    //         }
    //     })
    //     .catch((error) => console.log(error))
    // }


    return (
        <>
            <RegisterWrapper>
                {isMobile ? (
                    <Mobile>
                        <BackButton>
                            <Link to="login">
                                {icon_back}
                            </Link>
                        </BackButton>
                        <h1>회원가입</h1>
                        <p>이미 가입 하셨나요? <Link to="/login">로그인하기</Link></p>
                    </Mobile>
                ) : ''}
                <Row>
                    <RegisterImg url="/img/login_01.png" />
                    <Wrapper>
                    {isMobile ? '' : (
                        <BackButton>
                            <Link to="/">
                                {icon_back}
                            </Link>
                        </BackButton>
                    )}
                        <RegisterContent>
                            <RegisterTitle>
                                <h1>회원가입</h1>
                                <p>이미 가입 하셨나요? <Link to="/login">로그인하기</Link></p>
                            </RegisterTitle>
                            <RegisterInfor>
                                <IdInput type="text" name="user_name" onChange={(e) => set_user_name(e.target.value)} placeholder="이름" />
                                <IdInput type="text" name="user_phone" onChange={(e) => {
                                    const regex = /^[0-9\b ]{0,11}$/;
                                    if (regex.test(e.target.value)) {
                                        set_user_phone(e.target.value);
                                    }
                                    
                                }} value={user_phone} placeholder="휴대폰 번호 ( - 없이 입력 )" />
                                <IdInput type="text" name="user_login" onChange={(e) => set_user_login(e.target.value)} placeholder="이메일 주소" />
                                <PassWord>
                                    <PwInput type={!user_pw_eye ? "password" : 'text'} name="user_pw" onChange={(e) => set_user_pw(e.target.value)} placeholder="비밀번호" />
                                    <img onClick={e=>!user_pw_eye ? set_user_pw_eye(1) : set_user_pw_eye(0)} src={process.env.PUBLIC_URL + (!user_pw_eye ? "/img/register_01.png" : '/img/login_02.png')} alt="" />
                                </PassWord>
                                <PassWord>
                                    <PwInput type={!check_pw_eye ? "password" : 'text'} name="check_pw" onChange={(e) => set_check_pw(e.target.value)} placeholder="비밀번호 확인" />
                                    <img onClick={e=>!check_pw_eye ? set_check_pw_eye(1) : set_check_pw_eye(0)} src={process.env.PUBLIC_URL + (!check_pw_eye ? "/img/register_01.png" : '/img/login_02.png') } alt="" />
                                </PassWord>
                                <IdInput type="text" name="user_login" value={refCode} onChange={(e) => setRefCode(e.target.value)} placeholder="추천인 아이디 또는 추천코드 입력" />

                                {/* {raf ? (
                                    <p className="raf">추천코드 <strong>{raf}</strong></p>
                                ) : ''} */}
                            </RegisterInfor>
                            <RegisterButton onClick={Submit}>
                                <Button>가입하기</Button>
                            </RegisterButton>
                        </RegisterContent>
                    </Wrapper>
                </Row>
            </RegisterWrapper>
        </>
    );
};

export default Register;