import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const SideMenuWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.6s;
    z-index: 999;
    padding-bottom: 320px;
    ${props => {
        if(props.show) {
            return `
                left: 0px;
            `
        } else {
            return `
                left: -500px;
            `
        }
    }}
    .mobile {
        display: none;
    }
    @media ${props => props.theme.mobile}{
        left: -100vw;
        height:100%;
        padding-bottom:0px;
        ${props => {
            if(props.show) {
                return `
                    left: 0;
                `
            } else {
                return `
                    left: -100vw;
                `
            }
        }}
        .mobile {
            display: block;
        }
    }
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 990;
    position: fixed;
    top: 0;
    left: 0;
    ${props => {
        if(props.show) {
            return `
                display: block;
            `
        } else {
            return `
                display: none;
            `
        }
    }}
`;

export const SideMenuInner = styled.div`
    position: relative;
    width: 500px;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
    padding-bottom: 200px;
    border-right: 1px solid #EBEBEB;
    @media ${props => props.theme.mobile}{
        width: 100vw;
        height:100%;
        padding-bottom: 110px;
    }
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 56px;
    right: 56px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    div {
        position: absolute;
        top: 50%;
        right: -4px;
        width: 22px;
        height: 2px;
        background: #111;
        transform: rotate(45deg);
    }
    div:last-child {
        transform: rotate(-45deg);
    }
    @media ${props => props.theme.mobile}{
        top: 36px;
        right: 20px;
        width: 20px;
        height: 20px;
        div {
            width: 22px;
        }
    }
`;

export const Title = styled.div`
    padding: 80px 0px 30px 30px;
    &.main {
        padding: 80px 0px 30px 50px;
    }
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #111111;
    @media ${props => props.theme.mobile}{
        height: 58px;
        line-height: 58px;
        font-size: 16px;
        padding: 0;
        padding-left: 20px;
        box-sizing: initial;
        border-bottom: 1px solid #ebebeb;

        &.main {
            padding: 0px 20px;
        }
    }
`;

export const Category = styled.ul`
    height: 100%;
    overflow-y: auto;
    li {
        height: 56px;
        font-size:14px;
        line-height: 56px;
    }
    li:hover {
        background-color: #fbfbfb;
        div {
            display: block;
            right: -401px;
        }
        path {
            fill: #E55F74;
        }
    }
    li {
        div:hover {
            display: block;
            right: -401px;
        }
    }
    svg {
        transform: translateY(25%);
        margin-right: 20px;
        pointer-events: none;
    }
    > li > a{
        padding-left: 50px;   
    }
    > li a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }
    .arrow {
        position: absolute;
        right: 30px;
        pointer-events: none;
    }
    .sub_ul {
        li {
            padding-left: 30px;
        }
    }
    ::-webkit-scrollbar {
        display: none;
    }
    ${props => {
        if(props.show) {
            return `
            `
        } else {
            return `
                li {
                    pointer-events: none;
                }
            `
        }
    }}
    @media ${props => props.theme.mobile}{
        overflow-y: visible;
        display: block;
        height: auto;
        li {
            height: 65px;
            line-height: 65px;
            box-sizing: initial;
            border-bottom: 1px solid #ebebeb;
        }
        li:hover {
            div {
                display: none;
            }
        }
        svg {
            margin-right: 15px;
        }
        li > a {
            padding-left: 20px;
            font-size:14px;
        }
        .arrow {
            display: none;
        }
    }
`;

export const SubCategory = styled.div`
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 400px;
    height: 100vh;
    background-color: #fff;
    padding-bottom: 200px;
    ul {
        height: 100%;
        overflow-y: scroll;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const IsLogin = styled(Link)`
    width: calc(100% - 20px);
    height: 91px;
    font-size: 20px;
    line-height: 91px;
    font-weight: bold;
    color: #111111;
    padding-left: 20px;
    box-sizing: initial;
    border-bottom: 20px solid #f8f8f8;
    @media ${props => props.theme.mobile}{
        font-size: 18px;
        strong{
            margin-left:20px;
        }
        span {
            vertical-align:top;
            background-image: url(/img/user.png);
            background-size: 26px;
            background-position:center center;
            background-repeat: no-repeat;
            display:inline-block;
            width:36px;
            height:91px;
        }
    }
`;

export const MobileSideScroll = styled.div`
    height: 100vh;
    padding-bottom: 180px;
    @media ${props => props.theme.mobile}{
        height: 100%;
        overflow-y: auto;
        padding-bottom: 00px;
    }
`;

export const OtherMenu = styled.ul`
    display: block;
    padding-bottom: 50px;
    li {
        height: 65px;
        font-size: 16px;
        line-height: 65px;
        font-weight: 500;
        text-align: left;
        color: #111111;
        padding-left: 20px;
        border-bottom: 1px solid #ebebeb;
        box-sizing: initial;
    }
    li:first-child {
        border-top: 20px solid #f8f8f8;
        border-bottom: 20px solid #f8f8f8;
    }
    @media ${props=>props.theme.mobile} {
        li {
            font-size:13px;
            height:50px;
            line-height:50px;
        }
    }
`;

export const LoadingRoundStyle = styled.div`
    padding-left: 30px;
    margin-top: 10px;
`;