import styled from 'styled-components';

export const OrderWrapper = styled.div`
    position: relative;
`;

export const OrderTitle = styled.div`
    margin-bottom: 15px;
    h3{
        font-size: 20px;
        font-weight: bold;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        h3{
            font-size: 18px;
        }
    }
`;

export const OrderDetails = styled.div`
    border: 1px solid #ebebeb;
    padding: 30px;
    margin-bottom: 20px;
    @media ${props => props.theme.mobile}{
        padding: 30px 20px 30px 20px;
    }
`;

export const Product = styled.div`
    width: 160px;
    height: 120px;
    position: relative;
    display: inline-block;
    margin-right: 30px;
    background: #fbfbfb;
    :after{
        ${props => props.url ? `background: url("${props.url}");` : ''}
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 120px;
        height: 84px;
    }
    @media ${props => props.theme.mobile}{
        width: 30%;
        height: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
        :after{
            width: 100px;
            height: 70px;
        }
    }
`;

export const ProductDetails = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 21px;
    p{
        font-size: 13px;
        line-height: normal;
        color: #9a9a9e;
    }
    strong{
        display: block;
        margin-top: 2px;
        font-size: 17px;
        font-weight: 500;  
        line-height: normal;
        color: #111111;
    }
    small{
        display: block;
        margin-top: 10px;
        font-size: 15px;
        line-height: normal;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        width: 60%;
        margin-top: 9px;
        p{
            font-size: 11px;
        }
        strong{
            font-size: 15px;
        }
        small{
            font-size: 13px;
            width: 170px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const ProductButton = styled.div`
    position:absolute;
    right:20px;
    top:50%;
    transform:translateY(-50%);
    & > div {
        margin-bottom:10px;
    }
    @media ${props => props.theme.mobile}{
        position:static;
        margin: 0 -10px 0px -10px;
        transform:none;
    }
`;

export const OrderNull = styled.div`
    margin-top: 32px;
    font-size: 16px;
    @media ${props => props.theme.mobile}{
        margin-top: 24px;
        font-size: 14px;
    }
`;

export const SeeMore = styled.div`
    button{ 
        width: 90px;
       padding: 6px 0px 5px 0px;
       border: 0px;
       border-radius: 2px;
       background-color: #e55f74;
       font-size: 13px;
       font-weight: 500;
       line-height: normal;
       color: #fff;
       text-align: center;
    }
    @media ${props => props.theme.mobile}{
        position: relative;
        display: inline-block;
        top: 0;
        right: 0;
        transform: translateY(0);
        padding: 0 10px 0px 10px;
        width: 50%;
        button{
            width: 100%;
            padding: 15px 0px;
        }
    }
`;

export const OrderCancellation = styled.div`
    button{ 
        width: 90px;
        padding: 5px 0px 6px 0px;
       border: 0px;
       border-radius: 2px;
       background-color: #f5f5f5;
       font-size: 13px;
       font-weight: 500;
       line-height: normal;
       text-align: center;
       color: #111111;
    }
    @media ${props => props.theme.mobile}{
        position: relative;
        display: inline-block;
        top: 0;
        right: 0;
        transform: translateY(0);
        padding: 0 10px 0px 10px;
        width: 50%;
        button{
            width: 100%;
            padding: 15px 0px;
        }
    }
`;

export const OrderButton = styled.div`
    @media ${props => props.theme.mobile}{
        position: relative;
        display: block;
        margin-top: 70px;
        text-align: center;
        button{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background: #fff;
            border: 2px solid #999999;
            box-sizing: border-box;
            border-radius: 200px;
            padding: 13px 0px;
            font-size: 16px;
            text-align: center;
            color: #111;
        }
    }
`;



export const OldCard = styled.div`
    border: 5px solid red;
    padding: 30px;
    margin-bottom: 20px;
    @media ${props => props.theme.mobile}{
        padding: 30px 20px 30px 20px;
    }
`;

export const OldCardDetails = styled.div`
    position:relative;
    width: 100%;
    vertical-align: top;
    margin-top: 0px;
    h3 {
        line-height: normal;
        color: red;
        text-decoration:underline;
    }
    ${SeeMore} {
        button {
            width:140px;
        }
    }
    @media ${props => props.theme.mobile}{
        width: 100%;
        margin-top: 9px;
        p{
            font-size: 11px;
        }
        strong{
            font-size: 15px;
        }
        small{
            font-size: 13px;
            width: 170px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        ${SeeMore} {
            width:100%;
            padding:0px;
            margin-top:20px;
            button {
                width:100%;
            }
        }
    }
`;