import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import SidePopup from '../../../../components/SidePopup';
import {Link, useHistory} from 'react-router-dom';
import Joi from 'joi-browser';
import {DeleteUser} from '../../../../api/auth';
import {SetLoading} from '../../../../store/global';
import {SetUserData} from '../../../../store/me';
import { 
    Wrapper,
    Title,
    SubTitle,
    Information,
    PwTitle,
    PassWord,
    PwInput,
    ConfirmButton,
} from './styled';
import Button from '../../../../components/Button';

const ConfirmPopup = (props) => {
    const History = useHistory();
    const Dispatch = useDispatch();
    const [password, set_password] = useState('');
    const [password_toggle, set_password_toggle] = useState(0);
    const DoDeleteUser = (e) => {
        const schema ={
            password: Joi.string()
                .required()
                .error(() => ({message: '비밀번호를 입력해주세요.'})),
        }
        const {error, value} = Joi.validate({
            password: password,
        }, schema);

        if(error){
            toast.error(error.details[0].message);
        } else { 
            Dispatch(SetLoading(1));
            DeleteUser ({
                password: password       
            })
            .then((response) => {
                console.log(response.data.code)
                Dispatch(SetLoading(0));
                if(response.data.code == '200') {
                    toast.success(response.data.body.message);
                    Dispatch(SetUserData(''));
                    localStorage.removeItem('user_token');
                    History.push('/');
                } else {
                    toast.error(response.data.body.message);
                }
            })
            .catch(err => toast.error('서버 요청 오류!'));
        }
    }

    return (
        <SidePopup show={props.show} onClose={props.onClose}>
            <Wrapper>
                <Title>
                    <h2>회원 탈퇴</h2>
                </Title>
                <SubTitle>
                    <h3>회원탈퇴를 하더라도, 서비스 약관 및 개인정보 취급방침 동의하에 따라  일정 기간동안 회원 개인정보를 보관합니다.</h3>
                </SubTitle>
                <Information>
                    <ul>
                        <li>- 회원 정보</li>
                        <li>- 상품 구입 및 대금 결제에 관한 기록</li>
                        <li>- 상품 배송에 관한 기록</li>
                        <li>- 소비자 불만 또는 처리 과정에 관한 기록</li>
                        <li>- 게시판 작성 및 사용후기에 관한 기록</li>
                    </ul>
                    <p>※ 상세한 내용은 사이트 내 개인정보 취급방침을 참고 해 주세요.</p>
                    <p>질문이 있으세요?  <Link to="">1:1 문의</Link></p>
                </Information>
                <PwTitle>
                    <h3>비밀번호</h3>
                    <p>계정을 삭제하시려면 비밀번호를 입력해주세요.</p>
                </PwTitle>
                <PassWord>
                    <PwInput type={password_toggle ? 'text' : 'password'} onChange={e=>set_password(e.target.value)} value={password} placeholder="비밀번호" />
                    <img onClick={e => set_password_toggle(!password_toggle)} src={process.env.PUBLIC_URL + "/img/register_01.png"} alt="" />
                </PassWord>
                <ConfirmButton>
                    <Button onClick={DoDeleteUser} color="secondary">
                        탈퇴하기
                    </Button>
                </ConfirmButton>
            </Wrapper>
        </SidePopup>
    );
};

export default ConfirmPopup;