import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { GlobalPopup } from '../../api/global';
import { useDispatch, useSelector } from 'react-redux';
import { SetRaf } from '../../store/global';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { category } from '../../theme';
import MainPopup from './Popup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../../perfect-scrollbar.css';
import {
	HomeGetHit,
	HomeGetStory,
	HomeGetCate,
	HomeGetReview,
} from '../../api/home';
import {
	SkeletonMainProduct,
	SkeletonMainReview,
	SktonHomeStory,
} from '../../components/Skeleton';
import {
	MainPopupWrapper,
	HomeWrapper,
	MainPostWrapper,
	MainPost,
	MobileMainPost,
	MainPostImage,
	HomeContainer,
	CategoryWrapper,
	Category,
	CategoryLogo,
	CategoryName,
	TrendWrapper,
	Title,
	TrendSection,
	TrendProducts,
	TrendProduct,
	ProductImage,
	ProductType,
	ProductName,
	ProductPrice,
	TipWrapper,
	TipSection,
	TipCardWrapper,
	TipCard,
	CardImage,
	CardContent,
	CardArrow,
	RecommendWrapper,
	TitleSection,
	RecommendTabWrapper,
	RecommendTab,
	RecommendCardWrapper,
	CategoryCard,
	SubCategory,
	OpenChatWrapper,
	OpenChatSection,
	ReviewWrapper,
	ReviewSection,
	ReviewProducts,
	ReviewProduct,
	ReviewImage,
	ReviewContent,
	ReviewProductDetails,
	ReviewProductImage,
	ReviewProductName,
	ReviewProductPrice,
} from './styled';
import { MediaQuery, mobileQuery } from '../../components/Grid';

const Home = () => {
	const { jQuery } = window;
	const history = useHistory();
	const Dispatch = useDispatch();
	const me = useSelector((state) => state.me.user_data);
	const get_params = new URLSearchParams(useLocation().search);
	const [MainPopup_isshow, show_MainPopup] = useState(0);
	const [popup, set_popup] = useState('');

	const [maintain, set_maintain] = useState(0);
	const [reload_time, set_reload_time] = useState(3000);
	var today = new Date();
	const date = today.getDate();

	const closeTodayPopup = (e) => {
		show_MainPopup(0);
		localStorage.setItem('load', date);
	};
	const isMobile = MediaQuery(mobileQuery);

	const [hit, set_hit] = useState('');
	const [story, set_story] = useState('');
	const [story_total, set_story_total] = useState(0);
	const [cate, set_cate] = useState('');
	const [cate_products, set_cate_products] = useState('');
	const [test, set_test] = useState('');
	const [category_ID, set_category_ID] = useState(20);
	const [category_name, set_category_name] = useState('득모상품');
	const [review, set_review] = useState('');
	const [review_post, set_review_post] = useState('');
	const [raf_check, set_raf_check] = useState('');

	const selectCate = (e) => {
		if (category_ID !== e.target.dataset.id) {
			set_cate(0);
			set_category_ID(e.target.dataset.id);
			set_category_name(e.target.innerText);
		}
	};

	useEffect(() => {
		// 추천인 처리
		if (get_params.get('raf')) {
			if (!raf_check) {
				// localStorage.setItem('raf', get_params.get('raf'));
				Dispatch(SetRaf(get_params.get('raf')));
				toast.success(
					'추천코드가 적용되었습니다. 회원가입시 포인트 즉시 적립됩니다.'
				);
				set_raf_check(1);
			}
		}

		HomeGetHit()
			.then((response) => {
				if (response.data.code == 200) {
					set_hit(response.data.body.products);
					console.log(response.data.body.products);
				}
			})
			.catch((error) => console.log(error));

		HomeGetStory()
			.then((response) => {
				if (response.data.code == 200) {
					set_story(response.data.body.posts);
					set_story_total(response.data.body.total);
					console.log(response.data.body.posts);
				}
			})
			.catch((error) => console.log(error));

		HomeGetReview()
			.then((response) => {
				if (response.data.code == 200) {
					set_review(response.data.body);
					set_review_post(response.data.body.posts);
					console.log(response.data.body.posts);
				}
			})
			.catch((error) => console.log(error));

		GlobalPopup()
			.then((response) => {
				if (response.data.code == 200) {
					set_popup(response.data.body.posts);
				}
			})
			.catch((error) => console.log(error));

		jQuery('.scroll-container').mousewheel(function (e, delta) {
			// multiplying by 40 is the sensitivity,
			// increase to scroll faster.
			this.scrollLeft -= delta * 40;
			e.preventDefault();
		});

		setTimeout(function () {
			show_MainPopup(1);
		}, 3000);
	}, []);

	useEffect(() => {
		set_cate_products('');
		HomeGetCate({
			category_ID: category_ID,
		})
			.then((response) => {
				if (response.data.code == 200) {
					set_cate(response.data.body);
					set_cate_products(response.data.body.products);
					console.log(response.data.body.products);
				} else {
					set_cate_products([]);
				}
			})
			.catch((error) => console.log(error));
	}, [category_ID]);

	const DoWheel = (event) => {
		event.preventDefault();
		event.stopPropagation();
		// event.stopImmediatePropagation();

		// // console.log(e);
		const delta = Math.max(
			-1,
			Math.min(1, event.nativeEvent.wheelDelta || -event.nativeEvent.detail)
		);
		event.currentTarget.scrollLeft -= delta * 10;
		return false;
	};
	// const scrollRef = useXScroll();

	const cateList = [
		{ ID: 20, name: '득모상품', img: category?.hair },
		{ ID: 23, name: '불끈상품', img: category?.fire },
		{ ID: 699, name: '득모+불끈', img: category?.fire },
		{ ID: 433, name: '건강상품', img: category?.health },
		{ ID: 22, name: '여드름상품', img: category?.skin },
		{ ID: 384, name: '미용상품', img: category?.beauty },
		{ ID: 526, name: '꿀잠상품', img: category?.sleep },
		{ ID: 692, name: '집중력상품', img: category?.concent },
		{ ID: 457, name: '숙취 금연 금주 우울', img: category?.various },
		{ ID: 456, name: '다이어트식품', img: category?.weight },
		{ ID: 535, name: '남성호르몬', img: category?.mars },
		{ ID: 633, name: '여성호르몬', img: category?.venus },
		{ ID: 440, name: '반려동물', img: category?.pet },
		{ ID: 840, name: 'FC2포인트', img: category?.point },
	];

	const [m, setM] = useState(false);

	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setIsVisible((p) => !p);
		}, 3500);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<>
			<Header noBold={true} />
			<HomeWrapper>
				<MainPostWrapper>
					<MainPost>
						{/* <div className="rel"> */}
						{/* <span>사쿠라허브 친구초대 이벤트</span>
							// <div className="flex">
								<strong>
									지금 친구 초대하면
									<br />
									10,000포인트 즉시 적립!
								</strong>
								<Link to="/event/99941">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="163"
										height="16"
										viewBox="0 0 163 16"
										fill="none"
									>
										<path d="M0 15H162L134 1" stroke="white" />
									</svg>
								</Link>
							</div> */}
						{/* <div
							className="bg bg-01"
							onClick={() => {
								window.open('https://thepetherb.com/');
							}}
						/>
						<div
							className="bg bg-02"
							onClick={() => history.push(`/event/99941`)}
						/> */}
						{isMobile ? (
							<>
								{isVisible ? (
									<div
										className="bg bg-01"
										onClick={() => {
											window.open('https://thepetherb.com/');
										}}
									/>
								) : null}
								{!isVisible ? (
									<div
										className="bg bg-02"
										onClick={() => history.push(`/event/99941`)}
									/>
								) : null}
							</>
						) : (
							<>
								<div
									className="bg bg-01"
									onClick={() => {
										window.open('https://thepetherb.com/');
									}}
								/>
								<div
									className="bg bg-02"
									onClick={() => history.push(`/event/99941`)}
								/>
							</>
						)}
						{/* </div> */}
					</MainPost>
					<div className="cate">
						<h5>카테고리</h5>
						<ul className="pc">
							{cateList?.map((item, key) => (
								<li
									key={key}
									onClick={() => history.push(`/products/${item.ID}`)}
								>
									<a>
										<p>{item?.img}</p>
										<span>{item?.name}</span>
									</a>
								</li>
							))}
						</ul>
						<ul className="mo">
							{cateList?.slice(0, 7)?.map((item, key) => (
								<li
									key={key}
									onClick={() => history.push(`/products/${item.ID}`)}
								>
									<a>
										<p>{item?.img}</p>
										<span>{item?.name}</span>
									</a>
								</li>
							))}
							{!m ? (
								<li className="more">
									<a onClick={() => setM(!m)}>
										<p>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="30"
												height="30"
												viewBox="0 0 30 30"
												fill="none"
											>
												<circle
													cx="15"
													cy="15"
													r="11.75"
													stroke="#444444"
													stroke-width="2.5"
												/>
												<path
													d="M20 13L15 18L10 13"
													stroke="#444444"
													stroke-width="2.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</p>
										<span>더보기</span>
									</a>
								</li>
							) : null}

							{m
								? cateList?.slice(7, 100)?.map((item, key) => (
										<li
											key={key}
											onClick={() => history.push(`/products/${item.ID}`)}
										>
											<a>
												<p>{item?.img}</p>
												<span>{item?.name}</span>
											</a>
										</li>
								  ))
								: null}
						</ul>
					</div>
					{/* <MainPostImage /> */}
					{/* <MobileMainPost>
						<span>사쿠라허브 친구초대 이벤트</span>
						<strong>지금 친구 초대하면 10,000포인트 즉시 적립!</strong>
						<Link to="/event/99941">
							<button>자세히보기</button>
						</Link>
					</MobileMainPost> */}
				</MainPostWrapper>
				<HomeContainer>
					{/* <CategoryWrapper>
						<PerfectScrollbar className={'scroll-container'}>
							<Category
								is_plus={(me && me.user_role != 'subscriber') || 1 ? 1 : 0}
							>
								<li>
									<CategoryLogo to="/products/20">{category.hair}</CategoryLogo>
									<CategoryName>득모상품</CategoryName>
								</li>
								{(me && me.user_role != 'subscriber') || 1 ? (
									<>
										<li>
											<CategoryLogo to="/products/23">
												{category.fire}
											</CategoryLogo>
											<CategoryName>불끈상품</CategoryName>
										</li>

										<li>
											<CategoryLogo to="/products/699">
												{category.fire}
											</CategoryLogo>
											<CategoryName>득모+불끈</CategoryName>
										</li>
									</>
								) : (
									''
								)}
								<li>
									<CategoryLogo to="/products/433">
										{category.health}
									</CategoryLogo>
									<CategoryName>건강상품</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/22">{category.skin}</CategoryLogo>
									<CategoryName>여드름상품</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/384">
										{category.beauty}
									</CategoryLogo>
									<CategoryName>미용상품</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/526">
										{category.sleep}
									</CategoryLogo>
									<CategoryName>꿀잠상품</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/692">
										{category.concent}
									</CategoryLogo>
									<CategoryName>집중력상품</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/457">
										{category.various}
									</CategoryLogo>
									<CategoryName>숙취 금연 금주 우울</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/456">
										{category.weight}
									</CategoryLogo>
									<CategoryName>다이어트식품</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/535">
										{category.mars}
									</CategoryLogo>
									<CategoryName>남성호르몬</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/633">
										{category.venus}
									</CategoryLogo>
									<CategoryName>여성호르몬</CategoryName>
								</li>
								<li>
									<CategoryLogo to="/products/440">{category.pet}</CategoryLogo>
									<CategoryName>반려동물</CategoryName>
								</li>
								<li className="last-category">
									<CategoryLogo to="/products/840">
										{category.point}
									</CategoryLogo>
									<CategoryName>FC2포인트</CategoryName>
								</li>
							</Category>
						</PerfectScrollbar>
					</CategoryWrapper> */}
					<TrendWrapper>
						<div>
							<Title>지금 뜨고 있는 인기상품</Title>
							<TrendSection>
								<PerfectScrollbar className={'scroll-container'}>
									<TrendProducts>
										{hit ? (
											hit.map((item, key) => {
												return (
													<TrendProduct>
														<ProductImage
															url={item.thumbnail ? item.thumbnail : ''}
															to={'/product/' + (item.ID ? item.ID : '')}
														>
															{item?.is_sold_out ? (
																<div className="sold-out">임시품절</div>
															) : null}
														</ProductImage>
														<ProductType>
															{item.cate_name ? item.cate_name : ''}
														</ProductType>
														<ProductName>
															{item.title ? item.title : ''}
														</ProductName>
														<ProductPrice>
															{item.price ? item.price : ''}
														</ProductPrice>
													</TrendProduct>
												);
											})
										) : (
											<>
												<SkeletonMainProduct />
												<SkeletonMainProduct />
												<SkeletonMainProduct />
												<SkeletonMainProduct />
												<SkeletonMainProduct />
											</>
										)}
									</TrendProducts>
								</PerfectScrollbar>
							</TrendSection>
						</div>
					</TrendWrapper>
					<TipWrapper>
						<Title>사쿠라허브 TIP</Title>
						<TipSection>
							<PerfectScrollbar className={'scroll-container'}>
								<TipCardWrapper count={story_total ? story_total : ''}>
									{story ? (
										story.map((item, key) => {
											return (
												<TipCard
													to={'/story/single/' + (item.ID ? item.ID : '')}
												>
													<CardImage
														url={item.thumbnail ? item.thumbnail : ''}
													/>
													<CardContent
														color={item.color ? item.color : ''}
														dangerouslySetInnerHTML={{
															__html: item.title,
														}}
													></CardContent>
													<CardArrow />
												</TipCard>
											);
										})
									) : (
										<>
											<SktonHomeStory />
											<SktonHomeStory />
											<SktonHomeStory />
										</>
									)}
								</TipCardWrapper>
							</PerfectScrollbar>
						</TipSection>
					</TipWrapper>
					<RecommendWrapper>
						<TitleSection>
							<Title className="recommend">구매자들이 뽑은 추천상품</Title>
							<RecommendTabWrapper>
								<PerfectScrollbar className={'scroll-container'}>
									<RecommendTab
										is_plus={(me && me.user_role != 'subscriber') || 1 ? 1 : 0}
									>
										<li
											onClick={selectCate}
											data-id="20"
											className={category_ID == 20 ? 'active' : ''}
										>
											득모상품
										</li>
										{(me && me.user_role != 'subscriber') || 1 ? (
											<>
												<li
													onClick={selectCate}
													data-id="23"
													className={category_ID == 23 ? 'active' : ''}
												>
													불끈상품
												</li>
												<li
													onClick={selectCate}
													data-id="699"
													className={category_ID == 699 ? 'active' : ''}
												>
													득모+불끈
												</li>
											</>
										) : (
											''
										)}
										<li
											onClick={selectCate}
											data-id="433"
											className={category_ID == 433 ? 'active' : ''}
										>
											건강상품
										</li>
										<li
											onClick={selectCate}
											data-id="22"
											className={category_ID == 22 ? 'active' : ''}
										>
											여드름상품
										</li>
										<li
											onClick={selectCate}
											data-id="384"
											className={category_ID == 384 ? 'active' : ''}
										>
											미용상품
										</li>
										<li
											onClick={selectCate}
											data-id="526"
											className={category_ID == 526 ? 'active' : ''}
										>
											꿀잠상품
										</li>
										<li
											onClick={selectCate}
											data-id="692"
											className={category_ID == 692 ? 'active' : ''}
										>
											집중력상품
										</li>
										<li
											onClick={selectCate}
											data-id="457"
											className={category_ID == 457 ? 'active' : ''}
										>
											숙취 · 금연 · 금주 · 우울
										</li>
										<li
											onClick={selectCate}
											data-id="456"
											className={category_ID == 456 ? 'active' : ''}
										>
											다이어트식품
										</li>
										<li
											onClick={selectCate}
											data-id="535"
											className={category_ID == 535 ? 'active' : ''}
										>
											남성호르몬
										</li>
										<li
											onClick={selectCate}
											data-id="633"
											className={category_ID == 633 ? 'active' : ''}
										>
											여성호르몬
										</li>
										<li
											onClick={selectCate}
											data-id="440"
											className={category_ID == 440 ? 'active' : ''}
										>
											반려동물
										</li>
									</RecommendTab>
								</PerfectScrollbar>
							</RecommendTabWrapper>
						</TitleSection>
						<RecommendCardWrapper>
							<CategoryCard>
								<strong>{category_name}</strong>
								<span>사쿠라허브에서 저렴하게 만나보세요.</span>
								{/* <SubCategory>
                                        <li>두타스테리드+미녹시딜</li>
                                        <li>피나스테리드+스피로노락톤</li>
                                        <li>두타스테리드</li>
                                    </SubCategory> */}
							</CategoryCard>
							<TrendSection className="recommend">
								<PerfectScrollbar className={'scroll-container'}>
									<TrendProducts total={cate.total}>
										{cate_products ? (
											cate_products.map((item, key) => {
												return (
													<TrendProduct>
														<ProductImage
															url={item.thumbnail ? item.thumbnail : ''}
															to={'/product/' + (item.ID ? item.ID : '')}
														>
															{item?.is_sold_out ? (
																<div className="sold-out">임시품절</div>
															) : null}
														</ProductImage>
														<ProductType>
															{item.cate_name ? item.cate_name : ''}
														</ProductType>
														<ProductName>
															{item.title ? item.title : ''}
														</ProductName>
														<ProductPrice>
															{item.price ? item.price : ''}
														</ProductPrice>
													</TrendProduct>
												);
											})
										) : (
											<>
												<SkeletonMainProduct />
												<SkeletonMainProduct />
												<SkeletonMainProduct />
												<SkeletonMainProduct />
												<SkeletonMainProduct />
											</>
										)}
									</TrendProducts>
								</PerfectScrollbar>
							</TrendSection>
						</RecommendCardWrapper>
					</RecommendWrapper>
					<OpenChatWrapper>
						{/* <OpenChatSection>
                                <p><span>오픈채팅에 참여하세요!</span><strong>1:1 오픈채팅을 통해 상담을 받으실 수 있습니다.</strong></p>
                                <a href="https://open.kakao.com/o/sDvmaGhb" target="_blank">1:1 오픈채팅 접속</a>
                                <img src={process.env.PUBLIC_URL + "/img/openchat_content.png"} alt="openchat content" />
                            </OpenChatSection> */}
					</OpenChatWrapper>
					<ReviewWrapper>
						<Title>사쿠라허브 고객 리뷰</Title>
						<ReviewSection>
							<PerfectScrollbar className={'scroll-container'}>
								<ReviewProducts count={review.count}>
									{review_post ? (
										review_post.map((item, key) => {
											return (
												<ReviewProduct>
													<ReviewImage
														url={item.thumbnail ? item.thumbnail : ''}
														to={'/review/' + (item.ID ? item.ID : '')}
													/>
													<ReviewContent>
														<p>{item.post_title ? item.post_title : ''}</p>
														<small>
															<span>
																{item.author_display_name
																	? item.author_display_name
																	: ''}
															</span>
															{item.post_date ? item.post_date : ''}
														</small>
													</ReviewContent>
													{item.product_ID ? (
														<ReviewProductDetails
															onClick={(e) =>
																history.push(`/product/${item.product_ID}`)
															}
														>
															<ReviewProductImage
																url={
																	item.product_thumbnail
																		? item.product_thumbnail
																		: ''
																}
																to={
																	'/review/' +
																	(Number.isInteger(item.product_ID)
																		? item.product_ID
																		: '')
																}
															/>
															<ReviewProductName>
																<p>
																	{item.product_cate_name
																		? item.product_cate_name
																		: ''}
																</p>
																<span>
																	{item.product_name ? item.product_name : ''}
																</span>
															</ReviewProductName>
															<ReviewProductPrice>
																{item.product_price ? item.product_price : ''}
															</ReviewProductPrice>
														</ReviewProductDetails>
													) : (
														''
													)}
												</ReviewProduct>
											);
										})
									) : (
										<>
											<SkeletonMainReview />
											<SkeletonMainReview />
											<SkeletonMainReview />
											<SkeletonMainReview />
											<SkeletonMainReview />
											<SkeletonMainReview />
										</>
									)}
								</ReviewProducts>
							</PerfectScrollbar>
						</ReviewSection>
					</ReviewWrapper>
				</HomeContainer>
				{/* <MainPopup show={MainPopup_isshow} onClose={closeMainPopup} closeToday={closeTodayPopup} /> */}
				<MainPopupWrapper show={MainPopup_isshow}>
					{popup
						? popup.map((item, key) => {
								return (
									<MainPopup index={key}>
										<div
											dangerouslySetInnerHTML={{
												__html: item.content ? item.content : '',
											}}
										/>
									</MainPopup>
								);
						  })
						: ''}
				</MainPopupWrapper>
			</HomeWrapper>
			<Footer />
		</>
	);
};

export default Home;
