import styled from 'styled-components';

export const PointWrapper = styled.div`

`;

export const PointUse = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-end;
    padding: 35px 50px;
    border-radius: 2px;
    border: 1px solid #e3e5e8;
    background-color: #fbfbfb;
    p{
        font-size: 17px;
        font-weight: 500;
        line-height: normal;
        color: #111111;
    }
    span{
        display: block;
        margin-top: 5px;
        font-size: 13px;
        line-height: normal;
        letter-spacing: -0.13px;
        color: #666666;
    }
    p:nth-of-type(2){
        font-size: 25px;
        font-weight: bold;
        line-height: normal;
        color: #111111;
        top: 25%;
        left: 0;
        transform: translateY(-25%);
    }
    @media ${props => props.theme.mobile}{
        padding: 24px 15px;
        p{
            font-size: 15px;
        }
        span{
            font-size: 11px;
        }
        p:nth-of-type(2){
            font-size: 20px;
            top: 30%;
            transform: translateY(-30%);
        }
    }
`;

export const ItemTitle = styled.div`
    padding-bottom: 14px;
    border-bottom: 1px solid #111;
    margin-top: 50px;
    h3{
        font-size: 20px;
        font-weight: bold;
        line-height: normal;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        padding-bottom: 19px;
        h3{
            font-size: 18px;
        }
    }
`;

export const PointItem = styled.div`
    table{
        width: 100%;
        thead{
            border-bottom: 1px solid #ebebeb;
            tr{
                th{
                    font-size: 15px;  
                    padding: 15px 0px;
                    font-weight: normal;
                    text-align: center;
                    color: #111111;
                }
            }
        }
        tbody{
            tr{
                td{
                    padding: 15px 0px;
                    border-bottom: 1px solid #ebebeb;
                    font-size: 12px;
                    font-weight: normal;
                    text-align: center;
                    color: #111111;
                    p {
                        font-size: 12px;
                        color:#777;
                        line-height: 1.3;
                        font-weight:normal;
                    }
                }
            }
            .plus{
                font-weight: bold;
                color: green;
            }
            .minus{
                font-weight: bold;
                color: #e55f74;
            }
        }
    }
    @media ${props => props.theme.mobile}{
        table{
            line-height: normal;
            height: 0px;
            tbody{
                tr{
                    td{
                        padding: 20px 8px;
                        font-size: 11px;
                        width: 35%;
                        vertical-align: middle;
                    }
                    .date{
                        font-size: 13px;
                        font-weight: normal;
                    }
                    .text-left{
                        text-align: left;
                    }
                    .plus{  
                        font-size: 15px;
                        /* text-align: right; */
                        top: 0;
                        transform: none;
                    }
                    .minus{  
                        font-size: 15px;
                        text-align: right;
                        top: 0;
                        transform: none;
                        /* top: 10%; */
                        /* transform: translateY(10%); */
                    }
                }
            }
        }
    }
`;

export const PointButton = styled.div`

    position: relative;
    display: block;
    margin-top: 80px;
    text-align: center;
    button{
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: #fff;
        border: 2px solid #999999;
        box-sizing: border-box;
        border-radius: 200px;
        padding: 13px 0px;
        font-size: 16px;
        text-align: center;
        color: #111;
    }

`;


