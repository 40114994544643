import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { Row, Col, MediaQuery, mobileQuery } from '../Grid';
import InquirePopup from '../../views/Inquire';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleChatbot } from '../../store/global';
// import {MobileView, isMobile} from 'react-device-detect';
import {
	FooterWrapper,
	FooterContainer,
	FooterTop,
	FooterMenu,
	OpenChat,
	OpenChatLink,
	FooterBottom,
	ChatbotIcon,
} from './styled';

const Footer = () => {
	const isMobile = MediaQuery(mobileQuery);
	const Dispatch = useDispatch();
	const me = useSelector((state) => state.me.user_data);

	const [InquirePopup_isshow, show_InquirePopup] = useState(0);
	const showInquirePopup = (e) => {
		if (InquirePopup_isshow) {
			show_InquirePopup(0);
		} else {
			show_InquirePopup(1);
		}
	};

	return (
		<FooterWrapper>
			<FooterContainer>
				<FooterTop>
					<Row>
						<Col flex="0.41">
							{/* <OpenChat>
                                사쿠라허브 오픈채팅에 참여하세요!<br />
                                1:1 오픈채팅을 통해 상담을 받으실 수 있습니다.
                                <OpenChatLink href="https://open.kakao.com/o/sDvmaGhb" target="_blank" >
                                    1:1 오픈채팅 접속
                                </OpenChatLink>
                            </OpenChat> */}
						</Col>
						<Col>
							<FooterMenu>
								<li>
									<span>Detail</span>
									<ul className="sub_menu">
										<li>
											<Link to="/page/terms">이용약관</Link>
										</li>
										<li>
											<Link to="/page/guide">이용가이드</Link>
										</li>
										<li>
											<Link to="/page/privacy-policy">개인정보취급방침</Link>
										</li>
										<li>
											<Link to="/page/transaction-law">특정거래법</Link>
										</li>
									</ul>
								</li>
								<li>
									<span>Story</span>
									<ul className="sub_menu">
										<li>
											<Link to="/story/tip">제품사용 TIP</Link>
										</li>
										<li>
											<Link to="/story/lifestyle">라이프스타일</Link>
										</li>
										<li>
											<Link to="/story/hair">득모임</Link>
										</li>
									</ul>
								</li>
								<li>
									<span>Shop</span>
									<ul className="sub_menu">
										<li>
											<Link to="/products/best">카테고리</Link>
										</li>
										<li>
											<Link to="/pointshop">포인트상점</Link>
										</li>
									</ul>
								</li>
								<li className="service">
									<span>Service</span>
									<ul className="sub_menu">
										<li>
											<Link to="/service/notice">공지사항</Link>
										</li>
										<li>
											<Link onClick={showInquirePopup}>1대1 문의</Link>
										</li>
										<li>
											<Link to="/service/faq">자주 묻는 질문 (FAQ)</Link>
										</li>
									</ul>
								</li>
								<li className="contact">
									<span>Contact</span>
									<ul className="sub_menu">
										<li>
											사쿠라허브의 고객센터는 고객님을 위해
											<br />
											주중 오전 10시부터 6시까지 언제나 열려있습니다.
											<br />
											(점심시간 11:50~12:50)
										</li>
									</ul>
								</li>
							</FooterMenu>
						</Col>
					</Row>
				</FooterTop>
				<FooterBottom>
					{isMobile ? (
						<>
							<p className="t">
								고객센터 운영시간 <br />
								10:00 – 18:00, 점심시간 : 11:50~12:50
							</p>
						</>
					) : (
						''
					)}
					<ul>
						<li>
							<p>SAKURAHERB</p>
						</li>
						<li>
							<p>매니저 : KAWAKAMI HIDEO</p>
						</li>
						<li>
							<p>Country Japan</p>
						</li>
					</ul>
					<span>Copyright ⓒ 2019 Sakuraherb, Ltd. All rights reserved.</span>
				</FooterBottom>
			</FooterContainer>
			{me ? (
				<ChatbotIcon
					href="#"
					onClick={(e) => {
						e.preventDefault();
						Dispatch(ToggleChatbot(1));
					}}
				>
					<div className="icon">
						<div>
							<i />
							<i />
							<i />
						</div>
					</div>
					<span>1:1상담</span>
				</ChatbotIcon>
			) : (
				''
			)}

			<InquirePopup
				show={InquirePopup_isshow}
				onClose={showInquirePopup}
				submit_success={show_InquirePopup}
			/>
		</FooterWrapper>
	);
};

export default Footer;
