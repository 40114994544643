import styled from 'styled-components';
import { RowWrap, ColWrap } from '../../components/Grid/styled';

export const Wrapper = styled.div`
	position: relative;
	padding: 100px 0px 200px 0px;
	${ColWrap}:nth-of-type(1) {
		padding: 0 20px;
		width: 50%;
	}
	${ColWrap}:nth-of-type(2) {
		padding: 0 20px;
		width: 50%;
	}
	@media ${(props) => props.theme.mobile} {
		overflow: hidden;
		padding: 50px 0px 79px 0px;
		border-bottom: 1px solid #ebebeb;
		${RowWrap}:nth-of-type(1) {
			display: block;
			width: 100%;
		}
		${ColWrap}:nth-of-type(1) {
			display: block;
			width: 100%;
		}
		${ColWrap}:nth-of-type(2) {
			display: block;
			width: 100%;
		}
	}
`;

export const Container = styled.div`
	@media ${(props) => props.theme.mobile} {
		margin: 0px 20px;
	}
`;

export const ReviewTitle = styled.div`
	margin-bottom: 0px;
	h2 {
		font-family: 'Roboto';
		font-size: 27px;
		font-weight: bold;
		line-height: normal;
		color: #111111;
	}
`;

export const UseWrapper = styled.div`
	margin: 0px -20px;
	height: 435px;
	position: relative;
`;

export const UseReview = styled.div``;

export const ReviewImg = styled.div`
	${(props) => (props.url ? `background:url("${props.url}");` : '')}
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	width: auto;
	height: 435px;
	@media ${(props) => props.theme.mobile} {
		height: 335px;
	}
`;

export const User = styled.div`
	width: 100%;
	height: 435px;
	display: inline-block;
	padding: 68px 0px 0px 0px;
	text-align: center;
	border: 1px solid transparent;

	@media ${(props) => props.theme.tablet} {
		padding: 33px 0px 0px 0px;
	}
	@media ${(props) => props.theme.mobile} {
		height: 400px;
	}
`;

export const ReviewSlider = styled.div`
	overflow: hidden;
	position: relative;
	@media ${(props) => props.theme.mobile} {
		overflow-y: hidden;
	}
`;

export const ReviewBadge = styled.div`
	padding: 4px 13px 5px;
	margin: 0 auto;
	border-radius: 200px;
	border: solid 1px #e55f74;
	font-family: 'Roboto';
	font-size: 13px;
	color: #e55f74;
	width: 100px;
`;

export const Written = styled.div`
	margin-top: 20px;
	p {
		font-size: 23px;
		font-weight: 500;
		line-height: normal;
		text-align: center;
		color: #111111;
	}
	@media ${(props) => props.theme.mobile} {
		p {
			font-size: 18px;
		}
	}
`;

export const WrittenDate = styled.div`
	margin-top: 20px;
	p {
		font-size: 13px;
		line-height: 1.77;
		color: #9a9a9e;
	}
`;

export const SeemoreButton = styled.div`
	margin: 0 auto;
	margin-top: 40px;
	padding: 7px 28px 6px 29px;
	border-radius: 2px;
	border: 1px solid #ebebeb;
	width: 130px;
	p {
		font-size: 13px;
		line-height: 1.77;
		color: #111111;
	}
`;

export const UserItem = styled.div`
	background-color: #fbfbfb;
	padding: 14px 0px;
	width: 516px;
	margin: 0 auto;
	margin-top: 81px;

	display: flex;
	justify-content: space-between;
	position: relative;
	align-items: flex-end;
	p:nth-of-type(1) {
		transform: translateX(60%);
		text-align: left;
		font-size: 13px;
		font-weight: 500;
		color: #111111;
		:before {
			content: '';
			background-image: url('../img/order_01.png');
			background-repeat: no-repeat;
			background-size: cover;
			position: absolute;
			display: inline-block;
			top: 50%;
			left: -60%;
			transform: translateY(-50%) translateX(0%);
			width: 60px;
			height: 42px;

			border-radius: 1000px;
		}
	}
	p:nth-of-type(2) {
		transform: translateY(-50%);
		font-size: 13px;
		font-weight: bold;
		color: #111111;
	}
	@media ${(props) => props.theme.mobile} {
		width: auto;
		margin-top: 30px;
		p:nth-of-type(1) {
			font-size: 12px;
			transform: translateX(55%);
			:before {
				left: -55%;
				width: 50px;
				height: 35px;
			}
		}
	}
`;

export const ProductItem = styled.div`
	${(props) => (props.url ? `background:url("${props.url}");` : '')};
	background-size: cover;
	background-repeat: no-repeat;
	width: 60px;
	height: 43px;
	padding: 14px 5px;
	border-radius: 1000px;
	background-color: #fff;
`;

export const UsersWrapper = styled.div`
	/* margin-top: 83px; */
	margin-top: 10px;
	${ColWrap}:nth-of-type(1) {
		padding: 0 0px;
	}
	${ColWrap}:nth-of-type(2) {
		padding: 0 0px;
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 0px;
	}
`;

export const UsersReviewTitle = styled.div`
	position: relative;
	align-items: flex-end;
	h2 {
		font-size: 15px;
		font-weight: normal;
		color: #111111;
		display: inline-block;
	}
	@media ${(props) => props.theme.mobile} {
		h2 {
			display: none;
			font-size: 0;
		}
	}
`;

export const InputWrapper = styled.div`
	float: right;
	@media ${(props) => props.theme.mobile} {
		position: absolute;
		transform: translateY(-150%);
	}
`;

export const UsersReviewContent = styled.div`
	margin: 0px -15px;
`;

export const UsersReviewBox = styled.div`
	margin-top: 33px;
`;

export const UsersReview = styled.div`
	position: relative;
	width: 25%;
	display: inline-block;
	padding: 0px 15px;
	vertical-align: top;
	margin-bottom: 100px;
	@media ${(props) => props.theme.mobile} {
		width: 50%;
		display: inline-block;
		margin-bottom: 20px;
	}
`;

export const ReviewUserImg = styled.div`
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	box-sizing: border-box;
	width: auto;
	height: 240px;
	@media ${(props) => props.theme.mobile} {
		height: 0px;
		padding-bottom: 70.25%;
	}
`;

export const ReviewWriting = styled.div`
	margin: 20px 0px;
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 19px;
	p {
		margin-bottom: 10px;
		font-size: 15px;
		font-weight: 500;
		line-height: 22px;
		color: #111111;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	small {
		display: inline-block;
		font-size: 13px;
		line-height: 23px;
		color: #999;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	span {
		:after {
			display: inline-block;
			content: '';
			width: 1px;
			height: 10px;
			background-color: #999;
			margin: 0 6px;
		}
	}
`;

export const ReviewProductDetails = styled.div`
	display: table;
	vertical-align: middle;
	width: 100%;
	padding: 0px 0px 20px 0px;
	cursor: pointer;
	transition: 0.5s;
	:hover {
		opacity: 0.7;
	}
	@media ${(props) => props.theme.mobile} {
		display: none;
	}
`;

export const ReviewUserImage = styled.div`
	display: block;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-repeat: no-repeat;
	background-size: 60px auto;
	background-position: center center;
	background-color: ${(props) => (props.url ? '#fbfbfb' : 'none')};
	border: 1px solid #fbfbfb;
`;

export const ReviewUserItem = styled.div`
	display: table-cell;
	width: auto;
	vertical-align: middle;
	color: #111111;
	padding-left: 20px;
	p {
		font-weight: 500;
		font-size: 11px;
		line-height: 16px;
		margin-bottom: 2px;
	}
	span {
		display: inline-block;
		font-size: 13px;
		line-height: 19px;
	}
	@media ${(props) => props.theme.mobile} {
		p {
			font-size: 14px;
			padding-top: 6px;
			margin-bottom: 0px;
		}
		span {
			font-size: 14px;
			margin-bottom: 0px;
		}
	}
`;

export const ReviewUserPrice = styled.div`
	display: table-cell;
	width: 100px;
	vertical-align: middle;
	p {
		text-align: right;
		font-weight: bold;
		font-size: 13px;
		line-height: 19px;
		color: #111;
	}
	@media ${(props) => props.theme.mobile} {
		display: none;
		p {
			display: block;
			font-size: 14px;
			line-height: 20px;
			text-align: left;
			padding-left: 20px;
		}
	}
`;

export const ReviewMoreButton = styled.div`
	@media ${(props) => props.theme.mobile} {
		position: relative;
		display: block;
		margin: 0px 20px;
		margin-top: 70px;
		text-align: center;
		button {
			width: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			background: #fff;
			border: 2px solid #999999;
			box-sizing: border-box;
			border-radius: 200px;
			padding: 13px 0px;
			font-size: 16px;
			text-align: center;
			color: #111;
		}
	}
`;

export const WrittenButton = styled.div`
	position: relative;
	display: inline-block;
	margin-left: 20px;
	width: 120px;
	height: 40px;
	border-radius: 200px;
	font-size: 13px;
	font-weight: 500;
	line-height: 40px;
	text-align: center;
	background-color: #e55f74;
	color: #fff;
	cursor: pointer;
	margin-bottom: 8px;
	transform: translateY(-3px);
	transition: 0.3s;

	:hover {
		opacity: 0.9;
	}
	@media ${(props) => props.theme.mobile} {
		width: 90px;
		/* position: absolute; */
		/* right: 0; */
		/* transform: translateY(-150%); */
		transform: translateY(-5px);

		font-size: 12px;
	}
`;

export const IncludeUrl = styled.div`
	position: absolute;
	top: -10px;
	right: 0;
	border: 2px solid #e55f74;
	border-radius: 150px;
	padding: 4px 6px;
	background-color: #fff;
	transition: 0.4s;
	cursor: pointer;
	:hover {
		opacity: 0.6;
	}
`;

export const IsFinest = styled.div`
	display: inline-block;
	border: 2px solid #e55f74;
	border-radius: 150px;
	padding: 0 4px;
	margin-right: 6px;
	transition: 0.3s;
	:hover {
		color: #fff;
		background-color: #e55f74;
	}
`;
