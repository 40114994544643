import styled from 'styled-components';

// 메인페이지 베스트 스켈레톤 //

export const MainProductWrapper = styled.div`
    display: inline-block;
    width: 299px;
    height: auto;
    margin-right: 30px;
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    div {
        background-color: darkgray;
        border-radius: 2px;
    }
`;

export const MainProductDiv1 = styled.div`
    width: 299px;
    height: 299px;
    margin-bottom: 20px;
    @media ${(props) => props.theme.mobile} {
        height: 282px;
    }
`;

export const MainProductDiv2 = styled.div`
    width: 50px;
    height: 17px;
    margin-bottom: 8px;
`;

export const MainProductDiv3 = styled.div`
    width: 230px;
    height: 15px;
    margin-bottom: 15px;
    @media ${(props) => props.theme.mobile} {
        height: 20px;
        margin-bottom: 14px;
    }
`;

export const MainProductDiv4 = styled.div`
    width: 80px;
    height: 20px;
    @media ${(props) => props.theme.mobile} {
        height: 16px;
    }
`;

// 메인베스트 고객리뷰 스켈레톤 //

export const MainReviewWrapper = styled.div`
    display: inline-block;
    width: 353px;
    height: 450px;
    margin-right: 30px;
    background-color: #fff;
    animation: blink 1s linear infinite alternate;
`;

export const MainReviewDiv1 = styled.div`
    width: 353px;
    height: 240px;
    background-color: darkgray;
    border-radius: 2px;
`;

export const MainReviewDiv2 = styled.div`
    padding-top: 17px;
    padding-bottom: 21px;
    border-bottom: 1px solid #ebebeb;
    div:nth-child(1) {
        width: 240px;
        height: 28px;
        background-color: darkgray;
        border-radius: 2px;
        margin-bottom: 7px;
    }
    div:nth-child(2) {
        width: 140px;
        height: 22px;
        background-color: darkgray;
        border-radius: 2px;
    }
`;

export const MainReviewDiv3 = styled.div`
    display: table;
    vertical-align: middle;
    width: 100%;
    padding: 20px 0;
`;

export const MainReviewDiv4 = styled.div`
    display: table-cell;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: darkgray;
`;

export const MainReviewDiv5 = styled.div`
    display: table-cell;
    width: auto;
    vertical-align: middle;
    color: #111111;
    padding-left: 20px;
    div:nth-child(1) {
        width: 80px;
        height: 16px;
        background-color: darkgray;
        border-radius: 2px;
        margin-bottom: 3px;
    }
    div:nth-child(2) {
        width: 140px;
        height: 20px;
        background-color: darkgray;
        border-radius: 2px;
    }
`;

export const MainReviewDiv6 = styled.div`
    display: table-cell;
    text-align: right;
    padding-top: 25px;
    div {
        width: 70px;
        height: 20px;
        background-color: darkgray;
        border-radius: 2px;
    }
`;

// 이벤트 스켈레톤 //

export const SkeletonEventContent = styled.div`
    p:nth-of-type(1) {
        width: 110px;
        margin-top: 20px;
        font-size: 15px;
        font-weight: 500;
        color: darkgray;
        background-color: darkgray;
    }
    p:nth-of-type(2) {
        width: 300px;
        margin-top: 7px;
        font-size: 17px;
        font-weight: bold;
        color: darkgray;
        background-color: darkgray;
    }
    span:nth-of-type(1) {
        width: 150px;
        display: block;
        margin-top: 15px;
        font-size: 13px;
        color: darkgray;
        background-color: darkgray;
    }
`;

export const ContentImg = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 480px;
    height: 280px;
    box-sizing: border-box;
    background-color: darkgray;
    @media ${(props) => props.theme.mobile} {
        width: auto;
        height: 175px;
    }
`;

// 주문 내역 스켈레톤 //

export const Product = styled.div`
    width: 160px;
    height: 120px;
    position: relative;
    display: inline-block;
    margin-right: 30px;
    background: darkgray;
    animation: blink 1.4s ease-in-out infinite alternate;
    :after {
        animation: blink 1.4s ease-in-out infinite alternate;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 120px;
        height: 84px;
    }
    @media ${(props) => props.theme.mobile} {
        width: 30%;
        height: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
        :after {
            width: 100px;
            height: 70px;
        }
    }
`;

export const ProductDetails = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-top: 21px;
    p {
        font-size: 13px;
        line-height: normal;
        background-color: darkgray;
        color: darkgray;
        animation: blink 1.4s ease-in-out infinite alternate;
        width: 93px;
        height: 19px;
    }
    strong {
        display: block;
        margin-top: 2px;
        font-size: 17px;
        font-weight: 500;
        line-height: normal;
        background-color: darkgray;
        color: darkgray;
        animation: blink 1.4s ease-in-out infinite alternate;
        width: 125px;
        height: 25px;
    }
    small {
        display: block;
        margin-top: 10px;
        font-size: 15px;
        line-height: normal;
        background-color: darkgray;
        color: darkgray;
        animation: blink 1.4s ease-in-out infinite alternate;
        width: 237px;
        height: 22px;
    }
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media ${(props) => props.theme.mobile} {
        width: 60%;
        margin-top: 9px;
        p {
            font-size: 11px;
        }
        strong {
            font-size: 15px;
        }
        small {
            font-size: 13px;
            width: 165px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const ProductButton = styled.div`
    @media ${(props) => props.theme.mobile} {
        margin: 0 -10px 0px -10px;
    }
`;

export const SeeMore = styled.div`
    position: absolute;
    top: 45%;
    right: 30px;
    transform: translateY(-45%);
    button {
        width: 90px;
        padding: 6px 0px 5px 0px;
        border: 0px;
        border-radius: 2px;
        color: darkgray;
        background-color: darkgray;
        animation: blink 1.4s ease-in-out infinite alternate;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }
    @media ${(props) => props.theme.mobile} {
        position: relative;
        display: inline-block;
        top: 0;
        right: 0;
        transform: translateY(0);
        padding: 0 10px 0px 10px;
        width: 50%;
        button {
            width: 100%;
            padding: 15px 0px;
        }
    }
`;

export const OrderCancellation = styled.div`
    position: absolute;
    top: 65%;
    right: 30px;
    transform: translateY(-65%);
    button {
        width: 90px;
        padding: 5px 0px 6px 0px;
        border: 0px;
        border-radius: 2px;
        color: darkgray;
        background-color: darkgray;
        animation: blink 1.4s ease-in-out infinite alternate;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
    }
    @media ${(props) => props.theme.mobile} {
        position: relative;
        display: inline-block;
        top: 0;
        right: 0;
        transform: translateY(0);
        padding: 0 10px 0px 10px;
        width: 50%;
        button {
            width: 100%;
            padding: 15px 0px;
        }
    }
`;

export const OpenChatSection = styled.div``;

export const SkeletonSearchDiv = styled.div`
    width: 100%;
    height: 100%;
    animation: blink 1.4s ease-in-out infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export const SkeletonSearchImage = styled.a`
    display: block;
    width: 100%;
    max-width: 353px;
    height: 0;
    padding-bottom: 100%;
    background-color: #c4c4c4;
    margin: 0 auto;
    border-radius: 2px;
    @media ${(props) => props.theme.mobile} {
        padding-bottom: 53.4%;
    }
`;

export const SkeletonSearchDetail = styled.div`
    width: 100%;
    max-width: 353px;
    height: auto;
    padding: 20px 0;
    margin: 0 auto;
    padding-top: 22px;
    @media ${(props) => props.theme.mobile} {
        padding-top: 13px;
        padding-bottom: 49px;
    }
`;

export const SkeletonSearchType = styled.div`
    width: 30%;
    height: 19px;
    margin-bottom: 8px;
    background-color: #c4c4c4;
    @media ${(props) => props.theme.mobile} {
        font-size: 16px;
        line-height: 23px;
    }
`;

export const SkeletonSearchName = styled.div`
    width: 50%;
    height: 16px;
    margin-bottom: 15px;
    background-color: #c4c4c4;
    @media ${(props) => props.theme.mobile} {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
    }
`;

export const SkeletonSearchPrice = styled.div`
    width: 35%;
    height: 22px;
    background-color: #c4c4c4;
    @media ${(props) => props.theme.mobile} {
        font-size: 18px;
        line-height: 26px;
    }
`;

export const SktonHomeStoryTipCard = styled.a`
    display: inline-block;
    position: relative;
    width: 480px;
    height: 720px;
    margin-right: 31px;
    /* overflow-x: auto; */
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media ${(props) => props.theme.mobile} {
        width: 380px;
        height: 500px;
    }
`;

export const SktonHomeStoryCardImage = styled.div`
    width: 100%;
    height: 480px;
    background-color: #eee;
    @media ${(props) => props.theme.mobile} {
        height: 300px;
    }
`;

export const SktonHomeStoryCardContent = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 240px;
    background-color: darkgray;
    padding: 40px;
    @media ${(props) => props.theme.mobile} {
        height: 200px;
        font-size: 18px;
        line-height: 26px;
        padding: 30px;
    }
`;

export const SktonHomeStoryCardArrow = styled.div`
    position: absolute;
    left: 40px;
    bottom: 40px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    background-image: url(/img/arrow-right-sharp.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 18px auto;
    @media ${(props) => props.theme.mobile} {
        left: 30px;
        bottom: 30px;
        width: 48px;
        height: 48px;
        background-size: 24px auto;
    }
`;

export const SktonProductsProductWrapper = styled.div`
    display: inline-block;
    width: 25%;
    height: 0;
    padding: 0 15px;
    padding-bottom: 36%;
    vertical-align: top;
    margin-top: 6.528%;
    border-bottom: 1px solid #ebebeb;
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media (max-width: 1200px) {
        width: 33.3%;
        margin-top: 5.695%;
        padding-bottom: 53%;
    }
    @media ${(props) => props.theme.mobile} {
        padding: 0 10px;
        width: 50%;
        margin-top: 7.695%;
        padding-bottom: 64%;
    }
`;

export const SktonProductsProduct = styled.div`
    width: 100%;
    height: 100%;
`;

export const SktonProductsProductImage = styled.a`
    display: block;
    width: 100%;
    max-width: 353px;
    height: 0;
    padding-bottom: 100%;
    background-color: darkgray;
    margin: 0 auto;
    border-radius: 2px;
    @media ${(props) => props.theme.mobile} {
        padding-bottom: 84%;
    }
`;

export const SktonProductsProductDetail = styled.div`
    width: 100%;
    max-width: 353px;
    height: 0;
    padding: 20px 0;
    padding-bottom: 59.206798866%;
    margin: 0 auto;
    @media ${(props) => props.theme.mobile} {
        padding-top: 13px;
    }
`;

export const SktonProductsProductType = styled.div`
    width: 20%;
    height: 15px;
    background-color: darkgray;
    margin-bottom: 5px;
    @media ${(props) => props.theme.mobile} {
        height: 12px;
    }
`;

export const SktonProductsProductName = styled.div`
    width: 45%;
    height: 13px;
    margin-bottom: 16px;
    background-color: darkgray;
    @media ${(props) => props.theme.mobile} {
        width: 95%;
        height: 11px;
        margin-bottom: 10px;
    }
`;

export const SktonProductsProductPrice = styled.div`
    width: 25%;
    height: 18px;
    background-color: darkgray;
    @media ${(props) => props.theme.mobile} {
        height: 13px;
    }
`;

export const SktonProductsLabel = styled.div`
    position: relative;
    left: 20px;
    width: 25px;
    height: 35px;
    background-image: url(/img/gray-label.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    span {
        position: absolute;
        top: 4px;
        left: 50%;
        transform: translateX(-50%);
    }
    @media ${(props) => props.theme.mobile} {
        left: 10px;
        width: 20px;
        span {
            top: 2px;
        }
    }
`;

export const SktonStoryCard = styled.div`
    display: inline-block;
    width: 33.3%;
    height: auto;
    margin-bottom: 6.658%;
    vertical-align: top;
    padding: 0 15px;
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media ${(props) => props.theme.mobile} {
        width: 100%;
        max-width: 440px;
        margin: 0 auto;
        margin-bottom: 50px;
    }
`;

export const SktonCardImage = styled.a`
    display: inline-block;
    width: 100%;
    height: 0;
    padding-bottom: 58.333333333%;
    background-color: darkgray;
    border-radius: 2px;
    margin-bottom: 18px;
    @media ${(props) => props.theme.mobile} {
        margin-bottom: 20px;
    }
`;

export const SktonCardBadge = styled.div`
    display: inline-block;
    width: 25%;
    height: 21px;
    background-color: darkgray;
    box-sizing: border-box;
    border-radius: 300px;
    padding: 0 12px;
    margin-bottom: 16px;
    @media ${(props) => props.theme.mobile} {
        width: 30%;
        height: 20px;
        margin-top: 2px;
        margin-bottom: 11px;
    }
`;

export const SktonCardTitle = styled.div`
    width: 40%;
    height: 19px;
    background-color: darkgray;
    margin-bottom: 16px;
    border-radius: 2px;
    @media ${(props) => props.theme.mobile} {
        height: 19px;
        margin-bottom: 8px;
    }
`;

export const SktonCardTag = styled.div`
    width: 70%;
    height: 16px;
    background-color: darkgray;
    border-radius: 2px;
    @media ${(props) => props.theme.mobile} {
        height: 13px;
        line-height: 23px;
    }
`;

export const SktonStoryCardTag = styled.div`
    width: 15%;
    height: 16px;
    background-color: darkgray;
    border-radius: 2px;
    @media ${(props) => props.theme.mobile} {
        height: 13px;
        line-height: 23px;
    }
`;

// 이벤트 더보기

export const SktonMoreEventWrapper = styled.a`
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export const SktonEventMoreEventContent = styled.div`
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0px 20px;
    p {
        margin-top: 20px;
        background-color: darkgray;
        width: 20%;
        height: 15px;
    }
    p:nth-of-type(2) {
        margin-top: 7px;
        background-color: darkgray;
        width: 45%;
        height: 17px;
    }
    span {
        display: block;
        margin-top: 12px;
        background-color: darkgray;
        width: 35%;
        height: 14px;
    }

    @media ${(props) => props.theme.mobile} {
        display: block;
        margin-bottom: 50px;
        p {
            background-color: darkgray;
        }
        p:nth-of-type(2) {
            margin-top: 5px;
            background-color: darkgray;
            line-height: normal;
        }
        span {
            background-color: darkgray;
        }
    }
`;

export const SktonEventMoreContentImg = styled.div`
    background-color: darkgray;
    width: 480px;
    height: 280px;
    box-sizing: border-box;
    @media ${(props) => props.theme.mobile} {
        width: auto;
        height: 175px;
    }
`;

export const SktonUsersReview = styled.div`
    width: 25%;
    display: inline-block;
    padding: 0px 15px;
    vertical-align: top;
    margin-bottom: 100px;
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media ${(props) => props.theme.mobile} {
        width: 50%;
        display: inline-block;
        margin-bottom: 20px;
    }
`;

export const SktonReviewUserImg = styled.div`
    background-color: darkgray;
    box-sizing: border-box;
    width: auto;
    height: 240px;
    @media ${(props) => props.theme.mobile} {
        height: 0px;
        padding-bottom: 70.25%;
    }
`;

export const SktonReviewWriting = styled.div`
    margin: 20px 0px;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 22px;
    p {
        margin-top: 25px;
        margin-bottom: 17px;
        background-color: darkgray;
        width: 60%;
        height: 15px;
        border-radius: 2px;
    }
    small {
        display: inline-block;
        width: 50%;
        height: 13px;
        background-color: darkgray;
        border-radius: 2px;
    }
    @media ${(props) => props.theme.mobile} {
        padding-bottom: 24px;
        p {
            margin-top: 24px;
            margin-bottom: 15px;
            width: 80%;
            height: 16px;
        }
        small {
            width: 90%;
            height: 14px;
        }
    }
`;

export const SktonReviewProductDetails = styled.div`
    display: table;
    vertical-align: middle;
    width: 100%;
    padding: 0px 0px 20px 0px;
    @media ${(props) => props.theme.mobile} {
        display: none;
    }
`;

export const SktonReviewUserImage = styled.div`
    display: table-cell;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: darkgray;
`;

export const SktonReviewUserItem = styled.div`
    display: table-cell;
    width: auto;
    vertical-align: middle;
    padding-left: 20px;
    p {
        width: 50%;
        height: 11px;
        background-color: darkgray;
        border-radius: 2px;
        margin-bottom: 8px;
    }
    span {
        display: inline-block;
        width: 70%;
        height: 11px;
        background-color: darkgray;
        border-radius: 2px;
    }
    @media ${(props) => props.theme.mobile} {
        p {
            font-size: 14px;
            padding-top: 6px;
            margin-bottom: 0px;
        }
        span {
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
`;

export const SktonReviewUserPrice = styled.div`
    display: table-cell;
    width: 100px;
    vertical-align: middle;
    p {
        width: 80%;
        height: 15px;
        background-color: darkgray;
        border-radius: 2px;
    }
    @media ${(props) => props.theme.mobile} {
        p {
            display: block;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            padding-left: 20px;
        }
    }
`;

export const SktonPointProduct = styled.div`
    display: flex;
    position: relative;
    width: 50%;
    height: 300px;
    float: left;
    padding-top: 50px;
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    :after {
        display: block;
        position: absolute;
        bottom: 0;
        content: '';
        width: 100vw;
        height: 1px;
        background-color: #ebebeb;
    }
    @media ${(props) => props.theme.mobile} {
        width: 100%;
        display: block;
        height: 100%;
        padding-top: 0px;
        margin-bottom: 50px;
        border-bottom: none;
    }
`;

export const SktonPointProductImage = styled.a`
    display: inline-block;
    width: 40%;
    height: 200px;
    background-color: darkgray;
    border-radius: 2px;
    @media ${(props) => props.theme.mobile} {
        width: 100%;
        height: 0;
        padding-bottom: 53.18%;
        background-size: 45% auto;
    }
`;

export const SktonPointProductDetail = styled.div`
    width: 60%;
    padding-left: 30px;
    a {
        display: inline-block;
        width: 50%;
        height: 40px;
        border-radius: 200px;
        vertical-align: top;
        margin-right: 10px;
        background-color: darkgray;
    }
    @media ${(props) => props.theme.mobile} {
        width: 100%;
        padding-left: 0;
        a {
            width: calc(100% - 70px);
            height: 60px;
            line-height: 60px;
        }
    }
`;

export const SktonPointProductType = styled.div`
    width: 15%;
    height: 15px;
    background-color: darkgray;
    border-radius: 2px;
    margin-top: 28px;
    margin-bottom: 14px;
    @media ${(props) => props.theme.mobile} {
        width: 15%;
        font-size: 16px;
        line-height: 23px;
        padding-top: 20px;
        margin-top: 23px;
        margin-bottom: 8px;
    }
`;

export const SktonPointProductName = styled.div`
    width: 50%;
    height: 16px;
    background-color: darkgray;
    border-radius: 2px;
    margin-bottom: 16px;
    @media ${(props) => props.theme.mobile} {
        width: 70%;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 13px;
    }
`;

export const SktonPointProductPrice = styled.div`
    width: 20%;
    height: 19px;
    background-color: darkgray;
    border-radius: 2px;
    margin-bottom: 22px;
    @media ${(props) => props.theme.mobile} {
        width: 20%;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 22px;
    }
`;

export const SktonReviewImgStyle = styled.div`
    width: auto;
    height: 0;
    padding-bottom: 100%;
    border-radius: 2px;
    background-color: darkgray;
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media ${(props) => props.theme.mobile} {
    }
`;

export const SktonReviewBoxImgStyle = styled.div`
    width: auto;
    height: 80px;
    border-radius: 2px;
    background-color: darkgray;
    animation: blink 1s linear infinite alternate;
    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media ${(props) => props.theme.mobile} {
        font-size: 18px;
        line-height: 26px;
    }
`;
