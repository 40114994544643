import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 30px;
    max-height: 550px;
    margin-bottom:50px;
    overflow-y: auto;
    overflow-x: hidden;
    @media ${props => props.theme.mobile}{
        padding: 15px;
        margin-bottom:0px;
        max-height: 420px;
    }
`;

export const ReviewTitle = styled.div`
    padding-bottom: 19px;
    border: 1px solid #ebebeb;
    height: 69px;
    padding: 25px 0px 19px 30px;
    h2{
        font-size: 17px;
        font-weight: bold;
        line-height: normal;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        h2{
            font-size: 15px;
        }
        height: 64px;
        padding: 20px 0px 0px 20px;
    }
`;

export const Review = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-end;
`;

export const ReviewImg = styled.div`
    ${props => props.url ? `background:url("${props.url}");`: ''}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 25%;
    height: 77px;
    background-color: #fbfbfb;
    @media ${props => props.theme.mobile}{
        background-size: 70px auto;
        height: 75px;
    }
`;

export const ReviewItem = styled.div`
    width: 70%;
    height: 77px;
    vertical-align: top;
    padding: 15px 0px 20px 15px;
    span{
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        color: #9a9a9e;
    }
    p{
        margin-top: 5px;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        color: #111111;
    }
    p:nth-of-type(2){
        font-weight: normal;
    }
    @media ${props => props.theme.mobile}{
        padding: 0px 0px 0px 20px;
        height: 80px;
        span{
            font-size: 12px;
        }
        p{
            font-size: 12px;
        }
        p:nth-of-type(1){
            width: 160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const Write = styled.div`
    margin: 30px 0px 0px 0px;
    input {
        width:100%;
        margin-bottom:10px;
        position: relative;
        display: block;
        border: 1px solid #f0f0f0;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.73;
        padding: 20px;
        ::placeholder{
            color: #666;
        }
    }

    .text {
        ::placeholder {
            color: #aaa !important;
        }
    }

    .textarea {
        position: relative;
        z-index: 5;
        background: transparent;
        /* :focus {
            background: #fff;
        } */
        ${props => props.focus ? `
            background: #fff;
        ` : ``}
    }


    @media ${props=>props.theme.mobile} {
        margin: 15px 0px 0px 0px;
        input {
            padding:15px;
            font-size:13px;
            ::placeholder{
                font-size:13px;
                color: #666;
            }
        }
        textarea {
            padding: 15px;
            height:125px;
            font-size:13px;
            ::placeholder{
                font-size:13px;
                color: #666;
            }
        }
    }
`;

export const ReviewGallery = styled.div`
    overflow-x: scroll;
    overflow-y: visible;
    padding: 20px 0px 0px;
    width: 100%;
`;

export const GalleryList = styled.div`
    width: auto;
    max-width: 2200px;
    margin: 0px -5px;
    padding-bottom: 6px;
    .box{
        display: inline-block;
        width: 80px;
        height: 80px;
        padding: 0px 5px;
        position: relative;
        border: 1px dashed transparent;
        border-radius: 2px;
        vertical-align: top;
        margin-left:5px;
        :first-child {
            border: 1px dashed #9a9a9e;
        }
        a {
            cursor:pointer;
            display:none;
            position: absolute;
            top: 0px;
            right: 0px;
            transform:translate(50%,-50%);
            content:"";
            width: 18px;
            height: 18px;
            background-image: url("../img/close_01.svg");
        }
        :hover{
            border-radius: 2px;
            border: 1px dashed #e55f74;
            a {
                display:block;
            }
        }
    }
`;

export const Upload = styled.div`
    display:block;
    text-align: center;
    transform: translateY(50%);
    input {
        display:none;
    }
    cursor: pointer;
    p{
        font-size: 11px;
        font-weight: bold;
        color: #9a9a9e;
        span{
            color: #e55f74;
        }
    }
`;

export const Gallery = styled.div`
    ${props => props.url ? `background: url("${props.url}");` : ''};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 2px;
    width: auto;
    height: 80px;
    cursor: pointer;
`;

export const UploadButton = styled.div`
    width: 100%;
    height: auto;
    padding: 17px 0px;
    margin-top: 50px;
    display: block;
    position: relative;
    border-radius: 200px;
    background-color: #f5f5f5;;
    font-size: 15px;
    font-weight: 500;
    text-align: center; 
    color: #111;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        display: none;
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    button {
        width: 50%;
        height: 50px;
        background-color: #eeeeee;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #111111;
        border: none;
    }
    button:nth-of-type(2){
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #e55f74;
        color: #fff;
    }

`;

export const Placeholder = styled.div`
    position: absolute;
    width: 440px;
    height: 190px;
    transform: translateY(-200px);
    z-index: -1;
    box-sizing: border-box;
    color: #aaa;
    padding: 20px;
    div {
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 20px;
    }
    span {
        display: block;
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 10px;
    }
    small {
        font-size: 12px;
        line-height: 17px;
    }
    @media ${props => props.theme.mobile}{
        transform: translateY(-136px);
        width: calc(100% - 40px);
        div {
            display: none;
        }
        span {
            margin-bottom: 6px;
        }
        small {
            font-size: 11px;
            line-height: 13px;
        }
    }
`;

export const Count = styled.div`
    position: absolute;
    transform: translateY(-24px);
    z-index: 300;
    box-sizing: border-box;
    color: #aaa;
    right: 32px;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 20px;
    @media ${props => props.theme.mobile}{
        font-size: 11px;
        line-height: 13px;
    }
`;