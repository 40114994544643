import styled from 'styled-components';
import { RowWrap, ColWrap } from '../../../components/Grid/styled';

export const Wrapper = styled.div`
    position: relative;
    padding: 100px 0px 200px 0px;
    ${ColWrap}:nth-of-type(1) {
        width: 50%;
    }
    ${ColWrap}:nth-of-type(2) {
        padding-left: 30px;
        width: 50%;
    }
    @media ${(props) => props.theme.mobile} {
        overflow: hidden;
        padding: 50px 0px 79px 0px;
        border-bottom: 1px solid #ebebeb;
        ${RowWrap}:nth-of-type(1) {
            display: block;
            width: 100%;
        }
        ${ColWrap}:nth-of-type(1) {
            display: block;
            width: 100%;
            padding-left: 0px;
        }
        ${ColWrap}:nth-of-type(2) {
            display: block;
            width: 100%;
            padding-left: 0px;
            margin-top: 20px;
        }
    }
`;

export const Container = styled.div`
    @media ${(props) => props.theme.mobile} {
        margin: 0px 20px;
    }
`;

export const SingleWrapper = styled.div``;

export const ReviewBox = styled.div``;

export const ReviewImg = styled.div`
    background-image: url(${(props) => (props.url ? props.url : '')});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: auto;
    height: 0;
    padding-bottom: 100%;
    @media ${(props) => props.theme.mobile} {
    }
`;

export const ReviewImgBox = styled.div`
    margin-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
`;

export const BoxWrapper = styled.div`
    width: ${(props) => (props.count ? 90 * props.count + 'px' : '100%')};
    max-width: ${(props) => (props.count ? 90 * props.count + 'px' : '100%')};
    margin: 0px -5px;
    .box {
        display: inline-block;
        width: 90px;
        height: 80px;
        padding: 0px 5px;
    }
    @media ${(props) => props.theme.mobile} {
    }
`;

export const BoxImg = styled.div`
    background-image: url(${(props) => (props.url ? props.url : '')});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: auto;
    height: 80px;
    cursor: pointer;
`;

export const ReviewDetails = styled.div`
    border-bottom: 1px solid #ebebeb;
    padding: 0px 0px 31px 0px;
    margin-bottom: 20px;
`;

export const ReviewTitle = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 10px;
    div {
        display: inline-block;
        border: 2px solid #e55f74;
        border-radius: 150px;
        padding: 4px 10px;
        margin-right: 6px;
        font-size: 20px;
        font-weight: bold;
        color: #111111;
    }
    h2 {
        font-size: 20px;
        font-weight: bold;
        color: #111111;
    }
    p {
        font-size: 11px;
        color: #111111;
        :before {
            content: '';
            background-image: url('../img/review_single_02.png');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            display: inline-block;
            top: 50%;
            right: 13%;
            transform: translateY(-30%) translateX(-13%);
            width: 18px;
            height: 18px;
        }
    }
`;

export const ReviewUser = styled.div`
    margin-bottom: 20px;
    small {
        font-size: 13px;
        line-height: 1.77;
        color: #9a9a9e;
    }
    span {
        :after {
            display: inline-block;
            content: '';
            width: 1px;
            height: 10px;
            background-color: #999;
            margin: 0 6px;
        }
    }
`;

export const ReviewContent = styled.div`
    line-height: 1.6;
    font-size: 13px;
    p {
        font-size: 15px;
        line-height: 1.73;
        color: #111111;
    }
    img {
        display: none;
    }
    @media ${(props) => props.theme.mobile} {
        p {
            font-size: 13px;
        }
    }
`;

export const ReviewProductDetails = styled.div`
    display: table;
    vertical-align: middle;
    width: 100%;
    padding: 0px 0px 20px 0px;
    cursor: pointer;
    transition: 0.5s;
    :hover {
        opacity: 0.7;
    }
`;

export const ReviewUserImage = styled.div`
    display: table-cell;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-image: url(${(props) => (props.url ? props.url : '')});
    background-repeat: no-repeat;
    background-size: 60px auto;
    background-position: center center;
    background-color: #fbfbfb;
`;

export const ReviewUserItem = styled.div`
    display: table-cell;
    width: auto;
    vertical-align: middle;
    color: #111111;
    padding-left: 20px;
    p {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 2px;
    }
    span {
        display: inline-block;
        font-size: 13px;
        line-height: 19px;
    }
    @media ${(props) => props.theme.mobile} {
        p {
            font-size: 14px;
            padding-top: 6px;
            margin-bottom: 0px;
        }
        span {
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
`;

export const ReviewUserPrice = styled.div`
    display: table-cell;
    width: 100px;
    vertical-align: middle;
    p {
        text-align: right;
        font-weight: bold;
        font-size: 13px;
        line-height: 19px;
        color: #111;
    }
    @media ${(props) => props.theme.mobile} {
        p {
            display: block;
            font-size: 14px;
            line-height: 20px;
            transform: translateY(0%);
        }
    }
`;

export const MobileReviewTitle = styled.div`
    @media ${(props) => props.theme.mobile} {
        margin-bottom: 10px;
        h2 {
            font-size: 18px;
            font-weight: bold;
            color: #111111;
        }
    }
`;

export const MobileReviewUser = styled.div`
    @media ${(props) => props.theme.mobile} {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: flex-end;
        margin-bottom: 20px;
        small {
            font-size: 12px;
            line-height: 1.77;
            color: #9a9a9e;
        }
        span {
            :after {
                display: ${(props) =>
                    props.show
                        ? `
                    inline-block
                `
                        : `
                    none
                `};
                content: '';
                width: 1px;
                height: 10px;
                background-color: #999;
                margin: 0 6px;
            }
        }
        p {
            font-size: 11px;
            color: #111111;
            transform: translateY(-50%);
            :before {
                content: '';
                background-image: url('../img/review_single_02.png');
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                display: inline-block;
                top: 30%;
                right: 100%;
                transform: translateY(-30%) translateX(-30%);
                width: 18px;
                height: 18px;
            }
        }
    }
`;
export const ButtonGroup = styled.div`
    margin: 0px -10px;
    display: flex;
    flex-direction: row;
    div {
        padding: 0px 10px;
        vertical-align: top;
        flex: 1;
    }
    div:nth-child(2) + div {
        /* width: 70%; */
    }
    div:nth-child(2) {
        /* width: 30%; */
        flex: 0.3;
    }
    a:not(.trash-button) {
        transition: 0.3s;
        :hover {
            background: #eee;
        }
    }
    .trash-button {
        border: 1px solid #eee;
        line-height: 65px;
        transition: 0.3s;
        :hover {
            border: 1px solid #000;
        }
    }
`;

export const ReviewPoint = styled.div`
    width: fit-content;
    height: 39px;
    background-image: url('../img/speech_bubble.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 22px;
    font-size: 11px;
    line-height: 32px;
    text-align: left;
    color: #fff;
    margin-bottom: 10px;
    @media (max-width: 770px) {
        margin-bottom: 0;
        line-height: 34px;
    }
    span {
        font-weight: bold;
    }
    img {
        vertical-align: sub;
    }

    ${(props) =>
        props.isshow
            ? `
        display: block
    `
            : `
        display: none;
    `}
`;

export const IsFinest = styled.div`
    display: inline-block;
    height: 56px;
    line-height: 56px;
    font-size: 15px;
    font-weight: 500;
    transition: 0.5s;
    text-align: center;
    border: 200px;
    border: 1px solid #eee;
    padding: 0 10px;
    background-color: #fff;
    border-radius: 200px;
    margin-top: 10px;
    margin-left: auto;
    position: absolute;
    right: 0;
    cursor: pointer;

    ${(props) =>
        props.complete
            ? `
        pointer-events: none;
    `
            : `
        :hover {
            border: 1px solid #111;
        }
    `};
`;
