import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100%;
    /* background-image: linear-gradient(to left, rgba(255, 68, 0, 0.5), #fd746c); */
    background-image: linear-gradient(252.44deg, rgba(255, 68, 0, 0.5), rgba(253, 116, 108, 1));
`;

export const BackButton = styled.img`
    position: absolute;
    top: 57px;
    left: 56px;
    z-index: 1;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        top: 12px;
        left: 10px;
        width: 30px;
        height: auto;
    }
`;

export const CloseButton = styled.img`
    position: absolute;
    top: 59px;
    right: 59px;
    z-index: 1;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        top: 10px;
        right: 10px;
        width: 32px;
        height: auto;
    }
`;

export const InquireBox = styled.div`
    position: absolute;
    width: 430px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @media ${props => props.theme.mobile}{
        width: 90%;
    }
`;

export const TopSection = styled.div`
    width: 100%;
    margin-bottom: 30px;
    @media ${props => props.theme.mobile}{
        margin-bottom: 20px;
    }
`;

export const Title = styled.div`
    display: block;
    font-weight: bold;
    font-size: 25px;
    line-height: 38px;
    margin-left:0px;
    color: #FFF;
    @media ${props => props.theme.mobile}{
        font-size: 20px;
        font-weight: 500;
    }
`;

export const PageNum = styled.div`
    display: inline-block;
    width: auto;
    margin-right: 20px;
    font-size: 13px;
    font-weight: bold;
    line-height: 2.92;
    color: #fff;
    vertical-align: top;
`;

export const InquireTextarea = styled.textarea`
    width: calc(100% - 0px);
    height: 200px;
    margin: 15px 0 30px 20px;
    border-radius: 2px;
    border: solid 1px #fff;
    background-color: rgba(255, 255, 255, 0.08);
    margin-left: 0px;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #FFF;
    transition: 0.3s;
    ::placeholder {
        color: #FFF;
    }
    :hover {
        background: rgba(255, 255, 255, 0.01);
    }
    @media ${props => props.theme.mobile}{
        margin: 10px 0 25px 0px;
        width: 100%;
    }
`;

export const InquireInput = styled.input`
    display: block;
    width: calc(100% - 0px);
    height: 46px;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    font-weight: 500;
    font-size: 15px;
    line-height: 44px;
    color: #FFF;
    margin-left: 0px;
    margin-bottom: 15px;
    padding: 0 10px;
    transition: 0.3s;
    ::placeholder {
        color: #FFF;
    }
    :hover {
        background: rgba(255, 255, 255, 0.01);
    }
    @media ${props => props.theme.mobile}{
        margin-left: 0px;
        width: 100%;
    }
`;

export const Submit = styled.button`
    display: inline-block;
    width: 84px;
    height: 35px;
    line-height: 35px;
    background: #FFF;
    border-radius: 2px;
    font-weight: 500;
    font-family: "Noto Sans KR";
    font-size: 15px;
    line-height: 18px;
    color: #FD746C;
    border: none;
    margin-left: 0px;
    margin-top: 15px;
    padding-bottom: 2px;
    transition:.3s;
    ${props=>props.type=='default' ? 'margin-left:20px;background:transparent;color:#fff;border:1px solid #fff;float:right;' :''}
    :hover {
        opacity:.8;
    }
    img {
        position: relative;
        left: 6px;
        bottom: 1px;
    }
    @media ${props => props.theme.mobile}{
        margin-left: 0;
    }
`;


export const List = styled.ul`
    margin-top:20px;
    li {
        display: block;
        padding: 13px 25px;
        ${props=>props.type == 'list' ? `width:100%;` : `
            width:47%;
            float:left;
            margin-right:10px;
            padding: 25px 25px;
        ` }
        
        cursor: pointer;
        border: solid 1px #fff;
        background-color: rgba(255, 255, 255, 0.08);
        box-sizing: border-box;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #FFF;
        margin-bottom:10px;
        transition: .3s;
        :hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
        img {
            width:24px;
            height:24px;
            margin-right:10px;
        }
    }
    @media ${props => props.theme.mobile}{
        li {
            display: inline-flex;
            align-items: center;
            padding: 0 25px;
            min-height: 70px;
        }
    }
`;

export const Orders = styled.ul`
    margin-top:20px;
    max-height: 350px;
    overflow-x:hidden;
    overflow-y:auto;
    li {
        display: block;
        padding: 15px 25px;
        cursor: pointer;
        border: solid 1px #fff;
        background-color: rgba(255, 255, 255, 0.08);
        box-sizing: border-box;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #FFF;
        margin-bottom:15px;
        transition: .3s;
        :hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
        small {
            display:block;
            font-size:13px;
        }
    }
`;

export const Content = styled.div`
    margin-top:20px;
    border: solid 1px #fff;
    background-color: rgba(255, 255, 255, 0.08);
    padding: 10px;
    color:#fff;
    line-height:1.6;
    font-size:16px;
`;

export const ShippingInfo = styled.div`
    background:#fff;
    position:relative;
    padding:24px 0px;
    text-align:center;
    margin-top:20px;
    img{
        margin-right:10px;
        display:inline-block;
    }
    span {
        font-size:15px;
        font-weight:bold;
        color:#FD756B;
    }
`;

export const QnaWrapper = styled.div`
    margin-top:30px;
`;

export const Notice = styled.div`
    color: #fff;
    padding-top: 16px;
    position: relative;
    font-size: 16px;
    :before {
        position: relative;
        display: inline-block;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #fff;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 8px;
    }
    @media ${props => props.theme.mobile}{
        font-size: 14px;
    }
`;

export const OpenChatLink = styled.a`
    display: inline-block;
    width: auto;
    height: 35px;
    line-height: 34px;
    text-align: center;
    background: #fff;
    border-radius: 2px;
    font-weight: 500;
    font-size: 15px;
    color: #FD746C;
    border: none;
    margin-left: 15px;
    margin-top: 15px;
    padding: 0 10px;
    transition:.3s;
    float: right;
    :hover {
        opacity:.8;
    }

    @media ${props => props.theme.mobile}{
        margin-left: 10px;
    }
`;