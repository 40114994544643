import styled from 'styled-components';
import { RowWrap } from '../../../components/Grid/styled';

export const LoginWrapper = styled.div`
    position: relative;
    height: 100vh;
    overflow: hidden;
    @media ${props => props.theme.mobile}{
        overflow: scroll;
        ${RowWrap}{
            display: block;
        }
    }
`;

export const Mobile = styled.div`
    @media ${props => props.theme.mobile}{
        display: block;
        position: absolute;
        top: 30px;
        left: 15px;
        z-index: 9999;
        h1{
            margin-top: 96px;
            font-size: 24px;
            font-weight: bold;
            color: #111;
        }
    }
`;

export const BackButton = styled.div`
    svg{
        width: 34px;
        height: 34px;
    }
`;

export const LoginImg = styled.div`
    ${props => props.url ? `background: url("${props.url}");` : ''}
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    width: 40%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    @media ${props => props.theme.mobile}{
        background: url();
        width: 100%;
        height: 245px;
        display: block;
        background-color: #f5f5f5;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
    }
`;

export const Wrapper = styled.div`
    width: 60%;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    @media ${props => props.theme.mobile}{
        width: 100%;
        display: block;
        position: relative;
        left: 0;
        bottom: -150px;
    }
    & > ${BackButton} {
        position:absolute;
        left: 0px;
        top: -60px;
    }
`;

export const LoginContent = styled.div`
    width: 450px;
    h1{
        position: relative;
        font-size: 30px;
        font-weight: bold;
        text-align: left;
        color:#111;
    }
    @media ${props => props.theme.mobile}{
        width: 90%;
        margin: 0 auto;
        h1{
            display: none;
        }
    }
`;

export const IdInput = styled.input`
    position: relative;
    width: 450px;
    margin-top: 50px;
    padding-bottom: 9px;
    border: none;
    border-bottom: 1px solid #999;
    display: block;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
    color: #111111;
    ::placeholder{
        color: #111;
    }
    @media ${props => props.theme.mobile}{
        width: 100%;
        margin-top: 70px;
    }
`;

export const PwInput = styled.input`
    position: relative;
    width: 450px;
    display: block;
    margin: 50px 0px 30px 0px;
    padding-bottom: 9px;
    border: none;
    border-bottom: 1px solid #999;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
    color: #111111;
    ::placeholder{
        color: #111;
    }
    @media ${props => props.theme.mobile}{
        width: 100%;
        font-size: 16px;
    }
`;

export const PassWord = styled.div`
    position: relative;
    img{
        position: absolute;
        cursor:pointer;
        top: 0;
        right: 0px;
        width: 24px;
        height: 24px;
    }
    @media ${props => props.theme.mobile}{
        font-size: 16px;
    }
`;

export const CheckLogin = styled.div`
    position: relative;
    box-sizing: border-box;
    font-size: 13px;
    color:111;
    img{
        width: 24px;
        height: 24px;
    }
    p{
        margin-left: 8px;
        vertical-align: super;
        display: inline-block;
    }
    a{
        text-decoration: underline;
        vertical-align: super;
        float: right;
        margin-top: 7px;
    }
    input[type=checkbox]{
        display:none;
    }
    input[type=checkbox]:checked + label{
        svg{
            path {
                fill: #e55f74;
            }
            circle {
                stroke:#e55f74;
                fill:  #fff; 
            }
        }
    }
    @media ${props => props.theme.mobile}{
        font-size: 14px;
    }
`;


export const LoginButton = styled.div`
    position: relative;
    margin-top: 52px;
    @media ${props => props.theme.mobile}{
        font-size: 18px;
    }
`;

export const RegisterButton = styled.div`
    position: relative;
    margin-top: 10px;
    @media ${props => props.theme.mobile}{
        font-size: 18px;
    }
`;