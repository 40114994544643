import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 200px;
    @media ${(props) => props.theme.mobile} {
        padding: 0 20px;
        padding-top: 50px;
        padding-bottom: 80px;
        border-bottom: 1px solid #ebebeb;
    }
    select {
        width: 95%;
        height: 54px;
        background: none;
        border: none;
    }
`;

export const Title = styled.div`
    font-size: 27px;
    line-height: 39px;
    font-weight: bold;
    color: #111;
    margin-bottom: 30px;
    @media ${(props) => props.theme.mobile} {
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 19px;
    }
`;

export const PostCodeWrapper = styled.div`
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    & > div:first-child {
        position: fixed;
        max-width: 500px;
        height: 400px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }
    .overlay {
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        top: 0px;
        left: 0px;
        z-index: 998;
        width: 100%;
        height: 100%;
    }
`;

export const OrderInfoWrapper = styled.div`
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    div:last-child {
        border: none;
    }
`;

export const OrderInfo = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid #fafafa;
    @media ${(props) => props.theme.mobile} {
        position: relative;
    }
`;

export const ProductImage = styled.div`
    width: 200px;
    height: 150px;
    background: #fbfbfb;
    border-radius: 2px;
    background-image: url(${(props) => (props.url ? props.url : '')});
    background-repeat: no-repeat;
    background-size: 90% auto;
    background-position: center center;
    @media ${(props) => props.theme.mobile} {
        width: 153px;
        height: 120px;
    }
`;

export const ProductDetail = styled.div`
    width: calc(100% - 230px);
    padding-left: 30px;
    @media ${(props) => props.theme.mobile} {
        width: calc(100% - 153px);
        padding-left: 20px;
    }
`;

export const Name = styled.div`
    span {
        font-size: 15px;
        line-height: 22px;
        color: #111;
    }
    margin-top: 25px;
    @media ${(props) => props.theme.mobile} {
        display: table;
        width: 100%;
        height: 56px;
        span {
            display: table-cell;
            vertical-align: middle;
            height: 56px;
            font-size: 16px;
            line-height: 1.75;
        }
        margin-top: -6px;
    }
`;

export const Price = styled.div`
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
    color: #111111;
    margin-top: 10px;
    @media ${(props) => props.theme.mobile} {
        font-size: 18px;
        line-height: 26px;
        margin-top: 5px;
    }
`;

export const Amount = styled.div`
    display: flex;
    margin-top: 22px;
    img {
        width: 21px;
        height: 21px;
        cursor: pointer;
    }
    span {
        display: inline-block;
        margin: 0 14px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #111;
    }
    @media ${(props) => props.theme.mobile} {
        margin-top: 15px;
        img {
            width: 23px;
            height: 23px;
        }
    }
`;

export const Delete = styled.div`
    position: relative;
    width: 30px;
    span {
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 15px;
        line-height: 22px;
        color: #111;
        text-decoration: underline;
        cursor: pointer;
    }
    @media ${(props) => props.theme.mobile} {
        position: absolute;
        bottom: 43px;
        right: 0;
        span {
            font-size: 16px;
            line-height: 23px;
        }
    }
`;

export const SubTitle = styled.div`
    font-size: 20px;
    line-height: 29px;
    font-weight: bold;
    color: #111;
    margin-top: 50px;
    margin-bottom: 20px;
    @media ${(props) => props.theme.mobile} {
        margin-top: 30px;
    }
`;

export const UserInfor = styled.div`
    ${(props) => (props.isopen ? `display:block` : `display:none`)};
    padding: 0 0 35px 0;
`;

export const EditDone = styled.div`
    margin: 13px 0 0 0;
    width: 100%;
    text-align: center;
    button {
        width: auto;
        height: 54px;
        line-height: 54px;
        display: inline-block;
        position: relative;
        border: none;
        border: 1px solid #000;
        border-radius: 200px;
        background: #fff;
        padding: 0 49px 0 36px;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        :after {
            position: absolute;
            content: '';
            width: 15px;
            height: 14px;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            background-image: url('../img/edit_icon.png');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
`;

export const InputSection = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    input {
        display: inline-block;
        width: 49%;
        height: 56px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 200px;
        margin-bottom: 20px;
        padding-left: 30px;
        font-size: 15px;
        line-height: 22px;
        color: #111;
        :focus {
            border: 1px solid #000;
        }
    }
    .on_postcode {
        cursor: pointer;
    }
    .block {
        margin-right: 50%;
    }
    @media ${(props) => props.theme.mobile} {
        input {
            width: 100%;
            height: 60px;
        }
        /* input:last-child {
            margin-bottom: 0;
        } */
        select {
            height: 58px;
        }
        .block {
            margin-right: 0;
        }
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    button {
        position: absolute;
        width: 120px;
        height: 56px;
        line-height: 56px;
        border-radius: 200px;
        border: none;
        left: 49%;
        transform: translateX(-100%);
    }
    span {
        display: inline-block;
        padding-left: 20px;
        font-size: 15px;
        line-height: 22px;
        color: #111;
    }
    .coupon {
        color: #fff;
        background-color: #444;
    }
    .coupon_remove {
        color: #e55f74;
        margin-left: 20px;
        text-decoration: underline;
    }
    .point {
        color: #111;
        background-color: #f5f5f5;
    }
    @media ${(props) => props.theme.mobile} {
        button {
            height: 60px;
            line-height: 60px;
            left: 100%;
        }
        span {
            padding-left: 10px;
            font-size: 16px;
            line-height: 23px;
        }
        .input-point {
            margin-bottom: 15px;
        }
    }
`;

export const SelectStyle = styled.div`
    display: inline-block;
    width: 49%;
    height: 56px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 200px;
    margin-bottom: 20px;
    padding-left: 26px;
    font-size: 15px;
    line-height: 22px;
    color: #111;
    @media ${(props) => props.theme.mobile} {
        width: 100%;
        height: 60px;
    }
`;

export const Payment = styled.div`
    border-top: 1px dashed #ebebeb;
    border-bottom: 1px dashed #ebebeb;
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    .last-row {
        margin-top: 50px;
        span {
            font-weight: bold;
            font-size: 17px;
            line-height: 25px;
        }
    }
    .color {
        color: #e55f74;
    }
    @media ${(props) => props.theme.mobile} {
        margin-top: 50px;
        margin-bottom: 23px;
    }
`;

export const PaymentRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    span {
        font-size: 15px;
        line-height: 22px;
        color: #111;
        @media ${(props) => props.theme.mobile} {
            font-size: 16px;
            line-height: 23px;
        }
    }
`;

export const AgreementSection = styled.div`
    margin-bottom: 50px;
    .active {
        padding-bottom: 0;
        div {
            display: block;
        }
        .svg {
            transform: translateY(-50%) rotate(180deg);
        }
    }
`;

export const Agreement = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    background-color: #fbfbfb;
    border-radius: 2px;
    padding: 17px 0;
    margin-bottom: 30px;
    cursor: pointer;
    span {
        position: relative;
        display: inline-block;
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #111;
        padding: 0 70px;
    }
    input {
        display: none;
    }
    svg {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
        cursor: pointer;
    }
    input[type='checkbox'] + label {
        svg {
            g > path {
                transition: all ease 0.2s 0s;
            }
            g > circle {
                transition: all ease 0.2s 0s;
            }
        }
    }
    input[type='checkbox']:checked + label {
        svg {
            g > path {
                fill: #e55f74;
                transition: all ease 0.2s 0s;
            }
            g > circle {
                stroke: #e55f74;
                fill: #fff;
                transition: all ease 0.2s 0s;
            }
        }
    }
    @media ${(props) => props.theme.mobile} {
        margin-bottom: 20px;
        svg {
            left: 10px;
        }
        span {
            font-size: 14px;
            line-height: 20px;
            padding-left: 47px;
            padding-right: 30px;
        }
    }
`;

export const AgreementDetail = styled.div`
    display: none;
    width: 100%;
    background-color: #fff;
    padding: 25px 0;
    padding-left: 70px;
    margin-top: 17px;
    font-size: 13px;
    font-weight: normal;
    line-height: 26px;
    color: #111;
    border-bottom: 1px solid #ebebeb;
    @media ${(props) => props.theme.mobile} {
        padding: 20px 0;
        padding-left: 47px;
        padding-right: 30px;
    }
`;

export const ArrowDown = styled.img`
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    @media ${(props) => props.theme.mobile} {
        right: 10px;
    }
`;

export const AuthPaymentLayer = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .container {
        position: absolute;
        width: 100%;
        max-width: 500px;
        height: 670px;
        border-radius: 20px;
        overflow: hidden;
        background: #fff;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
    .iframe-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        height: 150px;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
        > div {
            margin: 0px auto 10px;
        }
        /* .wrap {

        } */
    }
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.3);
        z-index: 0;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
    @media ${(props) => props.theme.mobile} {
        height: 100vh;
        overflow: auto;
        .container {
            top: 0px;
            border-radius: 0px;
        }
    }
`;

export const ConfirmButton = styled(Link)`
    display: block;
    position: relative;
    height: 56px;
    font-weight: 500;
    font-size: 15px;
    line-height: 54px;
    color: #e55f74;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #e55f74;
    border-radius: 200px;
    cursor: pointer;

    ${(props) =>
        props.disabled
            ? `
        color: gray;
        border: 1px solid #ebebeb;
        cursor: default;

    `
            : ``};
`;

export const LoadingDot = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(45px) translateY(-50%);
    width: 20px;
    height: 20px;
    margin: 0 auto;
    padding: 10px;
    ${(props) =>
        props.disabled
            ? `
        display: block;
    `
            : `display: none;`};
    div {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateY(-40px);
    }

    div:nth-child(1) {
        width: 10px;
        height: 10px;
        border-radius: 8px;
        background-color: #a0a0a0;
        opacity: 0;
        animation: dot 2s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }

    div:nth-child(2) {
        width: 8px;
        height: 8px;
        border-radius: 6px;
        background-color: #a0a0a0;
        opacity: 0;
        animation: dot 2s 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }

    div:nth-child(3) {
        width: 6px;
        height: 6px;
        border-radius: 4px;
        background-color: #a0a0a0;
        opacity: 0;
        animation: dot 2s 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }

    div:nth-child(4) {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #a0a0a0;
        opacity: 0;
        animation: dot 2s 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }

    @keyframes dot {
        0% {
            opacity: 0.8;
            transform: rotate(0deg) translateY(-14px);
        }
        100% {
            opacity: 0.8;
            transform: rotate(360deg) translateY(-14px);
        }
    }
`;

export const PostBasic = styled.div`
    padding: 26px 0 30px 66px;
    margin: 0 0 20px 0;
    border: 1px solid #ebebeb;
    border-radius: 35px;
    box-sizing: border-box;
    position: relative;
    input {
        display: none;
    }
    svg {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 26px;
        left: 30px;
        cursor: pointer;
    }
    ${(props) =>
        props.onCheck
            ? `
        border: 1px solid #888888;
    `
            : ``}
    label {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: -0;
        left: -0;
        border-radius: 35px;
        cursor: pointer;
    }
    @media ${(props) => props.theme.mobile} {
        svg {
            left: 20px;
        }
        label {
            left: -23px;
        }
        padding: 26px 50px 20px 54px;
    }
`;
export const TogglePen = styled.div`
    img {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        top: 26px;
        right: 30px;
    }
    cursor: pointer;
`;

export const UserName = styled.div`
    margin: 0 0 15px 0;
    p {
        font-size: 15px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        display: inline-block;
        vertical-align: middle;
    }
    span {
        vertical-align: middle;
        margin: 0 0 0 15px;
        padding: 4px 10px 4px 10px;
        border: 1px solid rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
        border-radius: 30px;
        font-size: 12px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
    }
`;

export const UserPost = styled.div`
    p {
        margin: 0 0 7px 0;
        font-size: 15px;
        font-weight: normal;
        color: rgba(158, 158, 158, 0.6);
    }
    span {
        margin: 0 20px 0 0;
        font-size: 15px;
        font-weight: normal;
        color: rgba(158, 158, 158, 0.6);
    }
`;

export const PostNewBasic = styled.div`
    padding: 22px 0 22px 66px;
    margin: 0 0 20px 0;
    border: 1px solid #ebebeb;
    border-radius: 35px;
    box-sizing: border-box;
    position: relative;
    input {
        display: none;
    }
    svg {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
        cursor: pointer;
    }
    label {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: -0;
        left: -0;
        border-radius: 35px;
        cursor: pointer;
    }
    ${(props) =>
        props.onCheck
            ? `
        border: 1px solid #888888;
    `
            : ``}
`;

export const PostNew = styled.div`
    p {
        font-size: 15px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        display: inline-block;
    }
    span {
        margin: 0 0 0 15px;
        padding: 4px 10px 4px 10px;
        border: 1px solid rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
        border-radius: 30px;
        font-size: 12px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
    }
`;
export const ToggleNewPen = styled.div`
    img {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        z-index: -1;
    }
    cursor: pointer;
`;

export const PaymentTitle = styled.div`
    margin-bottom: 30px;
    padding-left: 50px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: #000000;
    @media ${(props) => props.theme.mobile} {
        margin-bottom: 23px;
        padding-left: 20px;
        font-size: 14px;
    }
`;
export const PaymentMethod = styled.div`
    margin: 20px 0;
    padding: 43px 0 50px 0;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    .last-row {
        margin: 0px 0 0 0;
        padding: 0px 20px 0 20px;
        span:first-child {
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            color: #000000;
        }
    }
    @media ${(props) => props.theme.mobile} {
        padding: 30px 0 30px 0;
        .last-row {
            margin: 0px 0 0 0;
            padding: 0px 20px 0 20px;
            span:first-child {
                font-size: 12px;
            }
        }
    }
`;

export const PaymentMethodTitle = styled.div`
    font-size: 20px;
    line-height: 29px;
    font-weight: bold;
    color: #111;
    margin-top: 50px;
    margin-bottom: 20px;
    @media ${(props) => props.theme.mobile} {
        margin-top: 30px;
    }
`;

export const PaymentMethodRow = styled.div`
    background: #f7f7f7;
    margin: 0 0 25px 0;
    padding: 0 20px;
    span {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        color: #000000;
    }
    span:first-child {
        font-weight: normal;
        color: #666666;
    }
    @media ${(props) => props.theme.mobile} {
        margin: 0 0 20px 0;
        padding: 0 20px;
        span {
            font-size: 12px;
        }
    }
`;
export const PaymentMethodItem = styled.div`
    padding: 20px 0px;
    padding-left: 30px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    position: relative;
    :last-child {
        border-bottom: none;
    }
    input {
        display: none;
    }
    svg {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        cursor: pointer;
    }
    label {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: -0;
        left: -0;
        border-radius: 35px;
        /* cursor: pointer; */
    }
    @media ${(props) => props.theme.mobile} {
        svg {
            left: 20px;
        }
        padding-left: 0;
        padding-right: 0;
    }
`;
export const PaymentMethodItemName = styled.div`
    position: relative;
    z-index: 10;
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        color: #000;
        display: inline-block;
    }
    span {
        margin: 0 0 0 15px;
        padding: 4px 10px 4px 10px;
        border: 1px solid rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
        border-radius: 30px;
        font-size: 12px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
    }
    .btn_ {
        font-weight: normal;
        color: #fff;
        background-color: #c82333;
        text-align: center;
        border: 2px solid transparent;
        padding: 0.375rem 0.75rem 0.475rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.6;
        border-radius: 0.25rem;
        transition: 0.3s;
        margin-left: 14px;
        cursor: pointer;
        :hover {
            opacity: 0.7;
        }
    }
`;
