import React from 'react';
import {
    Wrapper,
    Prev,
    Next,
    Bar,
    PageNum
} from './styled';

const Pagination = (props) => {

    const max = props.max;
    const barWidth = (max == 1 ? 100 : ((parseInt(props.current) + 1) > max) ? 100 : (parseInt(props.posts_per_page) * parseInt(props.current) / parseInt(props.total) * 100))
    return (
        <Wrapper>
            {(parseInt(props.current) - 1) ? (<Prev className="button" onClick={(e) => props.onPaging((parseInt(props.current) - 1))}></Prev>) : (<Prev className="button disable"></Prev>)}
            {((parseInt(props.current) + 1) <= max) ? (<Next className="button" onClick={(e) => props.onPaging((parseInt(props.current) + 1))}></Next>) :(<Next className="button disable"></Next>)}
            <Bar barWidth={barWidth}/>
            <PageNum>
                <div>{max == 1 ? (props.total) : ((parseInt(props.current) + 1) > max) ? props.total : (parseInt(props.posts_per_page) * parseInt(props.current)) }</div>
                <div >/</div>
                <div>{(props.total > props.posts_per_page) ? props.total : props.posts_per_page}</div>
            </PageNum>
        </Wrapper>
    );
};

export default Pagination;


