import styled from 'styled-components';
import { RowWrap } from '../../../components/Grid/styled';

export const RegisterWrapper = styled.div`
    position: relative;
    height: auto;
    min-height: 100vh;
    overflow: hidden;
    @media ${props => props.theme.mobile}{
        overflow-x: hidden;
        overflow-y: scroll;
        padding-bottom: 50px;
        ${RowWrap}{
            display: block;
        }
    }
`;

export const Mobile = styled.div`
    @media ${props => props.theme.mobile}{
        display: block;
        position: absolute;
        top: 30px;
        left: 15px;
        z-index: 999;
        h1{
            margin-top: 83px;
            font-size: 24px;
            font-weight: bold;
            color: #111;
        }
        p{
            font-size: 16px;
            margin-top: 10px;
            color: #111;
        }
        a{
            text-decoration: underline;
        }
    }
`;

export const BackButton = styled.div`
    svg{
        width: 34px;
        height: 34px;
    }
`;

export const RegisterImg = styled.div`
    ${props => props.url ? `background: url("${props.url}");` : ''}
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    width: 40%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    @media ${props => props.theme.mobile}{
        background: url();
        width: 100%;
        height: 245px;
        display: block;
        background-color: #f5f5f5;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
    }
`;

export const Wrapper = styled.div`
    width: 60%;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    & > ${BackButton} {
        position:absolute;
        left: 0px;
        top: -60px;
    }
    @media ${props => props.theme.mobile}{
        width: 100%;
        display: block;
        position: relative;
        top: 250px;
        left: 0;
    }
`;

export const RegisterContent = styled.div`
    width: 450px;
    position: relative;
    @media ${props => props.theme.mobile}{
        width: 90%;
        margin: 0px auto;
    }
`;

export const RegisterTitle = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-end;
    h1{
        font-size: 30px;
        font-weight: bold;
        color: #111;
        display: inline-block;
    }
    p{
        font-size: 15px;
        color:#111;
    }
    a{
        text-decoration: underline;
    }
    
    @media ${props => props.theme.mobile}{
        width: 90%;
        margin: 0 auto;
        h1{
            display: none;
        }
        p{
            display: none;
        }
        a{
            display: none;
        }
    }
`;

export const RegisterInfor = styled.div`
    position: relative;
    .raf {
        display:block;
        padding:15px;
        background: #f7f7f7;
        strong {
            float:right;
        }
    }
`;

export const IdInput = styled.input`
    position: relative;
    width: 450px;
    margin-top: 50px;
    padding-bottom: 9px;
    border: none;
    border-bottom: 1px solid #999;
    display: block;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
    color: #111111;
    ::placeholder{
        color: #111;
    }
    @media ${props => props.theme.mobile}{
        width: 100%;
        font-size: 16px;
    }
`;


export const PassWord = styled.div`
    position: relative;
    img{
        position: absolute;
        cursor:pointer;
        top: 0;
        right: 0px;
        width: 24px;
        height: 24px;
    }
    @media ${props => props.theme.mobile}{
        width: 100%;
        font-size: 16px;
    }
`;

export const PwInput = styled.input`
    position: relative;
    width: 450px;
    display: block;
    margin: 50px 0px 30px 0px;
    padding-bottom: 9px;
    border: none;
    border-bottom: 1px solid #999;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
    color: #111111;
    ::placeholder{
        color: #111;
    }
    @media ${props => props.theme.mobile}{
        width: 100%;
    }
`;

export const RegisterButton = styled.div`
    margin-top: 50px;
`;