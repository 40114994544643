import React, { useState, useEffect } from 'react';
import MainContainer from '../../../components/MainContainer';
import { Link } from 'react-router-dom';
import { MediaQuery, mobileQuery } from '../../../components/Grid';
import MainList from '../MainList';
import { OrderGetPosts, OrderCancel } from '../../../api/order';
import {
	OrderWrapper,
	OrderTitle,
	OrderDetails,
	Product,
	ProductDetails,
	ProductButton,
	OrderNull,
	SeeMore,
	OrderCancellation,
	OrderButton,
	OldCard,
	OldCardDetails,
} from './styled';
import { Wrapper, Container } from '../styled';
import UploadCertModal from './modal/UploadCertModal';
import { SkeletonOrder } from '../../../components/Skeleton';
import Pagination from '../../../components/Pagination';
import { toast } from 'react-toastify';

const OrderList = () => {
	const isMobile = MediaQuery(mobileQuery);
	const [more_history, set_more_history] = useState(0);
	const MoreButton = (e) => {
		if (!more_history) {
			set_more_history(1);
		}
	};

	const [current_paged, set_current_paged] = useState(0);
	const [total, set_total] = useState(0);
	const [paged, set_paged] = useState(1);
	const [orders, set_orders] = useState('');
	const posts_per_page = 5;
	const [order_empty, set_order_empty] = useState(0);

	const [openedCertPopup, setCertPopup] = useState(0);
	const [certOrderId, setCertOrderId] = useState(0);
	const toggleCertPopup = (e) => {
		e.preventDefault();
		const orderId = e.currentTarget.dataset.id;

		setCertOrderId(orderId);

		if (openedCertPopup) {
			setCertPopup(0);
		} else {
			setCertPopup(1);
		}
	};

	const onPaging = (page) => {
		set_paged(page);
		window.scrollTo(0, 0);
	};
	const DoCancel = (e) => {
		if (window.confirm('주문을 취소하시겠습니까?')) {
			if (e.currentTarget.dataset.id) {
				OrderCancel({
					order_ID: e.currentTarget.dataset.id,
				})
					.then((response) => {
						if (response.data.code == '200') {
							toast.success('주문이 취소되었습니다.');
							set_current_paged(0);
						} else {
							toast.error(response.data.body.message);
						}
					})
					.catch((err) => toast.error('서버요청오류!.'));
			}
		}
	};
	useEffect(() => {
		if (current_paged != paged) {
			set_orders('');
			OrderGetPosts({
				posts_per_page: posts_per_page,
				paged: paged,
			})
				.then((response) => {
					if (response.data.code == 200) {
						set_orders(response.data.body.orders);
						set_current_paged(response.data.body.paged);
						set_total(response.data.body.total);
					} else if (response.data.code == 500) {
						set_order_empty(1);
					}
				})
				.catch((error) => console.log(error));
		}
	});

	return (
		<>
			<MainContainer type="small">
				<Wrapper>
					<Container>
						<MainList />
						<OrderTitle>
							<h3>주문내역</h3>
						</OrderTitle>
						{orders ? (
							orders.map((order_item, key) => (
								<OrderWrapper>
									<OrderDetails>
										<Product url={order_item.product_data.thumbnail} />
										<ProductDetails>
											<p>{order_item.order_date}</p>
											<strong>
												#{order_item.order_ID} {order_item.order_status}
											</strong>
											<small>
												{order_item.product_data.name}{' '}
												{order_item.product_data.count - 1
													? '외 ' +
													  (order_item.product_data.count - 1) +
													  '개의 상품'
													: ''}
											</small>
										</ProductDetails>
										<ProductButton>
											<SeeMore>
												<Link to={'/mypage/order/' + order_item.order_ID}>
													<button>자세히보기</button>
												</Link>
											</SeeMore>
											{!order_item.vr_bank &&
											order_item.order_status == '결제대기중' ? (
												<OrderCancellation>
													<button
														onClick={toggleCertPopup}
														data-id={
															order_item.order_ID ? order_item.order_ID : ''
														}
													>
														해외송금확인증 첨부
													</button>
												</OrderCancellation>
											) : (
												''
											)}
											{order_item.is_tracking ? (
												<OrderCancellation>
													{/* <Link to={"/mypage/order/"+order_item.order_ID+"/delivery"}> */}
													<a href={order_item.is_tracking} target="_blank">
														<button>배송조회</button>
													</a>
												</OrderCancellation>
											) : (
												''
											)}
											{order_item.is_do_cancel ? (
												<OrderCancellation>
													{/* <Link to={"/mypage/order/"+order_item.order_ID+"/delivery"}> */}

													<button
														onClick={DoCancel}
														data-id={order_item.order_ID}
													>
														주문취소
													</button>
												</OrderCancellation>
											) : (
												''
											)}
										</ProductButton>
									</OrderDetails>
								</OrderWrapper>
							))
						) : order_empty ? (
							<>
								<OrderNull>주문내역이 없습니다.</OrderNull>
							</>
						) : (
							<>
								<OrderWrapper>
									<OrderDetails>
										<SkeletonOrder />
									</OrderDetails>
								</OrderWrapper>
								<OrderWrapper>
									<OrderDetails>
										<SkeletonOrder />
									</OrderDetails>
								</OrderWrapper>
								<OrderWrapper>
									<OrderDetails>
										<SkeletonOrder />
									</OrderDetails>
								</OrderWrapper>
								<OrderWrapper>
									<OrderDetails>
										<SkeletonOrder />
									</OrderDetails>
								</OrderWrapper>
								<OrderWrapper>
									<OrderDetails>
										<SkeletonOrder />
									</OrderDetails>
								</OrderWrapper>
							</>
						)}

						{/* <OldCard>
                            <OldCardDetails>
                                <a href={"http://old.sakuraherb.com/my-account/orders"}>
                                    <h3>2021.02.21 ~ 2021.03.06일자의 주문건을 찾고 계신가요?</h3>
                                </a>

                                <SeeMore>
                                    <a href={"http://old.sakuraherb.com/my-account/orders"}>
                                        <button>예전사이트 바로가기</button>
                                    </a>
                                </SeeMore>

                            </OldCardDetails>
                        </OldCard> */}
						{orders ? (
							<Pagination
								onPaging={onPaging}
								total={total}
								current={current_paged}
								posts_per_page={posts_per_page}
								max={
									total >= posts_per_page
										? parseInt(total / posts_per_page) + 1
										: 1
								}
							/>
						) : (
							''
						)}
					</Container>
				</Wrapper>
			</MainContainer>
			<UploadCertModal
				show={openedCertPopup}
				orderId={certOrderId}
				onClose={toggleCertPopup}
			/>
		</>
	);
};
export default OrderList;
