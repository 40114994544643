import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 110px;
	padding-bottom: 200px;
	@media ${(props) => props.theme.mobile} {
		padding-top: 30px;
		padding-bottom: 0px;
	}
`;

export const MainPostWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-height: 735px;
	margin-bottom: 100px;
	@media ${(props) => props.theme.mobile} {
		height: 100%;
		padding: 0 20px;
		padding-bottom: 50px;
		margin-bottom: 50px;
		border-bottom: 20px solid #f8f8f8;
	}
`;

export const LeftSection = styled.div`
	width: 62.2%;
	min-height: 735px;

	h4 {
		display: flex;
		gap: 0 30px;
		padding-bottom: 26px;
		margin-bottom: 26px;
		border-bottom: 1px solid #ddd;
	}
	span {
		font-size: 22px;
		color: #9a9a9e;
		font-weight: 500;
		cursor: pointer;
	}

	.active {
		color: #111;
	}

	.review {
		.list {
			margin-bottom: 30px;
		}
		.review_item {
			border-bottom: 1px solid #ebebeb;
			padding: 26px 0 26px 0;
			&:first-child {
				padding-top: 4px;
			}
		}
		.btn- {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 200px;
			border: 1px solid #e55f74;
			height: 56px;
			background: #fff;
			color: #e55f74;
			font-family: 'Noto Sans KR';
			font-size: 15px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.2s;
			&:hover {
				background-color: #e55f74;
				color: #fff;
			}
		}
		p {
			color: #111;
			font-family: 'Noto Sans KR';
			font-size: 15px;
			font-style: normal;
			font-weight: 700;
			line-height: 22px; /* 146.667% */
			margin-bottom: 14px;
			cursor: pointer;
		}
		.ct {
			color: #444;
			font-family: 'Noto Sans KR';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px; /* 157.143% */
			margin-bottom: 22px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			cursor: pointer;
		}
		.review-image {
			width: 147px;
			height: 100px;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			margin-bottom: 20px;
			cursor: pointer;
		}
		.io {
			cursor: default;
			span {
				color: #999;
				font-family: 'Noto Sans KR';
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 23px; /* 176.923% */
				cursor: auto;
			}
			i {
				color: #999;
				font-family: 'Noto Sans KR';
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 23px; /* 176.923% */
				padding: 0 2px;
			}
		}
	}

	.content {
		line-height: 1.4;
		img {
			max-width: 100%;
			height: auto;
		}
		p {
			margin: 10px 0;
		}
		iframe {
			display: none;
		}
		strong {
			display: block;
			font-size: 18px;
			line-height: 26px;
			margin-bottom: 5px;
		}
		span {
			display: block;
			font-size: 15px;
			line-height: 28px;
		}
	}

	@media ${(props) => props.theme.mobile} {
		display: none;
	}
`;

export const ProductImage = styled.div`
	width: 100%;
	height: 0;
	padding-bottom: 64.224%;
	background: #fbfbfb;
	border-radius: 2px;
	background-color: #f8f8f8;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 53% auto;
	margin-bottom: 50px;
	@media ${(props) => props.theme.mobile} {
		padding-bottom: 73.137%;
		margin-bottom: 29px;
	}
`;

export const Information = styled.div`
	position: relative;
	border-top: 1px solid #f8f8f8;
	border-bottom: 1px solid #f8f8f8;
	font-size: 17px;
	line-height: 25px;
	font-weight: 500;
	color: #111111;
	padding: 30px 0;
	cursor: pointer;
	img {
		position: absolute;
		top: 50%;
		right: 0;
		width: 24px;
		height: auto;
		transform: translateY(-50%);
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 35px;
		font-size: 18px;
		padding: 28px 0;
	}
`;

export const ProductDetail = styled.div`
	position: relative;
	width: 34.4%;
	min-height: 735px;

	.mo-content {
		margin: 60px 0 0 0;

		h4 {
			display: flex;
			gap: 0 26px;
			padding-bottom: 20px;
			margin-bottom: 30px;
			border-bottom: 1px solid #ddd;
		}
		span {
			color: #9a9a9e;
			font-family: 'Noto Sans KR';
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.active {
			color: #111;
		}

		.content {
			line-height: 1.4;
			img {
				max-width: 100%;
				height: auto;
			}
			p {
				margin: 10px 0;
				font-size: 15px;
			}
			iframe {
				display: none;
			}
			strong {
				display: block;
				font-size: 15px;
				line-height: 1.4;
				margin-bottom: 5px;
			}
			span {
				display: block;
				font-size: 13px;
				line-height: 1.4;
			}
		}
	}

	@media ${(props) => props.theme.mobile} {
		width: 100%;
		height: 100%;
	}
	a {
		display: inline-block;
		vertical-align: top;
		min-width: 180px;
		height: 60px;
	}

	.mo-content {
		.review {
			.list {
				margin-bottom: 30px;
			}
			.review_item {
				border-bottom: 1px solid #ebebeb;
				padding: 26px 0 26px 0;
				&:first-child {
					padding-top: 4px;
				}
			}
			.btn- {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 200px;
				border: 1px solid #e55f74;
				height: 60px;
				background: #fff;
				color: #e55f74;
				font-family: 'Noto Sans KR';
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding-bottom: 5px;
				cursor: pointer;
				transition: 0.2s;
			}
			p {
				color: #111;
				font-family: 'Noto Sans KR';
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 22px; /* 146.667% */
				margin-bottom: 12px;
				cursor: pointer;
			}
			.ct {
				color: #444;
				font-family: 'Noto Sans KR';
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 28px; /* 186.667% */
				margin-bottom: 16px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				cursor: pointer;
			}
			.review-image {
				width: 147px;
				height: 100px;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				margin-bottom: 16px;
				cursor: pointer;
			}
			.io {
				cursor: default;
				height: 20px;
				span {
					color: #999;
					font-family: 'Noto Sans KR';
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 100%; /* 176.923% */
					cursor: auto;
				}
				i {
					color: #999;
					font-family: 'Noto Sans KR';
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: 23px; /* 176.923% */
					padding: 0 2px;
				}
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		a {
			width: 50%;
			min-width: unset;
			height: 56px;
		}
	}
`;

export const Name = styled.div`
	font-weight: bold;
	font-size: 23px;
	line-height: 33px;
	color: #111;
	margin-bottom: 10px;
	@media ${(props) => props.theme.mobile} {
		font-size: 20px;
		margin-bottom: 15px;
	}
`;

export const Ingredient = styled.div`
	font-size: 15px;
	line-height: 22px;
	color: #111;
	margin-bottom: 30px;
	@media ${(props) => props.theme.mobile} {
		font-size: 16px;
	}
`;

export const BtnFlex = styled.div`
	display: flex;
	gap: 10px;
	.sold-out {
		color: rgba(0, 0, 0, 0.5);
		background: #eee !important;
		cursor: default;
	}
`;

export const Price = styled.div`
	border-top: 1px solid #ebebeb;
	padding-top: 30px;
	margin-bottom: 30px;
	font-size: 15px;
	line-height: 22px;
	color: #111;
	span {
		padding-left: 51px;
	}
	@media ${(props) => props.theme.mobile} {
		padding-top: 27px;
	}
`;

export const Shar = styled.div`
	.share-box {
		width: 60px;
		height: 60px;
		background-color: #f8f8f8;
		border-radius: 50%;
		position: relative;
		cursor: pointer;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
		}
	}
	@media ${(props) => props.theme.mobile} {
		.share-box {
			width: 56px;
			height: 56px;
		}
	}
`;

export const PopupWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 100;
	.overlay {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.4);
		position: fixed;
		top: 0;
		left: 0;
		transition: 0.2s;
	}
	.popup {
		max-width: 600px;
		width: 100%;
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
		.inner {
			padding: 50px 50px 30px 50px;
			background-color: #fff;
		}
		h2 {
			color: #111;
			font-size: 17px;
			font-weight: 700;
			line-height: normal;
		}
		.line {
			border-top: 1px solid #ebebeb;
			margin: 15px 0 26px 0;
		}
		.btn {
			width: 100%;
			height: 56px;
			border-radius: 200px;
			border: 1px solid #e55f74;
			background: #e55f74;
			margin-top: 15px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5px;
			p {
				color: #fff;
				font-size: 15px;
				font-weight: 500;
				line-height: 54px;
			}
		}
		.kakao {
			background: #fceb4f;
			border: none;
			img {
				width: 24px;
			}
			p {
				color: #111;
			}
		}
		.close {
			width: 100%;
			height: 60px;
			border-radius: 0px 0px 2px 2px;
			background: #eee;
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
			cursor: pointer;
			color: #111;
			font-size: 15px;
			text-align: center;
			font-weight: 500;
			line-height: 58px;
			transition: all 0.3s;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		.popup {
			max-width: 100%;
			padding: 0 30px;
			.inner {
				padding: 21px 20px 30px 20px;
			}
			h2 {
				font-size: 18px;
			}
			.line {
				margin: 21px -20px 30px -20px;
			}
			.btn {
				width: 100%;
				height: 60px;
				margin-top: 20px;
				gap: 8px;
				p {
					font-size: 16px;
				}
			}

			.close {
				font-size: 16px;
			}
		}
	}
`;

export const Hidden = styled.div`
	position: absolute;
	left: -1000px;
`;

export const Amount = styled.div`
	display: flex;
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 30px;
	margin-bottom: 30px;
	font-size: 15px;
	line-height: 22px;
	color: #111;
	align-items: baseline;
	div {
		padding-left: 51px;
	}
	img {
		width: 21px;
		height: 21px;
		cursor: pointer;
	}
	span {
		display: inline-block;
		margin: 0 10px;
		font-weight: 500;
	}
	select {
		max-width: 200px;
		border: 1px solid rgb(238, 238, 238);
		height: 40px;
		padding: 0 14px 0 4px;
		font-size: 15px;
		line-height: 22px;
		color: #111;
		cursor: pointer;
	}

	th,
	td {
		text-align: left;
		padding-right: 10px;
	}

	@media ${(props) => props.theme.mobile} {
		padding-bottom: 27px;
	}
`;

export const Option = styled.div`
	background-color: #f7f7f7;
	padding: 25px 20px 40px 20px;
	margin-bottom: 30px;
	table {
		width: 100%;
		th,
		td {
			text-align: center;
			padding: 15px 0;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			color: #000000;
		}
		td:nth-of-type(3) {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 24px;
			color: #6ac4db;
		}
		tr:first-child {
			th {
				font-family: 'Noto Sans KR';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 23px;
				letter-spacing: -0.03em;
				color: #787878;
				padding: 0;
			}
		}
		tr:not(:first-child) {
			border-bottom: 1px solid #ddd;
		}
		.radio {
			input {
				display: none;
			}
			label {
				display: inline-block;
				position: relative;
				top: 5px;
				background: #ffffff;
				border: 1px solid #dcdcdc;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				cursor: pointer;
				:after {
					position: absolute;
					display: none;
					content: '';
					width: 12px;
					height: 12px;
					background: #db6976;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
				}
			}
			input[type='radio']:checked + label {
				:after {
					display: block;
				}
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		padding: 15px 14px 30px 14px;
		margin-bottom: 25px;
		table {
			tr:first-child {
				th {
					font-size: 14px;
					line-height: 20px;
				}
			}
			td:nth-of-type(3) {
				font-size: 18px;
				line-height: 20px;
			}
			td {
				font-size: 17px;
				line-height: 20px;
			}
			.radio {
				input {
					display: none;
				}
				label {
					top: 6px;
				}
			}
		}
	}
`;

export const BasketBtn = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: 48%;
	height: 60px;
	border-radius: 100px;
	border: solid 1px #dfdfdf;
	/* background-image: url(/img/basket.png);
	background-position: 30px center;
	background-size: 20px 20px; */
	text-align: center;
	/* line-height: 59px; */
	font-size: 14px;
	background-repeat: no-repeat;
	cursor: pointer;
	img {
		width: 20px;
		height: 20px;
	}
	@media ${(props) => props.theme.mobile} {
		height: 56px;
	}
`;

export const Manual = styled.div`
	font-size: 15px;
	line-height: 26px;
	color: #111;
	margin-bottom: 30px;
	img {
		display: none;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 16px;
		line-height: 1.88;
	}
`;

export const Title = styled.div`
	font-weight: bold;
	font-size: 23px;
	line-height: 33px;
	letter-spacing: -0.03em;
	color: #111;
	margin-bottom: 20px;
	@media ${(props) => props.theme.mobile} {
		font-size: 20px;
		padding: 0 20px;
	}
`;

export const RelativeWrapper = styled.div`
	width: 100%;
	height: auto;
`;

export const RelativeSection = styled.div`
	width: 100%;
	overflow-x: auto;
	& > div {
		padding-bottom: 63px;
	}
	-webkit-overflow-scrolling: touch;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		padding-bottom: 79px;
		border-bottom: none;
		& > div {
			padding-bottom: 23px;
		}
	}
`;
export const RelativeProducts = styled.div`
	width: calc(330px * 15);
	height: auto;
	@media ${(props) => props.theme.mobile} {
		width: calc(200px * 15);
	}
`;

export const RelativeProduct = styled.div`
	display: inline-block;
	width: 298px;
	height: auto;
	margin-right: 30px;
	vertical-align: top;
	@media ${(props) => props.theme.mobile} {
		width: 170px;
		vertical-align: top;
	}
`;

export const RelativeImage = styled(Link)`
	display: block;
	width: 299px;
	height: 299px;
	background-color: #fbfbfb;
	border-radius: 2px;
	border: 1px solid #e9e9e9;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 20px;
	@media ${(props) => props.theme.mobile} {
		width: 180px;
		height: 180px;
		background-size: 150px 150px;
		/* border: 1px solid #eee; */
	}
`;

export const Signboard = styled.div`
	display: block;
	position: relative;
	border: 4px solid #ec233a;
	padding: 11px 0 16px 0;
	text-align: center;
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 26px;
	color: #ec233a;
	margin-bottom: 24px;
	margin-top: -10px;
	.arrow-down {
		position: absolute;
		width: 0;
		height: 0;
		border-left: 11px solid transparent;
		border-right: 11px solid transparent;
		border-top: 16px solid #f00;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 100%);
	}
	@media ${(props) => props.theme.mobile} {
		border: 3px solid #ec233a;
		padding: 8px 0 12px 0;
		font-size: 17px;
		.arrow-down {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 14px solid #f00;
		}
	}
`;

export const RelativeType = styled.div`
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	color: #111111;
	margin-bottom: 5px;
	@media ${(props) => props.theme.mobile} {
		font-size: 12px;
		margin-bottom: 8px;
	}
`;

export const RelativeName = styled.div`
	font-size: 13px;
	line-height: 19px;
	color: #111111;
	margin-bottom: 15px;
	@media ${(props) => props.theme.mobile} {
		font-size: 13px;
		margin-bottom: 14px;
	}
`;

export const RelativePrice = styled.div`
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #111111;
	@media ${(props) => props.theme.mobile} {
		font-size: 14px;
	}
`;

export const IsMobile = styled.div`
	display: none;
	@media ${(props) => props.theme.mobile} {
		display: block;
	}
`;

export const OptionSelectbox = styled.div`
	border: 2px solidr red;
	font-size: 13px;
	line-height: 19px;
	color: #111111;
	margin-bottom: 15px;
	@media ${(props) => props.theme.mobile} {
		font-size: 13px;
		margin-bottom: 14px;
	}
`;
