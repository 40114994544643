import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 200px;
    @media ${props => props.theme.mobile} {
        padding: 0 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
`;

export const TopSection = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 20px;
    margin-bottom: 50px;
    @media ${props => props.theme.mobile} {
        margin-bottom: 30px;
        padding-bottom: 10px;
    }
`;

export const Title = styled.h1`
    display: block;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #111;
    @media ${props => props.theme.mobile} {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 20px;
    }
`;

export const TabWrapper = styled.ul`
    display: inline-block;
    position: absolute;
    bottom: 16px;
    right: 0;
    width: auto;
    .active {
        a{
            color: #111;
        }
    }
    li:last-child {
        margin-right: 0px;
    }
    @media ${props => props.theme.mobile} {
        position: static;
    }
`;

export const Tab = styled.li`
    float: left;
    margin-right: 30px;
    a {
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
        color: #9A9A9E;
    }
    @media ${props => props.theme.mobile} {
        margin-right: 24px;
        a {
            font-size: 16px;
            line-height: 20px;
        }
    }
`;

export const StoryCardWrapper = styled.div`
    width: calc(100% + 30px);
    height: 100%;
    margin: 0 -15px;
    @media ${props => props.theme.mobile} {
        display: block;
    }
`;

export const StoryCard = styled.div`
    display: inline-block;
    width: 33.3%;
    height: auto;
    margin-bottom: 6.658%;
    vertical-align: top;
    padding: 0 15px;
    @media ${props => props.theme.mobile} {
        width: 100%;
        max-width: 440px;
        margin: 0 auto;
        margin-bottom: 50px;
    }
`;

export const CardImage = styled(Link)`
    display: inline-block;
    width: 100%;
    height: 0;
    padding-bottom: 58.333333333%;
    background-color: darkgray;
    border-radius: 2px;
    margin-bottom: 18px;
    background-image: url(${props => props.url ? props.url : ''});
    background-size: cover;
    background-position: center center;
    @media ${props => props.theme.mobile}{
        margin-bottom: 20px;
    }
`;

export const CardBadge = styled.div`
    display: inline-block;
    width: auto;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #E55F74;
    box-sizing: border-box;
    border-radius: 300px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #E55F74;
    text-align: center;
    padding: 0 12px;
    transition: 0.2s;
    margin-bottom: 7px;
    :hover {
        background: #E55F74;
        color: #fff;
    }
    @media ${props => props.theme.mobile}{
        height: 22px;
        line-height: 20px;
        font-size: 12px;
        margin-bottom: 9px;
    }
`;

export const CardTitle = styled.div`
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    color: #111;
    margin-bottom: 15px;
    @media ${props => props.theme.mobile}{
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 4px;
    }
`;

export const CardTag = styled.div`
    font-size: 13px;
    line-height: 19px;
    color: #111;
    @media ${props => props.theme.mobile}{
        font-size: 12px;
        line-height: 16px;
    }
`;

export const Empty = styled.div`
    padding: 50px 20px 150px 20px;
    @media ${props => props.theme.mobile}{
        padding: 10px 15px 70px 15px;
    }
`;