import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to left, rgba(255, 68, 0, 0.5), #fd746c);
`;

export const BackButton = styled.img`
    position: absolute;
    top: 57px;
    left: 56px;
    z-index: 1;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        top: 12px;
        left: 10px;
        width: 30px;
        height: auto;
    }
`;

export const CloseButton = styled.img`
    position: absolute;
    top: 59px;
    right: 59px;
    z-index: 1;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        top: 10px;
        right: 10px;
        width: 32px;
        height: auto;
    }
`;

export const InquireBox = styled.div`
    position: absolute;
    width: 550px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @media ${props => props.theme.mobile}{
        width: 90%;
    }
`;

export const TopSection = styled.div`
    width: 100%;
    margin-bottom: 30px;
    margin-left: 40px;
    @media ${props => props.theme.mobile}{
        margin-bottom: 20px;
        margin-left: 0px;
    }
`;

export const Title = styled.div`
    display: inline-block;
    font-weight: bold;
    font-size: 25px;
    line-height: 38px;
    color: #FFF;
    @media ${props => props.theme.mobile}{
        font-size: 20px;
        font-weight: 500;
    }
`;

export const PageNum = styled.div`
    display: inline-block;
    width: auto;
    margin-right: 20px;
    font-size: 13px;
    font-weight: bold;
    line-height: 2.92;
    color: #fff;
    vertical-align: top;
`;

export const InquireTextarea = styled.textarea`
    width: calc(100% - 40px);
    height: 200px;
    margin: 15px 0 30px 20px;
    border-radius: 2px;
    border: solid 1px #fff;
    background-color: rgba(255, 255, 255, 0.08);
    margin-left: 41px;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #FFF;
    transition: 0.3s;
    ::placeholder {
        color: #FFF;
    }
    :hover {
        background: rgba(255, 255, 255, 0.01);
    }
    @media ${props => props.theme.mobile}{
        margin: 10px 0 25px 0px;
        width: 100%;
    }
`;

export const InquireInput = styled.input`
    display: block;
    width: calc(100% - 40px);
    height: 46px;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    font-weight: 500;
    font-size: 15px;
    line-height: 44px;
    color: #FFF;
    margin-left: 41px;
    margin-bottom: 15px;
    padding: 0 10px;
    transition: 0.3s;
    ::placeholder {
        color: #FFF;
    }
    :hover {
        background: rgba(255, 255, 255, 0.01);
    }
    @media ${props => props.theme.mobile}{
        margin-left: 0px;
        width: 100%;
    }
`;

export const Submit = styled.button`
    display: inline-block;
    width: 84px;
    height: 35px;
    line-height: 35px;
    background: #FFF;
    border-radius: 2px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FD746C;
    border: none;
    margin-left: 41px;
    margin-top: 15px;
    img {
        position: relative;
        left: 6px;
        bottom: 1px;
    }
    @media ${props => props.theme.mobile}{
        margin-left: 0;
    }
`;