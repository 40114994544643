import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

import {
    MainContainerWrapper
} from './styled';

const MainContainer = (props) => {
    return (
        <>
            <Header />
            <MainContainerWrapper type={props.type}>
                {props.children}
            </MainContainerWrapper>    
            <Footer />
        </>
    );
};

export default MainContainer;