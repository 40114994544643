import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 100px;
	padding-bottom: 200px;
	overflow: hidden;
	@media ${(props) => props.theme.mobile} {
		padding: 50px 20px 30px 20px;
		border-bottom: 1px solid #ebebeb;
	}
`;

export const TitleSection = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 30px;
	@media ${(props) => props.theme.mobile} {
		display: block;
	}
`;

export const Title = styled.div`
	font-size: 30px;
	line-height: 43px;
	font-weight: bold;
	color: #111;
	padding-bottom: 4px;
	@media ${(props) => props.theme.mobile} {
		font-size: 24px;
		line-height: 35px;
		padding-bottom: 10px;
	}
`;

export const HavePoint = styled.div`
	display: flex;
	font-size: 20px;
	line-height: 29px;
	color: #111;
	margin-top: auto;
	svg {
		margin: 5px 4px 0 0;
		vertical-align: sub;
		transform: translateY(1px);
	}
	.btn {
		display: block;
		border: 1px solid #ebebeb;
		width: 92px;
		height: 37px;
		background: #fff;
		text-align: center;
		color: #111;
		font-family: 'Noto Sans KR';
		font-size: 15px;
		font-weight: 500;
		margin-right: 18px;
		/* line-height: 33px; */
	}
	span {
		color: #e55f74;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 18px;
		line-height: 26px;

		.btn {
			height: 32px;
			font-size: 13px;
			line-height: normal;
			margin-right: 10px;
		}
		svg {
			margin: 3px 4px 0 0;
			width: 20px;
			height: 20px;
		}
	}
`;

export const CategorySection = styled.div`
	label {
		text-align: left;
		padding-left: 30px;
	}
	@media ${(props) => props.theme.mobile} {
		div {
			width: 50%;
		}
		label {
			padding-left: 20px;
		}
	}
`;

export const ProductsSection = styled.div`
	width: 100%;
	height: 100%;
	.claerfix {
		*zoom: 1;
	}
	.claerfix:before,
	.claerfix:after {
		display: block;
		content: '';
		line-height: 0;
	}
	.claerfix:after {
		clear: both;
	}
`;

export const ProductsWrapper = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 50px;
	margin-bottom: 50px;
	@media ${(props) => props.theme.mobile} {
		margin-top: 20px;
	}
`;

export const Product = styled.div`
	display: flex;
	position: relative;
	width: 50%;
	height: 300px;
	float: left;
	padding-top: 50px;
	${(props) => (props.is_sold_out ? 'opacity: .4;' : '')}
	a:last-child {
		${(props) => (props.is_sold_out ? 'background-color:#eee;color:#000;' : '')}
	}
	a {
		${(props) => (props.is_sold_out ? 'cursor:not-allowed;' : '')}
	}
	:after {
		display: block;
		position: absolute;
		bottom: 0;
		content: '';
		width: 100vw;
		height: 1px;
		background-color: #ebebeb;
	}
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		display: block;
		height: 100%;
		padding-top: 0px;
		margin-bottom: 50px;
		border-bottom: none;
	}
`;

export const ProductImage = styled(Link)`
	display: inline-block;
	width: 40%;
	height: 200px;
	background: #fbfbfb;
	border-radius: 2px;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 57% auto;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		height: 0;
		padding-bottom: 53.18%;
		background-size: 45% auto;
	}
`;

export const ProductDetail = styled.div`
	width: 60%;
	padding-left: 30px;
	a {
		display: inline-block;
		width: 70%;
		vertical-align: top;
		font-weight: 500;
		font-size: 13px;
		color: #fff;
		margin-right: 10px;
	}
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		padding-left: 0;
		a {
			width: 95%;
			line-height: 16px;
			margin-bottom: 10px;
			height: auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;

export const ProductType = styled.div`
	padding-top: 23px;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	color: #111;
	margin-bottom: 5px;
	@media ${(props) => props.theme.mobile} {
		font-size: 16px;
		line-height: 23px;
		padding-top: 20px;
	}
`;

export const ProductName = styled(Link)`
	font-size: 15px !important;
	font-weight: bold !important;
	line-height: 25px;
	color: #111 !important;
	margin-bottom: 10px;
	@media ${(props) => props.theme.mobile} {
		font-size: 18px;
		line-height: 26px;
	}
`;

export const ProductPrice = styled.div`
	font-weight: normal;
	font-size: 17px;
	line-height: 25px;
	color: #111;
	margin-bottom: 25px;
	@media ${(props) => props.theme.mobile} {
		font-size: 18px;
		line-height: 26px;
	}
`;

export const BasketBtn = styled.div`
	position: relative;
	right: 0;
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: solid 1px #dfdfdf;
	background-image: url(/img/basket.png);
	background-position: center center;
	background-size: 60% 55.8%;
	background-repeat: no-repeat;
	cursor: pointer;
	@media ${(props) => props.theme.mobile} {
		width: 60px;
		height: 60px;
	}
`;
