import React, { useState } from 'react';
import {toast} from 'react-toastify';
import { Link } from 'react-router-dom';
import MainContainer from '../../components/MainContainer';
import FAQ from './FAQ';
import Notice from './Notice';
import InquirePopup from '../Inquire';
import RoleupPopup from '../Roleup';
import NoticeSingle from './Notice/Single';
import {useDispatch, useSelector} from 'react-redux';
import {ToggleChatbot} from '../../store/global';
import {
    Wrapper,
    FixedSection,
    TopSection,
    Title,
    LinkArea,
    TabWrapper,
    Tab,
} from './styled';

const guideImage = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8 14.6429C4.33214 14.6429 1.35714 11.6679 1.35714 8C1.35714 4.33214 4.33214 1.35714 8 1.35714C11.6679 1.35714 14.6429 4.33214 14.6429 8C14.6429 11.6679 11.6679 14.6429 8 14.6429Z" fill="white"/>
<path d="M9.99288 4.51255C9.45717 4.04291 8.75003 3.78577 8.00003 3.78577C7.25003 3.78577 6.54288 4.0447 6.00717 4.51255C5.45003 5.00005 5.14288 5.65541 5.14288 6.3572V6.49291C5.14288 6.57148 5.20717 6.63577 5.28574 6.63577H6.14288C6.22145 6.63577 6.28574 6.57148 6.28574 6.49291V6.3572C6.28574 5.5697 7.05538 4.92862 8.00003 4.92862C8.94467 4.92862 9.71431 5.5697 9.71431 6.3572C9.71431 6.91255 9.32146 7.42148 8.71253 7.65541C8.33396 7.80005 8.01253 8.05362 7.78217 8.38577C7.54824 8.72505 7.42681 9.13219 7.42681 9.54469V9.92862C7.42681 10.0072 7.4911 10.0715 7.56967 10.0715H8.42681C8.50538 10.0715 8.56967 10.0072 8.56967 9.92862V9.52327C8.57059 9.34991 8.62373 9.18086 8.72216 9.03815C8.82059 8.89545 8.95974 8.78572 9.12145 8.72327C10.175 8.31791 10.8554 7.38934 10.8554 6.3572C10.8572 5.65541 10.55 5.00005 9.99288 4.51255ZM7.28574 11.9286C7.28574 12.1181 7.361 12.2997 7.49495 12.4337C7.6289 12.5677 7.81059 12.6429 8.00003 12.6429C8.18947 12.6429 8.37115 12.5677 8.5051 12.4337C8.63906 12.2997 8.71431 12.1181 8.71431 11.9286C8.71431 11.7392 8.63906 11.5575 8.5051 11.4235C8.37115 11.2896 8.18947 11.2143 8.00003 11.2143C7.81059 11.2143 7.6289 11.2896 7.49495 11.4235C7.361 11.5575 7.28574 11.7392 7.28574 11.9286Z" fill="white"/>
</svg>;

const Service = () => {
    const Dispatch = useDispatch();
    const me = useSelector(state=>state.me.user_data);

    const pathname = window.location.pathname;
    const length = pathname.lastIndexOf("/");
    const last_path = pathname.slice(length+1);
    const tab_path = (Number(last_path) ? Number(last_path) : last_path);

    const [InquirePopup_isshow,show_InquirePopup] = useState(0);
    const showInquirePopup = (e) => {
        e.preventDefault();
        if(me) {
            Dispatch(ToggleChatbot(1));
        } else {
            toast.error('문의를 하시려면 로그인이 필요합니다.');
        }
        // if(InquirePopup_isshow) {
        //     show_InquirePopup(0);
        // } else {
        //     show_InquirePopup(1);
        // }
    }

    const [RoleupPopup_isshow,show_RoleupPopup] = useState(0);
    const showRoleupPopup = (e) => {
        e.preventDefault();
        if(RoleupPopup_isshow) {
            show_RoleupPopup(0);
        } else {
            show_RoleupPopup(1);
        }
    }

    return (
        <MainContainer>
            <Wrapper>
                <FixedSection>
                    <TopSection>
                        <Title>
                            안녕하세요.<br />
                            사쿠라허브 고객센터입니다.<br />
                            무엇을 도와드릴까요?
                        </Title>
                        <LinkArea>
                        {me.order_count ? 
                            <a className="openchat" href="/page/280141">
                                간이통관서 신청방법
                            </a>
                        :''}
                            {/* <Link className="paymentGuide" to="/service/notice/339890">
                                {guideImage} 해외송금 결제방법 안내
                            </Link> */}
                            <Link onClick={showInquirePopup} className="oneon" color="secondary" to="/#">
                                <img src={process.env.PUBLIC_URL + "/img/pencil.png"} />
                                1:1 문의하기
                            </Link>
                        </LinkArea>
                    </TopSection>
                    <TabWrapper>
                        <Tab className={tab_path === "faq" ? "active" : ""}><Link to="/service/faq">자주 묻는 질문</Link></Tab>
                        <Tab className={tab_path === "notice" || typeof tab_path === "number" ? "active" : ""}><Link to="/service/notice">공지사항</Link></Tab>
                        <Tab><a href="#" onClick={showRoleupPopup}>등업신청</a></Tab>
                        {/* <Tab className={tab_path === "Oneon" ? "active" : ""}><Link to='/service/Oneon'>1:1 문의</Link></Tab> */}
                        <Tab><Link to='/mypage/inquire'>
                            1:1 문의내역<img src={process.env.PUBLIC_URL + "/img/inquire_logo.svg"} />
                        </Link></Tab>
                    </TabWrapper>
                </FixedSection>
                {tab_path === "faq" ? <FAQ /> : ''}
                {tab_path === "notice" ? <Notice /> : ''}
                {/* {tab_path === "Oneon" ? <OneOn /> : ''} */}
                {typeof tab_path === "number" ? <NoticeSingle /> : ''}
            </Wrapper>
            <RoleupPopup show={RoleupPopup_isshow} onClose={showRoleupPopup} submit_success={show_RoleupPopup} />
            <InquirePopup show={InquirePopup_isshow} onClose={showInquirePopup} submit_success={show_InquirePopup} />
        </MainContainer>
    );
};

export default Service;