import styled from 'styled-components';

export const AccountWrapper = styled.div`
    a{
        margin-top: 50px;
    }
`;

export const IdTitle = styled.div`
    margin-bottom: -20px;
    h3{
        font-size: 20px;
        font-weight: bold;
        line-height: normal;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        margin-bottom: 26px;
        h3{
            font-size: 18px;
        }
    }
`;

export const IdAccount = styled.div`
    margin-top: 50px;
    p{
        font-size: 13px;
        line-height: normal;
        text-align: left;
        color: #9a9a9e;
        margin-bottom: 10px;
    }
    @media ${props => props.theme.mobile}{
        margin-top: 14px;
    }
`;

export const IdInput = styled.input`
    position: relative;
    width: 100%;
    display: block;
    padding-bottom: 9px;
    border: none;
    border-bottom: 1px solid #999;
    text-align: left;
    font-size: 15px;
    line-height: normal;
    color: #111111;
`;

export const PwTitle = styled.div`
    margin-top: 50px;
    h3{
        font-size: 20px;
        font-weight: bold;
        line-height: normal;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        h3{
            font-size: 18px;
        }
    }
`;

export const PwInput = styled.input`
    position: relative;
    margin-top: 50px;
    width: 100%;
    display: block;
    padding-bottom: 9px;
    border: none;
    border-bottom: 1px solid #999;
    text-align: left;
    font-size: 15px;
    line-height: normal;
    color: #111111;
    ::placeholder{
        color: #111;
    }
    @media ${props => props.theme.mobile}{
        margin-top: 30px;
    }
`;