import React, {useState} from 'react';
import MainContainer from '../../../components/MainContainer';
import MainList from '../MainList';
import ConfirmPopup from './ConfirmPopup';
import {
    WidthdrawWrapper,
    WidthdrawTitle,
    SidePopup,
} from './styled';

import {
    Wrapper,
    Container,
} from '../styled';

const Widthdraw = () => {
    const [SidePopup_isshow, show_SidePopup] = useState(0);
    const showSidePopup = (e) => {
        e.preventDefault();
        if(SidePopup_isshow) {
            show_SidePopup(0);
        } else {
            show_SidePopup(1);
        }
    }

    return (
        <>
            <MainContainer type="small">
                <Wrapper>
                    <Container>
                        <MainList />
                        <WidthdrawWrapper>
                            <WidthdrawTitle>
                                <h3>회원 탈퇴 안내</h3>
                            </WidthdrawTitle>
                            <p>회원 탈퇴를 신청하시면 현재 로그인 된 아이디는 사용하실 수 없습니다.</p>
                            <p>회원 탈퇴를 하시더라도, 서비스 약관 및 개인정보 취급방침 동의하에 따라 일정 기간동안 회원 개인정보를 보관합니다.</p>
                            <SidePopup onClick={showSidePopup}>
                                <p>정말 계정을 삭제하시겠어요?</p>
                            </SidePopup>
                        </WidthdrawWrapper>
                    </Container>
                </Wrapper>
            </MainContainer>
            <ConfirmPopup show={SidePopup_isshow} onClose={showSidePopup}/>
        </>
    );
};

export default Widthdraw;