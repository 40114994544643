import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {SetUserData} from '../../store/me';
import {
    Title,
    ContentWrapper,
    ContentBox,
    Content,
    ContentList,
} from './styled';

const Mypage = () => {
    const me = useSelector(state=>state.me.user_data);
    const History = useHistory();
    const Dispatch = useDispatch();
    const arrow = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path fill="#111" d="M13.5 4.5l-1.072 1.045 5.684 5.705H3v1.5h15.113l-5.685 5.68L13.5 19.5 21 12l-7.5-7.5z"/>
            </svg>
    const Logout = (e) => {
        e.preventDefault();
        toast.info('로그아웃이 완료되었습니다.');
        localStorage.removeItem('user_token');
        Dispatch(SetUserData(''));
        History.push('/');
    }
    return (
        <>
            <Title>
                <h2>안녕하세요, {me.user_name ? me.user_name : ''}님!</h2>
                <p>로그아웃을 하고 싶으신가요? <a href="#" onClick={Logout}>로그아웃하기</a></p>
            </Title>
            <ContentWrapper>
                <ContentList>
                    <Link to="/mypage">
                        <ContentBox>
                            <Content>
                                <div class="flex">
                                    <p>대시보드</p>
                                    <span>{arrow}</span>
                                </div>
                            </Content>
                        </ContentBox>
                    </Link>
                    <Link to="/mypage/orderlist">
                        <ContentBox>
                            <Content>
                                <div class="flex">
                                    <p>주문내역</p>
                                    <span>{arrow}</span>
                                </div>
                            </Content>
                        </ContentBox>
                    </Link>
                    <Link to="/mypage/account">
                        <ContentBox>
                            <Content>
                                <div class="flex">
                                    <p>계정정보</p>
                                    <span>{arrow}</span>
                                </div>
                            </Content>
                        </ContentBox>
                    </Link>
                    <Link to="/mypage/point">
                        <ContentBox>
                            <Content>
                                <div class="flex">
                                    <p>내 포인트</p>
                                    <span>{arrow}</span>
                                </div>
                            </Content>
                        </ContentBox>
                    </Link>
                    <Link to="/mypage/inquire">
                        <ContentBox>
                            <Content>
                                <div class="flex">
                                    <p>1:1 문의</p>
                                    <span>{arrow}</span>
                                </div>
                            </Content>
                        </ContentBox>
                    </Link>
                    <Link to="/mypage/withdraw">
                        <ContentBox>
                            <Content>
                                <div class="flex">
                                    <p>회원탈퇴</p>
                                    <span>{arrow}</span>
                                </div>
                            </Content>
                        </ContentBox>
                    </Link>
                </ContentList>
            </ContentWrapper>
        </>
    );
};

export default Mypage;