import React from 'react';
import {Wrapper} from './styled';

const RadioButton = ({value, id, name, onClick, onChange, dataID, checked, width, children }) => {
    return (
        <Wrapper width={width}>
            <input type="radio" name={name} id={id} data-id={dataID} value={value} onClick={onClick} onChange={onChange} checked={checked} />
            <label for={id}>{children}</label>
        </Wrapper>
    );
};

export default RadioButton;

