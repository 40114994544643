import styled from 'styled-components';

export const MainContainerWrapper = styled.div`
    max-width: 1542px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    ${props => {
        if(props.type == 'small'){
            return `max-width:1040px;`
        }
    }}

    @media ${props => props.theme.mobile}{
        padding: 0px 0px;
    }
`;