import styled from 'styled-components';


export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    @media ${props => props.theme.mobile}{
        padding: 0 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid #ebebeb;
    }
`;

export const InquiryTable = styled.ul`
    margin-bottom: 50px; 
    .head {
        padding: 20px 0;
        font-size: 15px;
        line-height: 22px;
        color: #111;
        border-top: 1px solid #111;
        .date {
            padding-left: 25px;
        }
    }
    .active {
        padding-bottom: 0;
        .answer {
            display: block;

            > div {
                display:block;
                
            }
        }
        .svg {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    @media ${props => props.theme.mobile}{
        .head {
            display: none;
        }
    }
`;

export const InquiryList = styled.li`
    display: flex;
    position: relative;
    padding: 22px 0;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid #ebebeb;
`;

export const Status = styled.div`
    width: 152px;
    text-align: center;
    span {
        display: inline-block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 26px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #9A9A9E;
        background-color: #fff;
        border: 1px solid #ebebeb;
        border-radius: 2px;
        padding: 3px 10px;
        @media ${props => props.theme.mobile}{
            height: 28px;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .complete {
        color: #E55F74;
        border: 1px solid #E55F74;
    }
    @media ${props => props.theme.mobile}{
        width: 20%;
        span {
            font-size: 11px;
            height: 25px;
            display: block;
            line-height: 16px;
        }
    }
`;

export const Title = styled.div`
    width: calc(100% - 304px);
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #111;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        width: 80%;
        padding-left:20px;
        font-size: 14px;
        line-height: 23px;  
        text-align: left;
        padding-right: 30px;
    }
`;

export const Date = styled.div`
    width: 152px;
    font-size: 15px;
    line-height: 22px;
    color: #111111;
    @media ${props => props.theme.mobile}{
        display: none;
    }
`;

export const ArrowDown = styled.img`
    position: absolute;
    top: 33px;
    right: 0;
    transform: translateY(-50%);
`;

export const Answer = styled.div`
    display: none;
    width: 100%;
    border-top: 1px solid #ebebeb;
    background-color: #FBFBFB;
    margin-top: 22px;
    padding-top: 24px;
    padding-left:200px;
    padding-bottom: 30px;
    > div {
        margin-bottom:20px;
    }
    p {
        line-height:1.6;
        font-size:13px;
        h2 {
            margin-bottom:10px;
        }
    }
    @media ${props => props.theme.mobile}{
        padding-left:20px;
        padding-right:20px;
        p {
            width: 80%;
            padding-right: 10px;
        }
    }
`;

export const AnswerStatus = styled.div`
    width: 152px;
    text-align: center;
    span {    
        display: inline-block;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 26px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        border-radius: 2px;
        padding: 3px 10px;
        color: #fff;
        background-color: #E55F74;
        border: 1px solid #E55F74;

    }
    @media ${props => props.theme.mobile}{
        width: 20%;
        span {
            height: 28px;
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

export const MoreButtonWrapper = styled.div`
    padding: 0 20px;
    padding-bottom: 79px;
    border-bottom: 1px solid #ebebeb;
`;

export const MoreButton = styled.div`
    width: 100%;
    height: 60px;
    font-size: 18px;
    font-weight: 500;
    line-height: 58px;
    border-radius: 500px;
    border: solid 1px #999;
    text-align: center;
`;

export const ButtonWrapper = styled.div`
    margin-top: 50px;
`;