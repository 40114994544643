import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HomeWrapper = styled.div`
	width: 100%;
	height: 100%;
	.sold-out {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 69px;
		/* transform: translateY(-100%); */
		background-color: rgba(0, 0, 0, 0.3);
		color: #fff;
		font-family: 'Noto Sans KR';
		font-size: 18px;
		font-weight: 700;
		line-height: 67px;
		text-align: center;
	}
	@media ${(props) => props.theme.mobile} {
		.sold-out {
			height: 40px;
			font-size: 13px;
			line-height: 38px;
		}
	}
`;

export const MainPopupWrapper = styled.div`
	display: ${(props) => (props.show ? 'block' : 'none')};
`;

export const MainPostWrapper = styled.div`
	position: relative;
	display: flex;
	gap: 0 70px;
	max-width: 1542px;
	width: 100%;
	height: auto;
	/* background: #222; */
	margin: 50px auto 80px auto;
	padding: 0 20px;

	.cate {
		width: 50%;
		padding-bottom: 10px;
		.pc {
			display: flex;
		}
		.mo {
			display: none;
		}
		h5 {
			color: #111;
			font-family: 'Noto Sans KR';
			font-size: 25px;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 20px;
		}
		ul {
			display: flex;
			gap: 20px;
			flex-wrap: wrap;
			li {
				width: 118px;
				height: 104px;
				border-radius: 20px;
				background: #fff;
				border: 1px solid #eee;
				box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.04);
				text-align: center;
				cursor: pointer;
				transition: 0.2s;

				span {
					font-family: 'Noto Sans KR';
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					color: #888;
					transition: 0.2s;
					word-break: keep-all;
				}
				padding: 22px 0 0 0;
				svg {
					path {
						transition: 0.2s;
					}
					margin-bottom: 11px;
				}

				&.more:hover {
					border: 1px solid #eee;
					span {
						color: #888;
					}
					path {
						fill: #fff;
					}
				}
				&:hover {
					border: 1px solid #e55f74;
					span {
						color: #e55f74;
					}
					path {
						fill: #e55f74;
					}
				}
			}
		}
	}

	@media (max-width: 1200px) {
		gap: 0 40px;
		.cate {
			ul {
				gap: 15px;
				li {
					width: 106px;
					height: 94px;
				}
			}
		}
	}
	@media (max-width: 1024px) {
		gap: 0 30px;
		padding: 0 0 0 20px;
		.cate {
			ul {
				gap: 10px;
				li {
					width: 96px;
					height: 84px;
					padding-top: 16px;

					span {
						font-size: 12px;
					}
					svg {
						margin-bottom: 8px;
					}
				}
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		display: block;
		margin: 0 auto 0 auto;
		padding: 0;
		border-radius: 0;
		.cate {
			width: 100%;
			padding: 50px 5px 50px 25px;
			ul {
				gap: 15px;
				li {
					width: 100px;
					height: 100px;
					padding-top: 23px;
					span {
						font-size: 13px;
					}
					svg {
						margin-bottom: 11px;
					}
				}
			}
		}
	}
	@media (max-width: 610px) {
		.cate {
			.pc {
				display: none;
			}
			.mo {
				display: flex;
			}
		}
	}
`;

export const MainPost = styled.div`
	position: relative;
	width: 56.5%;
	max-width: 1502px;
	height: 418px;
	color: #fff;
	/* margin: 0 auto; */
	/* padding: 244px 60px 60px 60px; */

	border-radius: 20px;
	overflow: hidden;

	.bg {
		display: inline-block;
		width: 50%;
		height: 100%;
		/* padding-bottom: 100%; */
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		cursor: pointer;
	}
	.bg-01 {
		background-image: url('/img/petherb.jpg');
	}
	.bg-02 {
		background-image: url('/img/event.png');
	}

	/* :after {
		display: block;
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50%;
		z-index: 0;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.5) 100%
		);
		border-radius: 20px;
	} */

	.rel {
		position: relative;
		z-index: 1;
	}

	span {
		display: block;
		color: #fff;
		font-family: 'Noto Sans KR';
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 10px;
	}
	strong {
		display: block;
		color: #fff;
		font-family: 'Noto Sans KR';
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		word-break: keep-all;
	}
	.flex {
		display: flex;
		justify-content: space-between;
		gap: 0 20px;
		a {
			align-self: flex-end;
			padding-bottom: 10px;
		}
	}
	button {
		width: auto;
		height: 46px;
		line-height: 44px;
		font-size: 15px;
		color: #fff;
		background: none;
		border: 1px solid #fff;
		border-radius: 500px;
		padding: 0 45px;
		transition: 0.4s;
	}
	button:hover {
		color: #111;
		background: #fff;
	}
	@media (max-width: 1200px) {
		/* padding: 244px 30px 60px 30px; */
		height: 326px;

		span {
			font-size: 14px;
		}
		strong {
			font-size: 24px;
		}
	}
	@media (max-width: 1024px) {
		max-width: 400px;
		height: auto;
		.bg {
			display: inline-block;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
		} /* padding: 244px 20px 60px 20px; */
	}
	@media ${(props) => props.theme.mobile} {
		display: block;
		width: 100%;
		border-radius: 0;
		max-width: initial;
		.bg {
			display: inline-block;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
		} /* padding: 244px 20px 60px 20px; */
		/* padding: 266px 20px 53px 20px; */

		.flex {
			svg {
				width: 100px;
				height: auto;
			}
		}
		span {
			font-size: 16px;
		}
		strong {
			font-size: 23px;
		}
	}
`;

export const MobileMainPost = styled.div`
	display: none;
	width: 100%;
	max-width: 1502px;
	height: auto;
	color: #fff;
	margin: 0 auto;
	padding-top: 30px;
	padding-left: 20px;
	span {
		display: block;
		font-size: 15px;
		line-height: 23px;
		margin-bottom: 10px;
	}
	strong {
		display: block;
		font-weight: 500;
		font-size: 20px;
		line-height: 36px;
		margin-bottom: 22px;
	}
	button {
		width: auto;
		height: 46px;
		line-height: 44px;
		font-size: 14px;
		color: #fff;
		background: none;
		border: 1px solid #fff;
		border-radius: 500px;
		padding: 0 37px;
		letter-spacing: -0.42px;
		transition: 0.4s;
	}
	button:hover {
		color: #111;
		background: #fff;
	}
	@media ${(props) => props.theme.mobile} {
		/* display: block; */
	}
`;

export const MainPostImage = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
	background-image: url(/img/mainpost.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	@media ${(props) => props.theme.mobile} {
		position: relative;
		width: 100%;
		height: 287px;
	}
`;

export const HomeContainer = styled.div`
	width: 100%;
	max-width: 1542px;
	margin: 0 auto;
	padding: 0 20px;

	@media ${(props) => props.theme.mobile} {
		padding: 0 0px;
	}
`;

export const CategoryWrapper = styled.div`
	width: 100%;
	max-width: 1502px;
	overflow-x: auto;
	margin-bottom: 110px;
	@media ${(props) => props.theme.mobile} {
		border-bottom: 20px solid #f8f8f8;
		margin-bottom: 50px;
	}
`;

export const Category = styled.ul`
	width: ${(props) => (props.is_plus ? '1802px' : '1502px')};
	height: 135px;
	margin-bottom: 40px;
	li {
		display: inline-block;
		position: relative;
		margin-right: 27px;
	}
	.last-category {
		margin-right: 0px;
		span {
			width: 100%;
		}
	}
	a:hover {
		border: 1px solid #e55f74;
		path {
			fill: #e55f74;
		}
	}
	@media ${(props) => props.theme.mobile} {
		width: 1100px;
		width: ${(props) => (props.is_plus ? '1270px' : '1100px')};
		/* height: 112px; */
		height: 88px;
		padding: 0 20px;
		li {
			margin-right: 29px;
		}
	}
`;

export const CategoryLogo = styled(Link)`
	position: relative;
	display: block;
	width: 100px;
	height: 100px;
	border: 1px solid #dfdfdf;
	border-radius: 50%;
	transition: 0.2s linear;
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		path {
			transition: 0.2s linear;
		}
	}
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		width: 60px;
		height: 60px;
	}
`;

export const CategoryName = styled.span`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + 20px);
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: #111111;
	margin-top: 15px;
	@media ${(props) => props.theme.mobile} {
		font-size: 13px;
		margin-top: 8px;
		font-weight: 500;
		color: #787878;
	}
`;

export const TrendWrapper = styled.div`
	width: 100vw;
	height: auto;
	margin-left: calc(50% - 50vw);
	background: #f8f8f8;
	padding: 150px 0;
	margin-bottom: 150px;
	> div {
		max-width: 1542px;
		padding: 0 20px;
		margin: 0 auto;
	}
	@media ${(props) => props.theme.mobile} {
		padding: 50px 0;
		margin-bottom: 51px;
	}
`;

export const Title = styled.h4`
	font-size: 25px;
	line-height: 36px;
	font-weight: bold;
	color: #111111;
	margin-bottom: 20px;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		font-size: 20px;
		line-height: 29px;
	}
`;

export const TrendSection = styled.div`
	width: 100%;
	overflow-x: hidden;
	padding-bottom: 0px !important;
	/* border-bottom: 2px solid #DFDFDF; */
	/* margin-bottom: 150px; */
	& > div {
		padding-bottom: 52px;
	}
	/* ::-webkit-scrollbar {
        width: 1em;
        height: 6px;
    };
    ::-webkit-scrollbar-track {
        -webkit-appearance: none;
        -moz-appearance: none;  
        background-color: transparent;
    };
    ::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 6px;
    } */
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		padding-bottom: 67px;
		margin-bottom: 0;
		& > div {
			padding-bottom: 23px;
		}
		/* border-bottom: 20px solid #f8f8f8; */
	}
`;
export const TrendProducts = styled.div`
	width: calc(330px * (${(props) => (props.total ? props.total : 15)}));
	height: auto;
	@media ${(props) => props.theme.mobile} {
		width: calc(210px * (${(props) => (props.total ? props.total : 15)}));
	}
`;

export const TrendProduct = styled.div`
	display: inline-block;
	width: 299px;
	height: auto;
	margin-right: 30px;
	@media ${(props) => props.theme.mobile} {
		width: 180px;
		vertical-align: top;
	}
`;

export const ProductImage = styled(Link)`
	position: relative;
	display: block;
	width: 299px;
	height: 299px;
	background-color: #fff;
	border-radius: 2px;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-position: center center;
	background-size: 60% 60%;
	background-repeat: no-repeat;
	margin-bottom: 20px;
	border: 1px solid #eee;
	@media ${(props) => props.theme.mobile} {
		width: 180px;
		height: 180px;
		background-size: 70%;
		border: 1px solid #eee;
	}
`;

export const ProductType = styled.div`
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	color: #111111;
	margin-bottom: 5px;
	@media ${(props) => props.theme.mobile} {
		font-size: 12px;
		margin-bottom: 8px;
	}
`;

export const ProductName = styled.div`
	font-size: 13px;
	line-height: 19px;
	color: #111111;
	margin-bottom: 15px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media ${(props) => props.theme.mobile} {
		font-size: 13px;
		margin-bottom: 10px;
	}
`;

export const ProductPrice = styled.div`
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #111111;
	@media ${(props) => props.theme.mobile} {
		font-size: 14px;
	}
`;

export const TipWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 104px;
	@media ${(props) => props.theme.mobile} {
		border-bottom: 20px solid #f8f8f8;
		margin-bottom: 50px;
	}
`;

export const TipSection = styled.div`
	width: 100%;
	height: auto;
	overflow-x: auto;
	& > div {
		padding-bottom: 50px;
	}
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		/* padding-bottom: 50px; */
		& > div {
			padding-bottom: 50px;
		}
	}
`;

export const TipCardWrapper = styled.div`
	width: ${(props) => (props.count ? 510 * props.count + 'px' : '1502px')};
	height: 720px;
	> a:last-child {
		margin-right: 0;
	}
	@media ${(props) => props.theme.mobile} {
		/* height: 500px; */
		/* width:820px; */
		width: ${(props) => (props.count ? 270 * props.count + 'px' : '1502px')};
		height: 350px;
	}
`;

export const TipCard = styled(Link)`
	display: inline-block;
	position: relative;
	width: 480px;
	height: 720px;
	margin-right: 31px;
	/* overflow-x: auto; */
	@media ${(props) => props.theme.mobile} {
		width: 240px;
		height: 360px;
	}
`;

export const CardImage = styled.div`
	width: 100%;
	height: 480px;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	@media ${(props) => props.theme.mobile} {
		height: 220px;
	}
`;

export const CardContent = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 240px;
	background-color: ${(props) => (props.color ? props.color : '#eee')};
	font-size: 20px;
	line-height: 29px;
	color: #ffffff;
	padding: 40px;
	@media ${(props) => props.theme.mobile} {
		height: 140px;
		font-size: 15px;
		line-height: 26px;
		padding: 17px;
	}
`;

export const CardArrow = styled.div`
	position: absolute;
	left: 40px;
	bottom: 40px;
	width: 40px;
	height: 40px;
	background-color: #fff;
	border-radius: 50%;
	background-image: url(/img/arrow-right-sharp.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 18px auto;
	@media ${(props) => props.theme.mobile} {
		left: 20px;
		bottom: 20px;
		width: 38px;
		height: 38px;
		background-size: 24px auto;
	}
`;

export const RecommendWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 200px;
	@media ${(props) => props.theme.mobile} {
		margin-bottom: 30px;
	}
`;

export const TitleSection = styled.div`
	display: flex;
	justify-content: space-between;
	@media (max-width: 1400px) {
		display: block;
	}
`;

export const RecommendTabWrapper = styled.div`
	width: auto;
	height: auto;
	max-width: calc(100% - 350px);
	position: relative;
	overflow-x: auto;
	padding-bottom: 10px;
	margin-bottom: 0px;

	.active {
		border: 1px solid #e55f74;
		color: #e55f74;
	}

	> div {
		padding-bottom: 10px;
	}

	/* ::-webkit-scrollbar {
        width: 1em;
        height: 6px;
    };
    ::-webkit-scrollbar-track {
        -webkit-appearance: none;
        -moz-appearance: none;  
        background-color: transparent;
    };
    ::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 6px;
    } */

	@media (max-width: 1400px) {
		margin-bottom: 24px;
		max-width: 100%;
	}
`;

export const RecommendTab = styled.ul`
	height: auto;
	width: ${(props) => (props.is_plus ? '1250px' : 'auto')};
	li {
		float: left;
		width: auto;
		height: 40px;
		padding: 0 10px;
		background: #ffffff;
		border: 1px solid #f0f0f0;
		border-radius: 200px;
		font-size: 13px;
		line-height: 38px;
		text-align: center;
		color: #111111;
		margin-right: 10px;
		transition: 0.3s;
		cursor: pointer;
	}
	li:last-child {
		margin-right: 0px;
	}
	li:hover {
		border: 1px solid #e55f74;
		color: #e55f74;
	}
	@media (max-width: 1400px) {
		/* width: 1110px; */
		width: ${(props) => (props.is_plus ? '1300px' : '1130px')};
		margin-top: 13px;
		padding: 0 20px;
	}
`;

export const RecommendCardWrapper = styled.div`
	width: 100%;
	overflow-x: auto;
	.recommend {
		display: inline-block;
		width: calc(100% - 330px);
		padding-bottom: 46px;
		margin-bottom: 0px;
	}
	@media ${(props) => props.theme.mobile} {
		.recommend {
			width: 100%;
			padding-bottom: 54px;
		}
	}
`;

export const CategoryCard = styled.div`
	position: relative;
	display: inline-block;
	width: 299px;
	height: 460px;
	color: #fff;
	background: #444444;
	border-radius: 2px;
	padding-top: 50px;
	padding-left: 30px;
	margin-right: 30px;
	vertical-align: top;
	strong {
		font-weight: bold;
		font-size: 21px;
		line-height: 30px;
		margin-bottom: 10px;
	}
	span {
		display: block;
		font-size: 13px;
		line-height: 19px;
		color: #ffffff;
	}
	@media ${(props) => props.theme.mobile} {
		display: none;
	}
`;

export const SubCategory = styled.ul`
	position: absolute;
	bottom: 40px;
	li {
		display: inline-block;
		width: auto;
		height: 30px;
		border: 1px solid rgba(255, 255, 255, 0.6);
		border-radius: 100px;
		padding: 0 13px;
		font-size: 13px;
		line-height: 28px;
		margin-bottom: 10px;
	}
`;

export const OpenChatWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 150px;
	@media ${(props) => props.theme.mobile} {
		border-bottom: 20px solid #f8f8f8;
		margin-bottom: 50px;
	}
`;

export const OpenChatSection = styled.div`
	position: relative;
	width: 100%;
	height: 200px;
	background: #021234;
	border-radius: 2px;
	padding-top: 53px;
	padding-left: 50px;
	font-size: 23px;
	line-height: 33px;
	color: #fff;
	span {
		opacity: 0.8;
		margin-right: 8px;
		font-weight: 300;
	}
	p {
		margin-bottom: 21px;
		strong {
			font-weight: 400;
		}
	}
	a {
		display: inline-block;
		width: auto;
		height: 42px;
		padding: 0 26px;
		font-weight: 500;
		font-size: 13px;
		line-height: 40px;
		color: #fff;
		background: #021234;
		border: 1px solid #fff;
		border-radius: 500px;
		transition: 0.3s;
	}
	a:hover {
		color: #021234;
		background: #fff;
	}
	img {
		position: absolute;
		width: 250px;
		height: auto;
		bottom: 0;
		right: 70px;
	}
	.mobile {
		display: none;
	}
	@media ${(props) => props.theme.mobile} {
		height: 180px;
		padding-top: 50px;
		padding-left: 30px;
		font-size: 20px;
		line-height: 1.5;
		p {
			/* display: none; */
			font-size: 17px;
			span {
				display: block;
			}
			strong {
				display: none;
			}
		}
		a {
			/* display: none; */
		}
		span {
			opacity: 1;
		}
		img {
			width: 160px;
			right: 0;
		}
		.opacity {
			opacity: 0.8;
		}
		.mobile {
			display: block;
			font-size: 15px;
		}
	}
`;

export const ReviewWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 150px;
	@media ${(props) => props.theme.mobile} {
		margin-bottom: 0px;
	}
`;

export const ReviewSection = styled.div`
	width: 100%;
	overflow-x: auto;
	/* ::-webkit-scrollbar {
        width: 1em;
        height: 6px;
    };
    ::-webkit-scrollbar-track {
        -webkit-appearance: none;
        -moz-appearance: none;  
        background-color: transparent;
    };
    ::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 6px;
    } */
	& > div {
		padding-bottom: 50px;
	}
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		padding-bottom: 50px;
		& > div {
			padding-bottom: 20px;
		}
	}
`;
export const ReviewProducts = styled.div`
	width: calc(385px * (${(props) => (props.count ? props.count : 6)}));
	height: auto;
	@media ${(props) => props.theme.mobile} {
		width: calc(190px * (${(props) => (props.count ? props.count : 6)}));
	}
`;

export const ReviewProduct = styled.div`
	display: inline-block;
	width: 353px;
	height: auto;
	margin-right: 30px;
	background-color: #fff;
	vertical-align: top;
	@media ${(props) => props.theme.mobile} {
		width: 170px;
		margin-right: 20px;
	}
`;

export const ReviewImage = styled(Link)`
	display: block;
	width: 353px;
	height: 240px;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	@media ${(props) => props.theme.mobile} {
		width: 170px;
		height: 120px;
	}
`;

export const ReviewContent = styled.div`
	padding: 20px 0;
	border-bottom: 1px solid #ebebeb;
	p {
		font-size: 15px;
		line-height: 22px;
		font-weight: 500;
		color: #111;
		padding-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	small {
		font-size: 13px;
		line-height: 23px;
		color: #999;
	}
	span {
		:after {
			display: inline-block;
			content: '';
			width: 1px;
			height: 10px;
			background-color: #999;
			margin: 0 6px;
		}
	}
	@media ${(props) => props.theme.mobile} {
		p {
			font-size: 14px;
			line-height: 1.53;
		}
		small {
			font-size: 12px;
			line-height: 20px;
		}
	}
`;

export const ReviewProductDetails = styled.div`
	display: table;
	vertical-align: middle;
	width: 100%;
	padding: 20px 0;
	cursor: pointer;
	transition: 0.5s;
	:hover {
		opacity: 0.6;
	}
	@media ${(props) => props.theme.mobile} {
		display: none;
	}
`;

export const ReviewProductImage = styled(Link)`
	display: block;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background-color: ${(props) => (props.url ? '#fbfbfb' : '')};
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-repeat: no-repeat;
	background-size: 60px auto;
	background-position: center center;
	border: 1px solid #fbfbfb;
`;

export const ReviewProductName = styled.div`
	display: table-cell;
	width: auto;
	vertical-align: middle;
	color: #111111;
	padding-left: 20px;
	p {
		font-weight: 500;
		font-size: 11px;
		line-height: 16px;
		margin-bottom: 2px;
	}
	span {
		display: inline-block;
		font-size: 13px;
		line-height: 19px;
	}
	@media ${(props) => props.theme.mobile} {
		display: inline-block;
		p {
			font-size: 14px;
			padding-top: 6px;
			margin-bottom: 0px;
		}
		span {
			font-size: 14px;
			margin-bottom: 0px;
		}
	}
`;

export const ReviewProductPrice = styled.div`
	display: table-cell;
	width: 100px;
	vertical-align: middle;
	text-align: right;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	color: #111;
	@media ${(props) => props.theme.mobile} {
		display: block;
		font-size: 14px;
		line-height: 20px;
		text-align: left;
		padding-left: 20px;
	}
`;
