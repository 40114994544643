import React, { useEffect, useRef, useState } from 'react';
import {
	GuideWrapper,
	LinkWrapper,
	MoBannerWrapper,
	Mochatting,
	QnaWrapper,
	RankingWrapper,
	Tabcontent,
	Wrapper,
	Hidden,
} from './styled';
import { Link } from 'react-router-dom';
import MainContainer from '../../components/MainContainer';
import { ref } from 'joi-browser';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	Autoplay,
} from 'swiper/modules';

// Import Swiper styles

import 'swiper/css';
import 'swiper/css/pagination';
import { GetRank } from '../../api/me';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Reward = () => {
	const gotoSlide = (key) => {
		ref?.current?.swiper?.slideTo(key);
	};
	const { Kakao } = window;
	const [activeIndex, setActiveIndex] = useState(0);
	const me = useSelector((state) => state.me.user_data);

	const rewardGuide = [
		{
			thumbnail: '/img/guide01.png',
		},
		{
			thumbnail: '/img/guide02.png',
		},
		{
			thumbnail: '/img/guide03.png',
		},
	];

	const qna = [
		{
			cate: '리워드',
			title: '별도 참여 조건이 있나요?',
			content:
				'별도 참여 조건은 없습니다.<br />사쿠라허브의 회원이시라면 누구나 참여 가능합니다.',
		},
		{
			cate: '리워드',
			title: '추천 명수에 제한이 있나요?',
			content:
				'추천 명수에 제한은 없습니다.<br/>지인이 사쿠라허브의 회원이 아니라면 몇 명이든 추천 가능합니다.',
		},
		{
			cate: '리워드',
			title: '가입한 친구가 상품을 구매할 때마다 포인트가 지급되나요?',
			content:
				'내 추천을 통해 가입한 지인이 상품 구매를 완료하고 주문 상태가 배송완료로 바뀔 시, 첫 구매에 한하여 포인트를 지급해드리고 있습니다.',
		},
		{
			cate: '리워드',
			title: '리워드는 어떻게 사용하나요?',
			content:
				'리워드는 ‘포인트 상점’에서 10만 포인트 단위로 캐쉬백 받으시거나 상품 구매 시 현금처럼 사용하실 수 있습니다.',
		},
		{
			cate: '리워드',
			title: '리워드 사용 기한이 있나요?',
			content:
				'리워드 사용 기한은 정해져 있지 않습니다.<br />내가 모은 리워드를 원하는 때에, 원하는 만큼 사용해보세요.',
		},
	];

	const [isAnswer, setIsAnswer] = useState(false);
	const toggle = (key) => {
		setIsAnswer((prevKey) => (prevKey === key ? null : key));
	};

	const [isMember, setIsMember] = useState(true);

	const [ranking, setRanking] = useState([]);

	useEffect(() => {
		GetRank({})
			.then((res) => {
				console.log(res);
				if (res.data.code == 200) {
					setRanking(res.data.body.items);
				} else {
					setRanking(null);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const KakaoShare = (e) => {
		Kakao.Link.sendDefault({
			objectType: 'feed',
			buttonTitle: '초대 수락하기',
			content: {
				title: `사쿠라허브로 초대합니다`,
				description: `초대를 수락하고 10,000원 할인받으세요.`,
				imageUrl:
					'http://sakuraherb.com/wp-content/uploads/2021/02/mainpost.png',
				link: {
					mobileWebUrl: me.referer_url ? me.referer_url : '',
					webUrl: me.referer_url ? me.referer_url : '',
				},
			},
		});
	};

	const copy_input = useRef('');
	const CopyLink = (e) => {
		e.preventDefault();
		copy_input.current.select();
		document.execCommand('copy');
		e.target.focus();
		toast.success('추천링크 복사가 완료되었습니다.');
	};

	return (
		<MainContainer>
			<Wrapper>
				<GuideWrapper>
					<h2>
						사쿠라허브에 친구 초대하고
						<br /> 포인트 받아가세요 !
					</h2>
					<p>
						친구들을 사쿠라허브에 가입하도록 초대하면
						<span>나에게, 친구에게 10,000 포인트</span> 지급해 드립니다.
					</p>
				</GuideWrapper>
				<Tabcontent>
					<div className="flex">
						<div className="tab-content">
							<Swiper
								modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
								ref={ref}
								spaceBetween={10}
								slidesPerView={1}
								speed={750}
								onSlideChange={(swiper) => setActiveIndex(swiper?.realIndex)}
								pagination={{ clickable: true }}
								autoplay={{ delay: 3000, disableOnInteraction: false }}
							>
								{rewardGuide?.map((item, key) => (
									<SwiperSlide key={key}>
										<div
											className="banner"
											style={{ backgroundImage: `url(${item?.thumbnail})` }}
										></div>
										<div className="bg"></div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
						<div className="guide">
							<h1>사쿠라허브 리워드 가이드</h1>
							<ul className="tab-list">
								<li
									className={activeIndex == 0 ? 'active' : ''}
									onClick={() => gotoSlide(0)}
								>
									<h3>
										01 <span>친구 초대하기</span>
									</h3>
									<p>
										내 추천 링크 또는 SNS 공유를 통해 친구들을 사쿠라허브에
										초대해요.
									</p>
								</li>
								<li
									className={activeIndex == 1 ? 'active' : ''}
									onClick={() => gotoSlide(1)}
								>
									<h3>
										02 <span>회원가입</span>
									</h3>
									<p>
										친구가 사쿠라허브에 가입하면 친구에게 10,000 포인트가
										지급돼요.
									</p>
								</li>
								<li
									className={activeIndex == 2 ? 'active' : ''}
									onClick={() => gotoSlide(2)}
								>
									<h3>
										03 <span>상품구매</span>
									</h3>
									<p>
										가입한 친구가 상품을 구입 완료하면 나에게 10,000포인트가
										지급돼요.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</Tabcontent>
				<LinkWrapper>
					{me?.ID ? (
						<div>
							<div className="copy-box">
								<p onClick={CopyLink} className="url">
									https://www.sakuraherb.com/?raf=ref6454304
								</p>
								<span onClick={CopyLink}>링크 복사</span>
							</div>
							<div className="shar-box">
								<div className="kakao" onClick={KakaoShare}>
									<img src="./img/icon-kakao.svg" alt="카카오아이콘" />
									<p>카카오톡으로 공유</p>
								</div>
								<div
									className="facebook"
									onClick={() =>
										window.open(
											'https://www.facebook.com/sharer/sharer.php?u=' +
												(me.referer_url ? me.referer_url : '') +
												'&amp;t=사쿠라허브로 초대합니다'
										)
									}
								>
									<img src="./img/icon-facebook.svg" alt="페이스북아이콘" />
									<p>페이스북으로 공유</p>
								</div>
							</div>
						</div>
					) : (
						<>
							<a className="banner">
								<div className="left">
									<h1>아직 사쿠라허브 회원이 아니신가요?</h1>
									<h2>지금 바로 가입하고 포인트 챙겨가세요 !</h2>
									<div
										className="btn"
										onClick={() => (window.location.href = '/login')}
									>
										<p>바로 시작하기</p>
										<img src="./img/start-arrow.svg" alt="화살표아이콘" />
									</div>
								</div>
								<div className="right">
									<img src="./img/banner-img.png" alt="" />
								</div>
							</a>
							<MoBannerWrapper>
								<a href="" className="mo-banner">
									<div className="left">
										<p>아직 사쿠라허브 회원이 아니신가요?</p>
										<h2>
											지금 바로 가입하고
											<br />
											<span>포인트 챙겨가세요 !</span>
										</h2>
									</div>
									<div className="right">
										<img src="./img/banner-img-mo2.png" alt="" />
									</div>
								</a>
							</MoBannerWrapper>
						</>
					)}
				</LinkWrapper>
				<RankingWrapper>
					<h2>이달의 추천인 랭킹</h2>
					{ranking?.length ? (
						ranking.map((item, key) => (
							<div className="ranking" key={key}>
								<div className="list">
									<div className="info">
										<div className="number">
											<p>{key + 1}</p>
										</div>
										<div className="name-box">
											<p className="name">{item?.user_name}</p>
											<p className="point">{item?.total}</p>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div className="">
							<br />
							이번달 내역이 아직 없습니다.
							<br />
						</div>
					)}
				</RankingWrapper>
				<QnaWrapper>
					<h2>궁금한 점이 있어요.</h2>
					{qna.map((item, key) => (
						<div className="qna" key={key}>
							<div className="list" onClick={() => toggle(key)}>
								<div className="info">
									<div className="q-box">
										<p>Q</p>
									</div>
									<div className="cont-box">
										<p className="cate">{item.cate}</p>
										<p className="title">{item.title}</p>
									</div>
								</div>
								<div className="arrow">
									<img
										src={
											isAnswer === key
												? '/img/icon-reward-arrow-toggle.svg'
												: '/img/icon-reward-arrow.svg'
										}
									/>
								</div>
							</div>
							{isAnswer === key && (
								<div className="answer">
									<div className="info">
										<div className="a-box">
											<p>A</p>
										</div>
										<div
											className="cont-box"
											dangerouslySetInnerHTML={{ __html: item.content }}
										/>
									</div>
								</div>
							)}
						</div>
					))}
				</QnaWrapper>
				<Mochatting>
					<p>
						사쿠라허브 오픈채팅에 참여하세요!
						<br /> 1:1 오픈채팅을 통해 상담을 받으실 수 있습니다.
					</p>
					<div className="btn">
						<img src="./img/icon-kakao.svg" alt="카카오아이콘" />
						<p>1:1 오픈채팅 접속</p>
					</div>
				</Mochatting>
				<Hidden>
					<input
						type="text"
						ref={copy_input}
						name="copy_account"
						value={me.referer_url ? me.referer_url : ''}
					/>
				</Hidden>
			</Wrapper>
		</MainContainer>
	);
};

export default Reward;
