import React, { useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import MainContainer from '../../components/MainContainer';
import { Br } from '../../components/Grid';
import MainList from './MainList';
import {
  Wrapper,
  Container,
  LinkWrapper,
  LinkTitle,
  LinkContent,
  CopyButton,
  ButtonWrapper,
  ShareButton,
  Share,
  Hidden,
} from './styled';

const Mypage = () => {
  const { Kakao } = window;
  const me = useSelector(state => state.me.user_data);
  const copy_input = useRef('');
  const copy_code_input = useRef('');
  const copy_twitter = useRef('');
  const CopyLink = e => {
    e.preventDefault();
    copy_input.current.select();
    document.execCommand('copy');
    e.target.focus();
    toast.success('추천링크 복사가 완료되었습니다.');
  };
  const CopyTwLink = e => {
    e.preventDefault();
    copy_twitter.current.select();
    document.execCommand('copy');
    e.target.focus();
    toast.success('트위터주소 복사가 완료되었습니다.');
  };
  const CopyCode = e => {
    e.preventDefault();
    copy_code_input.current.select();
    document.execCommand('copy');
    e.target.focus();
    toast.success('추천코드 복사가 완료되었습니다.');
  };
  const KakaoShare = e => {
    // Kakao.Link.createDefaultButton({
    //     container: '#kakao-link-btn',
    //     objectType: 'text',
    //     text: `[訃告]\n故 `+(res.data.body ? res.data.body.deceased : '')+` 별세 하셨기에 아래와 같이 부고를 전해드립니다\n\n[부고 확인]\nhttps://app.yepum.co.kr/front/obituary/`+res.data.body.ID+`\n\n황망한 마음에 일일이 연락 드리지 못함을 널리 혜량해 주시길 바랍니다\n\n상주 `+res.data.body.mourner[0].name,
    //     buttonTitle:'부고 확인',
    //     link: {
    //         mobileWebUrl:
    //           'https://app.yepum.co.kr/front/obituary/'+res.data.body.ID,
    //         webUrl:
    //           'https://app.yepum.co.kr/front/obituary/'+res.data.body.ID,
    //       },
    // });

    // title: '사쿠라허브로 초대합니다. ',
    //                     description: '초대를 수락하고 10,000원 할인받으세요.',
    //                     imageUrl: 'https://www.sakuraherb.com/wp-content/uploads/2019/06/sakuraherb.png',
    //                     link: {
    //                       webUrl: '<?php echo $refLink; ?>'
    //                     }
    Kakao.Link.sendDefault({
      objectType: 'feed',
      buttonTitle: '초대 수락하기',
      content: {
        title: `사쿠라허브로 초대합니다`,
        description: `초대를 수락하고 10,000원 할인받으세요.`,
        imageUrl:
          'http://sakuraherb.com/wp-content/uploads/2021/02/mainpost.png',
        link: {
          mobileWebUrl: me.referer_url ? me.referer_url : '',
          webUrl: me.referer_url ? me.referer_url : '',
        },
      },
    });
  };
  return (
    <>
      <MainContainer type="small">
        <Wrapper>
          <Container>
            <MainList />
            <LinkWrapper>
              <LinkTitle>
                <h3>친구 추천</h3>
              </LinkTitle>
              <LinkContent>
                <p>
                  친구들을 사쿠라허브에 가입하도록 초대하면{' '}
                  <span>나에게, 친구에게 10,000 포인트</span> 지급해 드립니다.
                </p>
                <p>추천자는 가입한 친구가 구입 완료해야 지급됩니다.</p>
              </LinkContent>
              <FlexBox>
                <div>
                  <h4>추천인 링크</h4>
                  <CopyButton>
                    <p onClick={CopyLink}>
                      {me.referer_url ? me.referer_url : ''}
                    </p>
                    <a href="#" onClick={CopyLink}>
                      링크 복사
                    </a>
                  </CopyButton>
                </div>
                <div>
                  <h4>추천인 코드</h4>
                  <CopyButton>
                    <p onClick={CopyCode}>
                      {me.referral_id ? me.referral_id : ''}
                    </p>
                    <a href="#" onClick={CopyCode}>
                      추천코드 복사
                    </a>
                  </CopyButton>
                </div>
              </FlexBox>
              <FlexBox>
                <div>
                  <h4>트위터 주소</h4>
                  <CopyButton>
                    <p onClick={CopyTwLink}>
                      {me.referral_twitter ? me.referral_twitter : ''}
                    </p>
                    <a href="#" onClick={CopyTwLink}>
                      링크 복사
                    </a>
                  </CopyButton>
                </div>
                <div className="nt">
                  <p>
                    *통신사 회선 접속 불가 상태가 발생하고 있습니다.
                    <br />
                    트위터 홍보 방법을 추천드립니다.
                  </p>
                  트위터 주소로 홍보시 추천인 코드 필수 입력이 필요합니다.
                </div>
              </FlexBox>
              <Hidden>
                <input
                  type="text"
                  ref={copy_input}
                  name="copy_account"
                  value={me.referer_url ? me.referer_url : ''}
                />
                <input
                  type="text"
                  ref={copy_code_input}
                  name="copy_code"
                  value={me.referral_id ? me.referral_id : ''}
                />
                <input
                  type="text"
                  ref={copy_twitter}
                  name="copy_twitter"
                  value={me.referral_twitter ? me.referral_twitter : ''}
                />
              </Hidden>
              <ButtonWrapper>
                <ShareButton>
                  <Share>
                    <button onClick={KakaoShare}>카카오톡으로 공유</button>
                  </Share>
                  <Share>
                    <a
                      href={
                        'https://www.facebook.com/sharer/sharer.php?u=' +
                        (me.referer_url ? me.referer_url : '') +
                        '&amp;t=사쿠라허브로 초대합니다'
                      }
                      target="_blank"
                      className="color"
                    >
                      페이스북으로 공유
                    </a>
                  </Share>
                </ShareButton>
              </ButtonWrapper>
            </LinkWrapper>
          </Container>
        </Wrapper>
      </MainContainer>
    </>
  );
};
const FlexBox = styled.div`
  display: flex;
  gap: 20px;
  > div {
    flex: 1;
    h4 {
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
  .nt {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    color: #111111;
    padding-top: 14px;
    margin-bottom: 20px;
    p {
      color: #ec233a;
    }
  }
  @media ${props => props.theme.mobile} {
    display: block;
    .nt {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: #111111;
      padding-top: 0px;
      margin-bottom: 20px;
      word-break: keep-all;
      p {
        color: #ec233a;
        padding-bottom: 6px;
      }
    }
  }
`;
export default Mypage;
