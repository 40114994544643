import styled from 'styled-components';

export const PopupWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9990;
	${(props) => {
		if (props.show) {
			return `
                display: block;
            `;
		} else {
			return `
                display: none;
            `;
		}
	}}
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: 50;
	position: absolute;
	top: 0;
	left: 0;
	${(props) => {
		if (props.show) {
			return `
                display: block;
            `;
		} else {
			return `
                display: none;
            `;
		}
	}}
`;

export const PopupInner = styled.div`
	max-width: 600px;
	width: ${(props) => (props.width ? props.width : `100%`)};
	height: ${(props) => (props.height ? props.height : `636px`)};
	position: relative;
	z-index: 100;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	background: #fff;
	/* border-radius: 50px; */
	overflow-y: auto;
	padding: 50px 50px 0 50px;
	position: relative;

	.title {
		display: flex;
		gap: 0 12px;
		color: #111;
		font-family: 'Noto Sans KR';
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		border-bottom: 1px solid #ebebeb;
		padding-bottom: 11px;
		margin-bottom: 32px;
		svg {
			position: relative;
			top: 1px;
		}
	}
	text-align: center;
	.qr {
		max-width: 308px;
		width: 100%;
		height: auto;
		margin-bottom: 10px;
	}
	.text {
		color: #888;

		text-align: center;
		font-family: 'Noto Sans KR';
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -0.16px;
	}
	.btn {
		position: absolute;
		background: #eee;
		width: 100%;
		height: 60px;
		color: #111;
		left: 0;
		bottom: 0;
		cursor: pointer;
		font-family: 'Noto Sans KR';
		font-size: 15px;
		font-weight: 500;
		line-height: 56px;
	}
`;

export const PopupClose = styled.div`
	position: relative;
	z-index: 100;
	cursor: pointer;
	.icon-close {
		position: absolute;
		top: 37px;
		right: 37px;
		color: #111;
	}
	${(props) =>
		props.full
			? `
        display: none;
    `
			: ``}
	@media ${(props) => props.theme.mobile} {
		display: none;
	}
`;
