import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 100px 0px 200px 0px;
    @media ${(props) => props.theme.mobile} {
        overflow: hidden;
        margin: 0px 0px;
        padding: 50px 0px 79px 0px;
        border-bottom: 1px solid #ebebeb;
    }
`;

export const Container = styled.div`
    @media ${(props) => props.theme.mobile} {
        margin: 0px 20px;
    }
`;

export const SingleTitle = styled.div`
    margin-bottom: 30px;
    h3 {
        font-size: 27px;
        font-weight: bold;
        line-height: normal;
        color: #111111;
    }
    @media ${(props) => props.theme.mobile} {
        font-size: 24px;
    }
`;

export const OrderTime = styled.div`
    padding: 27px 0px;
    margin-bottom: 29px;
    border-radius: 2px;
    border: 1px solid #e55f74;
    background-color: #ffffff;
    text-align: center;
    p {
        font-size: 15px;
        font-weight: normal;
        line-height: 1.73;
        color: #111111;
    }
    strong {
        font-weight: 500;
    }
    small {
        font-weight: 500;
        color: #e55f74;
    }
    @media ${(props) => props.theme.mobile} {
        p {
            font-size: 13px;
        }
    }
`;

export const ProductList = styled.div`
    .line {
        padding: 30px 0px;
    }
    .line-top {
        padding: 30px 0px;
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
    }
    .line-middle {
        padding: 30px 0px;
        border-bottom: 1px solid #ebebeb;
    }
    .line-bottom {
        padding: 30px 0px;
        border-top: 1px solid #ebebeb;
        border-style: dashed;
        td span {
            font-size: 12px;
            color: #e55f74;
            margin-top: 10px;
            display: block;
        }
        .btn_ {
            font-weight: normal;
            color: #fff;
            background-color: #c82333;
            text-align: center;
            padding: 6px 10px 8px 10px;
            font-size: 13px;
            font-weight: normal;
            border-radius: 0.25rem;
            transition: 0.3s;
            cursor: pointer;
            :hover {
                opacity: 0.7;
            }
        }
    }
    .bottom {
        padding: 20px 0px 30px 0px;
        border-bottom: 1px solid #ebebeb;
        border-style: dashed;
    }
    table {
        width: 100%;
        font-size: 15px;
        line-height: normal;
        color: #111111;
        tbody {
            .text-right {
                text-align: right;
            }
            .kakao.pc {
                display: inline-block;
            }
            .kakao.mobile {
                display: none;
            }
            .kakao {
                display: inline-block;
                border-radius: 200px;
                background: #fceb4f;
                color: #111;
                font-family: 'Noto Sans KR';
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 44px;
                width: auto;
                height: 46px;
                padding: 0 24px;
                cursor: pointer;
                svg {
                    position: relative;
                    top: -1px;
                    margin-right: 7px;
                    vertical-align: middle;
                }
            }
            .text-color {
                text-align: right;
                font-size: 17px;
                font-weight: bold;
                line-height: normal;
                color: #e55f74;
            }
            .color {
                font-size: 17px;
                font-weight: bold;
                line-height: normal;
                color: #111111;
            }
            td {
                line-height: 40px;
                height: 40px;
            }
        }
    }
    @media ${(props) => props.theme.mobile} {
        .line {
            padding: 20px 0px;
        }
        .line-top {
            padding: 20px 0px;
        }
        .line-middle {
            padding: 20px 0px;
        }
        .line-bottom {
            padding: 20px 0px;
            margin-bottom: 10px;
        }
        .bottom {
            padding: 10px 0px 20px 0px;
        }
        table {
            font-size: 12px;
            tbody {
                .text-color {
                    font-size: 15px;
                }
                .kakao.pc {
                    display: none;
                }
                .kakao.mobile {
                    display: inline-block;
                }
                .kakao {
                    display: inline-block;
                    border-radius: 200px;
                    background: #fceb4f;
                    font-size: 15px;
                    line-height: 44px;
                    height: 46px;
                    padding: 0 24px;
                    transform: translateY(10px);
                    svg {
                        position: relative;
                        width: 46px;
                        top: -1px;
                        margin-right: 7px;
                        vertical-align: middle;
                    }
                }
                .color {
                    font-size: 15px;
                }
                td {
                    line-height: 30px;
                    height: 30px;
                }
                td:first-child {
                    white-space: nowrap;
                }
            }
        }
    }
`;

export const OrderSummary = styled.div`
    margin-top: 30px;
    border-radius: 2px;
    border: 1px solid #e3e5e8;
    background-color: #fbfbfb;
    padding: 34px 0px;
    text-align: center;
    p {
        font-size: 15px;
        font-weight: normal;
        line-height: 1.73;
        text-align: center;
        color: #111111;
    }
    strong {
        font-weight: 500;
    }
    @media ${(props) => props.theme.mobile} {
        p {
            font-size: 13px;
        }
    }
`;

export const OrderTrackWrapper = styled.div`
    margin-bottom: 30px;
`;

export const OrderTrack = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    padding: 16px 20px;
    border-radius: 2px;
    border: 1px solid #e3e5e8;
    background-color: #fbfbfb;
    margin-bottom: 15px;
    .title {
        margin-bottom: 12px;
        color: #111;
        font-size: 16px;
    }
    .desc {
        color: #111;
        font-size: 13px;
    }
    span {
        font-size: 13px;
        color: #e55f74;
    }
    a {
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        background-color: #e55f74;
        border: none;
        border-radius: 200px;
        padding: 0 14px;
        color: #fff;
        height: 32px;
        line-height: 30px;
        font-size: 14px;
    }
    @media ${(props) => props.theme.mobile} {
        .title {
            margin-bottom: 12px;
            color: #111;
            font-size: 15px;
        }
        .desc {
            color: #111;
            font-size: 12px;
        }
        span {
            font-size: 12px;
            color: #e55f74;
        }
        a {
            position: absolute;
            display: inline-block;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            background-color: #e55f74;
            border: none;
            border-radius: 200px;
            padding: 0 10px;
            color: #fff;
            height: 26px;
            line-height: 24px;
            font-size: 12px;
        }
    }
`;
