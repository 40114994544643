import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 110px;
  padding-bottom: 200px;
  @media ${props => props.theme.mobile} {
    padding-top: 30px;
    padding-bottom: 0px;
  }
`;

export const MainPostWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 735px;
  margin-bottom: 100px;
  @media ${props => props.theme.mobile} {
    height: 100%;
    padding: 0 20px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 20px solid #f8f8f8;
  }
`;

export const LeftSection = styled.div`
  width: 62.2%;
  min-height: 735px;
  @media ${props => props.theme.mobile} {
    display: none;
  }
`;

export const ProductImage = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 64.224%;
  background: #fbfbfb;
  border-radius: 2px;
  background-color: #f8f8f8;
  background-image: url(${props => (props.url ? props.url : '')});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 53% auto;
  margin-bottom: 50px;
  @media ${props => props.theme.mobile} {
    padding-bottom: 73.137%;
    margin-bottom: 29px;
  }
`;

export const Information = styled.div`
  position: relative;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #f8f8f8;
  font-size: 17px;
  line-height: 25px;
  font-weight: 500;
  color: #111111;
  padding: 30px 0;
  cursor: pointer;
  img {
    position: absolute;
    top: 50%;
    right: 0;
    width: 24px;
    height: auto;
    transform: translateY(-50%);
  }
  @media ${props => props.theme.mobile} {
    margin-top: 35px;
    font-size: 18px;
    padding: 28px 0;
  }
`;

export const ProductDetail = styled.div`
  position: relative;
  width: 34.4%;
  min-height: 735px;
  @media ${props => props.theme.mobile} {
    width: 100%;
    height: 100%;
  }
  a {
    display: inline-block;
    vertical-align: top;
    min-width: 180px;
    margin-bottom: 30px;
  }
  @media ${props => props.theme.mobile} {
    a {
      width: 75%;
    }
  }
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 23px;
  line-height: 33px;
  color: #111;
  margin-bottom: 10px;
  @media ${props => props.theme.mobile} {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

export const Ingredient = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: #111;
  margin-bottom: 30px;
  @media ${props => props.theme.mobile} {
    font-size: 16px;
  }
`;

export const Price = styled.div`
  border-top: 1px solid #ebebeb;
  padding-top: 30px;
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 22px;
  color: #111;
  span {
    padding-left: 51px;
  }
  s {
    color: red;
    text-decoration: none;
  }
  @media ${props => props.theme.mobile} {
    padding-top: 27px;
  }
`;

export const Amount = styled.div`
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 30px;
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 22px;
  color: #111;
  div {
    padding-left: 51px;
  }
  img {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
  span {
    display: inline-block;
    margin: 0 10px;
    font-weight: 500;
  }
  @media ${props => props.theme.mobile} {
    padding-bottom: 27px;
  }
`;

export const BasketBtn = styled.div`
  position: absolute;
  right: 0;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid 1px #dfdfdf;
  background-image: url(/img/basket.png);
  background-position: center center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const Manual = styled.div`
  font-size: 15px;
  line-height: 26px;
  color: #111;
  margin-bottom: 30px;
  @media ${props => props.theme.mobile} {
    font-size: 16px;
    line-height: 1.88;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 23px;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #111;
  margin-bottom: 20px;
  @media ${props => props.theme.mobile} {
    font-size: 20px;
    padding: 0 20px;
  }
`;

export const RelativeWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const RelativeSection = styled.div`
  width: 100%;
  overflow-x: auto;
  & > div {
    padding-bottom: 63px;
  }
  -webkit-overflow-scrolling: touch;
  @media ${props => props.theme.mobile} {
    padding: 0 20px;
    padding-bottom: 79px;
    border-bottom: none;
    & > div {
      padding-bottom: 23px;
    }
  }
`;
export const RelativeProducts = styled.div`
  width: calc(330px * 15);
  height: auto;
  @media ${props => props.theme.mobile} {
    width: calc(200px * 15);
  }
`;

export const RelativeProduct = styled.div`
  display: inline-block;
  width: 299px;
  height: auto;
  margin-right: 30px;
  @media ${props => props.theme.mobile} {
    width: 180px;
    vertical-align: top;
  }
`;

export const RelativeImage = styled(Link)`
  display: block;
  width: 299px;
  height: 435px;
  background-color: #fbfbfb;
  border-radius: 2px;
  background-image: url(${props => (props.url ? props.url : '')});
  background-position: center center;
  background-size: 240px 240px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  @media ${props => props.theme.mobile} {
    width: 180px;
    height: 180px;
    background-size: 150px 150px;
    /* border: 1px solid #eee; */
  }
`;

export const RelativeType = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #111111;
  margin-bottom: 5px;
  @media ${props => props.theme.mobile} {
    font-size: 12px;
    margin-bottom: 8px;
  }
`;

export const RelativeName = styled.div`
  font-size: 13px;
  line-height: 19px;
  color: #111111;
  margin-bottom: 15px;
  @media ${props => props.theme.mobile} {
    font-size: 13px;
    margin-bottom: 14px;
  }
`;

export const RelativePrice = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #111111;
  @media ${props => props.theme.mobile} {
    font-size: 14px;
  }
`;

export const IsMobile = styled.div`
  display: none;
  @media ${props => props.theme.mobile} {
    display: block;
  }
`;
