import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 100px 0px 200px 0px;
    @media ${props => props.theme.mobile}{
        overflow: hidden;
        padding: 50px 0px 50px 0px;
        border-bottom: 1px solid #ebebeb;
    }
`;

export const Container = styled.div`
    @media ${props => props.theme.mobile}{
        margin: 0px 20px;
    }
`;

export const Hidden = styled.div`position:absolute;left:-1000px;`;

export const Title = styled.div`
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-end;
    h2{
        font-size: 27px;
        font-weight: bold;
        color: #111111;
    }
    p{
        font-size: 13px;
        color: #111111;
    }
    a{
        text-decoration: underline;
    }
    @media ${props => props.theme.mobile}{
        display: block;
        h2{
            margin-bottom: 16px;
            font-size: 21px;
        }
        p{
            font-size: 12px;
        }
    }
`;



export const ContentWrapper = styled.div`
    margin: 0px -10px;
    margin-bottom: 30px;
`;


export const ContentList = styled.div`

`;

export const ContentBox = styled.div`
    padding: 0px 10px;
    padding-bottom: 20px;
    display:inline-block;  
    width: 33.33%;  
    @media ${props => props.theme.mobile}{
        width: 50%;
        padding-bottom: 10px;
    }
`;

export const Content = styled.div`
    width: auto;  
    border-radius: 2px;
    border: 1px solid #dfdfdf;
    background-color: #ffffff;
    .flex{     
        vertical-align:top;
        padding: 39px 30px;
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: flex-end;
        p{
            font-size: 15px;
            font-weight: 500;
            line-height: 23px;
            color: #111111;
        }
    }
    @media ${props => props.theme.mobile}{
        .flex{
            padding: 16px 16px;
            p{
                font-size: 14px;
            }
        }
    }
`;

export const LinkWrapper = styled.div`

`;

export const LinkTitle = styled.div`
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 14px;
    margin-bottom: 20px;
    h2{
        font-size: 20px;
        font-weight: bold;
        color: #111111;
    }
`;

export const LinkContent = styled.div`
    margin-bottom: 30px;
    p{
        font-size: 15px;
        line-height: 1.87;
        color: #000000;
    }
    span{
        font-weight: 500;
    }
    @media ${props => props.theme.mobile}{
        margin-bottom: 30px;
        p{
            font-size: 12px;
            line-height: 1.87;
            color: #000000;
        }
        span{
            font-weight: 500;
        }
    }
`;

export const CopyButton = styled.div`
    flex:1;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-end;
    padding: 17px 30px;  
    margin-bottom: 20px;
    border: 1px solid #e3e5e8;
    border-radius: 200px;
    background-color: #fbfbfb;
    p{
        font-size: 15px;
        line-height: 16px;
        color: #000000;
    }
    p:nth-of-type(2){
        text-decoration: underline;
    }
    a {
        font-size :14px;
        color: #e55f74;
    }
    @media ${props => props.theme.mobile}{
        display: block;
        padding: 20px;
        p{
            font-size: 13px;
        }
        p:nth-of-type(2){
            display:none;
        }
        a {
            display:none;
        }
    }
`;

export const ButtonWrapper = styled.div`
    margin: 0px -10px;
`;

export const ShareButton = styled.div`
    
`;

export const Share = styled.div`
    width: 50%;
    padding: 0px 10px;
    display: inline-block;
    a,
    button{
        width: 100%;
        height: 56px;
        display: block;
        position: relative;
        border: 1px solid #fff;
        border-radius: 200px;
        font-size: 15px;
        font-weight: 500;
        line-height: 54px;
        text-align: center;
        color: #111;
        background-color: #fceb4f;
        :before{
            content:"";
            background-image: url('../img/kakaoimg1.png');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 30%;
            transform: translateY(-50%) translateX(-30%);
            width: 27px;
            height: 27px;
        }
    }
    .color{
        color:  #ffffff;
        background-color: #425993;
        position: relative;
        :before{
            content:"";
            background-image: url('../img/facebook_01.png');
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            display: inline-block;
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 30%;
            transform: translateY(-50%) translateX(-30%);
            width: 27px;
            height: 27px;
        }
    }
    @media ${props => props.theme.mobile}{
        display: block;
        width: 100%;
        button{
            font-size: 14px;
            :before{
                left: 20%;
            }
        }
        .color{
            font-size: 14px;
            margin-top: 10px;
            :before{
                left: 20%;
            }
        }
    }
`;