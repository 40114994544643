import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { MediaQuery, mobileQuery } from '../../components/Grid';
import MainContainer from '../../components/MainContainer';
import RadioButton from '../../components/CheckBox';
import Pagination from '../../components/Pagination';
import LoadingRound from '../../components/LoadingRound';
import { GlobalCategory } from '../../api/global';
import { ProductGetPosts } from '../../api/product';
import { SktonProducts } from '../../components/Skeleton';
import {
	Wrapper,
	CategoryTitle,
	CategorySection,
	ChildCategorySection,
	ProductsWrapper,
	ProductWrapper,
	Product,
	ProductImage,
	ProductDetail,
	ProductType,
	ProductName,
	ProductPrice,
} from './styled';
import { Helmet } from 'react-helmet';

const Products = () => {
	const get_params = new URLSearchParams(useLocation().search);
	const History = useHistory();
	const isMobile = MediaQuery(mobileQuery);
	const params = useParams();

	const [current_cate, set_current_cate] = useState(0);
	const [global_category, set_global_category] = useState('');
	const [global_categories, set_global_categories] = useState('');
	const [children, setChildren] = useState('');
	const [products, set_products] = useState('');
	const [test, set_test] = useState(1);
	const [current_paged, set_current_paged] = useState(0);
	const [total, set_total] = useState(0);
	const [paged, set_paged] = useState(
		get_params.get('paged') ? get_params.get('paged') : 1
	);
	const posts_per_page = 12;

	const onPaging = (page) => {
		set_products('');
		console.log(page);
		History.push('/products/' + params.ID + '/?paged=' + page);
		set_paged(page);
		window.scrollTo(0, 0);
	};

	//   useEffect(() => {
	//     if (current_cate != params.ID) {
	//       set_current_cate(params.ID);
	//       GlobalCategory({
	//         category_ID: params.ID,
	//       })
	//         .then(response => {
	//           if (response.data.code == 200) {
	//             set_global_category(response.data.body);
	//             set_global_categories(response.data.body.categories);
	//             setChildren(response.data.body.children);
	//             console.log(response.data.body.body);
	//           }
	//         })
	//         .catch(error => console.log(error));
	//     }

	//     if (test !== params.ID || current_paged != paged) {
	//       if (test !== params.ID) {
	//         set_paged(get_params.get('paged') ? get_params.get('paged') : 1);
	//       }
	//       set_test(params.ID);
	//       set_products('');
	//       ProductGetPosts({
	//         category_ID: params.ID,
	//         keyword: '',
	//         posts_per_page: posts_per_page,
	//         paged: paged,
	//       })
	//         .then(response => {
	//           if (response.data.code == 200) {
	//             set_products(response.data.body.products);
	//             set_current_paged(response.data.body.paged);
	//             set_total(response.data.body.total);
	//           }
	//         })
	//         .catch(error => console.log(error));
	//     }
	//   });

	useEffect(() => {
		set_current_cate(params.ID);
		set_paged(1);
		GlobalCategory({
			category_ID: params.ID,
		})
			.then((response) => {
				if (response.data.code == 200) {
					set_global_category(response.data.body);
					set_global_categories(response.data.body.categories);
					setChildren(response.data.body.children);
					console.log(response.data.body.body);
				}
			})
			.catch((error) => console.log(error));

		ProductGetPosts({
			category_ID: params.ID,
			keyword: '',
			posts_per_page: posts_per_page,
			paged: 1,
		})
			.then((response) => {
				if (response.data.code == 200) {
					set_products(response.data.body.products);
					set_total(response.data.body.total);
				}
			})
			.catch((error) => console.log(error));
	}, [params.ID]);

	useEffect(() => {
		if (0 !== current_cate) {
			ProductGetPosts({
				category_ID: params.ID,
				keyword: '',
				posts_per_page: posts_per_page,
				paged: paged,
			})
				.then((response) => {
					if (response.data.code == 200) {
						set_products(response.data.body.products);
						set_total(response.data.body.total);
					}
				})
				.catch((error) => console.log(error));
		}
	}, [paged]);

	return (
		<MainContainer>
			<Helmet>
				<title>
					{global_category?.current_name
						? global_category?.current_name +
						  ' - 탈모인 성지 사쿠라허브 | 의약품 직구대행 | 핀페시아 | 타다주브 | 실주브 | 올리주브 | 카마그라'
						: '탈모인 성지 사쿠라허브 | 의약품 직구대행 | 핀페시아 | 타다주브 | 실주브 | 올리주브 | 카마그라'}
				</title>
				<meta
					property="og:title"
					content={
						global_category?.current_name
							? global_category?.current_name +
							  ' - 탈모인 성지 사쿠라허브 | 의약품 직구대행 | 핀페시아 | 타다주브 | 실주브 | 올리주브 | 카마그라'
							: '탈모인 성지 사쿠라허브 | 의약품 직구대행 | 핀페시아 | 타다주브 | 실주브 | 올리주브 | 카마그라'
					}
				/>
				<meta
					name="description"
					content={
						global_category?.current_name
							? global_category?.current_name +
							  ' - 탈모인 성지,핀페시아, 핀페시아 직구, 에프페시아, 이소트레티노인, 타다주브, 아이소주브, 멜라토닌, 미녹시딜, 카마그라, 비달리스타, 아보다트, 프로페시아, 이버멕틴, 이소트레티노인, 이소티논, 니타조사나이드, 니클로사마이드, 하이드록시클로로퀸, 핀주브, 핀스톰, 피나스테리드 직구를 대신해 드립니다.'
							: '탈모인 성지,핀페시아, 핀페시아 직구, 에프페시아, 이소트레티노인, 타다주브, 아이소주브, 멜라토닌, 미녹시딜, 카마그라, 비달리스타, 아보다트, 프로페시아, 이버멕틴, 이소트레티노인, 이소티논, 니타조사나이드, 니클로사마이드, 하이드록시클로로퀸, 핀주브, 핀스톰, 피나스테리드 직구를 대신해 드립니다.'
					}
				/>
				<meta
					property="og:description"
					content={
						global_category?.current_name
							? global_category?.current_name +
							  ' - 탈모인 성지,핀페시아, 핀페시아 직구, 에프페시아, 이소트레티노인, 타다주브, 아이소주브, 멜라토닌, 미녹시딜, 카마그라, 비달리스타, 아보다트, 프로페시아, 이버멕틴, 이소트레티노인, 이소티논, 니타조사나이드, 니클로사마이드, 하이드록시클로로퀸, 핀주브, 핀스톰, 피나스테리드 직구를 대신해 드립니다.'
							: '탈모인 성지,핀페시아, 핀페시아 직구, 에프페시아, 이소트레티노인, 타다주브, 아이소주브, 멜라토닌, 미녹시딜, 카마그라, 비달리스타, 아보다트, 프로페시아, 이버멕틴, 이소트레티노인, 이소티논, 니타조사나이드, 니클로사마이드, 하이드록시클로로퀸, 핀주브, 핀스톰, 피나스테리드 직구를 대신해 드립니다.'
					}
				/>
			</Helmet>
			<Wrapper>
				<CategoryTitle>
					{global_category.category_name ? global_category.category_name : ''}
				</CategoryTitle>
				{global_category?.current_name}
				<CategorySection>
					{global_categories ? (
						global_categories.map((item, key) => {
							return (
								<>
									{key === 0 ? (
										<Link
											to={
												'/products/' +
												(global_category.category_ID
													? global_category.category_ID
													: '')
											}
										>
											<RadioButton
												value="전체보기"
												name="cate"
												id="0"
												checked={
													params.ID &&
													params.ID ==
														(global_category.category_ID
															? global_category.category_ID
															: '')
												}
											>
												전체보기
											</RadioButton>
										</Link>
									) : (
										''
									)}
									<Link to={'/products/' + (item.ID ? item.ID : '')}>
										<RadioButton
											value={item.name ? item.name : ''}
											name="cate"
											id={key + 1}
											checked={params.ID && params.ID == item.ID}
										>
											{item.name ? item.name : ''}
										</RadioButton>
									</Link>
								</>
							);
						})
					) : (
						<>
							{global_category.category_ID && !global_categories ? (
								<Link
									to={
										'/products/' +
										(global_category.category_ID
											? global_category.category_ID
											: '')
									}
								>
									<RadioButton
										value="전체보기"
										name="cate"
										id="0"
										checked={
											params.ID &&
											params.ID ==
												(global_category.category_ID
													? global_category.category_ID
													: '')
										}
									>
										전체보기
									</RadioButton>
								</Link>
							) : (
								<LoadingRound className="loading" />
							)}
						</>
					)}
				</CategorySection>
				{children?.[0]?.ID != global_categories?.[0]?.ID ? (
					<ChildCategorySection>
						{children ? (
							children.map((item, key) => {
								return (
									<>
										<Link to={'/products/' + (item.ID ? item.ID : '')}>
											{item.name ? item.name : ''}
										</Link>
									</>
								);
							})
						) : (
							<></>
						)}
					</ChildCategorySection>
				) : null}
				<ProductsWrapper>
					{products ? (
						products.map((item, key) => {
							return (
								<ProductWrapper>
									<Product>
										<ProductImage
											url={item.thumbnail ? item.thumbnail : ''}
											to={'/product/' + (item.ID ? item.ID : '')}
										>
											{item?.is_sold_out ? (
												<div className="sold-out">임시품절</div>
											) : null}
										</ProductImage>
										<ProductDetail>
											<ProductType>
												{item.cate_name ? item.cate_name : ''}
											</ProductType>
											<ProductName>{item.title ? item.title : ''}</ProductName>
											<ProductPrice>
												{item.price ? item.price : ''}
											</ProductPrice>
										</ProductDetail>
									</Product>
								</ProductWrapper>
							);
						})
					) : (
						<>
							<SktonProducts />
							<SktonProducts />
							<SktonProducts />
							<SktonProducts />
							<SktonProducts />
							<SktonProducts />
							<SktonProducts />
							<SktonProducts />
						</>
					)}
				</ProductsWrapper>
				<Pagination
					onPaging={onPaging}
					total={total}
					current={paged}
					posts_per_page={posts_per_page}
					max={
						total >= posts_per_page ? parseInt(total / posts_per_page) + 1 : 1
					}
				/>
			</Wrapper>
		</MainContainer>
	);
};

export default Products;
