import axios from './defaultClient';

const nameSpace = '/ep/v1/notice';

export const NoticeGetPosts = async (args) => {
    return await axios.get( nameSpace + '/',{
        params:{
            paged: args.paged,
            posts_per_page: args.posts_per_page
        }
    })
}

export const NoticeGetPost = async (args) => {
    return await axios.get( nameSpace + '/' + args.notice_ID,{

    })
}

