import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 100px;
	padding-bottom: 200px;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		padding-top: 50px;
		padding-bottom: 80px;
		border-bottom: 1px solid #ebebeb;
	}
`;

export const Title = styled.div`
	font-size: 27px;
	line-height: 39px;
	font-weight: bold;
	color: #111;
	margin-bottom: 30px;
	@media ${(props) => props.theme.mobile} {
		font-size: 24px;
		line-height: 35px;
		margin-bottom: 19px;
	}
`;

export const OrderInfoWrapper = styled.div`
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
	div:last-child {
		border: none;
	}
`;

export const OrderInfo = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 30px 0;
	border-bottom: 1px solid #fafafa;
	@media ${(props) => props.theme.mobile} {
		position: relative;
	}
`;

export const ProductImage = styled.div`
	width: 200px;
	height: 150px;
	background: #fbfbfb;
	border-radius: 2px;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-repeat: no-repeat;
	background-size: 90% auto;
	background-position: center center;
	@media ${(props) => props.theme.mobile} {
		width: 123px;
		height: 120px;
	}
`;

export const ProductDetail = styled.div`
	width: calc(100% - 230px);
	padding-left: 30px;
	@media ${(props) => props.theme.mobile} {
		width: calc(100% - 123px);
		padding-left: 20px;
	}
`;

export const Name = styled.div`
	span {
		font-size: 15px;
		line-height: 22px;
		color: #111;
	}
	margin-top: 25px;
	@media ${(props) => props.theme.mobile} {
		display: table;
		width: 100%;
		height: 56px;
		span {
			display: table-cell;
			vertical-align: middle;
			font-size: 14px;
			line-height: 1.65;
		}
		margin-top: -6px;
	}
`;

export const Price = styled.div`
	font-size: 17px;
	line-height: 25px;
	font-weight: 500;
	color: #111111;
	margin-top: 10px;
	@media ${(props) => props.theme.mobile} {
		font-size: 14px;
		line-height: 26px;
		margin-top: 5px;
	}
`;

export const Amount = styled.div`
	display: flex;
	margin-top: 22px;
	img {
		width: 21px;
		height: 21px;
		cursor: pointer;
	}
	span {
		display: inline-block;
		margin: 0 14px;
		font-weight: 500;
		font-size: 15px;
		line-height: 22px;
		color: #111;
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 15px;
		img {
			width: 23px;
			height: 23px;
		}
	}
`;

export const Delete = styled.div`
	position: relative;
	width: 30px;
	span {
		display: inline-block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		font-size: 15px;
		line-height: 22px;
		color: #111;
		text-decoration: underline;
		cursor: pointer;
	}
	@media ${(props) => props.theme.mobile} {
		position: absolute;
		bottom: 43px;
		right: 0;
		span {
			font-size: 16px;
			line-height: 23px;
		}
	}
`;

export const SubTitle = styled.div`
	font-size: 20px;
	line-height: 29px;
	font-weight: bold;
	color: #111;
	margin-top: 50px;
	margin-bottom: 20px;
	@media ${(props) => props.theme.mobile} {
		margin-top: 30px;
	}
`;

export const InputSection = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	input {
		display: inline-block;
		width: 49%;
		height: 56px;
		background: #ffffff;
		border: 1px solid #ebebeb;
		box-sizing: border-box;
		border-radius: 200px;
		margin-bottom: 20px;
		padding-left: 30px;
		font-size: 15px;
		line-height: 22px;
		color: #111;
	}
	select {
		width: 95%;
		height: 54px;
		background: none;
		border: none;
	}
	.block {
		margin-right: 50%;
	}
	@media ${(props) => props.theme.mobile} {
		input {
			width: 100%;
			height: 60px;
		}
		input:last-child {
			margin-bottom: 0;
		}
		select {
			height: 58px;
		}
		.block {
			margin-right: 0;
		}
	}
`;

export const InputWrapper = styled.div`
	position: relative;
	width: 100%;
	button {
		position: absolute;
		width: 120px;
		height: 56px;
		line-height: 56px;
		border-radius: 200px;
		border: none;
		left: 49%;
		transform: translateX(-100%);
	}
	span {
		display: inline-block;
		padding-left: 20px;
		font-size: 15px;
		line-height: 22px;
		color: #111;
	}
	.coupon {
		color: #fff;
		background-color: #444;
	}
	.coupon_remove {
		color: #e55f74;
		margin-left: 20px;
		text-decoration: underline;
	}
	.point {
		color: #111;
		background-color: #f5f5f5;
	}
	@media ${(props) => props.theme.mobile} {
		button {
			height: 60px;
			line-height: 60px;
			left: 100%;
		}
		span {
			padding-left: 10px;
			font-size: 16px;
			line-height: 23px;
		}
		.input-point,
		.input-coupon {
			margin-bottom: 15px;
		}
	}
`;
export const NullPage = styled.div`
	position: relative;
	padding: 100px 0px;
	text-align: center;
	img {
		width: 60px;
		height: 60px;
		margin-bottom: 10px;
	}
	span {
		display: block;
		font-size: 15px;
		line-height: 1.73;
		letter-spacing: -0.45px;
		color: #111;
	}
`;

export const Payment = styled.div`
	border-top: 1px dashed #ebebeb;
	border-bottom: 1px dashed #ebebeb;
	padding-top: 30px;
	padding-bottom: 50px;
	margin-bottom: 50px;
	.last-row {
		margin-top: 50px;
		span {
			font-weight: bold;
			font-size: 17px;
			line-height: 25px;
		}
	}
	.color {
		color: #e55f74;
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 50px;
		margin-bottom: 23px;
	}
`;

export const PaymentRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	span {
		font-size: 15px;
		line-height: 22px;
		color: #111;
		@media ${(props) => props.theme.mobile} {
			font-size: 16px;
			line-height: 23px;
		}
	}
`;

export const ConfirmButton = styled(Link)`
	width: 100%;
	display: block;
	height: 56px;
	font-weight: 500;
	font-size: 15px;
	line-height: 54px;
	color: #e55f74;
	text-align: center;
	background-color: #ffffff;
	border: 1px solid #e55f74;
	border-radius: 200px;

	cursor: pointer;
	transition: 0.2s;
	:hover {
		color: #fff;
		background-color: #e55f74;
	}
`;
