import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import MainContariner from '../../components/MainContainer';
import { MediaQuery, mobileQuery } from '../../components/Grid';
import Pagination from '../../components/Pagination';
import { StoryGetPosts } from '../../api/story';
import { SktonStory } from '../../components/Skeleton';
import {
  Wrapper,
  TopSection,
  Title,
  TabWrapper,
  Tab,
  StoryCardWrapper,
  StoryCard,
  CardImage,
  CardBadge,
  CardTitle,
  CardTag,
  Empty,
} from './styled';

const Story = () => {
  const isMobile = MediaQuery(mobileQuery);
  const params = useParams();
  const tab_li = params.category;
  const category_ID = tab_li
    .replace(/tip/gi, 450)
    .replace(/lifestyle/gi, 449)
    .replace(/hair/gi, 1);

  const [story, set_story] = useState('');
  const [current_paged, set_current_paged] = useState(0);
  const [total, set_total] = useState(0);
  const [paged, set_paged] = useState(1);
  const [current_cate, set_current_cate] = useState('');
  const posts_per_page = 6;
  const [value_null, set_value_null] = useState(0);

  const onPaging = page => {
    console.log(page);
    set_story('');
    set_paged(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    set_story('');
    StoryGetPosts({
      category_ID: category_ID,
      paged: paged,
      posts_per_page: posts_per_page,
    })
      .then(response => {
        if (response.data.code == 200) {
          set_story(response.data.body.posts);
          set_total(response.data.body.total);
          console.log(response.data.body.posts);
        } else if (response.data.code == 500) {
          set_value_null(1);
          set_total(0);
        }
      })
      .catch(error => console.log(error));
  }, [paged, category_ID]);

  return (
    <MainContariner>
      <Wrapper>
        <TopSection>
          <Title>STORY</Title>
          <TabWrapper>
            <Tab className={tab_li === 'tip' ? 'active' : ''}>
              <Link onClick={() => set_paged(1)} to="/story/tip">
                제품사용 TIP
              </Link>
            </Tab>
            <Tab className={tab_li === 'lifestyle' ? 'active' : ''}>
              <Link onClick={() => set_paged(1)} to="/story/lifestyle">
                라이프스타일
              </Link>
            </Tab>
            <Tab className={tab_li === 'hair' ? 'active' : ''}>
              <Link onClick={() => set_paged(1)} to="/story/hair">
                득모임
              </Link>
            </Tab>
          </TabWrapper>
        </TopSection>
        <StoryCardWrapper>
          {story ? (
            story.map((item, key) => {
              return (
                <StoryCard>
                  <CardImage
                    url={item.thumbnail ? item.thumbnail : ''}
                    to={'/story/single/' + (item.ID ? item.ID : '')}
                  />
                  <CardBadge>{item.cate_name ? item.cate_name : ''}</CardBadge>
                  <CardTitle>
                    {item.post_title ? item.post_title : ''}
                  </CardTitle>
                  <CardTag>#TIP</CardTag>
                </StoryCard>
              );
            })
          ) : (
            <>
              {value_null ? (
                <Empty>아직 글이 없습니다.</Empty>
              ) : (
                <>
                  <SktonStory />
                  <SktonStory />
                  <SktonStory />
                </>
              )}
            </>
          )}
        </StoryCardWrapper>
        <Pagination
          onPaging={onPaging}
          total={total}
          current={paged}
          posts_per_page={posts_per_page}
          max={
            total >= posts_per_page ? parseInt(total / posts_per_page) + 1 : 1
          }
        />
      </Wrapper>
    </MainContariner>
  );
};

export default Story;
