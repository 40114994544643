import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MediaQuery, mobileQuery} from '../../../components/Grid';
import Pagination from '../../../components/Pagination';
import { NoticeGetPosts } from '../../../api/notice';
import LoadingRound from '../../../components/LoadingRound';
import {
    Wrapper,
    NoticeTable,
    NoticeList,
    LeftArea,
    Emphasis,
    Title,
    Date,
    MoreButtonWrapper,
    MoreButton,
    LoadingRoundWrapper
} from './styled';

const Notice = () => {

    const isMobile = MediaQuery(mobileQuery);
    const [notice, set_notice] = useState('');
    const [notice_post, set_notice_post] = useState('');

    const [current_paged, set_current_paged] = useState(0);
    const [total, set_total] = useState(0);
    const [paged, set_paged] = useState(1);
    const posts_per_page = 15;

    const onPaging = (page) => {
        console.log(page);
        set_paged(page);
    }

    useEffect(() => {
        if(current_paged != paged){
            NoticeGetPosts({
                paged: paged,
                posts_per_page: posts_per_page
            })
            .then(response => {
                if(response.data.code == 200){
                    set_notice(response.data.body);
                    set_notice_post(response.data.body.posts);
                    set_current_paged(response.data.body.paged);
                    set_total(response.data.body.total);
                    console.log(response.data.body);
                }
            })
            .catch(error => console.log(error));
        } 
    });

    return (
        <>
            <Wrapper>
                <NoticeTable>
                    {notice_post ? notice_post.map((item, key) => {
                            return (
                                <Link to={item.ID == '100565' ? '/event/' : (item.ID == '225695' ? '/event/?type=choice' : ("/service/notice/" + (item.ID ? item.ID : '')))}>
                                    <NoticeList>
                                        {item.is_important ? (
                                            <Emphasis>
                                                중요
                                            </Emphasis>
                                        ) : ''}   
                                        <Title dangerouslySetInnerHTML={{__html:item.post_title ? item.post_title : ''}}>
                                        </Title>
                                        <Date>
                                            {item.post_date ? item.post_date : ''}
                                        </Date>
                                    </NoticeList>
                                </Link>
                            )
                        }) : <>
                            <LoadingRoundWrapper>
                                <LoadingRound />
                            </LoadingRoundWrapper>
                        </>
                    }
                </NoticeTable>
                <Pagination onPaging={onPaging} total={total} current={current_paged} posts_per_page={posts_per_page} max={(total >= posts_per_page) ? (parseInt(total / posts_per_page) + 1) : 1}/>
            </Wrapper>
        </>
    );
};

export default Notice;