import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isLogined } from '../../api/auth';

const AuthContainer = (props) => {
	const History = useHistory();

	useEffect(() => {
		if (localStorage.getItem('user_token')) {
			isLogined()
				.then((response) => {
					if (response.data.data.status != 200) {
						toast.error('로그인 정보가 만료되었습니다.');
						localStorage.removeItem('user_token');
						History.push('/login');
					}
				})
				.catch((error) => {
					console.log(error);
					localStorage.removeItem('user_token');
				});
		} else {
			toast.error('로그인이 필요합니다.');
			History.push('/login');
		}
	});

	return <>{props.children}</>;
};

export default AuthContainer;
