import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 100px;
	padding-bottom: 200px;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		padding-top: 50px;
		padding-bottom: 80px;
		border-bottom: 1px solid #ebebeb;
	}
`;

export const Title = styled.div`
	font-size: 27px;
	line-height: 39px;
	font-weight: bold;
	color: #111;
	margin-bottom: 30px;
	@media ${(props) => props.theme.mobile} {
		font-size: 24px;
		line-height: 35px;
		margin-bottom: 19px;
	}
`;

export const OrderInfoWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 50px;
	@media ${(props) => props.theme.mobile} {
		margin-bottom: 50px;
	}
`;

export const OrderInfoRow = styled.div`
	border-top: 1px solid #ebebeb;
	padding-top: 30px;
	padding-bottom: 10px;
`;

export const DetailInfo = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	span {
		font-size: 15px;
		line-height: 22px;
		color: #111;
	}
	span:first-child {
		white-space: nowrap;
	}
	strong {
		font-weight: bold;
		font-size: 17px;
		line-height: 25px;
		color: #111;
	}
	li {
		font-size: 15px;
		line-height: 22px;
		color: #111;
		text-align: right;
		margin-bottom: 20px;
	}
	.first-ul {
		li:last-child {
			margin-bottom: 0px;
		}
	}
	@media ${(props) => props.theme.mobile} {
		span {
			font-size: 16px;
			line-height: 23px;
		}
		span:first-child {
			margin-right: 20px;
		}
	}
`;

export const AccountInfoRow = styled.div`
	border-top: 1px dashed #ebebeb;
	border-bottom: 1px dashed #ebebeb;
	padding: 30px 0;
	margin-bottom: 30px;
	.first-row {
		margin-bottom: 50px;
	}
	@media ${(props) => props.theme.mobile} {
		.first-row {
			margin-bottom: 20px;
		}
	}
`;

export const DetailAccount = styled.div`
	display: flex;
	justify-content: space-between;
	span {
		font-size: 15px;
		line-height: 22px;
		color: #111;
	}
	span:first-child {
		white-space: nowrap;
	}
	.color {
		color: #e55f74;
	}
	@media ${(props) => props.theme.mobile} {
		span {
			font-size: 16px;
			line-height: 23px;
		}
		span:last-child {
			text-align: right;
		}
	}
`;

export const Notice = styled.div`
	width: 100%;
	height: auto;
	font-size: 15px;
	line-height: 26px;
	color: #111;
	text-align: center;
	background: #fbfbfb;
	border: 1px solid #e3e5e8;
	border-radius: 2px;
	padding: 34px 0;
	strong {
		font-size: 15px;
		line-height: 26px;
		color: #111;
	}
	@media ${(props) => props.theme.mobile} {
		padding: 21px 0;
	}
`;

export const ConfirmButton = styled(Link)`
	display: inline-block;
	width: 100%;
	height: 56px;
	font-weight: 500;
	font-size: 15px;
	line-height: 54px;
	color: #e55f74;
	text-align: center;
	background-color: #ffffff;
	border: 1px solid #e55f74;
	border-radius: 200px;
	cursor: pointer;
	transition: 0.2s;

	:hover {
		color: #fff;
		background-color: #e55f74;
	}
`;
