import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding-top: 50px;
    @media ${props => props.theme.mobile}{
        padding: 0 20px;
        padding-top: 30px;
    }
`;

export const Category = styled.ul`
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .active {
       border: 1px solid darkgray;
    }
    @media ${props => props.theme.mobile}{
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
`;

export const CategoryList = styled.li`
    position: relative;
    display: block;
    width: 22.7%;
    height: 80px;
    border: 1px solid #E3E5E8;
    border-radius: 2px;
    box-sizing: border-box;
    /* padding-top: 30px; */
    /* padding-left: calc(30px + 3%);     */
    span {
        pointer-events: none;
    }
    .icon {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 5%;
        transform: translateY(-50%);
    }
    .text {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: calc(8% + 30px);
        transform: translateY(-50%);
    }
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #111;
    box-sizing: border-box;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        width: 47.7%;
        height: 60px;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 20px;
        padding-top: 28px;
        padding-left: 14%;    
        .icon {
            transform: translateY(-50%) scale(0.8, 0.8);
        }
    }
`;

export const QusetionWrapper = styled.ul`
    width: 100%;
    margin-bottom: 50px;
    .answer {
        display: none;
    }
    .active {
        .answer {
            display: block;
        }
        .svg {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    li:last-child {
        border-bottom: 1px solid #ebebeb;
    }
    @media ${props => props.theme.mobile}{
        margin-bottom: 30px; 
    }
`;

export const Question = styled.li`
    position: relative;
    width: 100%;
    height: auto;
    border-top: 1px solid #ebebeb;
    padding: 30px 0;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        padding: 24px 0;
    }
`;

export const QuestionTitle = styled.div`
    padding-left: 56px;
    p {
        font-size: 13px;
        line-height: 19px;
        color: #9A9A9E;
        margin-bottom: 10px;
    }
    span {
        display: block;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #111;
    }
    @media ${props => props.theme.mobile}{
    }
`;

export const QLogo = styled.div`
    position: absolute;
    top: 55px;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    background-color: #F5F5F5;
    border-radius: 50%;
    text-align: center;
    font-family: "Roboto";
    font-size: 17px;
    line-height: 36px;
    font-weight: 500;
    color: #111;
    @media ${props => props.theme.mobile}{
        top: 50px;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
`;

export const ArrowDown = styled.img`
    position: absolute;
    top: 55px;
    right: 0;
    transform: translateY(-50%);
    @media ${props => props.theme.mobile}{
        top: 50px;
    }
`;

export const Answer = styled.div`
    position: relative;
    display: block;
    border-top: 1px solid #ebebeb;
    margin-top: 30px;
    padding-top: 30px;
`;

export const AnswerInner = styled.div`
    font-size: 15px;
    line-height: 26px;
    color: #111;
    padding-left: 56px;
`;

export const ALogo = styled.div`
    position: absolute;
    top: 50%;
    width: 36px;
    height: 36px;
    background-color: #E55F74;
    border-radius: 50%;
    font-family: "Roboto";
    font-size: 17px;
    line-height: 36px;
    font-weight: 500;
    color: #fff;
    text-align: center;
`;

export const MoreButtonWrapper = styled.div`
    padding: 0 20px;
    padding-bottom: 79px;
    border-bottom: 1px solid #ebebeb;
`;

export const MoreButton = styled.div`
    width: 100%;
    height: 60px;
    font-size: 18px;
    font-weight: 500;
    line-height: 58px;
    border-radius: 500px;
    border: solid 1px #999;
    text-align: center;
`;