import styled from 'styled-components';

export const WidthdrawWrapper = styled.div`
    p{
        font-size: 15px;
        line-height: 1.87;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        font-size: 13px;
    }
`;

export const WidthdrawTitle = styled.div`
    padding-bottom: 14px;
    border-bottom: 1px solid #111;
    margin-top: 50px;
    margin-bottom: 20px;
    h3{
        font-size: 20px;
        font-weight: bold;
        line-height: normal;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        h3{
            font-size: 18px;
        }
    }
`;

export const SidePopup = styled.div`
    margin-top: 30px;
    cursor: pointer;
    p{
        display: block;
        text-decoration: underline;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.87;
        color: #111111;
    }
    @media ${props => props.theme.mobile}{
        font-size: 13px;
    }
`;