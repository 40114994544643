import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { SetLoading } from '../../store/global';
import Popup from '../../components/Popup';
import {toast} from 'react-toastify';
import Joi from 'joi-browser';
import { ChatbotPost } from '../../api/chatbot';
import {
    Wrapper,
    BackButton,
    CloseButton,
    InquireBox,
    TopSection,
    Title,
    PageNum,
    InquireTextarea,
    InquireInput,
    Submit
} from './styled';

const InquirePopup = (props) => {
    const Dispatch = useDispatch();
    const history = useHistory();
    const[question_title, set_question_title] = useState('');
    const[question_comment, set_question_comment] = useState('');
    const[user_email, set_user_email] = useState('');
    const[user_phone, set_user_phone] = useState('');

    const aa = (e) => { 
        const schema ={
            question_title: Joi.string()
                .required()
                .error(() => ({message: '문의제목을 입력해주세요.'})),
            question_comment: Joi.string()
                .required()
                .error(() => ({message: '문의내용을 입력해주세요.'})),
            user_phone: Joi.number()
                .required()
                .error(() => ({message: '전화번호를 입력해주세요.'})),
            user_email: Joi.string()
                .required()
                .error(() => ({message: '이메일을 입력해주세요.'}))
        }
        const {error, value} = Joi.validate({
            question_title: question_title,
            question_comment: question_comment,
            user_phone: user_phone,
            user_email: user_email
        }, schema);

        if(error){
            toast.error(error.details[0].message);
        } else { 
            Dispatch(SetLoading(1));
            ChatbotPost ({
                question_title: question_title,
                question_comment: question_comment,
                user_phone: user_phone,
                user_email: user_email
            })
            .then((response) => {
                Dispatch(SetLoading(0));
                if(response.data.code == '200') {
                    toast.success('문의가 접수되었습니다.');
                    props.submit_success();
                } else {
                    alert(response.data.body.message)
                }
            })
            .catch((error) => {console.log(error); Dispatch(SetLoading(0));})
        }
    }

    return (
        <Popup show={props.show} onClose={props.onClose} full={true}>
            <Wrapper>
                {/* <BackButton onClick={props.onClose} src={process.env.PUBLIC_URL + "/img/arrow-back.svg"} /> */}
                <CloseButton onClick={props.onClose} src={process.env.PUBLIC_URL + "/img/close-button.svg"} />
                <InquireBox>
                    <TopSection>
                        <PageNum>
                            
                        </PageNum>
                        <Title>
                            문의내용을 작성해주세요.
                        </Title>
                    </TopSection>
                    <InquireInput type="text" name="question_title" onChange={(e) => set_question_title(e.target.value)} placeholder="문의제목을 입력해주세요." />
                    <InquireTextarea onChange={(e) => set_question_comment(e.target.value)} name="question_comment" placeholder="문의내용을 입력해주세요." />
                    <InquireInput type="text" name="user_email" onChange={(e) => set_user_phone(e.target.value)} placeholder="전화번호를 입력해주세요." />
                    <InquireInput type="text" name="user_phone" onChange={(e) => set_user_email(e.target.value)} placeholder="이메일을 입력해주세요." />
                    <Submit onClick={aa} spellcheck="false">
                        Enter
                        <img src={process.env.PUBLIC_URL + "/img/arrow-enter.svg"} />
                    </Submit>
                </InquireBox>
            </Wrapper>
        </Popup>
    );
};

export default InquirePopup;