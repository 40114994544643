import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    background-color: #f8f8f8;  
`;

export const PageHeader = styled.div`
    width: 100%;
    height: 50px;
    background-color: #444444;
    font-size: 17px;
    line-height: 50px;
    font-weight: 500;
    color: #fff;
    text-align: center;

    @media ${props => props.theme.mobile}{
        height:91px;
        font-size: 20px;
        line-height: 91px;
        font-weight: 600;
        padding: 0 20px;
    }
`;

export const HeaderContainer = styled.div`
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 30px;
        height: auto;
    }
`;

export const Container = styled.div`
    max-width: 800px;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 50px);
    margin: 0 auto;
    padding: 50px;
    background-color: #fff;
    line-height: 1.4;
    img{
        max-width:100%;
        height:auto;
    }
    strong {
        display: inline-block;
        font-size: 16px;
        margin: 16px 0;
    }
    p {
        margin: 12px 0;
        font-size: 13px;
    }
    li {
        margin: 10px 0;
        font-size: 13px;
    }
    div{
        margin: 0 auto;
    }
    @media ${props => props.theme.mobile}{
        padding: 50px 20px;
    }
`;


