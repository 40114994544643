import React, { useState, useEffect } from 'react';
import MainContainer from '../../../components/MainContainer';
import LoadingRound from '../../../components/LoadingRound';
import { MediaQuery, mobileQuery } from '../../../components/Grid';
import MainList from '../MainList';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import InquirePopup from '../../Inquire';
import { GetQna } from '../../../api/me';
import {
    Wrapper,
    Container
} from '../styled';
import {
    InquiryTable,
    InquiryList,
    Status,
    Title,
    Date,
    ArrowDown,
    Answer,
    AnswerStatus,
    ButtonWrapper
} from './styled';

const Inquire = (props) => {

    const isMobile = MediaQuery(mobileQuery);
    const [total, set_total] = useState(0);
    const [paged, set_paged] = useState(1);
    const [current_paged, set_current_paged] = useState(0);
    const posts_per_page = 12;
    const [no_result, set_no_result] = useState(0);

    const Toggle = (e) => {
        if(e.currentTarget.classList.contains("active")){
            e.currentTarget.classList.remove('active');
        } else {
            e.currentTarget.classList.add('active');
        }
    }

    const [InquirePopup_isshow,show_InquirePopup] = useState(0);
    const showInquirePopup = (e) => {
        e.preventDefault();
        if(InquirePopup_isshow) {
            show_InquirePopup(0);
        } else {
            show_InquirePopup(1);
        }
    }

    const submit_success = (e) => {
        show_InquirePopup(0);
        set_current_paged(99);
    }

    const accordion_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path fill="#444" d="M16.59 15.41L12 10.83l-4.59 4.58L6 14l6-6 6 6-1.41 1.41z"/>
        </svg>
    )

    const onPaging = (page) => {
        set_paged(page);
        window.scrollTo(0,0);
    }

    const [qna, set_qna] = useState("");
    useEffect(() => {
        if(current_paged != paged){
            set_current_paged(paged);
            GetQna({
                posts_per_page: posts_per_page,
                paged: paged
            })
            .then(response => {
                if(response.data.code == 200){
                    set_qna(response.data.body.posts);
                    set_total(response.data.body.total);
                } else {
                    set_no_result(1);
                }
            })
            .catch(error => console.log(error));
        } 
    });


    return (
        <>
            <MainContainer type="small">
                <Wrapper>
                    <Container>
                        <MainList />
                        <InquiryTable>
                            <InquiryList className="head">
                                <Status>
                                    문의상태
                                </Status>
                                <Title>
                                    문의내역
                                </Title>
                                <Date className="date">
                                    날짜
                                </Date>
                            </InquiryList>

                            {qna ? qna.map((item, key) => {
                                    return (
                                        <InquiryList onClick={Toggle}>
                                            <Status>
                                                <span className={item.answer_content ? "complete" : ''}>
                                                    {item.status ? item.status : ''}
                                                </span>
                                            </Status>
                                            <Title>
                                                {item.question_title ? item.question_title : ''}
                                            </Title>
                                            <Date>
                                                {item.date ? item.date : ''}
                                            </Date>
                                            <ArrowDown className="svg" src={process.env.PUBLIC_URL + "/img/arrow-down.svg"}/>
                                            <Answer className="answer">
                                                <div>
                                                    <p>
                                                        <h2>문의내용</h2>
                                                        {item.question_content ? item.question_content : ''}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <h2>답변</h2>
                                                        {item.answer_content ? item.answer_content : '아직 답변이 없습니다.'}
                                                    </p>
                                                </div>
                                            </Answer>  
                                        </InquiryList>
                                    )
                                }) : <>
                                    {no_result ? (<p>아직 문의가 없습니다.</p>) : (<LoadingRound/>)}
                                </>
                            }
                        </InquiryTable>
                        <Pagination  onPaging={onPaging} total={total} current={current_paged} posts_per_page={posts_per_page} max={(total >= posts_per_page) ? (parseInt(total / posts_per_page) + 1) : 1}/>
                        <ButtonWrapper>
                            <Button onClick={showInquirePopup} color="secondary">
                                1:1 문의하기
                            </Button>
                        </ButtonWrapper>
                            
                    </Container>
                    <InquirePopup show={InquirePopup_isshow} onClose={showInquirePopup} submit_success={submit_success} />
                </Wrapper>
            </MainContainer>
        </>
    );
};

export default Inquire;