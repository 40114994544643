import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 168px;
  background-color: #fff;
  @media ${props => props.theme.mobile} {
    overflow-x: hidden;
    height: 58px;
    ${props =>
      props.noBold
        ? ``
        : `
            box-sizing: initial;
            border-bottom: 20px solid #f8f8f8;
        `};
  }
`;

export const MainHeader = styled.div`
  position: fixed;
  width: 100%;
  height: 101px;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  z-index: 5;
  padding: 0px 20px;
  @media ${props => props.theme.mobile} {
    height: 58px;
    padding: 0px 0px;
  }
`;

export const MainHeaderContainer = styled.div`
  position: relative;
  max-width: 1502px;
  width: 100%;
  height: 101px;
  margin: 0 auto;
  z-index: 10;
  @media ${props => props.theme.mobile} {
    position: none;
    height: 58px;
  }
`;

export const SubHeader = styled.div`
  position: fixed;
  top: 101px;
  left: 0;
  width: 100%;
  height: 67px;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  z-index: 3;
  transition: 0.4s ease-in-out;
  padding: 0px 20px;
  &.hide {
    transform: translateY(-67px);
  }
  @media ${props => props.theme.mobile} {
    display: none;
    padding: 0px 0px;
  }
`;

export const SubHeaderContainer = styled.div`
  position: relative;
  max-width: 1502px;
  width: 100%;
  height: 67px;
  margin: 0 auto;
  @media ${props => props.theme.mobile} {
    display: none;
  }
`;

export const Hamburger = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #f7f7f7;
  border-radius: 50%;
  z-index: 20;
  cursor: pointer;
  div {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-7px);
    width: 18px;
    height: 2px;
    background-color: #111;
  }
  .s {
    margin: 4px 0;
  }
  @media ${props => props.theme.mobile} {
    background-color: #fff;
    width: 0px;
    margin: 0px 0px;
    left: 12px;
    div {
      transform: translateX(-10%) translateY(-7px);
      width: 25px;
    }
    .s {
      margin: 5px 0;
    }
  }
  @media (max-width: 330px) {
    left: 15px;
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 80px;
  transform: translateY(-50%);
  width: 200px;
  height: auto;
  z-index: 20;
  @media ${props => props.theme.mobile} {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 150px;
  }
  @media (max-width: 330px) {
    width: 140px;
  }
`;

export const SearchbarSection = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding-left: 329px;
  padding-right: 219px;
  transition: 0.6s ease;

  @media ${props => props.theme.mobile} {
    ${props =>
      props.show
        ? `
            top: 0px;
        `
        : `
            top: -160px;
            transition: 0.3s ease;
        `};
    transform: translateY(0%);
    padding: 0 0;
    background-color: #fff;
    z-index: 1000;
  }
`;

export const SearchbarWrapper = styled.div`
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  @media ${props => props.theme.mobile} {
    text-align: center;
    padding: 60px 0;
    img {
      left: auto;
      right: 5%;
    }
  }
`;

export const Searchbar = styled.input`
  position: relative;
  width: calc(100% - 64px);
  height: 56px;
  padding-left: 64px;
  line-height: 56px;
  background-color: #eee;
  border: none;
  border-radius: 200px;
  transition: 0.8s;
  ::placeholder {
    color: #5a5a5a;
  }
  @media ${props => props.theme.mobile} {
    ${props =>
      props.show
        ? `
            width: 90%;
        `
        : `
            width: 0%;
        `};
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    border-radius: 0;
    padding: 0 0;
    border-bottom: 2px solid #e55f74;
    ::placeholder {
      color: gray;
    }
  }
`;

export const MobileSearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 54px;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
`;

export const MobileSearchClose = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url(/img/closebutton.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  left: 6.8%;
  top: 25px;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  ${props => {
    if (props.show) {
      return `
                display: block;
            `;
    } else {
      return `
                display: none;
            `;
    }
  }}
`;

export const RightMenu = styled.ul`
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 160px;
  a > span {
    float: left;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  a > span:hover {
    background-color: #f7f7f7;
  }
  > li {
    float: left;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    i {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background: #e55f74;
      display: block;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  > li:hover {
    background-color: #f7f7f7;
  }
  .user {
    background-image: url(/img/user.png);
    background-size: 26px;
  }
  .bell {
    background-image: url(/img/bell.png);
    background-size: 24px;
    position: relative;
    cursor: pointer;
  }
  .basket {
    display: block;
    background-image: url(/img/newbag.png);
    background-size: 22px;
  }
  @media ${props => props.theme.mobile} {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-45%);
    display: flex;
    width: 80px;
    .user {
      display: none;
    }

    > li {
      float: none;
      display: none;
    }
    > li.bell {
      /* display: none; */
      display: block;
    }
    li:last-child {
      display: block;
    }
  }
`;

export const SubMenu = styled.ul`
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  justify-content: space-between;
  width: 367px;
  height: 25px;
  li {
    float: left;
    width: fit-content;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    text-decoration: none;
    color: #000;
  }
`;

export const SubMenuRight = styled.ul`
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  justify-content: space-between;
  width: 265px;
  height: 24px;
  li {
    float: left;
    font-size: 15px;
    line-height: 24px;
    text-decoration: none;
    color: #000;
  }
  img {
    width: 21px;
    height: auto;
    margin-right: 5px;
  }
  .point {
    font-weight: 600;
    width: 100px;
  }
  .li-login {
    position: relative;
  }
  .li-login:after,
  .li-login:before {
    display: block;
    position: absolute;
    content: '';
    top: 3px;
    width: 1px;
    height: 14px;
    background-color: #eee;
  }
  .li-login:before {
    left: -16px;
  }
  .li-login:after {
    right: -16px;
  }
`;

export const NoticeBox = styled.ul`
  ${props => (props.show ? `display: block` : `display: none`)};
  position: fixed;
  z-index: 99999999999999;
  overflow-y: auto;
  top: 0;
  right: 0;
  transform: translateY(50px);
  width: 200px;
  height: 260px;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  li {
    border-bottom: 1px solid #f8f8f8;
    padding-top: 10px;
    cursor: pointer;
  }
  li:first-child {
    padding-top: 0;
  }
  li:last-child {
    border-bottom: none;
  }
  .last-notice {
    border: none;
    div {
      margin-bottom: 0;
    }
  }
`;

export const NoticeContent = styled.p`
  font-size: 13px;
  line-height: 22px;
  color: #111111;
`;

export const NoticeTime = styled.div`
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.03em;
  color: #a3a3a3;
  margin-bottom: 10px;
`;
