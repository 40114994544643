import styled from 'styled-components';

export const StyleTextArea = styled.textarea`
    width: ${props => props.width ? props.width : '100%'};
    padding: ${props => props.padding ? props.padding : '20px'}; 
    height: ${props => props.height ? props.height : '300px'};
    position: relative;
    display: block;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.73;
    ::placeholder{
        color: #111;
    }
`;