import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Row, MediaQuery, mobileQuery } from '../../../components/Grid/';
import { Link, useHistory } from 'react-router-dom';
import Joi from 'joi-browser';
import Button from '../../../components/Button';
import { SignIn } from '../../../api/auth';
import { Getme } from '../../../api/me';
import { SetLoading } from '../../../store/global';
import { useDispatch } from 'react-redux';
import { SetUserData } from '../../../store/me';
import {
  LoginWrapper,
  Mobile,
  BackButton,
  LoginImg,
  Wrapper,
  LoginContent,
  IdInput,
  PassWord,
  PwInput,
  CheckLogin,
  Forggot,
  LoginButton,
  RegisterButton,
} from './styled';

const Login = props => {
  const onKeyPress = e => {
    if (e.key === 'Enter') {
      Submit();
    }
  };
  const isMobile = MediaQuery(mobileQuery);
  const history = useHistory();
  const Dispatch = useDispatch();

  const longin_03 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="11.5" fill="#fff" stroke="#DFDFDF" />
      <path
        fill="#DFDFDF"
        d="M10.667 14.39L8.47 12.195l-.942.943 3.138 3.138 6.471-6.472-.943-.942-5.528 5.528z"
      />
    </svg>
  );
  const icon_back = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <path
        fill="#111"
        d="M26.916 15.583H10.116l5.142-6.176c.24-.29.357-.663.322-1.037-.035-.375-.216-.72-.506-.96-.29-.241-.662-.357-1.037-.323-.374.035-.72.217-.96.506l-7.084 8.5c-.047.068-.09.139-.127.213 0 .07 0 .113-.1.184-.064.162-.097.335-.098.51 0 .175.034.348.099.51 0 .07 0 .113.099.184.037.074.08.145.127.213l7.084 8.5c.133.16.3.288.488.376.189.088.394.134.602.134.332 0 .652-.115.907-.326.144-.12.262-.265.35-.43.086-.165.14-.345.157-.53.017-.186-.003-.373-.058-.551-.056-.178-.145-.344-.265-.487l-5.142-6.176h16.801c.376 0 .737-.15 1.002-.415.266-.266.415-.626.415-1.002s-.15-.736-.415-1.002c-.265-.265-.626-.415-1.002-.415z"
      />
    </svg>
  );

  const [user_login, set_user_login] = useState('');
  const [user_pw, set_user_pw] = useState('');
  const [is_eye, set_is_eye] = useState(0);

  const Submit = e => {
    const schema = {
      user_login: Joi.string()
        .required()
        .error(() => ({ message: '이메일을 입력해주세요.' })),
      user_pw: Joi.string()
        .required()
        .error(() => ({ message: '비밀번호를 입력해주세요.' })),
    };
    const { error, value } = Joi.validate(
      {
        user_login: user_login,
        user_pw: user_pw,
      },
      schema
    );

    if (error) {
      toast.error(error.details[0].message);
    } else {
      Dispatch(SetLoading(1));
      SignIn({
        user_login: user_login,
        user_pw: user_pw,
      })
        .then(response => {
          console.log(response.data.code);
          if (response.data.code == '200') {
            Getme()
              .then(res => {
                if (res.data.code == 200) {
                  Dispatch(SetUserData(res.data.body));
                }
                toast.success('환영합니다!');
                localStorage.setItem(
                  'user_token',
                  response.data.body.user_token
                );
                Dispatch(SetLoading(0));
                window.location.href = '/';
              })
              .catch(error => {
                toast.error('인터넷 쿠키 삭제 후 재시도 해주세요');
                Dispatch(SetLoading(0));
              });
          } else {
            toast.error(response.data.body.message);
            Dispatch(SetLoading(0));
          }
        })
        .catch(error => {
          console.log(error);
          Dispatch(SetLoading(0));
          toast.error('인터넷 쿠키 삭제 후 재시도 해주세요');
        });
    }
  };

  return (
    <>
      <LoginWrapper>
        {isMobile ? (
          <Mobile>
            <BackButton>
              <Link to="/">{icon_back}</Link>
            </BackButton>
            <h1>로그인</h1>
          </Mobile>
        ) : (
          ''
        )}
        <Row>
          <LoginImg url="/img/login_01.png" />
          <Wrapper>
            {isMobile ? (
              ''
            ) : (
              <BackButton>
                <Link to="/">{icon_back}</Link>
              </BackButton>
            )}
            <LoginContent>
              <h1>로그인</h1>
              {/* <Input value={user_id} onChange={(e) => set_user_id(e.target.value)} type="textbox" placeholder="아이디 또는 이메일" padding="0px 0px" width="400px"/>
                            <Input value={user_password} onKeyPress={onKeyPress} onChange={(e) => set_user_password(e.target.value)} type="password" placeholder="비밀번호" padding="0px 0px" width="400px"/>
                            <Button onClick={DoLogin} type="primary" color="#fff" bgColor="#3B86D1" width="400px">
                                로그인
                            </Button> */}
              {/* <Input type="password" placeholder="비밀번호" /> */}
              <IdInput
                type="text"
                name="user_login"
                onChange={e => set_user_login(e.target.value)}
                placeholder="이메일"
              />
              <PassWord>
                <PwInput
                  type={is_eye ? 'text' : 'password'}
                  name="user_pw"
                  onKeyPress={onKeyPress}
                  onChange={e => set_user_pw(e.target.value)}
                  placeholder="비밀번호"
                />
                <img
                  onClick={e => (is_eye ? set_is_eye(0) : set_is_eye(1))}
                  src={process.env.PUBLIC_URL + '/img/login_02.png'}
                  alt=""
                />
              </PassWord>
              <CheckLogin>
                <input type="checkbox" id="img_checkbox" />
                <label for="img_checkbox">
                  {longin_03}
                  <p>로그인 상태 유지</p>
                </label>
                <Link to="/find-pw">비밀번호 찾기</Link>
              </CheckLogin>
              <LoginButton onClick={Submit}>
                <Button>로그인</Button>
              </LoginButton>
              <RegisterButton>
                <Button to="/register" color="secondary">
                  회원가입
                </Button>
              </RegisterButton>
            </LoginContent>
          </Wrapper>
        </Row>
      </LoginWrapper>
    </>
  );
};

export default Login;
