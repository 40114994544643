import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import MainContainer from '../../../components/MainContainer';
import { SkeletonMainProduct } from '../../../components/Skeleton';
import Description from '../Popup/Description';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddToCart, ClearCart } from '../../../store/cart';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../../../perfect-scrollbar.css';
import { ProductGetPost, ProductGetRelated } from '../../../api/product';
import {
	Wrapper,
	MainPostWrapper,
	ProductImage,
	Information,
	ProductDetail,
	Name,
	Ingredient,
	Price,
	Amount,
	Manual,
	LeftSection,
	RelativeWrapper,
	Title,
	RelativeSection,
	RelativeProducts,
	RelativeProduct,
	RelativeImage,
	RelativeType,
	RelativeName,
	RelativePrice,
	IsMobile,
} from './styled';

const PoingShopSingle = () => {
	const Dispatch = useDispatch();
	const me = useSelector((state) => state.me.user_data);

	const params = useParams();

	const [product, set_product] = useState();
	const [product_ID, set_product_ID] = useState();
	const [product_content, set_product_content] = useState();
	const [related, set_related] = useState();
	const History = useHistory();

	const { jQuery } = window;
	useEffect(() => {
		if (product_ID !== params.ID) {
			ProductGetPost({
				product_ID: params.ID,
			})
				.then((response) => {
					if (response.data.code == 200) {
						set_product(response.data.body);
						set_product_ID(params.ID);
						set_product_content(response.data.body.content);

						console.log(response.data.body);
					}
				})
				.catch((error) => console.log(error));
		}
		if (!related) {
			ProductGetRelated({
				product_ID: params.ID,
			})
				.then((response) => {
					if (response.data.code == 200) {
						set_related(response.data.body.products);
						console.log(response.data.body.products);
					}
				})
				.catch((error) => console.log(error));
		}
		jQuery('.scroll-container').mousewheel(function (e, delta) {
			// multiplying by 40 is the sensitivity,
			// increase to scroll faster.
			this.scrollLeft -= delta * 40;
			e.preventDefault();
		});
	});

	const [count, set_count] = useState(1);
	const plusCouont = () => {
		set_count(count + 1);
	};
	const minusCouont = () => {
		if (count >= 2) {
			set_count(count - 1);
		}
	};

	const [Description_isshow, show_Description] = useState(0);
	const showDescription = (e) => {
		e.preventDefault();
		if (Description_isshow) {
			show_Description(0);
		} else {
			show_Description(1);
		}
	};

	const DoCheckOut = (e) => {
		if (!product) {
			alert('상품 로딩중입니다.');
		}
		Dispatch(ClearCart());

		Dispatch(
			AddToCart(
				product.ID,
				'',
				product.thumbnail,
				count,
				product.title,
				product.price_int,
				'',
				'point'
			)
		);
		toast.success('장바구니에 추가되었습니다!', { position: 'bottom-right' });
		// console.log(AddToCart(product.ID, count, product.title, product.price_int));
		History.push('/order/');
	};

	return (
		<MainContainer>
			<Wrapper>
				<MainPostWrapper>
					<LeftSection>
						<ProductImage
							url={product && product.thumbnail ? product.thumbnail : ''}
						/>
						<Information onClick={showDescription}>
							제품 설명
							<img
								src={process.env.PUBLIC_URL + '/img/arrow-next.svg'}
								alt="arrow"
							/>
						</Information>
					</LeftSection>
					<ProductDetail>
						<Name>{product && product.title ? product.title : ''}</Name>
						<Ingredient>
							{product && product.subtitle ? product.subtitle : ''}
						</Ingredient>
						<IsMobile>
							<ProductImage
								url={product && product.thumbnail ? product.thumbnail : ''}
							/>
						</IsMobile>

						<Price>
							금액
							<span>{product && product.price ? product.price : ''} P</span>
						</Price>

						<Amount>
							수량
							<div>
								<img
									onClick={minusCouont}
									src={process.env.PUBLIC_URL + '/img/minus.png'}
									alt=""
								/>
								<span>{count}</span>
								<img
									onClick={plusCouont}
									src={process.env.PUBLIC_URL + '/img/plus.png'}
									alt=""
								/>
							</div>
						</Amount>
						<Manual
							dangerouslySetInnerHTML={{
								__html: product && product.short_desc ? product.short_desc : '',
							}}
						></Manual>
						{product && parseInt(product.price_int) > parseInt(me.point) ? (
							<Price>
								<s>포인트가 부족하여 구매할 수 없습니다.</s>
							</Price>
						) : (
							<Button width="58%" onClick={DoCheckOut}>
								구매하기
							</Button>
						)}
						<Price>
							보유포인트
							<span>
								{me ? parseInt(me.point).toLocaleString('en') + ' P' : ''}
							</span>
						</Price>
						{/* <BasketBtn onClick={DoAddCart} /> */}
						<IsMobile>
							<Information onClick={showDescription}>
								제품 설명
								<img
									src={process.env.PUBLIC_URL + '/img/arrow-next.svg'}
									alt="arrow"
								/>
							</Information>
						</IsMobile>
					</ProductDetail>
				</MainPostWrapper>
				<RelativeWrapper>
					<Title>함께 많이 본 상품</Title>
					<RelativeSection>
						<PerfectScrollbar className={'scroll-container'}>
							<RelativeProducts>
								{related ? (
									related.map((item, key) => {
										return (
											<RelativeProduct>
												<RelativeImage
													onClick={(e) => set_product('')}
													url={item.thumbnail ? item.thumbnail : ''}
													to={'/product/' + (item.ID ? item.ID : '')}
												/>
												<RelativeType>
													{item.cate_name ? item.cate_name : ''}
												</RelativeType>
												<RelativeName>
													{item.title ? item.title : ''}
												</RelativeName>
												<RelativePrice>
													{item.price ? item.price : ''}
												</RelativePrice>
											</RelativeProduct>
										);
									})
								) : (
									<>
										<SkeletonMainProduct />
										<SkeletonMainProduct />
										<SkeletonMainProduct />
										<SkeletonMainProduct />
										<SkeletonMainProduct />
									</>
								)}
							</RelativeProducts>
						</PerfectScrollbar>
					</RelativeSection>
				</RelativeWrapper>
			</Wrapper>
			<Description
				show={Description_isshow}
				onClose={showDescription}
				content={product_content}
			/>
		</MainContainer>
	);
};

export default PoingShopSingle;
