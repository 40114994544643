import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import MainContainer from '../../../components/MainContainer';
import { SkeletonMainProduct } from '../../../components/Skeleton';
import Description from './Popup/Description';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddToCart } from '../../../store/cart';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../../../perfect-scrollbar.css';
import { ProductGetPost, ProductGetRelated } from '../../../api/product';
import { ReviewGetPosts } from '../../../api/review';
import {
	Wrapper,
	MainPostWrapper,
	ProductImage,
	Information,
	ProductDetail,
	Name,
	Ingredient,
	Price,
	Amount,
	BasketBtn,
	Manual,
	LeftSection,
	RelativeWrapper,
	Title,
	RelativeSection,
	RelativeProducts,
	RelativeProduct,
	RelativeImage,
	RelativeType,
	RelativeName,
	RelativePrice,
	IsMobile,
	Option,
	Signboard,
	OptionSelectbox,
	BtnFlex,
	Shar,
	PopupWrapper,
	Hidden,
} from './styled';

const ProductSingle = () => {
	const Dispatch = useDispatch();
	const { Kakao } = window;

	const params = useParams();

	const [product, set_product] = useState();
	const [product_ID, set_product_ID] = useState();
	const [product_content, set_product_content] = useState();
	const [related, set_related] = useState();
	const History = useHistory();
	const { jQuery } = window;
	const [option, set_option] = useState({
		ID: '',
		name: '',
		price: '',
		sale: '',
	});
	const int_price = option?.price
		? parseInt(option.price.replace(/,/g, ''))
		: 0;

	const me = useSelector((state) => state.me.user_data);

	// useEffect(() => {
	//   if (product_ID !== params.ID) {
	//     ProductGetPost({
	//       product_ID: params.ID,
	//     })
	//       .then(response => {
	//         if (response.data.code == 200) {
	//           set_product(response.data.body);
	//           set_product_ID(params.ID);
	//           set_product_content(response.data.body.content);
	//           let option = response.data.body?.option[0];
	//           set_option({
	//             ID: option?.ID,
	//             name: option?.name,
	//             price: option?.price,
	//             sale: option?.sale_price,
	//             percent: option?.sale_percent,
	//           });
	//           console.log(response.data.body);
	//         }
	//       })
	//       .catch(error => console.log(error));
	//   }
	//   if (!related) {
	//     ProductGetRelated({
	//       product_ID: params.ID,
	//     })
	//       .then(response => {
	//         if (response.data.code == 200) {
	//           set_related(response.data.body.products);
	//           console.log(response.data.body.products);
	//         }
	//       })
	//       .catch(error => console.log(error));
	//   }
	//   jQuery('.scroll-container').mousewheel(function (e, delta) {
	//     // multiplying by 40 is the sensitivity,
	//     // increase to scroll faster.
	//     this.scrollLeft -= delta * 40;
	//     e.preventDefault();
	//   });
	// });

	useEffect(() => {
		ProductGetPost({
			product_ID: params.ID,
		})
			.then((response) => {
				if (response.data.code == 200) {
					set_product(response.data.body);
					set_product_ID(params.ID);
					set_product_content(response.data.body.content);
					let option = response.data.body?.option[0];
					set_option({
						ID: option?.ID,
						name: option?.name,
						price: option?.price,
						sale: option?.sale_price,
						percent: option?.sale_percent,
					});
					console.log(response.data.body);
				}
			})
			.catch((error) => console.log(error));
	}, [params.ID]);

	useEffect(() => {
		if (product) {
			// Enliple Tracker
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.innerHTML = `
        window.ENP_VAR = { 
          collect: {
            productCode: '${product?.ID || ''}',
            productName: '${product?.title || ''}',
            price: '${product?.price_int || ''}',
            dcPrice: '${product?.promotion_price || ''}',
            soldOut: '${product?.is_sold_out ? 'Y' : 'N'}',
            imageUrl: '${product?.thumbnail || ''}',
            topCategory: '${product?.category_name || ''}',
          }
        };
      `;

			document.body.appendChild(script);

			// Clean up the script on component unmount
			return () => {
				document.body.removeChild(script);
			};
		}
	}, [product]);

	const [paged, setPaged] = useState(1);
	const [total, setTotal] = useState(0);
	const [reviews, setReviews] = useState([]);

	let posts_per_page = 5;

	useEffect(() => {
		ReviewGetPosts({
			paged: paged,
			posts_per_page: posts_per_page,
			product_ID: params.ID,
		})
			.then((response) => {
				if (response.data.code == 200) {
					setTotal(response.data.body.total);
					setReviews(response.data.body.posts);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, [params.ID]);

	useEffect(() => {
		if (paged > 1) {
			ReviewGetPosts({
				paged: paged,
				posts_per_page: posts_per_page,
				product_ID: params.ID,
			})
				.then((response) => {
					if (response.data.code == 200) {
						setReviews([...reviews, ...response.data.body.posts]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [paged]);

	useEffect(() => {
		ProductGetRelated({
			product_ID: params.ID,
		})
			.then((response) => {
				if (response.data.code == 200) {
					set_related(response.data.body.products);
					console.log(response.data.body.products);
				}
			})
			.catch((error) => console.log(error));

		jQuery('.scroll-container').mousewheel(function (e, delta) {
			// multiplying by 40 is the sensitivity,
			// increase to scroll faster.
			this.scrollLeft -= delta * 40;
			e.preventDefault();
		});
	}, [params.ID]);

	const [count, set_count] = useState(1);
	const plusCouont = () => {
		set_count(count + 1);
	};
	const minusCouont = () => {
		if (count >= 2) {
			set_count(count - 1);
		}
	};

	const [Description_isshow, show_Description] = useState(0);
	const showDescription = (e) => {
		e.preventDefault();
		if (Description_isshow) {
			show_Description(0);
		} else {
			show_Description(1);
		}
	};

	const DoCheckOut = (e) => {
		if (!product) {
			alert('상품 로딩중입니다.');
		} else {
			if (product && product.option && !option.name) {
				toast.error('옵션을 선택해주세요.');
			} else {
				Dispatch(
					AddToCart(
						product.ID,
						option.ID,
						product.thumbnail,
						count,
						product.title,
						option?.price ? int_price : product?.price_int,
						option.name
					)
				);
				toast.success('장바구니에 추가되었습니다!', {
					position: 'bottom-right',
				});
				// console.log(AddToCart(product.ID, count, product.title, product.price_int));
				History.push('/order/');
			}
		}
	};

	const DoAddCart = (e) => {
		if (!product) {
			alert('상품 로딩중입니다.');
		} else {
			if (product?.is_sold_out) {
				toast.error('임시 품절 상품입니다.');
				return;
			}

			if (product && product.option && !option.name) {
				toast.error('옵션을 선택해주세요.');
			} else {
				Dispatch(
					AddToCart(
						product.ID,
						option.ID,
						product.thumbnail,
						count,
						product.title,
						option?.price ? int_price : product?.price_int,
						option.name
					)
				);
				toast.success('장바구니에 추가되었습니다!', {
					position: 'bottom-right',
				});
			}
		}
	};

	const selectOption = (args) => {
		console.log(JSON.parse(args));
		var obj = JSON.parse(args);
		set_option({
			ID: obj.ID,
			name: obj.name,
			price: obj.price,
			sale: obj.sale_price,
			percent: obj.sale_percent,
		});
	};

	const [isShare, setIsShare] = useState(false);
	const shareToggle = () => {
		setIsShare((p) => !p);
	};

	const KakaoShare = (e) => {
		if (!me?.ID) {
			toast.error('로그인이 필요합니다.');
			return;
		}
		Kakao.Link.sendDefault({
			objectType: 'feed',
			buttonTitle: '초대 수락하기',
			content: {
				title: `사쿠라허브로 초대합니다`,
				description: `${product?.title}`,
				imageUrl: `${
					product?.thumbnail
						? product?.thumbnail
						: 'http://sakuraherb.com/wp-content/uploads/2021/02/mainpost.png'
				}`,

				link: {
					mobileWebUrl: `${window.location.href}?raf=${me?.referral_id}`,
					webUrl: `${window.location.href}?raf=${me?.referral_id}`,
				},
			},
		});
	};

	const copy_input = useRef('');
	const CopyLink = (e) => {
		if (!me?.ID) {
			toast.error('로그인이 필요합니다.');
			return;
		}

		e.preventDefault();
		copy_input.current.select();
		document.execCommand('copy');
		e.target.focus();
		toast.success('링크 복사가 완료되었습니다.');
	};

	const [tab, setTab] = useState(1);

	return (
		<MainContainer>
			<Helmet>
				<title>
					{product?.seo_title
						? product?.seo_title
						: '탈모인 성지 사쿠라허브 | 의약품 직구대행 | 핀페시아 | 타다주브 | 실주브 | 올리주브 | 카마그라'}
				</title>
				<meta
					property="og:title"
					content={
						product?.seo_title
							? product?.seo_title
							: '탈모인 성지 사쿠라허브 | 의약품 직구대행 | 핀페시아 | 타다주브 | 실주브 | 올리주브 | 카마그라'
					}
				/>
				<meta
					name="description"
					content={
						product?.seo_desc
							? product?.seo_desc
							: '탈모인 성지,핀페시아, 핀페시아 직구, 에프페시아, 이소트레티노인, 타다주브, 아이소주브, 멜라토닌, 미녹시딜, 카마그라, 비달리스타, 아보다트, 프로페시아, 이버멕틴, 이소트레티노인, 이소티논, 니타조사나이드, 니클로사마이드, 하이드록시클로로퀸, 핀주브, 핀스톰, 피나스테리드 직구를 대신해 드립니다.'
					}
				/>
				<meta
					property="og:description"
					content={
						product?.seo_desc
							? product?.seo_desc
							: '탈모인 성지,핀페시아, 핀페시아 직구, 에프페시아, 이소트레티노인, 타다주브, 아이소주브, 멜라토닌, 미녹시딜, 카마그라, 비달리스타, 아보다트, 프로페시아, 이버멕틴, 이소트레티노인, 이소티논, 니타조사나이드, 니클로사마이드, 하이드록시클로로퀸, 핀주브, 핀스톰, 피나스테리드 직구를 대신해 드립니다.'
					}
				/>
				<link
					rel="og:url"
					href={product ? 'https://sakuraherbs.com/product/' + product.ID : ''}
				/>
				<link
					rel="canonical"
					href={product ? 'https://sakuraherbs.com/product/' + product.ID : ''}
				/>
				<meta
					property="og:image"
					content={product ? product.thumbnail : ''}
				></meta>
				<script type="application/ld+json">
					{product
						? JSON.stringify({
								'@context': 'https://schema.org/',
								'@type': 'Product',
								sku: `${product.ID}`,
								image: `${product ? product.thumbnail : ''}`,
								name: `${product.title}`,
								offers: {
									'@type': 'Offer',
									availability: 'https://schema.org/InStock',
									price: `${product.price_int}`,
									priceCurrency: 'KRW',
								},
								aggregateRating: {
									'@type': 'AggregateRating',
									ratingValue: '4.7',
									bestRating: '5',
									ratingCount: `${
										product.reviewTotal ? product.reviewTotal : '54'
									}`,
								},
						  })
						: ''}
				</script>
			</Helmet>
			<Wrapper>
				<MainPostWrapper>
					<LeftSection>
						<ProductImage
							url={product && product.thumbnail ? product.thumbnail : ''}
						/>
						{/* <Information onClick={showDescription}>
              제품 설명
              <img
                src={process.env.PUBLIC_URL + '/img/arrow-next.svg'}
                alt="arrow"
              />
            </Information> */}
						<h4>
							<span
								onClick={() => setTab(1)}
								className={tab == 1 ? 'active' : ''}
							>
								제품설명
							</span>
							{total ? (
								<span
									onClick={() => setTab(2)}
									className={tab == 2 ? 'active' : ''}
								>
									이용후기
								</span>
							) : null}
						</h4>
						{tab == 1 ? (
							<div
								className="content"
								dangerouslySetInnerHTML={{
									__html: product_content?.desc,
								}}
							></div>
						) : null}
						{tab == 2 ? (
							<div className="review">
								<div className="list">
									{reviews?.length
										? reviews?.map((item, key) => (
												<div className="review_item">
													<p onClick={() => window.open(`/review/${item?.ID}`)}>
														{item?.post_title}
													</p>
													<div
														onClick={() => window.open(`/review/${item?.ID}`)}
														className="ct"
													>
														{item.post_content}
													</div>
													{item?.thumbnail !=
													'https://api-sakuraherb.com/wp-content/uploads/2021/11/noimg.png' ? (
														<div
															onClick={() => window.open(`/review/${item?.ID}`)}
															className="review-image"
															style={{
																backgroundImage: `url(${item?.thumbnail})`,
															}}
														/>
													) : null}
													<div className="io">
														<span>
															{item?.author_display_name} <i>|</i>{' '}
															{item?.post_date}
														</span>
													</div>
												</div>
										  ))
										: null}
								</div>
								{total > reviews?.length ? (
									<div onClick={() => setPaged((p) => p + 1)} className="btn-">
										이용후기 더보기
									</div>
								) : null}
							</div>
						) : null}
					</LeftSection>
					<ProductDetail>
						<Name>{product && product.title ? product.title : ''}</Name>
						{/* <Ingredient>
                            {product && product.subtitle ? product.subtitle : ''}
                        </Ingredient> */}
						<IsMobile>
							<ProductImage
								url={product && product.thumbnail ? product.thumbnail : ''}
							/>
						</IsMobile>
						<Price>
							금액
							<span>{product?.price}</span>
						</Price>
						{product?.option && (
							<>
								<Signboard>
									<div className="arrow-down" />
									점점 높아지는 할인율 !
								</Signboard>

								<Option>
									{/* <q>옵션</q> */}
									<div>
										{/* <select onChange={e => selectOption(e.target.value)}>
                    {product?.option
                      ? product.option.map((item, key) => {
                          return (
                            <option
                              value={JSON.stringify(item)}
                              selected={option.ID == item.ID ? 1 : 0}
                              data-name={item.name ? item.name : ''}
                              data-price={item.price ? item.price : ''}
                              data-sale={item.sale_price ? item.sale_price : ''}
                            >
                              {item?.name}
                            </option>
                          );
                        })
                      : ''}
                  </select> */}
										<table>
											<tr>
												<th></th>
												<th>수량</th>
												<th>할인율</th>
												<th>판매가</th>
											</tr>
											{product?.option
												? product.option.map((item, key) => {
														return (
															<tr>
																<td>
																	<span className="radio">
																		<input
																			type="radio"
																			id={item.ID}
																			name="drone"
																			value={JSON.stringify(item)}
																			checked={
																				item.ID === option.ID ? true : false
																			}
																			onChange={(e) =>
																				selectOption(e.target.value)
																			}
																		/>
																		<label for={item.ID} />
																	</span>
																</td>
																<td>{item.name}</td>
																<td>
																	{item.sale_percent ? item.sale_percent : '0%'}
																</td>
																<td>{item.price}원</td>
															</tr>
														);
												  })
												: ''}
										</table>
									</div>
								</Option>
							</>
						)}
						<Amount>
							수량
							<div>
								<img
									onClick={minusCouont}
									src={process.env.PUBLIC_URL + '/img/minus.png'}
									alt=""
								/>
								<span>{count}</span>
								<img
									onClick={plusCouont}
									src={process.env.PUBLIC_URL + '/img/plus.png'}
									alt=""
								/>
							</div>
						</Amount>
						<Manual
							dangerouslySetInnerHTML={{
								__html: product && product.short_desc ? product.short_desc : '',
							}}
						></Manual>
						<BtnFlex>
							{product?.is_sold_out ? (
								<Button className="sold-out" width="50%">
									임시품절
								</Button>
							) : (
								<Button width="50%" onClick={DoCheckOut}>
									구매하기
								</Button>
							)}

							<BasketBtn onClick={DoAddCart}>
								<img src="/img/basket.png" alt="" />
								장바구니
							</BasketBtn>
							<Shar onClick={shareToggle}>
								<div className="share-box">
									<img src="/img/icon_share.svg" alt="" />
								</div>
							</Shar>
						</BtnFlex>

						<IsMobile>
							{/* <Information onClick={showDescription}>
                제품 설명
                <img
                  src={process.env.PUBLIC_URL + '/img/arrow-next.svg'}
                  alt="arrow"
                />
              </Information> */}
							<div className="mo-content">
								<h4>
									<span
										onClick={() => setTab(1)}
										className={tab == 1 ? 'active' : ''}
									>
										제품설명
									</span>
									{total ? (
										<span
											onClick={() => setTab(2)}
											className={tab == 2 ? 'active' : ''}
										>
											이용후기
										</span>
									) : null}
								</h4>
								{tab == 1 ? (
									<div
										className="content"
										dangerouslySetInnerHTML={{
											__html: product_content?.desc,
										}}
									></div>
								) : null}
								{tab == 2 ? (
									<div className="review">
										<div className="list">
											{reviews?.length
												? reviews?.map((item, key) => (
														<div className="review_item">
															<p
																onClick={() =>
																	window.open(`/review/${item?.ID}`)
																}
															>
																{item?.post_title}
															</p>
															<div
																onClick={() =>
																	window.open(`/review/${item?.ID}`)
																}
																className="ct"
															>
																{item.post_content}
															</div>
															{item?.thumbnail !=
															'https://api-sakuraherb.com/wp-content/uploads/2021/11/noimg.png' ? (
																<div
																	onClick={() =>
																		window.open(`/review/${item?.ID}`)
																	}
																	className="review-image"
																	style={{
																		backgroundImage: `url(${item?.thumbnail})`,
																	}}
																/>
															) : null}
															<div className="io">
																<span>
																	{item?.author_display_name} <i>|</i>{' '}
																	{item?.post_date}
																</span>
															</div>
														</div>
												  ))
												: null}
										</div>
										{total > reviews?.length ? (
											<div
												onClick={() => setPaged((p) => p + 1)}
												className="btn-"
											>
												이용후기 더보기
											</div>
										) : null}
									</div>
								) : null}
							</div>
						</IsMobile>
					</ProductDetail>
				</MainPostWrapper>
				<RelativeWrapper>
					<Title>함께 많이 본 상품</Title>
					<RelativeSection>
						<PerfectScrollbar className={'scroll-container'}>
							<RelativeProducts>
								{related ? (
									related.map((item, key) => {
										return (
											<RelativeProduct>
												<RelativeImage
													onClick={(e) => set_product('')}
													url={item.thumbnail ? item.thumbnail : ''}
													to={'/product/' + (item.ID ? item.ID : '')}
												/>
												<RelativeType>
													{item.cate_name ? item.cate_name : ''}
												</RelativeType>
												<RelativeName>
													{item.title ? item.title : ''}
												</RelativeName>
												<RelativePrice>
													{item.price ? item.price : ''}
												</RelativePrice>
											</RelativeProduct>
										);
									})
								) : (
									<>
										<SkeletonMainProduct />
										<SkeletonMainProduct />
										<SkeletonMainProduct />
										<SkeletonMainProduct />
										<SkeletonMainProduct />
									</>
								)}
							</RelativeProducts>
						</PerfectScrollbar>
					</RelativeSection>
				</RelativeWrapper>
				{isShare && (
					<PopupWrapper>
						<div className="overlay" onClick={shareToggle}></div>
						<div className="popup">
							<div className="inner">
								<h2>추천인 링크와 함께 공유하기</h2>
								<div className="line"></div>
								<div onClick={CopyLink} className="btn">
									<img src="/img/icon-share.svg" alt="공유아이콘" />
									<p>링크 복사</p>
								</div>
								<div onClick={KakaoShare} className="btn kakao">
									<img src="/img/icon-kakao.png" alt="" />
									<p>카카오톡으로 공유</p>
								</div>
							</div>
							<div className="close" onClick={shareToggle}>
								닫기
							</div>
						</div>
					</PopupWrapper>
				)}
			</Wrapper>
			<Hidden>
				<input
					type="text"
					ref={copy_input}
					name="copy_account"
					value={`${window.location.href}?raf=${me?.referral_id}`}
				/>
			</Hidden>
			<Description
				show={Description_isshow}
				onClose={showDescription}
				content={product_content}
			/>
		</MainContainer>
	);
};

export default ProductSingle;
