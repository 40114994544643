import React, { useState, useEffect } from 'react';
import { MediaQuery, mobileQuery} from '../../../components/Grid';
import Pagination from '../../../components/Pagination';
import { FaqGetPosts } from '../../../api/faq';
import LoadingRound from '../../../components/LoadingRound';
import {
    Wrapper,
    Category,
    CategoryList,
    QusetionWrapper,
    Question,
    QLogo,
    QuestionTitle,
    ArrowDown,
    Answer,
    AnswerInner,
    ALogo
} from './styled';


const FAQ = () => {

    const isMobile = MediaQuery(mobileQuery);
    const Toggle = (e) => {
        if(e.currentTarget.classList.contains("active")){
            e.currentTarget.classList.remove('active');
        } else {
            e.currentTarget.classList.add('active');
        }
    }

    const [faq, set_faq] = useState('');
    const [faq_post, set_faq_post] = useState('');
    const [cate_ID, set_cate_ID] = useState('');
    const [tmp, set_tmp] = useState(1);

    const [current_paged, set_current_paged] = useState(0);
    const [total, set_total] = useState(0);
    const [paged, set_paged] = useState(1);
    const posts_per_page = 6;


    const selectCategory = (e) => {
        if(cate_ID !== e.target.dataset.id) {
            set_cate_ID(e.target.dataset.id);
            set_faq_post();
        } else {
            set_cate_ID('');
            set_faq_post();
        }
    }

    const onPaging = (page) => {
        console.log(page);
        set_paged(page);
        set_faq_post('');
    }

    useEffect(() => {
        if(tmp !== cate_ID  || current_paged != paged){
            if(tmp !== cate_ID){
                set_paged(1);
            }
            set_tmp(cate_ID)
            FaqGetPosts({
                category_ID: cate_ID,
                paged: paged,
                posts_per_page: posts_per_page
            })
            .then(response => {
                if(response.data.code == 200){
                    set_current_paged(response.data.body.paged);
                    set_total(response.data.body.total);
                    set_faq(response.data.body);
                    set_faq_post(response.data.body.posts);
                }
            })
            .catch(error => console.log(error));
        } 
    });

    const delivery_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
            <path fill="#111" d="M0 5.625V7.5h17.813v14.063H12.04c-.418-1.612-1.867-2.813-3.604-2.813-1.736 0-3.185 1.2-3.603 2.813H3.75v-4.688H1.875v6.563h2.959c.418 1.611 1.867 2.812 3.604 2.812 1.736 0 3.185-1.2 3.603-2.813h7.793c.418 1.612 1.867 2.813 3.604 2.813 1.736 0 3.185-1.2 3.603-2.813H30v-7.646l-.06-.147-1.874-5.625-.203-.644h-8.175v-3.75H0zm.938 3.75v1.875h8.437V9.375H.937zm18.75 1.875h6.825l1.612 4.805v5.508h-1.084c-.418-1.612-1.867-2.813-3.604-2.813-1.736 0-3.185 1.2-3.603 2.813h-.146V11.25zM1.875 13.125V15H7.5v-1.875H1.875zm6.563 7.5c1.047 0 1.874.828 1.874 1.875 0 1.047-.827 1.875-1.874 1.875-1.048 0-1.876-.828-1.876-1.875 0-1.047.828-1.875 1.875-1.875zm15 0c1.047 0 1.875.828 1.875 1.875 0 1.047-.828 1.875-1.875 1.875-1.048 0-1.875-.828-1.875-1.875 0-1.047.827-1.875 1.875-1.875z"/>
        </svg>
    );
    const payment_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
            <path fill="#111" d="M21.533 3.75c-.148.004-.295.023-.44.059L5.86 7.822c-.602.159-1.135.512-1.516 1.005-.381.492-.59 1.097-.593 1.72v12.89c0 1.543 1.27 2.813 2.813 2.813h16.875c1.542 0 2.812-1.27 2.812-2.813V11.25c0-1.542-1.27-2.813-2.813-2.813H10.899l10.665-2.812V7.5h1.875V5.625c0-1.055-.898-1.89-1.905-1.875zm-14.97 6.563h16.875c.53 0 .937.406.937.937v12.188c0 .53-.407.937-.938.937H6.563c-.53 0-.937-.407-.937-.938V11.25c0-.53.407-.938.938-.938zm14.53 5.624c-.372 0-.73.149-.994.412-.263.264-.412.622-.412.995s.149.73.412.994c.264.264.622.412.995.412s.73-.148.994-.412.412-.621.412-.994-.148-.73-.412-.995c-.264-.263-.621-.412-.994-.412z"/>
        </svg>
    );
    const product_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
            <path fill="#111" d="M15 2.813c-2.574 0-4.688 2.113-4.688 4.687v.938H5.684l-.058.879-.938 16.875-.059.995h20.743l-.058-.996-.938-16.875-.06-.879h-4.628V7.5c0-2.574-2.113-4.688-4.687-4.688zm0 1.874c.746 0 1.461.297 1.989.824.527.528.823 1.243.823 1.989v.938h-5.625V7.5c0-.746.297-1.461.824-1.989.528-.527 1.243-.824 1.989-.824zm-7.56 5.625h2.872v2.813h1.876v-2.813h5.624v2.813h1.875v-2.813h2.872l.82 15H6.622l.819-15z"/>
        </svg>
    );
    const comment_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
            <path fill="#111" d="M2.813 4.688v16.875H7.5v4.76l5.955-4.76h13.732V4.688H2.813zm1.874 1.875h20.625v13.125H12.796l-3.42 2.735v-2.735H4.687V6.563z"/>
        </svg>
    );

    return (
        <>
            <Wrapper>
                <Category>
                    <CategoryList onClick={selectCategory} className={ cate_ID == 800 ? 'active' : ''} data-id="800">
                        <span className="icon">
                            {delivery_icon}
                        </span>
                        <span className="text">
                            배송/주문
                        </span>
                    </CategoryList>
                    <CategoryList onClick={selectCategory} className={ cate_ID == 799 ? 'active' : ''} data-id="799">
                        <span className="icon">
                            {payment_icon}
                        </span>
                        <span className="text">
                            구매/결제
                        </span>
                    </CategoryList>
                    <CategoryList onClick={selectCategory} className={ cate_ID == 805 ? 'active' : ''} data-id="805">
                        <span className="icon">
                            {product_icon}
                        </span>
                        <span className="text">
                            회원/쿠폰/포인트
                        </span>
                    </CategoryList>
                    <CategoryList onClick={selectCategory} className={ cate_ID == 803 ? 'active' : ''} data-id="803">
                        <span className="icon">
                            {comment_icon}
                        </span>
                        <span className="text">
                            기타관련
                        </span>
                    </CategoryList>
                </Category>
                <QusetionWrapper>
                    {faq_post ? faq_post.map((item, key) => {
                            return (
                                <Question onClick={Toggle}>
                                    <QLogo>Q</QLogo>
                                    <QuestionTitle>
                                        <p>{item.category_name ? item.category_name : ''}</p>
                                        <span>{item.post_title ? item.post_title : ''}</span>
                                    </QuestionTitle>
                                    <ArrowDown className="svg" src={process.env.PUBLIC_URL + "/img/arrow-down.svg"}/>
                                    <Answer className="answer">
                                        <ALogo>A</ALogo>
                                        <AnswerInner dangerouslySetInnerHTML={{
                                            __html: item.post_content
                                        }}>   
                                        </AnswerInner>
                                    </Answer>
                                </Question>
                            )
                        }) : <>
                            <LoadingRound />
                        </>
                    }
                </QusetionWrapper>
                <Pagination onPaging={onPaging} total={total} current={current_paged} posts_per_page={posts_per_page} max={(total >= posts_per_page) ? (parseInt(total / posts_per_page) + 1) : 1}/>
            </Wrapper>
        </>
    );
};

export default FAQ;