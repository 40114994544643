import React from 'react';
import { PopupWrapper, Overlay, PopupInner, PopupClose } from './styled';

const QrModal = ({ show, onClose, qrLink }) => {
	return (
		<PopupWrapper show={show}>
			<Overlay show={show} onClick={onClose} />
			<PopupInner show={show}>
				<div className="title">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="48"
						height="20"
						viewBox="0 0 48 20"
						fill="none"
					>
						<g clip-path="url(#clip0_2701_1002)">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M37.9821 20.0039H9.99707C4.47468 20.0039 -0.00292969 15.5263 -0.00292969 10.0039C-0.00292969 4.48152 4.47468 0.00390625 9.99707 0.00390625H38.007C43.5294 0.00390625 48.007 4.48152 48.007 10.0039C48.007 15.5263 43.5045 20.0039 37.9821 20.0039Z"
								fill="#FCEB4F"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.2105 4.82227C8.20051 4.82227 5.7627 6.73769 5.7627 9.12575C5.7627 10.6432 6.7826 11.9864 8.30001 12.7576L7.77762 14.6979C7.75275 14.7476 7.77762 14.8223 7.82737 14.872C7.85225 14.8969 7.902 14.9218 7.92687 14.9218C7.95175 14.9218 8.0015 14.8969 8.02638 14.8969L10.2403 13.4044C10.5637 13.4541 10.8871 13.479 11.2353 13.479C14.2453 13.479 16.6831 11.5387 16.6831 9.1755C16.6831 6.73769 14.2453 4.82227 11.2105 4.82227Z"
								fill="black"
							/>
							<path
								d="M21.2899 12.69V15.476H19.2998V5.2024H20.6928L20.9416 5.84916C21.3645 5.42628 22.0112 4.97852 23.0311 4.97852C24.9714 4.97852 25.8918 6.4213 25.8918 8.78449C25.8918 11.2472 24.4739 12.8143 22.4341 12.8143C22.0361 12.8392 21.7376 12.8143 21.2899 12.69ZM21.2899 6.86906V11.2969C21.3894 11.3218 21.663 11.3467 21.9366 11.3467C23.3545 11.3467 23.9018 10.3517 23.9018 8.78449C23.9018 7.41633 23.5287 6.59543 22.4093 6.59543C22.0112 6.59543 21.6132 6.69493 21.2899 6.86906Z"
								fill="black"
							/>
							<path
								d="M30.1969 8.11778H31.3163V7.86902C31.3163 7.04813 30.8436 6.67499 30.0476 6.67499C29.4257 6.67499 28.6546 6.84912 28.0078 7.17251L27.4606 5.8541C28.1571 5.35659 29.2516 5.0332 30.2218 5.0332C32.1123 5.0332 33.1571 6.02823 33.1571 7.94365V12.6451H31.764L31.565 12.0233C30.769 12.5954 30.0228 12.8441 29.376 12.8441C27.9332 12.8441 27.1123 11.9735 27.1123 10.5058C27.1123 8.93868 28.182 8.11778 30.1969 8.11778ZM31.3412 10.7297V9.36156H30.4208C29.4009 9.36156 28.8785 9.7347 28.8785 10.4561C28.8785 11.0034 29.1521 11.277 29.7491 11.277C30.2964 11.3019 30.9929 11.0282 31.3412 10.7297Z"
								fill="black"
							/>
							<path
								d="M39.1739 11.8719C38.4774 13.7127 37.6565 15.056 36.4376 15.8023L35.2187 14.6829C35.9152 14.061 36.4376 13.464 36.8604 12.668L34.2236 5.4789L36.1888 4.95652L37.8803 10.7774L39.547 4.93164L41.4873 5.4789L39.1739 11.8719Z"
								fill="black"
							/>
						</g>
						<defs>
							<clipPath id="clip0_2701_1002">
								<rect width="47.9851" height="20" fill="white" />
							</clipPath>
						</defs>
					</svg>
					<div>카카오페이 결제하기</div>
				</div>
				<img className="qr" src={qrLink} />
				<p className="text">
					카카오페이 송금하기로 이동하는 QR코드입니다.
					<br />
					코드를 스캔하여 결제를 진행해 주세요.
				</p>
				<div onClick={onClose} className="btn">
					닫기
				</div>
			</PopupInner>
		</PopupWrapper>
	);
};

export default QrModal;
