import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;
	padding-top: 100px;
	padding-bottom: 200px;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		padding-top: 50px;
	}
`;

export const TopSection = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 54px;
	@media ${(props) => props.theme.mobile} {
		margin-bottom: 30px;
	}
`;

export const Keyword = styled.div`
	display: inline-block;
	font-size: 30px;
	line-height: 43px;
	font-weight: bold;
	color: #111111;
	@media ${(props) => props.theme.mobile} {
		font-size: 24px;
		line-height: 35px;
	}
`;

export const SearchResult = styled.div`
	position: absolute;
	top: 25px;
	right: 0;
	display: inline-block;
	font-family: Noto Sans KR;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	color: #000;
	span {
		color: #e55f74;
	}
	@media ${(props) => props.theme.mobile} {
		position: static;
		display: block;
		font-size: 16px;
		font-weight: 500;
		line-height: 23px;
		margin-top: 15px;
	}
`;

export const ProductsWrapper = styled.div`
	width: calc(100% + 30px);
	height: 100%;
	margin: 0 -15px;
	margin-bottom: 50px;
`;

export const ProductWrapper = styled.div`
	display: inline-block;
	width: 25%;
	height: 659px;
	padding: 0 15px;
	vertical-align: top;
	margin-top: 100px;
	@media (max-width: 1200px) {
		width: 33.3%;
	}
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		height: 387px;
		margin-top: 50px;
	}
`;

export const Product = styled.div`
	width: 100%;
	height: 100%;
`;

export const ProductImage = styled(Link)`
	position: relative;
	display: block;
	width: 100%;
	max-width: 353px;
	height: 450px;
	background-color: #fbfbfb;
	background-image: url(${(props) => (props.url ? props.url : '')});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 240px auto;
	margin: 0 auto;
	border-radius: 2px;

	.sold-out {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 69px;
		/* transform: translateY(-100%); */
		background-color: rgba(0, 0, 0, 0.3);
		color: #fff;
		font-family: 'Noto Sans KR';
		font-size: 18px;
		font-weight: 700;
		line-height: 67px;
		text-align: center;
	}

	@media (max-width: 1000px) {
		height: 400px;
	}
	@media (max-width: 870px) {
		height: 370px;

		.sold-out {
			height: 40px;
			font-size: 13px;
			line-height: 38px;
		}
	}
	@media ${(props) => props.theme.mobile} {
		height: 235px;
	}
`;

export const ProductDetail = styled.div`
	width: 100%;
	max-width: 353px;
	height: 209px;
	padding: 20px 0;
	margin: 0 auto;
	:after {
		display: block;
		position: absolute;
		content: '';
		width: 100%;
		height: 1px;
		background-color: #ebebeb;
		margin-top: 109px;
	}
	@media ${(props) => props.theme.mobile} {
		height: 152px;
		padding: 13px 0 0 0;
		border-bottom: 1px solid #ebebeb;
		:after {
			display: none;
		}
	}
`;

export const ProductType = styled.div`
	font-size: 13px;
	line-height: 19px;
	font-weight: 500;
	color: #111;
	margin-bottom: 5px;
	@media ${(props) => props.theme.mobile} {
		font-size: 16px;
		line-height: 23px;
	}
`;

export const ProductName = styled.div`
	font-size: 13px;
	line-height: 19px;
	color: #111;
	margin-bottom: 15px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media ${(props) => props.theme.mobile} {
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 10px;
		height: auto;
	}
`;

export const ProductPrice = styled.div`
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #111;
	@media ${(props) => props.theme.mobile} {
		font-size: 18px;
		line-height: 26px;
	}
`;

export const MoreButtonWrapper = styled.div`
	padding: 0 20px;
	padding-bottom: 79px;
	border-bottom: 1px solid #ebebeb;
`;

export const MoreButton = styled.div`
	width: 100%;
	height: 60px;
	font-size: 18px;
	font-weight: 500;
	line-height: 58px;
	border-radius: 500px;
	border: solid 1px #999;
	text-align: center;
`;

export const Empty = styled.div`
	padding-left: 20px;
	font-size: 22px;
	line-height: 26px;
`;
