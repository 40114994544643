import styled from 'styled-components';

export const Wrapper = styled.div`
	max-width: 1060px;
	width: 100%;
	margin: 0 auto;
	padding: 0 30px 200px 30px;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
	}
`;

export const Hidden = styled.div`
	position: absolute;
	left: -1000px;
`;

export const GuideWrapper = styled.div`
	margin-top: 100px;
	h2 {
		color: #111;
		font-family: 'Noto Sans KR';
		font-size: 27px;
		font-weight: 700;
		line-height: 42px;
	}
	p {
		margin-top: 10px;
		color: #000;
		font-family: 'Noto Sans KR';
		font-size: 15px;
		font-weight: 400;
		line-height: 28px;
		span {
			font-family: 700;
		}
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 50px;
		h2 {
			font-size: 24px;
			line-height: 38px;
		}
		p {
			margin-top: 20px;
			font-size: 16px;
			line-height: 26px;
		}
	}
`;

export const Tabcontent = styled.div`
	max-width: 1000px;
	width: 100%;
	margin: 0 auto;
	.flex {
		display: flex;
		gap: 40px;
		margin-top: 50px;
		.tab-content {
			max-width: 304px;
			width: 100%;
			.banner {
				width: 100%;
				height: 278px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				position: relative;
			}
			.bg {
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 30px;
				background: linear-gradient(
					180deg,
					rgba(255, 255, 255, 0) 0%,
					#fff 100%
				);
			}
		}
		.guide {
			max-width: 646px;
			width: 100%;
			h1 {
				color: #111;
				font-size: 20px;
				font-weight: 700;
				line-height: normal;
			}
			ul {
				li {
					margin-top: 20px;
					border: 1px solid #ebebeb;
					background: #fff;
					padding: 26px 30px;
					display: flex;
					gap: 68px;
					cursor: pointer;
					transition: all 0.3s;
					&.active {
						border: 1px solid #e55f74;
						color: #e55f74;
						h3 {
							color: rgba(229, 95, 116, 0.3);
							span {
								color: #e55f74;
							}
						}
					}
					h3 {
						color: rgba(0, 0, 0, 0.3);
						font-family: 'Noto Sans KR';
						font-size: 14px;
						font-weight: 700;
						line-height: normal;
						max-width: 110px;
						width: 100%;
						span {
							color: #000;
							margin-left: 8px;
						}
					}
					p {
						font-size: 14px;
						font-weight: 400;
						line-height: 150%;
						letter-spacing: -0.28px;
					}
				}
			}
		}
	}
	.swiper {
		padding-bottom: 36px;
	}

	.swiper-pagination {
		display: flex;
		gap: 3px;
		justify-content: center;
	}
	.swiper-pagination-bullet {
		background-color: #d9d9d9;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		cursor: pointer;
	}
	.swiper-pagination-bullet-active {
		background-color: #e55f74;
	}
	@media ${(props) => props.theme.mobile} {
		.flex {
			margin-top: 40px;
			flex-wrap: wrap;
			justify-content: center;
			.guide {
				max-width: 100%;
				width: 100%;
				ul {
					li {
						padding: 25px 28px;
						flex-wrap: wrap;
						gap: 12px;
						align-items: center;
						h3 {
							font-size: 16px;
							font-weight: 700;
							max-width: 125px;
						}
						p {
							font-size: 16px;
							letter-spacing: -0.32px;
						}
					}
				}
			}
		}
	}
`;

export const LinkWrapper = styled.div`
	margin-top: 50px;
	.copy-box {
		border-radius: 200px;
		border: 1px solid #e3e5e8;
		background: #fbfbfb;
		padding: 17px 30px;
		display: flex;
		justify-content: space-between;
		p {
			font-size: 15px;
			font-weight: 400;
			line-height: normal;
		}
		span {
			font-size: 15px;
			font-weight: 400;
			line-height: normal;
			text-decoration: underline;
			text-underline-offset: 3px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.shar-box {
		margin-top: 20px;
		display: flex;
		gap: 20px;
		div {
			border-radius: 200px;
			width: 50%;
			height: 56px;
			display: flex;
			gap: 5px;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				opacity: 0.9;
			}
			p {
				color: #111;
				font-size: 15px;
				font-weight: 500;
				line-height: normal;
			}
		}
		.kakao {
			background: #fceb4f;
		}
		.facebook {
			background: #425993;
			p {
				color: #fff;
			}
		}
	}
	.banner {
		background: #021234;
		padding: 42px 40px;
		height: 214px;
		display: flex;
		position: relative;
		.left {
			h1 {
				color: #fff;
				font-size: 23px;
				opacity: 0.8;
				font-weight: 500;
				line-height: normal;
			}
			h2 {
				color: #fff;
				margin-top: 2px;
				font-size: 23px;
				font-weight: 500;
				line-height: normal;
			}
			.btn {
				margin-top: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 500px;
				border: 1px solid #fff;
				background: #021234;
				max-width: 144px;
				width: 100%;
				height: 42px;
				cursor: pointer;
				transition: all 0.3s;
				&:hover {
					opacity: 0.8;
				}
				p {
					color: #fff;
					font-size: 13px;
					font-weight: 500;
					line-height: normal;
				}
			}
		}
		.right {
			max-width: 423px;
			width: 100%;
			position: absolute;
			right: 40px;
			bottom: 0;
			img {
				width: 100%;
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		margin-top: 40px;
		.copy-box {
			padding: 20px;
			p {
				font-size: 14px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
			span {
				font-size: 14px;
				white-space: nowrap;
				margin-left: 10px;
			}
		}
		.shar-box {
			flex-wrap: wrap;
			div {
				width: 100%;
				height: 60px;
				gap: 8px;
				p {
					font-size: 16px;
				}
			}
		}
		.banner {
			display: none;
		}
	}
`;
export const MoBannerWrapper = styled.div`
	display: none;
	@media ${(props) => props.theme.mobile} {
		display: block;
		margin: 0 -20px;
		.mo-banner {
			background: #021234;
			padding: 45px 30px 50px 30px;
			height: 180px;
			position: relative;
			display: block;
			.left {
				p {
					color: #fff;
					font-size: 13px;
					font-weight: 500;
					line-height: normal;
					opacity: 0.8;
				}
				h2 {
					color: #fff;
					margin-top: 6px;
					font-size: 20px;
					font-weight: 400;
					line-height: 30px;
					span {
						font-weight: 700;
					}
				}
			}
			.right {
				max-width: 154px;
				width: 100%;
				position: absolute;
				right: 0px;
				bottom: 0;
				img {
					width: 100%;
				}
			}
		}
	}
`;

export const RankingWrapper = styled.div`
	margin-top: 50px;
	h2 {
		color: #111;
		font-size: 20px;
		font-weight: 700;
		line-height: normal;
		border-bottom: 1px solid #111;
		padding-bottom: 20px;
	}
	.ranking {
		&:nth-child(2) {
			.number {
				background-color: #e55f74 !important;
				p {
					color: #fff !important;
				}
			}
		}
		&:nth-child(3) {
			.number {
				background-color: rgba(229, 95, 116, 0.8) !important;
				p {
					color: #fff !important;
				}
			}
		}
		&:nth-child(4) {
			.number {
				background-color: rgba(229, 95, 116, 0.4) !important;
				p {
					color: #fff !important;
				}
			}
		}
		.list {
			padding: 30px 0;
			border-bottom: 1px solid #ebebeb;
			.info {
				display: flex;
				align-items: center;
				gap: 20px;
				.number {
					width: 36px;
					height: 36px;
					background-color: #f5f5f5;
					border-radius: 50%;
					p {
						color: rgba(1, 1, 1, 0.6);
						text-align: center;
						font-family: 'Roboto';
						font-size: 17px;
						font-weight: 500;
						line-height: 36px;
						letter-spacing: 0.85px;
					}
				}
				.name-box {
					.name {
						color: #9a9a9e;
						font-size: 13px;
						font-weight: 400;
						line-height: normal;
					}
					.point {
						color: #111;
						margin-top: 10px;
						font-size: 15px;
						font-weight: 500;
						line-height: normal;
					}
				}
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		.ranking {
			.list {
				padding: 30px 0;
				.info {
					.number {
						width: 40px;
						height: 40px;
						p {
							font-size: 20px;
							font-weight: 500;
							line-height: 40px;
							letter-spacing: 1;
						}
					}
					.name-box {
						.name {
							font-size: 14px;
						}
						.point {
							margin-top: 5px;
							font-size: 16px;
						}
					}
				}
			}
		}
	}
`;

export const QnaWrapper = styled.div`
	margin-top: 50px;
	h2 {
		color: #111;
		font-size: 20px;
		font-weight: 700;
		line-height: normal;
		border-bottom: 1px solid #111;
		padding-bottom: 20px;
	}
	.qna {
		.list {
			padding: 30px 0;
			border-bottom: 1px solid #ebebeb;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				opacity: 0.8;
			}
			.info {
				display: flex;
				align-items: center;
				gap: 20px;
				.q-box {
					width: 36px;
					height: 36px;
					background-color: #f5f5f5;
					border-radius: 50%;
					p {
						color: rgba(1, 1, 1, 0.6);
						text-align: center;
						font-family: 'Roboto';
						font-size: 17px;
						font-weight: 500;
						line-height: 36px;
						letter-spacing: 0.85px;
					}
				}
				.cont-box {
					.cate {
						color: #9a9a9e;
						font-size: 13px;
						font-weight: 400;
						line-height: normal;
					}
					.title {
						color: #111;
						margin-top: 10px;
						font-size: 15px;
						font-weight: 500;
						line-height: normal;
					}
				}
			}
			.arrow {
				cursor: pointer;
			}
		}
	}
	.answer {
		padding: 40px 0;
		border-bottom: 1px solid #ebebeb;
		.info {
			display: flex;
			align-items: center;
			gap: 20px;
			.a-box {
				width: 36px;
				height: 36px;
				background-color: #e55f74;
				border-radius: 50%;
				p {
					color: #fff;
					text-align: center;
					font-family: 'Roboto';
					font-size: 17px;
					font-weight: 500;
					line-height: 36px;
					letter-spacing: 0.85px;
				}
			}
			.cont-box {
				color: #111;
				font-size: 15px;
				font-weight: 400;
				line-height: 26px;
			}
		}
	}
	@media ${(props) => props.theme.mobile} {
		.qna {
			.list {
				.info {
					.q-box {
						width: 40px;
						height: 40px;
						p {
							font-size: 20px;
							line-height: 40px;
							letter-spacing: 1px;
						}
					}
					.cont-box {
						.cate {
							font-size: 14px;
						}
						.title {
							margin-top: 5px;
							font-size: 16px;
						}
					}
				}
			}
		}
		.answer {
			padding: 30px 0;
			.info {
				.a-box {
					width: 40px;
					height: 40px;
					p {
						font-size: 20px;
						line-height: 40px;
						letter-spacing: 1px;
					}
				}
				.cont-box {
					font-size: 14px;
					font-weight: 400;
					line-height: 26px;
				}
			}
		}
	}
`;

export const Mochatting = styled.div`
	display: none;
	@media ${(props) => props.theme.mobile} {
		display: block;
		padding: 50px 0;
		p {
			color: #111;
			text-align: center;
			font-size: 18px;
			font-weight: 400;
			line-height: 30px;
		}
		.btn {
			border-radius: 200px;
			background: #fceb4f;
			height: 60px;
			margin-top: 26px;
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: center;

			p {
				color: #111;
				font-size: 16px;
				font-weight: 500;
				line-height: normal;
			}
		}
	}
`;
