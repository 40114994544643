import React, { useState, useEffect } from 'react';
import MainContainer from '../../../components/MainContainer';
import {Link, useParams} from 'react-router-dom';
import { EventGetPost, EventGetRelated } from '../../../api/event';
import { SktonMoreEvent } from '../../../components/Skeleton';
import {
    Wrapper,
    Container,
    EventTitle,
    EventPostContent,
    EventSingleView,
    EventWrapper,
    EventContent,
    ContentWrapper,
    ContentTitle,
    ContentImg,
} from './styled';

const EventSingle = () => {

    const params = useParams();
    const [event, set_event] = useState('');
    const [current_event, set_current_event] = useState('');
    const [event_related, set_event_related] = useState('');


    useEffect(() => {
        if(current_event != params.event_ID) {
            set_current_event(params.event_ID);
            EventGetPost({
                event_ID: params.event_ID
            })  
            .then(response => {
                if(response.data.code == 200){
                    set_event(response.data.body);
                    EventGetRelated({
                        event_ID: params.event_ID
                    })
                    .then(response => {
                        if(response.data.code == 200){
                            set_event_related(response.data.body.posts);
                        }
                    })
                    .catch((error => console.log(error)))
                }
            })
            .catch((error => console.log(error)))
        }
    })
    
    return (
        <MainContainer>
            <Wrapper>
                <Container>
                    <EventTitle>
                        <h1>{event.post_title ? event.post_title : ''}</h1>
                        <span>{event.event_date_range ? event.event_date_range : ''}</span>
                    </EventTitle>
                    {/* <EventSingleView url={event.thumbnail ? event.thumbnail : ''} /> */}
                    <EventPostContent dangerouslySetInnerHTML={{
                            __html: event.post_content
                        }}>
                    </EventPostContent>
                    <ContentTitle>
                        <h2>더 많은 이벤트 보기</h2>
                    </ContentTitle>
                    <EventWrapper>
                        <ContentWrapper>
                            {event_related ? event_related.map((item, key) => {
                                    return (
                                        <Link to={"/event/" + item.ID}>
                                            <EventContent>
                                                <ContentImg url={item.thumbnail ? item.thumbnail : ''} />
                                                <p>{item.cate_name ? item.cate_name : ''}</p>
                                                <p>{item.post_title ? item.post_title : ''}</p>
                                                <span>{item.event_date_range ? item.event_date_range : ''}</span>
                                            </EventContent>
                                        </Link>
                                    )
                                }) : <>
                                    <SktonMoreEvent />
                                    <SktonMoreEvent />
                                    <SktonMoreEvent />
                                </>
                            }
                        </ContentWrapper>
                    </EventWrapper>
                </Container>
            </Wrapper>
        </MainContainer>
    );
};

export default EventSingle;