import React, { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import { SetLoading, ToggleChatbot } from '../../../store/global';
import {toast} from 'react-toastify';
import Joi from 'joi-browser';
import { ChatbotPost } from '../../../api/chatbot';
import {
    QnaWrapper,
    InquireTextarea,
    InquireInput,
    Submit
} from '../styled';

const QnaTemplate = (props) => {
    const Dispatch = useDispatch();
    const[question_title, set_question_title] = useState('');
    const[question_comment, set_question_comment] = useState('');
    const[user_email, set_user_email] = useState('');
    const[user_phone, set_user_phone] = useState('');

    const aa = (e) => { 
        const schema ={
            question_title: Joi.string()
                .required()
                .error(() => ({message: '문의제목을 입력해주세요.'})),
            question_comment: Joi.string()
                .required()
                .error(() => ({message: '문의내용을 입력해주세요.'})),
            user_phone: Joi.number()
                .required()
                .error(() => ({message: '전화번호를 입력해주세요.'})),
            user_email: Joi.string()
                .required()
                .error(() => ({message: '이메일을 입력해주세요.'}))
        }
        const {error, value} = Joi.validate({
            question_title: question_title,
            question_comment: question_comment,
            user_phone: user_phone,
            user_email: user_email
        }, schema);

        if(error){
            toast.error(error.details[0].message);
        } else { 
            Dispatch(SetLoading(1));
            ChatbotPost ({
                question_title: question_title,
                question_comment: question_comment,
                user_phone: user_phone,
                user_email: user_email
            })
            .then((response) => {
                Dispatch(SetLoading(0));
                if(response.data.code == '200') {
                    toast.success('문의가 접수되었습니다.');
                    Dispatch(ToggleChatbot());
                } else {
                    alert(response.data.body.message)
                }
            })
            .catch((error) => {console.log(error); Dispatch(SetLoading(0));})
        }
    }

    return (
        <>
            <QnaWrapper>
                <InquireInput type="text" name="question_title" onChange={(e) => set_question_title(e.target.value)} placeholder="문의제목을 입력해주세요." />
                <InquireTextarea onChange={(e) => set_question_comment(e.target.value)} name="question_comment" placeholder="문의내용을 입력해주세요." />
                <InquireInput type="text" name="user_email" onChange={(e) => set_user_phone(e.target.value)} placeholder="전화번호를 입력해주세요." />
                <InquireInput type="text" name="user_phone" onChange={(e) => set_user_email(e.target.value)} placeholder="이메일을 입력해주세요." />
                <Submit onClick={aa} spellcheck="false">
                    Enter
                    <img src={process.env.PUBLIC_URL + "/img/arrow-enter.svg"} />
                </Submit>
                <Submit type="default" onClick={e=>{props.DoAction('intro',[]);}}>처음으로</Submit>
            </QnaWrapper>
        </>
    );
};

export default QnaTemplate;