import axios from './defaultClient';

const nameSpace = '/ep/v1/event';

export const EventGetPosts = async (args) => {
    return await axios.get( nameSpace + '/',{
        params:{
            paged: args.paged,
            posts_per_page: args.posts_per_page,
            type: args.type
        }
    })
}

export const EventGetPost = async (args) => {
    return await axios.get( nameSpace + '/' + args.event_ID,{
       
    })
}

export const EventGetRelated = async (args) => {
    return await axios.get( nameSpace + '/' + args.event_ID + '/related',{
        
    })
}