import React, {useState, useRef} from 'react';
import SidePopup from '../../../components/SidePopup';
import {
    Wrapper,
    Title,
    Summary
} from './styled';

const Description = ({show, onClose, content}) => {
    const li_el = useRef(1);
    const[open, set_open] = useState(0);
    const Toggle = (e) => {
        console.log(li_el.current.classList);


        // if(e.currentTarget.classList.length){
        //     e.currentTarget.classList.remove('active');
        // } else {
        //     e.currentTarget.classList.add('active');
        // }

        if(open === e.target.dataset.id){
            set_open(0);
        } else {
            set_open(e.target.dataset.id);
        }
    }

    return (
        <SidePopup show={show} onClose={onClose}>
            <Wrapper>
                <Title>
                    제품 설명
                </Title>
                <Summary dangerouslySetInnerHTML={{
                            __html: (content && content.desc ? content.desc : '')
                        }}>
                    {/* <p>
                        새로운 유형의 ‘핀페시아’는 착색료 퀴놀린 옐로우 (Quinoline Yellow)는 포함되어 있지 않습니다.
                    </p>
                    <p>
                        핀페시아는 피나스테라이드를 유효 성분으로하는 마시는 타입의 AGA (남성형 탈모증) 치료제로 유명한 ‘프로페시아’의 제네릭 의약품입니다.
                    </p> */}
                </Summary>
            </Wrapper>
        </SidePopup>
    );
};

export default Description;