import axios from './defaultClient';

const nameSpace = '/ep/v1/order';

export const OrderGetPosts = async (args) => {
    return await axios.get(nameSpace + '/', {
        params: {
            paged: args.paged,
            posts_per_page: args.posts_per_page,
        },
    });
};

export const OrderGetProducts = async (args) => {
    return await axios.get(nameSpace + '/products/', {
        params: {
            paged: args.paged,
            posts_per_page: args.posts_per_page,
        },
    });
};

export const OrderPost = async (args) => {
    return await axios.post(nameSpace + '/', {
        order_items: args.order_items,
        point: args.point,
        subtotal: args.subtotal,
        coupon_code: args.coupon_code,
        billing_name: args.billing_name,
        billing_email: args.billing_email,
        billing_phone: args.billing_phone,
        address_KEY: args.address_KEY,
        new_address: args.new_address,
        payment_method: args.payment_method,
        is_vr_discount: args.is_vr_discount,
    });
};

export const OrderGetPost = async (args) => {
    return await axios.get(nameSpace + '/' + args.order_ID, {});
};
export const OrderCancel = async (args) => {
    return await axios.post(nameSpace + '/' + args.order_ID + '/cancel/', {});
};

export const OrderGetCoupon = async (args) => {
    return await axios.get(nameSpace + '/coupon/' + args.coupon_code, {
        params: {
            subtotal: args.subtotal,
            cart_items: args.cart_items,
        },
    });
};
export const OrderUploadCert = async (args) => {
    return await axios.post(nameSpace + '/upload/cert', args, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};
