import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MediaQuery, mobileQuery } from '../../components/Grid';
import MainContainer from '../../components/MainContainer';
import Pagination from '../../components/Pagination';
import { SktonProducts } from '../../components/Skeleton';
import { ProductGetPosts } from '../../api/product';
import {
	Wrapper,
	TopSection,
	Keyword,
	SearchResult,
	ProductsWrapper,
	ProductWrapper,
	Product,
	ProductImage,
	ProductDetail,
	ProductType,
	ProductName,
	ProductPrice,
	Empty,
	MoreButtonWrapper,
	MoreButton,
} from './styled';

const Search = () => {
	const isMobile = MediaQuery(mobileQuery);
	const params = useParams();
	const keyword = params.keyword;

	const [current_paged, set_current_paged] = useState(1);
	const [total, set_total] = useState(0);
	const [paged, set_paged] = useState(1);
	const [products, set_products] = useState('');
	const [tmp_keyword, set_keyword] = useState('');
	const [empty, set_empty] = useState(0);
	const posts_per_page = 12;

	const onPaging = (page) => {
		console.log(page);
		set_paged(page);
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		if (tmp_keyword != keyword || current_paged != paged) {
			if (tmp_keyword != keyword) {
				set_total(0);
				set_paged(1);
				// set_current_paged(1);
			}
			set_keyword(keyword);
			set_empty(0);
			set_products();
			ProductGetPosts({
				category_ID: '',
				keyword: keyword,
				posts_per_page: 12,
				paged: paged,
			})
				.then((response) => {
					if (response.data.code == 200) {
						console.log('aaa');
						set_products(response.data.body.products);
						set_current_paged(response.data.body.paged);
						set_total(response.data.body.total);
						console.log(response.data.body.products);
					} else if (response.data.code == 500) {
						set_empty(1);
					}
				})
				.catch((error) => console.log(error));
		}
	});

	return (
		<MainContainer>
			<Wrapper>
				<TopSection>
					<Keyword>검색결과: {keyword}</Keyword>
					<SearchResult>
						"{keyword}"에 대해 총 <span>{total ? total : 0}</span>건이
						검색되었습니다.
					</SearchResult>
				</TopSection>
				<ProductsWrapper>
					{products ? (
						products.map((item, key) => {
							return (
								<ProductWrapper>
									<Product>
										<ProductImage
											url={item.thumbnail ? item.thumbnail : ''}
											to={'/product/' + (item.ID ? item.ID : '')}
										>
											{item?.is_sold_out ? (
												<div className="sold-out">임시품절</div>
											) : null}
										</ProductImage>
										<ProductDetail>
											<ProductType>
												{item.cate_name ? item.cate_name : ''}
											</ProductType>
											<ProductName>{item.title ? item.title : ''}</ProductName>
											<ProductPrice>
												{item.price ? item.price : ''}
											</ProductPrice>
										</ProductDetail>
									</Product>
								</ProductWrapper>
							);
						})
					) : (
						<>
							{empty ? (
								<Empty>검색결과가 없습니다.</Empty>
							) : (
								<>
									<SktonProducts />
									<SktonProducts />
									<SktonProducts />
									<SktonProducts />
									<SktonProducts />
									<SktonProducts />
									<SktonProducts />
									<SktonProducts />
								</>
							)}
						</>
					)}
				</ProductsWrapper>
				<Pagination
					onPaging={onPaging}
					total={total}
					current={current_paged}
					posts_per_page={posts_per_page}
					max={
						total >= posts_per_page ? parseInt(total / posts_per_page) + 1 : 1
					}
				/>
			</Wrapper>
		</MainContainer>
	);
};

export default Search;
