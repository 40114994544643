import React from 'react';
import styled from 'styled-components';

const ParkingStyle = styled.div`
    padding: 30px;
    margin:0px auto;
    max-width:1200px;
    text-align:center;
    h1 {
        margin-bottom:20px;
        font-size:26px;
    }
    p{
        line-height:24px;
        color:#777;
    }
`;

const Parking = () => {
    return (
        <ParkingStyle>
            <h1>서비스 점검중</h1>
            <p>더 좋은 서비스 제공을 위해 서비스 점검중입니다.</p>
            <p>2021-03-25 01:00 ~ 07:00</p>
        </ParkingStyle>
    );
};

export default Parking;