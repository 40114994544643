import styled from 'styled-components';


export const Wrapper = styled.div`
    position: relative;
    height: 100%;
    .button {
        position: absolute;
        bottom: 0;
    }
`;

export const Container = styled.div`
    padding: 50px 50px 50px 50px;
    overflow-y: auto;
    height: 574px;

    font-size: 15px;
    font-weight:300;
    line-height: 28px;
    a {
        text-decoration: underline;
        color: #FF5C75;
    }
    > div > div {
        padding:20px 25px;
        margin-bottom:20px;
        margin-top:10px;
        border-radius:20px;
        background:#f7f7f7;
    }
    strong {
        display: block;
        font-weight: bold;
        font-size: 22px;
        line-height: 25px;
        color: #f82747;
        /* color: #111; */
        /* padding-bottom: 15px; */
        margin-bottom: 20px;
        /* border-bottom: 1px solid #EBEBEB; */
    }
    p {
        font-size: 15px;
        line-height: 28px;
        color: #444;
        margin-bottom: 10px;
        font-weight:300;
    }
    span {
        display: block;
        font-size: 15px;
        font-weight:300;
        line-height: 28px;
        color: #444;
        margin-bottom: 10px;
    }
    @media ${props => props.theme.mobile}{
        padding: 50px 20px 0 20px;
        height: calc(100% - 60px);
        overflow-x: hidden;
        overflow-y: auto;

    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    button {
        width: 50%;
        height: 60px;
        background: #eee;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #000;
        border: none;
    }
    button:first-child {
        :after{
            display: block;
            position: absolute;
            content: "";
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 50%;
            height: 30px;
            border-right: 1px solid rgba(255, 255, 255, 0.15);
        }
    }
    button:last-child {
        background:#000;
        color:#fff;
    }
`;