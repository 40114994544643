import styled from 'styled-components';

export const InputSelectWrapper = styled.div`
    display:inline-block;
    vertical-align:top;
    position:relative;
    width: ${props => props.width ? props.width : '75%'}; 
    @media(max-width: 360px) {
        width: ${props => props.width ? props.width : '70%'};
    }
    &:after{
        display:none !important;
    }
`;
export const Arrow = styled.label`
    z-index: 2;
    display:block;
    position: absolute;
    transform: rotate(0deg) translateX(0%);
    position: absolute;
    top: 25%;
    right: 10px;
    &:after{
        display:none;
    }
`;

export const StyleInputSelect = styled.select`
    z-index: 1;
    position: relative;
    background: #FFFFFF;
    width: ${props => props.width ? props.width : '100%'}; 
    padding: 9px 0px 9px 9px; 
    height: 40px;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "NotoSansKR";
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #667281;
    cursor: pointer;
`;