import axios from './defaultClient';

const nameSpace = '/ep/v1/faq';

export const FaqGetPosts = async (args) => {
    return await axios.get( nameSpace + '/',{
        params:{
            posts_per_page: args.posts_per_page,
            paged: args.paged,
            category_ID: args.category_ID
        }
    })
}
