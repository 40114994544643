import React, { useState, useEffect } from 'react';
import Joi from 'joi-browser';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SetLoading } from '../../../../../store/global';
import Popup from '../../../../../components/Popup';
import TextArea from '../../../../../components/TextArea';
import LoadingRound from '../../../../../components/LoadingRound';
import { Br, MediaQuery, mobileQuery } from '../../../../../components/Grid';
import {
  ReviewInit,
  ReviewInsertPost,
  ReviewInsertImage,
  ReviewGetGallery,
  ReviewDeleteImage,
} from '../../../../../api/review';
import {
  Wrapper,
  ReviewTitle,
  Review,
  ReviewImg,
  ReviewItem,
  Write,
  ReviewGallery,
  GalleryList,
  Gallery,
  Upload,
  UploadButton,
  ButtonWrapper,
  Placeholder,
  Count,
} from './styled';

const WriteReviewPopup = props => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const [review_title, set_review_title] = useState('');
  const [review_content, set_review_content] = useState('');
  const [tmp_review_item, init_tmp_review] = useState('');
  const [current_review_form, set_current_review_form] = useState('');
  const [gallery, set_gallery] = useState('');
  const [gallery_count, set_gallery_count] = useState(0);
  const gallery_icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#9A9A9E"
        d="M22.166 11.666c-.31 0-.606.123-.825.342-.218.219-.341.516-.341.825v3.943l-1.727-1.726c-.61-.605-1.434-.945-2.293-.945-.858 0-1.682.34-2.292.945l-.817.828-2.893-2.905c-.61-.605-1.434-.944-2.292-.944-.86 0-1.683.34-2.293.944l-1.727 1.738V8.166c0-.31.123-.606.342-.825.219-.218.516-.341.825-.341h9.333c.31 0 .607-.123.825-.342.22-.219.342-.516.342-.825 0-.31-.123-.606-.342-.825-.218-.219-.515-.342-.825-.342H5.833c-.928 0-1.818.37-2.475 1.025-.656.657-1.025 1.547-1.025 2.475v14.257c.003.86.346 1.682.953 2.29.608.608 1.431.95 2.29.953H20.09c.316-.002.63-.05.933-.14.674-.189 1.267-.593 1.688-1.152.422-.558.648-1.24.645-1.94v-9.601c0-.155-.03-.309-.09-.452-.06-.143-.149-.273-.26-.381-.11-.109-.242-.194-.386-.252-.144-.057-.299-.085-.454-.082zM5.833 23.333c-.31 0-.606-.123-.825-.342-.219-.218-.342-.515-.342-.825v-4.165l3.372-3.371c.17-.17.401-.265.642-.265.24 0 .47.095.641.265l8.715 8.703H5.833zM21 22.166c-.008.226-.08.445-.21.63L15.516 17.5l.829-.817c.083-.085.183-.153.293-.2.11-.046.229-.07.348-.07.12 0 .238.024.348.07.11.047.21.115.294.2L21 20.078v2.088zm3.5-17.5h-1.167V3.5c0-.31-.123-.606-.342-.825-.218-.22-.515-.342-.825-.342-.31 0-.606.123-.825.342-.218.219-.341.515-.341.825v1.166h-1.167c-.31 0-.606.123-.825.342-.219.219-.342.516-.342.825 0 .31.123.606.342.825.219.219.516.342.825.342H21v1.166c0 .31.123.607.341.825.22.22.516.342.825.342.31 0 .607-.123.825-.342.22-.218.342-.515.342-.825V7H24.5c.31 0 .606-.123.825-.342.218-.219.341-.516.341-.825 0-.31-.123-.606-.341-.825-.22-.219-.516-.342-.825-.342z"
      />
    </svg>
  );

  const [count, set_count] = useState(0);

  const isMobile = MediaQuery(mobileQuery);

  const UploadGallery = e => {
    const fd = new FormData();
    //FormData에 key, value 추가하기
    // console.log(e.target.files);
    Array.from(e.target.files).map((file, key) => {
      fd.append('image_array[]', file);
    });

    Dispatch(SetLoading(1));
    ReviewInsertImage(fd, tmp_review_item.tmp_review_ID)
      .then(response => {
        if (response.data.code == '200') {
          LoadGallery();
        }
      })
      .catch(error => console.log(error));
  };

  const LoadGallery = () => {
    ReviewGetGallery({
      review_ID: tmp_review_item.tmp_review_ID,
    })
      .then(response => {
        if (response.data.code == '200') {
          set_gallery(response.data.body.gallery);
          set_gallery_count(response.data.body.count);
          Dispatch(SetLoading(0));
        } else {
          set_gallery('');
          Dispatch(SetLoading(0));
        }
      })
      .catch(err => console.log(err));
  };

  const DeleteGalleryItem = e => {
    console.log(e.target.dataset.id);
    Dispatch(SetLoading(1));
    if (e.target.dataset.id) {
      ReviewDeleteImage({
        attachment_ID: e.target.dataset.id,
      })
        .then(response => {
          if (response.data.code == '200') {
            LoadGallery();
          }
        })
        .catch(err => console.log(err));
    }
  };

  const InsertReview = e => {
    const schema = {
      review_title: Joi.string()
        .required()
        .error(() => ({ message: '제목을 입력해주세요.' })),
      review_content: Joi.string()
        .required()
        .error(() => ({ message: '내용을 입력해주세요.' })),
    };
    const { error, value } = Joi.validate(
      {
        review_title: review_title,
        review_content: review_content,
      },
      schema
    );

    if (error) {
      toast.error(error.details[0].message);
    } else {
      Dispatch(SetLoading(1));
      ReviewInsertPost({
        tmp_review_ID: tmp_review_item.tmp_review_ID,
        post_title: review_title,
        post_content: review_content,
      })
        .then(response => {
          Dispatch(SetLoading(0));
          if (response.data.code == '200') {
            toast.success('리뷰 작성이 완료되었습니다!');
            // toast.success('리뷰를 작성하여 포인트가 적립되었습니다!');
            History.push('/review/' + tmp_review_item.tmp_review_ID);
          } else {
            toast.error(response.data.body.message);
          }
        })
        .catch(err => {
          toast.error('서버 요청 오류!');
          Dispatch(SetLoading(0));
        });
    }
  };

  useEffect(() => {
    if (props.order_ID) {
      if (current_review_form != props.order_ID + '' + props.product_ID) {
        set_current_review_form(props.order_ID + '' + props.product_ID);

        if (!tmp_review_item) {
          ReviewInit({
            product_ID: props.product_ID,
            product_name: props.product_name,
            order_ID: props.order_ID,
          })
            .then(response => {
              if (response.data.code == '200') {
                init_tmp_review(response.data.body);
              } else {
                toast.error(response.data.body.message);
              }
            })
            .catch(err => {
              toast.error('서버 요청오류!');
              console.log(err);
            });
        }
      }
    }
  });
  const [focus, set_focus] = useState();
  const textareaHandler = e => {
    set_review_content(e.target.value);
    set_count(e.target.value.length);
    if (e.target.value.length) {
      set_focus(1);
    } else {
      set_focus(0);
    }
  };

  if (!tmp_review_item)
    return (
      <Popup show={props.show} onClose={props.onClose} width="500px;">
        <ReviewTitle>
          <h2>리뷰 작성하기</h2>
        </ReviewTitle>
        <Wrapper>
          <LoadingRound />
        </Wrapper>
      </Popup>
    );

  return (
    <>
      <Popup
        show={props.show}
        onClose={props.onClose}
        width="500px"
        height="auto"
      >
        <ReviewTitle>
          <h2>리뷰 작성하기</h2>
        </ReviewTitle>
        <Wrapper>
          <Review>
            <ReviewImg url={tmp_review_item.product_thumbnail} />
            <ReviewItem>
              <span>주문일: {tmp_review_item.order_date}</span>
              <p>{tmp_review_item.product_name}</p>
            </ReviewItem>
          </Review>
          <Write focus={focus}>
            <input
              class="text"
              type="text"
              onChange={e => set_review_title(e.target.value)}
              value={review_title}
              placeholder="리뷰 제목을 입력해주세요."
            />
            <TextArea
              class="textarea"
              onChange={textareaHandler}
              value={review_content}
              placeholder=""
              type="textarea"
              maxLength="800"
              height="200px"
            ></TextArea>
            <Placeholder>
              <div>리뷰 내용을 입력해주세요.</div>
              <span>이용후기는 1번만 참여 가능합니다.</span>
              <small>
                1. 사진 없는 후기 3,000포인트
                <br />
                2. 사진 후기 5,000포인트
                <br />
                3. 사진+500자 이상 후기 10,000포인트
                <br />
                4. 프리미엄 후기 30,000포인트 (리뷰 내용에 블로그 주소를
                입력해주세요.)
              </small>
            </Placeholder>
            <Count>{count} / 500</Count>
          </Write>
          <ReviewGallery>
            <GalleryList>
              <label for="add" className="box">
                <Upload>
                  <input
                    id="add"
                    type="file"
                    name="upload[]"
                    onChange={UploadGallery}
                    multiple
                  />
                  {gallery_icon}
                  <p>
                    <span>{gallery_count}</span>/∞
                  </p>
                </Upload>
              </label>
              {gallery
                ? gallery.map((gallery_item, key) => (
                    <div className="box">
                      <Gallery url={gallery_item.thumb_image_url} />
                      <a
                        onClick={DeleteGalleryItem}
                        data-id={gallery_item.attachment_ID}
                      ></a>
                    </div>
                  ))
                : ''}
            </GalleryList>
          </ReviewGallery>
        </Wrapper>
        <ButtonWrapper className="button">
          <button onClick={props.onClose}>닫기</button>
          <button onClick={InsertReview}>등록하기</button>
        </ButtonWrapper>
      </Popup>
    </>
  );
};

export default WriteReviewPopup;
