import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainContainer from '../../../components/MainContainer'
import { MediaQuery, mobileQuery} from '../../../components/Grid';
import { StoryGetPost } from '../../../api/story';
import {
    Wrapper,
    StorySingleContainer,
    StoryImageSection,
    StoryImage,
    StoryDetail,
    StoryBadge,
    StoryDate,
    StoryTitle,
    StorySubTitle,
    Profile,
    Avartar,
    Relative,
    RelativeTitle,
    ProductList,
    ProductImage,
    ProductDetail,
    ProductPrice,
    StoryButton
} from './styled';
import { Helmet } from 'react-helmet';


const StorySingle = () => {
    
    const isMobile = MediaQuery(mobileQuery);
    const params = useParams();
    const [story, set_story] = useState('');

    useEffect(() => {
        if(!story) {
            StoryGetPost({
                story_ID: params.ID
            })
            .then(response => {
                if(response.data.code == 200){
                    set_story(response.data.body);
                }
            })
            .catch((error => console.log(error)))
        }
    })
    
    return (
        <>
            <Helmet>
                <title>{story.post_title ? story.post_title : '스토리 - 사쿠라허브'}</title>
                <meta
                    name="description"
                    content={story.description ? story.description : '안녕하세요. 사쿠라허브입니다 :)'}
                />
                <meta property="og:title" content={story.post_title ? story.post_title : '스토리 - 사쿠라허브'}/>
                <meta property="og:image" content={story.thumbnail ? story.thumbnail : 'https://wp.sakura-herb.com/wp-content/uploads/2021/11/noimg.png'}/>
                <meta property="og:url" content={"https://www.sakuraherb.com/story/single/"+params.ID}/>
                <meta property="og:description" content={story.description ? story.description : '안녕하세요. 사쿠라허브입니다 :)'}/>
            </Helmet>
            <MainContainer>
                <Wrapper>
                    <StorySingleContainer>
                        <StoryImageSection>
                            <StoryImage dangerouslySetInnerHTML={{
                                    __html: story.post_content
                                }}>
                            </StoryImage>
                        </StoryImageSection>
                        <StoryDetail>
                            <StoryBadge>
                                {story.cate_name ? story.cate_name : ''}
                            </StoryBadge>
                            <StoryDate>
                                {story.post_date ? story.post_date : ''}
                            </StoryDate>
                            <StoryTitle>
                                {story.post_title ? story.post_title : ''}
                            </StoryTitle>
                            <Profile>
                                <Avartar />
                                <span>사쿠라허브</span>
                            </Profile>
                            {isMobile ? (
                                ''
                            ) : 
                                <>
                                    {/* <Relative>
                                        <RelativeTitle>관련상품</RelativeTitle>
                                        <ProductList>
                                            <ProductImage />
                                            <ProductDetail>
                                                <span>
                                                    득모+불끈<br/>
                                                    이버멕틴6mg+알벤다졸400mg
                                                </span>
                                            </ProductDetail>
                                            <ProductPrice>
                                                <span>120,000원</span>
                                            </ProductPrice>
                                        </ProductList>
                                        <ProductList>
                                            <ProductImage />
                                            <ProductDetail>
                                                <span>
                                                    득모+불끈<br/>
                                                    이버멕틴6mg+알벤다졸400mg
                                                </span>
                                            </ProductDetail>
                                            <ProductPrice>
                                                <span>120,000원</span>
                                            </ProductPrice>
                                        </ProductList>
                                        <ProductList>
                                            <ProductImage />
                                            <ProductDetail>
                                                <span>
                                                    득모+불끈<br/>
                                                    이버멕틴6mg+알벤다졸400mg
                                                </span>
                                            </ProductDetail>
                                            <ProductPrice>
                                                <span>120,000원</span>
                                            </ProductPrice>
                                        </ProductList>
                                    </Relative> */}
                                    <StoryButton to="/story/tip">
                                        목록으로
                                    </StoryButton>
                                </>
                            }
                        </StoryDetail>
                    </StorySingleContainer>
                    {!isMobile ? (
                            ''
                        ) : 
                            <>
                                {/* <Relative>
                                    <RelativeTitle>관련상품</RelativeTitle>
                                    <ProductList>
                                        <ProductImage />
                                        <ProductDetail>
                                            <span>
                                                득모+불끈<br/>
                                                이버멕틴6mg+알벤다졸400mg
                                            </span>
                                        </ProductDetail>
                                        <ProductPrice>
                                            <span>120,000원</span>
                                        </ProductPrice>
                                    </ProductList>
                                    <ProductList>
                                        <ProductImage />
                                        <ProductDetail>
                                            <span>
                                                득모+불끈<br/>
                                                이버멕틴6mg+알벤다졸400mg
                                            </span>
                                        </ProductDetail>
                                        <ProductPrice>
                                            <span>120,000원</span>
                                        </ProductPrice>
                                    </ProductList>
                                    <ProductList>
                                        <ProductImage />
                                        <ProductDetail>
                                            <span>
                                                득모+불끈<br/>
                                                이버멕틴6mg+알벤다졸400mg
                                            </span>
                                        </ProductDetail>
                                        <ProductPrice>
                                            <span>120,000원</span>
                                        </ProductPrice>
                                    </ProductList>
                                </Relative> */}
                                <StoryButton to="/story/tip">
                                    목록으로
                                </StoryButton>
                            </>
                    }
                </Wrapper>
            </MainContainer>
        </>
    );
};

export default StorySingle;