import React, { useState, useEffect } from 'react';
import LoadingRound from '../../components/LoadingRound';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { SetLoading, ToggleChatbot } from '../../store/global';
import Popup from '../../components/Popup';
import {toast} from 'react-toastify';
import Joi from 'joi-browser';
import { ChatbotAction } from '../../api/chatbot';
import {
    Wrapper,
    BackButton,
    CloseButton,
    InquireBox,
    TopSection,
    Title,
    PageNum,
    InquireTextarea,
    InquireInput,
    Submit
} from './styled';
import ListTemplate from './components/List';
import QnaTemplate from './components/Qna';
import OrdersTemplate from './components/Orders';
import ContentViewTemplate from './components/ContentView';
import OrderViewListTemplate from './components/OrderViewList';
import Loading from '../../components/Loading';

const Chatbot = (props) => {
    const Dispatch = useDispatch();
    const me = useSelector(state=>state.me.user_data);
    const [shipping_days, set_shipping_days] = useState('');
    const [back_action, set_back_action] = useState('');
    const [type, set_type] = useState('list');
    const [list_type, set_list_type] = useState('list');
    const [title, set_title] = useState('안녕하세요 '+me.user_name+' 고객님,<br/>사쿠라허브입니다. 무엇을 도와드릴까요?');
    const [subtitle, set_subtitle] = useState();
    const [openchat, set_openchat] = useState(0);
    const [actions, set_actions] = useState([
        {
            action_ID: 'question_orders',
            label: '주문한 상품에 대해 문의하고 싶어요.',
        },
        {
            action_ID: 'faq_start',
            label: '이 외 다른 문의가 있어요.',
        },
    ]);
    const DoAction = (action_ID, action_props) => { 
        Dispatch(SetLoading(1));
        set_title('');

        if(action_ID == 'intro') {
            set_title('안녕하세요 '+me.user_name+' 고객님,<br/>사쿠라허브입니다. 무엇을 도와드릴까요?');
            set_type('list');
            set_list_type('list');
            Dispatch(SetLoading(0));
            set_back_action('');
            set_actions([
                {
                    action_ID: 'question_orders',
                    label: '주문한 상품에 대해 문의하고 싶어요.',
                },
                {
                    action_ID: 'faq_start',
                    label: '이 외 다른 문의가 있어요.',
                },
            ]);
            return;
        }

        if(action_ID == 'done') {
            Dispatch(SetLoading(0));
            set_title('안녕하세요 '+me.user_name+' 고객님,<br/>사쿠라허브입니다. 무엇을 도와드릴까요?');
            set_type('list');
            set_list_type('list');
            set_back_action('');
            set_actions([
                {
                    action_ID: 'question_orders',
                    label: '주문한 상품에 대해 문의하고 싶어요.',
                },
                {
                    action_ID: 'faq_start',
                    label: '이 외 다른 문의가 있어요.',
                },
            ]);
            Dispatch(ToggleChatbot());
            Dispatch(SetLoading(0));
            return;
        }

        if(action_ID == 'qna_start') {
            set_type('qna');
            set_title('문의 내용을 작성해주세요.');
            Dispatch(SetLoading(0));
            return;
        }

        ChatbotAction ({
            action_ID: action_ID,
            props: action_props,
        })
        .then((response) => {
            Dispatch(SetLoading(0));
            if(response.data.code == '200') {
                // if(action_ID == 'faq_start'){
                //     set_title('문의 유형을 선택해주세요.');
                // }
                if(response.data.body.type == 'list') {
                    set_list_type(response.data.body.actions.type);
                }
                if(response.data.body.back) {
                    set_back_action(response.data.body.back);
                } else {
                    set_back_action('');
                }

                if(response.data.body.actions.shipping_days){
                    set_shipping_days(response.data.body.actions.shipping_days);
                } else {
                    set_shipping_days('');
                }

                if(response.data.body.type)
                    set_type(response.data.body.type);

                if(response.data.body.type == 'content_view' || response.data.body.type == 'orders') {
                    set_actions(response.data.body.actions);
                }else{
                    set_actions(response.data.body.actions.actions);
                }
                set_title(response.data.body.title);
                set_openchat(response.data.body.openchat);
            } else {
                alert(response.data.body.message)
            }
            console.log(response.data.body.openchat);
            console.log('1');
        })
        .catch((error) => {console.log(error); Dispatch(SetLoading(0));})

    }

    const TemplateReducer = () => {
        switch (type) {
            case 'list':
                return (<ListTemplate list_type={list_type} actions={actions} back={back_action} DoAction={DoAction} set_subtitle={set_subtitle}/>);
            break;
            case 'qna':
                return (<QnaTemplate DoAction={DoAction}/>);
            break;
            case 'orders':
                return (<OrdersTemplate actions={actions} back={back_action} DoAction={DoAction}/>);
            break;
            case 'order_view':
                return (<OrderViewListTemplate actions={actions} shipping_days={shipping_days} DoAction={DoAction} back={back_action} set_subtitle={set_subtitle}/>);
            case 'content_view':
                return (<ContentViewTemplate actions={actions} DoAction={DoAction} back={back_action} openchat={openchat} set_subtitle={set_subtitle}/>);
        }
    }
    
    const OnClose = e => {
        Dispatch(ToggleChatbot());
    }

    return (
        <Popup show={props.show} onClose={OnClose} full={true}>
            <Wrapper>
                {/* <BackButton onClick={props.onClose} src={process.env.PUBLIC_URL + "/img/arrow-back.svg"} /> */}
                <CloseButton onClick={OnClose} src={process.env.PUBLIC_URL + "/img/close-button.svg"} />
                <InquireBox>
                    <TopSection>
                        <PageNum>
                            {/* {subtitle} */}
                        </PageNum>
                        {title ? (
                            <Title dangerouslySetInnerHTML={ {__html: title}}/>
                        ) : (
                            <Title>
                                <LoadingRound/>
                            </Title>
                        )}
                        
                        {title && type ? TemplateReducer() : ''}
                    </TopSection>
                </InquireBox>
            </Wrapper>
        </Popup>
    );
};

export default Chatbot;