import styled from 'styled-components';

export const LoadingRoundStyle = styled.div`
    width: 45px;
    height: 45px;
    border: 1px solid #ddd;
    border-top: 2px solid #111;
    border-radius: 50%;
    animation: circle 1.4s linear infinite;
    box-sizing: border-box;
    @keyframes circle {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
