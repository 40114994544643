import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 200px;
    @media ${props => props.theme.mobile}{
        padding-top: 50px;
        padding-bottom: 50px;
    }
`;

export const FixedSection = styled.div`
    @media ${props => props.theme.mobile}{
        padding: 0 20px;
    }
`;

export  const TopSection = styled.div`
    /* justify-content: space-between; */
    margin-bottom: 60px;
    @media ${props => props.theme.mobile}{
        display: block;
        margin-bottom: 40px;
    }
`;

export const Title = styled.div`
    font-size: 27px;
    line-height: 42px;
    font-weight: bold;
    color: #111;
    @media ${props => props.theme.mobile}{
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 30px;
    }
`;

export const LinkArea = styled.div`
    margin-top: 30px;
    a{  
        display: inline-block;
        width: 200px;
        height: 50px;
        font-weight: 500;
        font-size: 13px;
        line-height: 50px;
        text-align: center;
        color: #111;
        border-radius: 200px;
    }
    .openchat{
        background: #FBE54D;
        img {
            position: relative;
            top: -3px;
            width: 24px;
            height: auto;
            margin-right: 5px;
        }
    }
    .paymentGuide{
        background: #3c3c3c;
        color: #fff;
        margin-left: 10px;
        svg {
            position: relative;
            bottom: -2px;
        }
    }
    .oneon {
        background: #F5F5F5;
        margin-left: 10px;
        img {
            position: relative;
            top: -3px;
            width: 20px;
            height: auto;
            margin-right: 5px;
        }
    }
    @media ${props => props.theme.mobile}{
        display: flex;
        justify-content: space-between;
        .openchat{
           transform: scale(0.95, 0.95);
        }
        .oneon {
            transform: scale(0.95, 0.95);
        }
    }
`;

export const TabWrapper = styled.ul`
    display: inline-block;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    border-bottom: 1px solid #111;
    padding-bottom: 20px;
    .active {
        a{
            color: #111;
        }
    }
    @media ${props => props.theme.mobile}{
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 12px;
        li:last-child {
            margin-right: 0px;
        }
    }
`;

export const Tab = styled.li`
    float: left;
    margin-right: 30px;
    a {
        color: #9A9A9E;
    }
    img {
        position: relative;
        left: 7px;
        bottom: 3px;
    }
    @media ${props => props.theme.mobile}{
        margin-right: 16px;
    }
`;