import React, {useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {SetLoading} from '../../../../store/global';
import Popup from '../../../../components/Popup';
import Button from '../../../../components/Button';
import {
    Wrapper,
    PopupContainer,
    Title,
    Write,
    ButtonWrapper,
    CancelButton,
    WriteButton,
    Name,
    Load,
    ImageArea,
    CiImage,
    Example,
    ExampleImage,
    Information
} from './styled';
import { OrderUploadCert } from '../../../../api/order';
const pdf_icon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path fill="#6A36FF" d="M16.875 6.875c0-.083-.016-.166-.049-.243-.032-.077-.08-.146-.14-.204l-4.37-4.37-.019-.018-.025-.023-.019-.013-.03-.023-.016-.01-.037-.022-.01-.005c-.015-.008-.03-.015-.046-.021l-.007-.003c-.016-.007-.033-.013-.05-.018l-.008-.002c-.017-.005-.034-.01-.051-.013l-.017-.002-.044-.007c-.02-.002-.041-.003-.062-.003H4.374c-.331 0-.65.132-.883.367-.235.234-.367.552-.367.883v13.75c0 .331.132.65.367.884.234.234.552.366.883.366h11.25c.332 0 .65-.132.884-.366.235-.235.366-.553.367-.884V6.887v-.012zM12.5 4.009l2.24 2.241H12.5V4.009zm3.125 12.866H4.374V3.125h6.876v3.75c0 .166.066.325.183.442.117.117.276.183.442.183h3.75v9.375zm-3.125-5c0 .166-.066.325-.183.442-.118.117-.277.183-.442.183h-1.25v1.25c0 .166-.066.325-.183.442-.118.117-.277.183-.442.183-.166 0-.325-.066-.442-.183-.117-.117-.183-.276-.183-.442V12.5h-1.25c-.166 0-.325-.066-.442-.183-.117-.117-.183-.276-.183-.442 0-.166.066-.325.183-.442.117-.117.276-.183.442-.183h1.25V10c0-.166.066-.325.183-.442.117-.117.276-.183.442-.183.165 0 .324.066.442.183.117.117.183.276.183.442v1.25h1.25c.165 0 .324.066.442.183.117.117.183.276.183.442z"/>
</svg>
const UploadCertModal = (props) => {
    const Dispatch = useDispatch();
	const [img_file, set_img_file] = useState('');
	const [ci_url, set_ci_url] = useState('');

    const gallery_icon = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28">
        <path fill="#9A9A9E" d="M22.166 11.666c-.31 0-.606.123-.825.342-.218.219-.341.516-.341.825v3.943l-1.727-1.726c-.61-.605-1.434-.945-2.293-.945-.858 0-1.682.34-2.292.945l-.817.828-2.893-2.905c-.61-.605-1.434-.944-2.292-.944-.86 0-1.683.34-2.293.944l-1.727 1.738V8.166c0-.31.123-.606.342-.825.219-.218.516-.341.825-.341h9.333c.31 0 .607-.123.825-.342.22-.219.342-.516.342-.825 0-.31-.123-.606-.342-.825-.218-.219-.515-.342-.825-.342H5.833c-.928 0-1.818.37-2.475 1.025-.656.657-1.025 1.547-1.025 2.475v14.257c.003.86.346 1.682.953 2.29.608.608 1.431.95 2.29.953H20.09c.316-.002.63-.05.933-.14.674-.189 1.267-.593 1.688-1.152.422-.558.648-1.24.645-1.94v-9.601c0-.155-.03-.309-.09-.452-.06-.143-.149-.273-.26-.381-.11-.109-.242-.194-.386-.252-.144-.057-.299-.085-.454-.082zM5.833 23.333c-.31 0-.606-.123-.825-.342-.219-.218-.342-.515-.342-.825v-4.165l3.372-3.371c.17-.17.401-.265.642-.265.24 0 .47.095.641.265l8.715 8.703H5.833zM21 22.166c-.008.226-.08.445-.21.63L15.516 17.5l.829-.817c.083-.085.183-.153.293-.2.11-.046.229-.07.348-.07.12 0 .238.024.348.07.11.047.21.115.294.2L21 20.078v2.088zm3.5-17.5h-1.167V3.5c0-.31-.123-.606-.342-.825-.218-.22-.515-.342-.825-.342-.31 0-.606.123-.825.342-.218.219-.341.515-.341.825v1.166h-1.167c-.31 0-.606.123-.825.342-.219.219-.342.516-.342.825 0 .31.123.606.342.825.219.219.516.342.825.342H21v1.166c0 .31.123.607.341.825.22.22.516.342.825.342.31 0 .607-.123.825-.342.22-.218.342-.515.342-.825V7H24.5c.31 0 .606-.123.825-.342.218-.219.341-.516.341-.825 0-.31-.123-.606-.341-.825-.22-.219-.516-.342-.825-.342z"/>
    </svg>
    
    const review_icon =<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none" viewBox="0 0 13 13">
            <g clip-path="url(#clip0)">
                <path fill="#DBDBDB" d="M2.935 12.548c-.314.16-.67-.121-.606-.481l.674-3.843L.14 5.497c-.267-.255-.128-.722.23-.772l3.98-.565L6.125.644c.16-.317.593-.317.753 0L8.652 4.16l3.98.565c.358.05.497.517.229.772L9.999 8.224l.675 3.843c.063.36-.293.642-.606.48L6.5 10.716l-3.566 1.833z"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H13V13H0z"/>
                </clipPath>
            </defs>
        </svg>


    const InsertReview = (e) => {
        const fd = new FormData();

        fd.append('orderId', props.orderId);
        Array.from(img_file).map((img_file, key) => {
            fd.append('certImage', img_file);
        })

        Dispatch(SetLoading(1));
        OrderUploadCert(fd)
        .then(response => {
            Dispatch(SetLoading(0));
            if(response.data.code == '200') {
                toast.success('이체확인증이 정상 처리되었습니다.');
                window.location.reload();
            } else {
                toast.error(response.data.body.message);
            }
        })
        .catch(err => {
            toast.error('서버 요청 오류!');
            Dispatch(SetLoading(0));
        });
    

    }

    useEffect(()=>{
        // Dispatch(SetLoading(1));

    })


	const uploadFile = (e) => {

		set_img_file(e.target.files);
		if(e.target.files){
			set_ci_url(URL.createObjectURL(e.target.files[0]));
		} // background-image 일떄
	}




    return (
        <>
            <Popup show={props.show} onClose={props.onClose} width="500px" height="auto">
                <PopupContainer>
                    <Title>
                        <h2>해외송금확인증 첨부하기</h2>
                    </Title>
                    <Wrapper>
						<Information>
							<Name className="ci"><small>송금금액과 송금인이 잘 보이도록 전체를 첨부해주세요.</small><br/><small><strong>첨부가 안 되는 경우 고객센터 1:1 문의를 통해 첨부해 주세요.</strong></small></Name>
							<Load > 
								<label for="imgUpload" className="load">
									{pdf_icon}
									이미지 첨부하기
								</label>
							</Load>
							<input accept="image/*" type='file' id="imgUpload" className="imgUpload" onChange={uploadFile} />
							{ci_url ? 
                                <ImageArea>
                                    <CiImage url={ci_url}/>
                                </ImageArea>
                            : ''}
                            <Example>
                                <p>예시 이미지 :</p>
                                <ImageArea>
                                    <ExampleImage url={'/img/cert-example.jpg'}/>
                                </ImageArea>
                            </Example>
							{/* <label for="up_load" className="load">
								{pdf_icon}
								파일 첨부하기
							</label>
							<input ref={ref} className="load_file" type="file" accept="image" id="up_load" name="upload[]" onChange={UploadFile} /> */}
							{/* <div className="ci_image">
								{is_file ? is_file : ''}
							</div> */}
						</Information>
                    </Wrapper>
                    <ButtonWrapper>
                        <CancelButton onClick={props.onClose}>
                            <Button>취소</Button>
                        </CancelButton>
                        <WriteButton onClick={InsertReview}>
                            <Button>등록하기</Button>
                        </WriteButton>
                        {/* <button onClick={props.onClose}>취소</button>
                        <button onClick={InsertReview}>등록하기</button> */}
                    </ButtonWrapper>
                </PopupContainer>
            </Popup>
        </>
    );
};

export default UploadCertModal;