import styled from 'styled-components';
import { color } from '../../theme.jsx';
import { Link } from 'react-router-dom';

export const StyledButton = styled(Link)`
    display: block;
    width: ${props => props.width ? props.width : "100%"};
    height: ${props => props.height ? props.height : "56px"};
    line-height: ${props => props.height ? props.height : "56px"};
    border-radius: ${props => props.type === 'normal' ? '' : '200px'};
    background-color: ${props => color[props.color]};
    color: ${props => props.color === 'primary' || props.color === 'black' ? '#fff' : '#111'};
    font-size: 15px;
    font-weight: 500;
    transition: 0.5s;
    text-align: center;
    text-decoration: none;
`;