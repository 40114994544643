import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { category } from '../../theme';
import LoadingRound from '../../components/LoadingRound';
import { GlobalCategory } from '../../api/global';
import {
	SideMenuWrapper,
	Overlay,
	SideMenuInner,
	CloseButton,
	Title,
	Category,
	SubCategory,
	IsLogin,
	MobileSideScroll,
	OtherMenu,
	LoadingRoundStyle,
} from './styled';

const SideMenu = ({ show, onClose }) => {
	const me = useSelector((state) => state.me.user_data);
	const [cate_ID, set_cate_ID] = useState(1);
	const [global_category, set_global_category] = useState('');
	const [global_categories, set_global_categories] = useState('');
	const [tmp, set_tmp] = useState('');

	const hoverCategory = (e) => {
		if (cate_ID != e.target.dataset.id) {
			set_global_categories();
			set_global_category();
			set_cate_ID(e.target.dataset.id);
		}
	};

	useEffect(() => {
		if (tmp !== cate_ID) {
			set_tmp(cate_ID);
			if (cate_ID) {
				set_global_categories(0);
				GlobalCategory({
					category_ID: cate_ID,
				})
					.then((response) => {
						if (response.data.code == 200) {
							set_global_category(response.data.body);
							set_global_categories(response.data.body.categories);
						}
					})
					.catch((error) => console.log(error));
			}
		}
	});

	return (
		<SideMenuWrapper show={show}>
			<Overlay show={show} onClick={onClose} />
			<SideMenuInner show={show}>
				{me ? (
					<IsLogin to="/mypage" className="mobile">
						{me.user_name}님 환영합니다.{' '}
						<strong>
							<span></span>MY
						</strong>
					</IsLogin>
				) : (
					<IsLogin to="/login" className="mobile">
						로그인이 필요합니다.
					</IsLogin>
				)}
				<CloseButton onClick={onClose}>
					<div />
					<div />
				</CloseButton>
				<MobileSideScroll>
					<Title className="main" onMouseOver={hoverCategory}>
						카테고리
					</Title>
					<Category show={show}>
						<li>
							<Link
								onClick={onClose}
								to="/products/20"
								onMouseOver={hoverCategory}
								data-id="20"
							>
								{category.hair}
								득모상품
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>득모상품</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/20">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						{(me && me.user_role != 'subscriber') || 1 ? (
							<>
								<li>
									<Link
										onClick={onClose}
										to="/products/23"
										onMouseOver={hoverCategory}
										data-id="23"
									>
										{category.fire}
										불끈상품
										<span className="arrow">{category.arrow}</span>
									</Link>
									<SubCategory>
										<Title>불끈상품</Title>
										<ul className="sub_ul">
											<li>
												<Link onClick={onClose} to="/products/23">
													전체보기
												</Link>
											</li>
											{global_categories ? (
												global_categories.map((item, key) => {
													return (
														<>
															<li>
																<Link
																	onClick={onClose}
																	to={'/products/' + (item.ID ? item.ID : '')}
																>
																	{item.name ? item.name : ''}
																</Link>
															</li>
														</>
													);
												})
											) : (
												<>
													{global_category ? (
														''
													) : (
														<LoadingRoundStyle>
															<LoadingRound />
														</LoadingRoundStyle>
													)}
												</>
											)}
										</ul>
									</SubCategory>
								</li>
								<li>
									<Link
										onClick={onClose}
										to="/products/699"
										onMouseOver={hoverCategory}
										data-id="699"
									>
										{category.fire}
										득모+불끈
										<span className="arrow">{category.arrow}</span>
									</Link>
									<SubCategory>
										<Title>득모+불끈</Title>
										<ul className="sub_ul">
											<li>
												<Link onClick={onClose} to="/products/699">
													전체보기
												</Link>
											</li>
											{global_categories ? (
												global_categories.map((item, key) => {
													return (
														<>
															<li>
																<Link
																	onClick={onClose}
																	to={'/products/' + (item.ID ? item.ID : '')}
																>
																	{item.name ? item.name : ''}
																</Link>
															</li>
														</>
													);
												})
											) : (
												<>
													{global_category ? (
														''
													) : (
														<LoadingRoundStyle>
															<LoadingRound />
														</LoadingRoundStyle>
													)}
												</>
											)}
										</ul>
									</SubCategory>
								</li>
							</>
						) : (
							''
						)}
						<li>
							<Link
								onClick={onClose}
								to="/products/433"
								onMouseOver={hoverCategory}
								data-id="433"
							>
								{category.health}
								건강상품
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>건강상품</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/433">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/22"
								onMouseOver={hoverCategory}
								data-id="22"
							>
								{category.skin}
								여드름상품
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>여드름상품</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/22">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/384"
								onMouseOver={hoverCategory}
								data-id="384"
							>
								{category.beauty}
								미용상품
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>미용상품</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/384">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/526"
								onMouseOver={hoverCategory}
								data-id="526"
							>
								{category.sleep}
								꿀잠상품
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>꿀잠상품</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/526">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/692"
								onMouseOver={hoverCategory}
								data-id="692"
							>
								{category.concent}
								집중력상품
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>집중력상품</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/692">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/457"
								onMouseOver={hoverCategory}
								data-id="457"
							>
								{category.various}
								숙취 금연 금주 우울
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>숙취 금연 금주 우울</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/457">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/456"
								onMouseOver={hoverCategory}
								data-id="456"
							>
								{category.weight}
								다이어트식품
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>다이어트식품</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/456">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/535"
								onMouseOver={hoverCategory}
								data-id="535"
							>
								{category.mars}
								남성호르몬
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>남성호르몬</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/535">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/633"
								onMouseOver={hoverCategory}
								data-id="633"
							>
								{category.venus}
								여성호르몬
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>여성호르몬</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/633">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/440"
								onMouseOver={hoverCategory}
								data-id="440"
							>
								{category.pet}
								반려동물
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>반려동물</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/440">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
						<li>
							<Link
								onClick={onClose}
								to="/products/840"
								onMouseOver={hoverCategory}
								data-id="840"
							>
								{category.point}
								FC2포인트
								<span className="arrow">{category.arrow}</span>
							</Link>
							<SubCategory>
								<Title>FC2포인트</Title>
								<ul className="sub_ul">
									<li>
										<Link onClick={onClose} to="/products/840">
											전체보기
										</Link>
									</li>
									{global_categories ? (
										global_categories.map((item, key) => {
											return (
												<>
													<li>
														<Link
															onClick={onClose}
															to={'/products/' + (item.ID ? item.ID : '')}
														>
															{item.name ? item.name : ''}
														</Link>
													</li>
												</>
											);
										})
									) : (
										<>
											{global_category ? (
												''
											) : (
												<LoadingRoundStyle>
													<LoadingRound />
												</LoadingRoundStyle>
											)}
										</>
									)}
								</ul>
							</SubCategory>
						</li>
					</Category>
					<OtherMenu className="mobile">
						<li>
							<Link to="/pointshop">포인트상점</Link>
						</li>
						<li>
							<Link to="/best">베스트</Link>
						</li>
						<li>
							<Link to="/event">이벤트</Link>
						</li>
						<li>
							<Link to="/review">이용후기</Link>
						</li>
						<li>
							<Link to="/story/tip">스토리</Link>
						</li>
						<li>
							<Link to="/service/faq">고객센터</Link>
						</li>
					</OtherMenu>
				</MobileSideScroll>
			</SideMenuInner>
		</SideMenuWrapper>
	);
};

export default SideMenu;
