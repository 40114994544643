import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 107px 0px 189px 0px;
    @media ${(props) => props.theme.mobile} {
        overflow: hidden;
        padding: 50px 0px 29px 0px;
        /* border-top: 20px solid #f8f8f8; */
        border-bottom: 1px solid #ebebeb;
    }
`;

export const Container = styled.div`
    @media ${(props) => props.theme.mobile} {
        margin: 0px 20px;
    }
`;

export const EventTitle = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-end;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 19px;
    margin-bottom: 50px;
    h1 {
        font-size: 25px;
        font-weight: bold;
        color: #111;
        display: inline-block;
    }
    span {
        display: block;
        font-size: 15px;
        color: #9a9a9e;
    }
    @media ${(props) => props.theme.mobile} {
        display: block;
        margin-bottom: 30px;
        h1 {
            margin-bottom: 10px;
            font-family: 'Roboto';
            font-size: 19px;
            line-height: 1.5;
        }
        span {
            font-size: 11px;
        }
    }
`;

export const EventPostContent = styled.div`
    width: 100%;
    height: auto;
    min-height: 500px;
    margin-bottom: 50px;
    img {
        max-width: 100%;
    }
    p {
        line-height: 1.6;
        margin-bottom: 15px;
    }
`;

export const EventSingleView = styled.div`
    background-image: url(${(props) => (props.url ? props.url : '')});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 0;
    padding-top: 58.44%;
    margin-bottom: 150px;
    background-color: #c4c4c4;
    @media ${(props) => props.theme.mobile} {
        width: auto;
        height: 600px;
        margin-bottom: 50px;
    }
`;

export const ContentTitle = styled.div`
    margin-bottom: 20px;
    h2 {
        font-size: 23px;
        font-weight: bold;
        color: #111;
    }
    @media ${(props) => props.theme.mobile} {
        border-top: 20px solid #f8f8f8;
        padding-top: 50px;
        margin: 0px -20px 20px -20px;
        h2 {
            margin: 0px 20px;
        }
    }
`;

export const EventWrapper = styled.div`
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0px -20px;
    padding-bottom: 50px;
    @media ${(props) => props.theme.mobile} {
        overflow: hidden;
        padding-bottom: 0px;
    }
`;

export const ContentWrapper = styled.div`
    max-width: 3000px;
    width: 3000px;

    @media ${(props) => props.theme.mobile} {
        max-width: auto;
        width: auto;
    }
`;

export const EventContent = styled.div`
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0px 20px;
    p {
        margin-top: 20px;
        font-size: 15px;
        font-weight: 500;
        color: #000;
    }
    p:nth-of-type(2) {
        margin-top: 7px;
        font-size: 17px;
        font-weight: bold;
    }
    span {
        display: block;
        margin-top: 15px;
        font-size: 13px;
        color: #9a9a9e;
    }

    @media ${(props) => props.theme.mobile} {
        display: block;
        margin-bottom: 50px;
        p {
            font-size: 13px;
        }
        p:nth-of-type(2) {
            margin-top: 5px;
            font-size: 14px;
            line-height: normal;
        }
        span {
            font-size: 11px;
        }
    }
`;

export const ContentImg = styled.div`
    ${(props) => (props.url ? `background: url("${props.url}");` : '')}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 480px;
    height: 480px;
    box-sizing: border-box;
    @media ${(props) => props.theme.mobile} {
        width: auto;
        height: 0px;
        padding-bottom: 100%;
    }
`;
