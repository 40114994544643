import axios from './defaultClient';

const nameSpace = '/ep/v1/home';

export const HomeGetSlider = async (args) => {
    return await axios.get( nameSpace + '/slider',{

    })
}

export const HomeGetHit = async (args) => {
    return await axios.get( nameSpace + '/products/hit/',{
       params: {
           posts_per_page: 15
       }
    })
}

export const HomeGetStory = async (args) => {
    return await axios.get( nameSpace + '/story',{
        
    })
}

export const HomeGetCate = async (args) => {
    return await axios.get( nameSpace + '/products/cate',{
        params:{
            category_ID: args.category_ID
        }
    })
}

export const HomeGetReview = async (args) => {
    return await axios.get( nameSpace + '/review',{
        
    })
}
