import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { PageGetPost } from '../../api/page';
import LoadingRound from '../../components/LoadingRound';
import { Wrapper, PageHeader, HeaderContainer, Container } from './styled';

const Page = () => {
	const params = useParams();
	//https://wp.sakuraherb.com/wp-admin/post.php?post=280141&action=edit
	const page_ID = params.page_ID
		.replace(/privacy-policy/gi, 3)
		.replace(/transaction-law/gi, 18709757)
		.replace(/terms/gi, 18709247)
		.replace(/guide/gi, 18709753);
	const page_title = params.page_ID
		.replace(280141, '간이통관서')
		.replace(/privacy-policy/gi, '개인정보처리방침')
		.replace(/transaction-law/gi, '특정상 거래법')
		.replace(/terms/gi, '이용약관')
		.replace(/guide/gi, '이용가이드');
	const [page_item, set_page_item] = useState('');

	useEffect(() => {
		PageGetPost({
			page_ID: page_ID,
		})
			.then((response) => {
				if (response.data.code == 200) {
					set_page_item(response.data.body);
				}
			})
			.catch((error) => console.log(error));
	}, [page_ID]);

	return (
		<Wrapper>
			<PageHeader>
				<HeaderContainer>
					<a href="#" onClick={() => window.history.back()}>
						<img
							src={process.env.PUBLIC_URL + '/img/arrow-back.png'}
							alt="arrow"
						/>
					</a>
					{page_title}
				</HeaderContainer>
			</PageHeader>
			{page_item?.ID ? (
				<Container
					dangerouslySetInnerHTML={{
						__html: page_item.post_content,
					}}
				></Container>
			) : (
				<Container>
					<LoadingRound />
				</Container>
			)}
		</Wrapper>
	);
};

export default Page;
