import styled from 'styled-components';

export const Wrapper = styled.div`
    display: inline-block;
    width: ${props => props.width ? props.width : `25%`};
    label{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 50px;
        line-height: 48px;
        border: 1px solid #ebebeb;
        cursor: pointer;
        color: #111;
        font-size: 15px;
        text-align: center;
        vertical-align: bottom;
        background: #fff;
        transition: all ease 0.3s;     
        -webkit-tap-highlight-color: transparent; 
     };

    input[type=radio],
    input[type=checkbox] {
        display: none;  
    }
    input[type=radio]:checked + label {
        color: #E55F74;
        border: 1px solid #E55F74;
        transition: all ease 0.3s;      
        :before{
            display: block;
            position: absolute;
            content: "";
            width: 24px;
            height: 24px;
            top: 50%;
            right: 24px;
            transform: translateY(-50%);
            background-image: url(/img/check.svg);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    } 
`;