import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    @media ${props => props.theme.mobile}{
        padding: 0 20px;
    }
`;

export const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0;
    @media ${props => props.theme.mobile}{
        padding: 24px 0;
    }
`;

export const Title = styled.div`
    font-size: 17px;
    line-height: 25px;
    font-weight: bold;
    color: #111;
    @media ${props => props.theme.mobile}{
        font-size: 16px;
    }
`;

export const Date = styled.div`
    font-size: 15px;
    line-height: 22px;
    color: #9A9A9E;
    @media ${props => props.theme.mobile}{
        font-size: 13px;
    }
`;

export const Content = styled.div`
    font-size: 15px;
    line-height: 26px;
    color: #111;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 30px 0;
    margin-bottom: 30px;
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    @media ${props => props.theme.mobile}{
        font-size: 16px;
        line-height: 1.75;
        padding: 20px 0;
    }
`;

export const Comment = styled.div`
    width: 100%;
    height: auto;
`;

export const CommentWrite = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    input {
        width: 100%;
        height: 80px;
        padding: 20px 100px 20px 20px;
        box-sizing: border-box;
        font-size: 15px;
        line-height: 22px;
        color: #111111;
        border: 1px solid #EBEBEB;
        border-radius: 2px;
    }
    button {
        position: absolute;
        right: 0;
        width: 80px;
        height: 80px;
        background-color: #E55F74;
        border: none;
        border-radius: 0px 0px 2px 2px;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #fff;
    }
    @media ${props => props.theme.mobile}{
        input {
            padding: 20px 20px 20px 20px;
            font-size: 14px;
            line-height: 20px;
        }
        button {
            position: relative;
            width: 100%;
            height: 50px;
            font-size: 16px;
            line-height: 23px;
        }
    }
`;

export const Total = styled.div`
    font-size: 13px;
    line-height: 26px;
    color: #111;
    margin-bottom: 10px;
    strong {
        font-size: 13px;
        line-height: 26px;
        color: #111;
        font-weight: 500;
    }
    span {
        font-size: 13px;
        line-height: 26px;
        color: #E55F74;
        font-weight: 500;
    }
    @media ${props => props.theme.mobile}{
        font-size: 14px;
        line-height: 1.86;
        strong {
            font-size: 14px;
            line-height: 1.86;
        }
        span {
            font-size: 14px;
            line-height: 1.86;
        }
    }
`;

export const CommentListWrapper = styled.ul`
    border-top: 1px solid #ebebeb;
    margin-bottom: 30px;
`;

export const CommentList = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    padding: 25px 0;
    border-bottom: 1px solid #ebebeb;
    @media ${props => props.theme.mobile}{
        padding: 20px 0;
    }
`;

export const Avartar = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f5f5f5;
`;

export const Detail = styled.div`
    width: calc(100% - 60px);
    padding-left: 15px;

`;

export const UserID = styled.span`
    display: inline-block;
    padding-top: 6px;
    margin-right: 10px;
    font-size: 13px;
    line-height: 19px;
    color: #111;
    @media ${props => props.theme.mobile}{
        font-size: 14px;
        line-height: 20px;
    }
`;

export const Editor = styled.div`
    float: right;
    padding-top: 6px;
    @media ${props => props.theme.mobile}{
        position: absolute;
        padding-top: 0px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
`;

export const CommentDate = styled.span`
    font-size: 13px;
    line-height: 19px;
    color: #9A9A9E;
    @media ${props => props.theme.mobile}{
        font-size: 12px;
    }
`;

export const CommentEdit = styled.span` 
    font-size: 13px;
    line-height: 19px;
    color: #111111;
    text-decoration: underline;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        font-size: 14px;
        line-height: 20px;
    }
`;

export const CommentRm = styled.span` 
    font-size: 13px;
    line-height: 19px;
    color: #111111;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
`;

export const CommentContent = styled.div`
    font-size: 15px;
    line-height: 22px;
    color: #111111;
    margin-top: 5px;
    padding-right: 60px;
    @media ${props => props.theme.mobile}{
        font-size: 16px;
        line-height: 23px;
        padding-right: 70px;
    }
`;

export const MoreButton = styled.button`
    width: 100%;
    height: 56px;
    background: #fff;
    border: 1px solid #E55F74;
    border-radius: 200px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #E55F74;
    transition: 0.2s;
    @media ${props => props.theme.mobile}{
        font-size: 16px;
        line-height: 23px;
    }
`;