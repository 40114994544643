import React, {useState, useRef} from 'react';
import SidePopup from '../../../../components/SidePopup/index';
import {
    Wrapper,
    Title,
    Summary,
    DescriptionMenu,
    ArrowDown,
    Explain
} from './styled';

const Description = ({show, onClose, content}) => {
    const li_el = useRef(1);
    const[open, set_open] = useState(0);
    const Toggle = (e) => {
        console.log(li_el.current.classList);


        // if(e.currentTarget.classList.length){
        //     e.currentTarget.classList.remove('active');
        // } else {
        //     e.currentTarget.classList.add('active');
        // }

        if(open === e.target.dataset.id){
            set_open(0);
        } else {
            set_open(e.target.dataset.id);
        }
    }

    return (
        <SidePopup show={show} onClose={onClose}>
            <Wrapper>
                <Title>
                    제품 설명
                </Title>

                <Summary dangerouslySetInnerHTML={{
                            __html: (content && content.desc ? content.desc : '')
                        }}>
                    {/* <p>
                        새로운 유형의 ‘핀페시아’는 착색료 퀴놀린 옐로우 (Quinoline Yellow)는 포함되어 있지 않습니다.
                    </p>
                    <p>
                        핀페시아는 피나스테라이드를 유효 성분으로하는 마시는 타입의 AGA (남성형 탈모증) 치료제로 유명한 ‘프로페시아’의 제네릭 의약품입니다.
                    </p> */}
                </Summary>
                <DescriptionMenu>
                    
                    {content && content.effect ? (
                        <li onClick={Toggle} ref={li_el} data-id="1">  
                            효과
                            <ArrowDown src={process.env.PUBLIC_URL + "/img/arrow-down.svg"} isopen={open === "1"}/>
                            <Explain onClick={[]} isopen={open === "1"} dangerouslySetInnerHTML={{
                                __html: content.effect
                            }}/>
                        </li>
                    ) : ''}
                    {content && content.directions ? (
                        <li onClick={Toggle} ref={li_el} data-id="2"> 
                            용법
                            <ArrowDown src={process.env.PUBLIC_URL + "/img/arrow-down.svg"} isopen={open === "2"}/>
                            <Explain onClick={[]} isopen={open === "2"} dangerouslySetInnerHTML={{
                                __html: content.directions
                            }}/>
                        </li>
                    ) : ''}
                    
                    {content && content.notices ? (
                        <li onClick={Toggle} ref={li_el} data-id="3"> 
                            주의사항
                            <ArrowDown src={process.env.PUBLIC_URL + "/img/arrow-down.svg"} isopen={open === "3"}/>
                            <Explain onClick={[]} isopen={open === "3"} dangerouslySetInnerHTML={{
                                __html: content.notices
                            }}/>
                        </li>
                    ) : ''}

                    {content && content.side_effect ? (
                        <li onClick={Toggle} ref={li_el} data-id="4"> 
                            부작용
                            <ArrowDown src={process.env.PUBLIC_URL + "/img/arrow-down.svg"} isopen={open === "4"}/>
                            <Explain onClick={[]} isopen={open === "4"} dangerouslySetInnerHTML={{
                                __html: content.side_effect
                            }}/>
                        </li>
                    ) : ''}

                    {content && content.ingredient ? (
                        <li onClick={Toggle} ref={li_el} data-id="5"> 
                            성분
                            <ArrowDown src={process.env.PUBLIC_URL + "/img/arrow-down.svg"} isopen={open === "5"}/>
                            <Explain onClick={[]} isopen={open === "5"} dangerouslySetInnerHTML={{
                                __html: content.ingredient 
                            }}/>
                        </li>
                    ) : ''}
                </DescriptionMenu>
            </Wrapper>
        </SidePopup>
    );
};

export default Description;