import React, { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SetUserData } from '../../store/me';
import { MediaQuery, mobileQuery } from '../../components/Grid';
import SideMenu from '../SideMenu';
import MiniBasket from '../MiniBasket';
import { isLogined } from '../../api/auth';
import { Getme, GetNotifications } from '../../api/me';
import {
	HeaderWrapper,
	MainHeader,
	MainHeaderContainer,
	Hamburger,
	Logo,
	SearchbarSection,
	SearchbarWrapper,
	Searchbar,
	MobileSearchIcon,
	MobileSearchClose,
	Overlay,
	RightMenu,
	SubHeader,
	SubHeaderContainer,
	SubMenu,
	SubMenuRight,
	NoticeBox,
	NoticeContent,
	NoticeTime,
} from './styled';
import { setPointMinPrice } from '../../store/cart';
import { GlobalSystem } from '../../api/global';
import { SetRaf } from '../../store/global';

const Header = (props) => {
	const isMobile = MediaQuery(mobileQuery);
	const is_cart = useSelector((state) => state?.cart?.cart_items?.length);
	const me = useSelector((state) => state.me.user_data);
	const [noti, set_noti] = useState('');
	const [is_noti_empty, set_is_noti_empty] = useState('');
	const [once, set_once] = useState(0);
	const [is_read, set_is_read] = useState(0);
	const Dispatch = useDispatch();
	const get_params = new URLSearchParams(useLocation().search);
	const [raf_check, set_raf_check] = useState('');

	useEffect(() => {
		if (get_params.get('raf')) {
			if (!raf_check) {
				// localStorage.setItem('raf', get_params.get('raf'));
				Dispatch(SetRaf(get_params.get('raf')));
				toast.success(
					'추천코드가 적용되었습니다. 회원가입시 포인트 즉시 적립됩니다.'
				);
				set_raf_check(1);
			}
		}
	}, []);

	useEffect(() => {
		if (localStorage.getItem('user_token')) {
			if (!once) {
				set_once(1);
				isLogined()
					.then((response) => {
						if (response.data.data.status == 200) {
							// console.log('로그인 체크 성공');
							Getme()
								.then((response) => {
									if (response.data.code == 200) {
										Dispatch(SetUserData(response.data.body));
									}
								})
								.catch((error) => toast.error(error));
						} else {
							toast.error('로그인 정보가 만료되었습니다.1');
							Dispatch(SetUserData(0));
							localStorage.removeItem('user_token');
						}
					})
					.catch((error) => {
						toast.error('로그인 정보가 만료되었습니다.');
						// Dispatch(SetUserData(0));
						// localStorage.removeItem('user_token');
					});
			}
		}
		if (!noti) {
			if (me) {
				GetNotifications()
					.then((res) => {
						if (res.data.code == '200') {
							set_noti(res.data.body.results);
							if (
								localStorage.getItem('last_noti') ===
								res.data.body.results[0].id
							) {
								set_is_read(1);
							} else {
								set_is_read(0);
							}
							set_is_noti_empty(0);
						} else {
							set_is_noti_empty(1);
						}
					})
					.catch((error) => {});
			} else {
				set_noti('');
				set_is_noti_empty(1);
			}
		}
	}, []);

	useEffect(() => {
		GlobalSystem()
			.then((res) => {
				if (res.data.code === '200') {
					Dispatch(setPointMinPrice(res.data.body.minimum_amount));
				} else {
					Dispatch(setPointMinPrice(0));
				}
			})
			.catch((error) => {
				console.log(error);
				Dispatch(setPointMinPrice(0));
			});
	}, []);

	const history = useHistory();

	const [hide, setHide] = useState(false);
	const [pageY, setPageY] = useState(0);
	const documentRef = useRef(document);
	const [keyword, set_keyword] = useState('');

	const PressSearch = (e) => {
		if (e.key === 'Enter') {
			history.push('/search/' + keyword);
		}
	};

	const [show_searchbar, set_show_searchbar] = useState(0);
	const ToggleSearch = (e) => {
		if (show_searchbar) {
			set_show_searchbar(0);
		} else {
			set_show_searchbar(1);
		}
	};

	const [side, set_side] = useState(0);
	const openSideMenu = (e) => {
		if (side) {
			set_side(0);
		} else {
			set_side(1);
		}
	};

	const [open_basket, set_open_basket] = useState(0);
	const openBasket = (e) => {
		e.preventDefault();
		if (open_basket) {
			set_open_basket(0);
		} else {
			set_open_basket(1);
		}
	};

	const [open_notice, set_open_notice] = useState(0);
	const openNotice = (e) => {
		if (open_notice) {
			set_open_notice(0);
		} else {
			set_open_notice(1);
			if (!is_noti_empty) {
				if (noti) {
					localStorage.setItem('last_noti', noti[0].id);
				}
			}
		}
	};

	const handleScroll = () => {
		const { pageYOffset } = window;
		const hide = pageYOffset > 188 && pageY < pageYOffset;
		setHide(hide);
		setPageY(pageYOffset);
	};

	const Logout = (e) => {
		e.preventDefault();

		Dispatch(SetUserData(''));
		localStorage.removeItem('user_token');
		toast.success('로그아웃이 완료되었습니다!');
	};

	useEffect(() => {
		documentRef.current.addEventListener('scroll', handleScroll);
		return () =>
			documentRef.current.removeEventListener('scroll', handleScroll);
	});

	return (
		<HeaderWrapper noBold={props.noBold}>
			<MainHeader>
				<MainHeaderContainer>
					<Hamburger onClick={openSideMenu}>
						<div className="l" />
						<div className="s" />
						<div className="l" />
					</Hamburger>
					<Link to="/">
						<Logo
							src={process.env.PUBLIC_URL + '/img/logo.png'}
							alt="SakuraHub logo"
						/>
					</Link>
					<SearchbarSection show={show_searchbar}>
						<SearchbarWrapper show={show_searchbar}>
							<Searchbar
								show={show_searchbar}
								type="text"
								spellcheck="false"
								placeholder="상품명 및 성분을 검색해보세요."
								onChange={(e) => set_keyword(e.target.value)}
								value={keyword}
								onKeyPress={PressSearch}
							/>
							<Link to={'/search/' + keyword}>
								<img
									src={process.env.PUBLIC_URL + '/img/search_icon.svg'}
									alt="search icon"
								/>
							</Link>
							{isMobile ? (
								<>
									<MobileSearchClose onClick={ToggleSearch} />
								</>
							) : (
								''
							)}
						</SearchbarWrapper>
					</SearchbarSection>
					{isMobile ? (
						<MobileSearchIcon onClick={ToggleSearch}>
							<img
								src={process.env.PUBLIC_URL + '/img/search_icon.svg'}
								alt="search icon"
							/>
						</MobileSearchIcon>
					) : (
						''
					)}
					<RightMenu>
						<li>
							<Link to="/mypage">
								<span className="user" />
							</Link>
						</li>
						<li className="bell" onClick={openNotice}>
							{!is_read && noti && !is_noti_empty ? <i></i> : ''}
							<NoticeBox show={open_notice}>
								{!is_noti_empty ? (
									<>
										{noti
											? noti.map((noti_item, key) => (
													<li onClick={(e) => history.push(noti_item.link)}>
														<NoticeContent
															dangerouslySetInnerHTML={{
																__html: noti_item.message,
															}}
														/>
														<NoticeTime>{noti_item.wdate}</NoticeTime>
													</li>
											  ))
											: ''}
									</>
								) : (
									<li>
										<NoticeContent>아직 알림이 없습니다.</NoticeContent>
									</li>
								)}
							</NoticeBox>
						</li>
						<li>
							<a href="javascript:;" onClick={openBasket}>
								<span className="basket" />
								{is_cart ? <i></i> : ''}
							</a>
						</li>
					</RightMenu>
					<SideMenu show={side} onClose={openSideMenu} />
					<MiniBasket show={open_basket} onClose={openBasket} />
				</MainHeaderContainer>
			</MainHeader>
			<SubHeader className={hide ? 'hide' : ''}>
				<SubHeaderContainer>
					<SubMenu>
						<Link to="/best">
							<li>베스트</li>
						</Link>
						<Link to="/event">
							<li>이벤트</li>
						</Link>
						<Link to="/review">
							<li>이용후기</li>
						</Link>
						<Link to="/story/tip">
							<li>스토리</li>
						</Link>
						<Link to="/service/faq">
							<li>고객센터</li>
						</Link>
					</SubMenu>
					<SubMenuRight>
						<Link to="/pointshop">
							<li className="point">
								<img
									src={process.env.PUBLIC_URL + '/img/point.png'}
									alt="search icon"
								/>
								포인트상점
							</li>
						</Link>
						{!me ? (
							<>
								<Link to="/login">
									<li className="li-login">로그인</li>
								</Link>
								<Link to="/register">
									<li>회원가입</li>
								</Link>
							</>
						) : (
							<>
								<Link to="/mypage">
									<li className="li-login">마이페이지</li>
								</Link>
								<a href="javscript:;" onClick={Logout}>
									<li>로그아웃</li>
								</a>
							</>
						)}
					</SubMenuRight>
				</SubHeaderContainer>
			</SubHeader>
			<Overlay show={show_searchbar} onClick={ToggleSearch} />
		</HeaderWrapper>
	);
};

export default Header;
