import React from 'react';
import {
    StyleTextArea
} from './styled';

const TextArea = (props) => {
    switch(props.type) {
        case 'textarea':
            return (
                <StyleTextArea className={props.class} padding={props.padding} defaultValue={props.value} ref={props.ref} placeholder={props.placeholder}  name={props.name} width={props.width} height={props.height} onChange={props.onChange} maxLength={props.maxLength} readOnly={props.readOnly}>{props.children}</StyleTextArea>
            );
        default: ;
    }
}

export default TextArea;