import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SetLoading } from '../../../store/global';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import MainContainer from '../../../components/MainContainer';
import Button from '../../../components/Button';
import { SktonReviewImg, SktonReviewBoxImg } from '../../../components/Skeleton';
import { Br, Row, Col, MediaQuery, mobileQuery } from '../../../components/Grid';
import { ReviewGetPost, ReviewDeletePost, ReviewInsertFinest } from '../../../api/review';

import {
    Wrapper,
    Container,
    SingleWrapper,
    ReviewBox,
    ReviewImg,
    ReviewImgBox,
    BoxWrapper,
    BoxImg,
    ReviewDetails,
    ReviewTitle,
    ReviewUser,
    ReviewContent,
    MobileReviewTitle,
    MobileReviewUser,
    ReviewProductDetails,
    ReviewUserImage,
    ReviewUserItem,
    ReviewUserPrice,
    ButtonGroup,
    ReviewPoint,
    IsFinest,
} from './styled';

const ReviewSingle = () => {
    const isMobile = MediaQuery(mobileQuery);
    const params = useParams();
    const History = useHistory();
    const Dispatch = useDispatch();
    const [review, set_review] = useState('');
    const [review_gallery, set_review_gallery] = useState('');
    const trash_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M18.0929 22.6663H5.90626C5.64972 22.6603 5.39689 22.6037 5.16221 22.4999C4.92754 22.3961 4.71561 22.2471 4.53855 22.0614C4.36148 21.8756 4.22275 21.6568 4.13027 21.4175C4.0378 21.1781 3.99339 20.9229 3.99959 20.6663V7.48633H5.33293V20.6663C5.32656 20.7478 5.33637 20.8297 5.36177 20.9074C5.38718 20.985 5.42769 21.0569 5.48097 21.1189C5.53425 21.1808 5.59925 21.2316 5.67224 21.2684C5.74523 21.3051 5.82476 21.3271 5.90626 21.333H18.0929C18.1744 21.3271 18.254 21.3051 18.3269 21.2684C18.3999 21.2316 18.4649 21.1808 18.5182 21.1189C18.5715 21.0569 18.612 20.985 18.6374 20.9074C18.6628 20.8297 18.6726 20.7478 18.6663 20.6663V7.48633H19.9996V20.6663C20.0058 20.9229 19.9614 21.1781 19.8689 21.4175C19.7764 21.6568 19.6377 21.8756 19.4606 22.0614C19.2836 22.2471 19.0717 22.3961 18.837 22.4999C18.6023 22.6037 18.3495 22.6603 18.0929 22.6663V22.6663Z"
                fill="black"
            />
            <path
                d="M20.5203 6.00033H3.33366C3.15685 6.00033 2.98728 5.93009 2.86225 5.80506C2.73723 5.68004 2.66699 5.51047 2.66699 5.33366C2.66699 5.15685 2.73723 4.98728 2.86225 4.86225C2.98728 4.73723 3.15685 4.66699 3.33366 4.66699H20.5203C20.6971 4.66699 20.8667 4.73723 20.9917 4.86225C21.1168 4.98728 21.187 5.15685 21.187 5.33366C21.187 5.51047 21.1168 5.68004 20.9917 5.80506C20.8667 5.93009 20.6971 6.00033 20.5203 6.00033Z"
                fill="black"
            />
            <path d="M14 8.66699H15.3333V18.667H14V8.66699Z" fill="black" />
            <path d="M8.66699 8.66699H10.0003V18.667H8.66699V8.66699Z" fill="black" />
            <path
                d="M15.3337 3.90634H14.067V2.66634H9.93366V3.90634H8.66699V2.66634C8.66657 2.32397 8.79785 1.99456 9.03366 1.74634C9.26947 1.49812 9.59172 1.35013 9.93366 1.33301H14.067C14.4089 1.35013 14.7312 1.49812 14.967 1.74634C15.2028 1.99456 15.3341 2.32397 15.3337 2.66634V3.90634Z"
                fill="black"
            />
        </svg>
    );

    useEffect(() => {
        if (!review) {
            ReviewGetPost({
                review_ID: params.review_ID,
            })
                .then((response) => {
                    if (response.data.code == 200) {
                        set_review(response.data.body);
                        set_review_gallery(response.data.body.gallery);
                        set_thumbnail(response.data.body.gallery[0].image_url);
                        console.log(response.data.body);
                    }
                })
                .catch((error) => console.log(error));
        }
    });

    const [thumbnail, set_thumbnail] = useState('');
    const selectGallery = (e) => {
        set_thumbnail(e.target.dataset.url);
    };
    const DeleteReview = (e) => {
        if (window.confirm('정말 삭제하시겠습니까?')) {
            Dispatch(SetLoading(1));
            ReviewDeletePost({
                review_ID: params.review_ID,
            })
                .then((res) => {
                    if (res.data.code == '200') {
                        toast.success('삭제 완료되었습니다.');
                        History.push('/review/');
                    } else {
                        toast.error(res.data.body.message);
                    }
                    Dispatch(SetLoading(0));
                })
                .catch((err) => {
                    console.log(err);
                    toast.error('서버 요청오류');
                    Dispatch(SetLoading(0));
                });
        }
    };

    const InsertFinest = (e) => {
        ReviewInsertFinest({
            review_ID: params.review_ID,
        })
            .then((res) => {
                if (res.data.code == '200') {
                    toast.success(res.data.body.message);
                } else {
                    toast.error('서버 요청오류');
                }
            })
            .catch((err) => {});
    };

    return (
        <>
            <MainContainer type="small">
                <Wrapper>
                    <Container>
                        <SingleWrapper>
                            <Row>
                                <Col>
                                    <ReviewBox>
                                        {review_gallery ? <ReviewImg url={thumbnail} /> : <SktonReviewImg />}
                                        <ReviewImgBox>
                                            <BoxWrapper count={review.gallery_count ? review.gallery_count : ''}>
                                                {review_gallery ? (
                                                    review_gallery.map((item, key) => {
                                                        return (
                                                            <div className="box" onClick={selectGallery}>
                                                                <BoxImg
                                                                    url={item.image_url ? item.image_url : ''}
                                                                    data-url={
                                                                        item.thumb_image_url ? item.thumb_image_url : ''
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <>
                                                        <div className="box">
                                                            <SktonReviewBoxImg />
                                                        </div>
                                                    </>
                                                )}
                                            </BoxWrapper>
                                        </ReviewImgBox>
                                    </ReviewBox>
                                </Col>
                                <Col>
                                    <ReviewDetails>
                                        {isMobile ? (
                                            <>
                                                <ReviewPoint isshow={review.amount_point ? review.amount_point : ''}>
                                                    즉시 사용 가능한
                                                    <span>
                                                        {' '}
                                                        {review.finest ? 30000 : review.amount_point} 포인트
                                                    </span>{' '}
                                                    적립완료!
                                                </ReviewPoint>
                                                <MobileReviewTitle>
                                                    <h2>{review.post_title ? review.post_title : ''}</h2>
                                                </MobileReviewTitle>
                                                <MobileReviewUser
                                                    show={review.author_display_name ? review.author_display_name : ''}
                                                >
                                                    <small>
                                                        <span>
                                                            {review.author_display_name
                                                                ? review.author_display_name
                                                                : ''}
                                                        </span>
                                                        {review.post_date ? review.post_date : ''}
                                                    </small>
                                                    {/* <p>도움돼요 18</p> */}
                                                </MobileReviewUser>
                                            </>
                                        ) : (
                                            <>
                                                <ReviewPoint isshow={review.amount_point ? review.amount_point : ''}>
                                                    즉시 사용 가능한{' '}
                                                    <img src={process.env.PUBLIC_URL + '/img/coins.svg'} />
                                                    <span>
                                                        {' '}
                                                        {review.finest ? 30000 : review.amount_point} 포인트
                                                    </span>{' '}
                                                    적립완료! 소중한 후기 감사합니다.
                                                </ReviewPoint>
                                                <ReviewTitle>
                                                    {review.is_administrator ? (
                                                        review.finest ? (
                                                            <div>프리미엄 후기</div>
                                                        ) : (
                                                            ''
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                    <h2>{review.post_title ? review.post_title : ''}</h2>
                                                    {/* <p>도움돼요 18</p> */}
                                                </ReviewTitle>
                                                <ReviewUser>
                                                    <small>
                                                        <span>
                                                            {review.author_display_name
                                                                ? review.author_display_name
                                                                : ''}
                                                        </span>
                                                        {review.post_date ? review.post_date : ''}
                                                    </small>
                                                </ReviewUser>
                                            </>
                                        )}
                                        <ReviewContent
                                            dangerouslySetInnerHTML={{
                                                __html: review.post_content,
                                            }}
                                        />
                                    </ReviewDetails>
                                    {review.product_image ? (
                                        <ReviewProductDetails
                                            onClick={(e) => History.push(`/product/${review.product_ID}`)}
                                        >
                                            <ReviewUserImage url={review.product_image ? review.product_image : ''} />
                                            <ReviewUserItem>
                                                <p>{review.product_cate_name ? review.product_cate_name : ''}</p>
                                                <span>{review.product_name ? review.product_name : ''}</span>
                                            </ReviewUserItem>
                                            <ReviewUserPrice>
                                                <p>{review.product_price ? review.product_price : ''}</p>
                                            </ReviewUserPrice>
                                        </ReviewProductDetails>
                                    ) : (
                                        ''
                                    )}
                                    <ButtonGroup>
                                        <div>
                                            <Button color="secondary" to="/review">
                                                목록으로
                                            </Button>
                                        </div>
                                        {review.is_mine ? (
                                            <div>
                                                <Button className={'trash-button'} onClick={DeleteReview} color="#fff">
                                                    {trash_icon}
                                                </Button>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </ButtonGroup>
                                    {review.is_administrator ? (
                                        review.is_finest ? (
                                            review.finest ? (
                                                <IsFinest complete={review.finest}>적용 완료</IsFinest>
                                            ) : (
                                                <IsFinest onClick={InsertFinest}>프리미엄 적용</IsFinest>
                                            )
                                        ) : (
                                            ''
                                        )
                                    ) : (
                                        ''
                                    )}
                                </Col>
                            </Row>
                        </SingleWrapper>
                    </Container>
                </Wrapper>
            </MainContainer>
        </>
    );
};

export default ReviewSingle;
