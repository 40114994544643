import axios from './defaultClient';

const nameSpace = '/ep/v1/me';

export const Getme = async (args) => {
	return await axios.get(nameSpace + '/', {});
};

export const Postme = async (args) => {
	return await axios.post(nameSpace + '/', {
		user_name: args.user_name,
		user_phone: args.user_phone,
		current_password: args.current_password,
		user_password: args.user_password,
		check_password: args.check_password,
	});
};

export const GetPoint = async (args) => {
	return await axios.get(nameSpace + '/point', {
		params: {
			paged: args.paged,
			posts_per_page: args.posts_per_page,
		},
	});
};

export const GetQna = async (args) => {
	return await axios.get(nameSpace + '/qna', {
		params: {
			posts_per_page: args.posts_per_page,
			paged: args.paged,
		},
	});
};

export const GetNotifications = async (args) => {
	return await axios.get(nameSpace + '/notifications', {});
};
// export const EventGetPosts = async (args) => {
//     return await axios.get( nameSpace + '/notification',{

//     })
// }

export const GetAddress = async (args) => {
	return await axios.get(nameSpace + '/address', {});
};

export const GetRank = async (args) => {
	return await axios.get('/ep/v1/reffer/rank', {
		params: args,
	});
};
