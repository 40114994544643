import React, { useState, useEffect, useRef } from 'react';
import Joi from 'joi-browser';
import { useHistory } from 'react-router-dom';
import DaumPostcode from 'react-daum-postcode';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { OrderGetCoupon, OrderPost } from '../../api/order';
import { SetLoading } from '../../store/global';
import { RemoveCart, UpdateQtyCart, EarnPoint, UseCoupon, RemoveCoupon, ClearCart } from '../../store/cart';
import MainContainer from '../../components/MainContainer';
import { GetAddress } from '../../api/me';

import {
    Wrapper,
    Title,
    OrderInfoWrapper,
    OrderInfo,
    ProductImage,
    ProductDetail,
    Name,
    Price,
    Amount,
    Delete,
    SubTitle,
    InputSection,
    InputWrapper,
    SelectStyle,
    Payment,
    PaymentRow,
    AgreementSection,
    Agreement,
    AgreementDetail,
    ArrowDown,
    ConfirmButton,
    PostCodeWrapper,
    LoadingDot,
    PostBasic,
    UserName,
    UserPost,
    TogglePen,
    PostNewBasic,
    PostNew,
    ToggleNewPen,
    UserInfor,
    EditDone,
    PaymentTitle,
    PaymentMethod,
    PaymentMethodRow,
    PaymentMethodTitle,
    PaymentMethodItem,
    PaymentMethodItemName,
    AuthPaymentLayer,
} from './styled';
import country_list from '../../components/country.array';
import LoadingRound from '../../components/LoadingRound';
const Order = () => {
    const check_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="11.5" fill="#fff" stroke="#DFDFDF" />
        </svg>
    );

    const onCheck_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="11.5" fill="#fff" stroke="#E55F74" />
            <rect width="14" height="14" x="5" y="5" fill="#E55F74" rx="7" />
        </svg>
    );

    const Dispatch = useDispatch();
    const cart_items = useSelector((state) => state.cart.cart_items);
    const cart = useSelector((state) => state.cart);
    const me = useSelector((state) => state.me.user_data);
    const [selectedPaymentMethod, selectPaymentMethod] = useState('paykiwoom');
    const point_min_price = useSelector((state) => state.cart.point_min_price);

    const shipping_address1_el = useRef(0);

    const History = useHistory();

    const [use_point, set_use_point] = useState(cart.point);
    const [coupon_code, set_coupon_code] = useState(cart.coupon);

    const [disabled, set_disabled] = useState(0);

    const plusCount = (e) => {
        Dispatch(UpdateQtyCart(e.currentTarget.dataset.key, '+'));
    };
    const minusCount = (e) => {
        Dispatch(UpdateQtyCart(e.currentTarget.dataset.key, '-'));
    };

    const RemoveCartItem = (e) => {
        const key = e.currentTarget.dataset.key;
        Dispatch(RemoveCart(key));
        console.log(key);
    };

    const UsePoint = (e) => {
        if (cart.subtotal < point_min_price) {
            toast.error(`장바구니에 ${point_min_price?.toLocaleString()}원 이상 담아야 사용 가능합니다.`);
            set_use_point('');
            return;
        }
        if (parseInt(me.point) < parseInt(use_point)) {
            toast.error('포인트가 부족합니다.');
            set_use_point('');
            return;
        }
        Dispatch(EarnPoint(use_point));
        toast.success(
            <>
                <strong>{cart.point} 포인트</strong> 적용되었습니다.
            </>
        );
    };

    const UseCoupon_ = (e) => {
        OrderGetCoupon({
            coupon_code: coupon_code,
            subtotal: cart.subtotal,
            cart_items: cart_items,
        })
            .then((response) => {
                if (response.data.code == '200') {
                    Dispatch(UseCoupon(response.data.body.coupon_code, response.data.body.discount_amount));
                    toast.success('쿠폰 적용이 완료되었습니다.');
                } else {
                    toast.error(response.data.body.message);
                }
            })
            .catch((err) => toast.error(err));
    };

    const RemoveCoupon_ = (e) => {
        Dispatch(RemoveCoupon());
        set_coupon_code('');
        toast.warn('쿠폰 적용 해제되었습니다.');
    };

    const Toggle = (e) => {
        if (e.currentTarget.classList.contains('active')) {
            e.currentTarget.classList.remove('active');
        } else {
            e.currentTarget.classList.add('active');
        }
    };

    const checkIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g transform="translate(-35 -97) translate(20 80) translate(15 17)">
                            <circle cx="13.5" cy="13.5" r="13" fill="#FFF" stroke="#CFCFCF" />
                            <path
                                fill="#CFCFCF"
                                fill-rule="nonzero"
                                d="M11.546 18c-.163 0-.327-.062-.451-.185l-3.908-3.868c-.25-.247-.25-.647 0-.893.25-.247.653-.247.902 0l3.457 3.421 7.365-7.29c.25-.247.652-.247.902 0s.25.646 0 .893l-7.816 7.737c-.124.123-.288.185-.451.185z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

    const [billing_name, set_billing_name] = useState(me.user_name);
    const [billing_email, set_billing_email] = useState(me.user_email);
    const [billing_phone, set_billing_phone] = useState(me.user_phone);
    // const [shipping_country, set_shipping_country] = useState('KR');
    const [isPostOpen, setIsPostOpen] = useState(null);
    const [term_check, set_term_check] = useState(0);
    const [shipping_notice_check, set_shipping_notice_check] = useState(0);

    const [address_KEY, set_address_KEY] = useState(0);
    const [address_last_KEY, set_address_last_KEY] = useState(0);

    const [revise, set_revise] = useState(null);
    const [address, set_address] = useState();

    // 배송지 수정
    const [new_shipping_address1, set_new_shipping_address1] = useState('');
    const [new_shipping_address2, set_new_shipping_address2] = useState('');
    const [new_shipping_postcode, set_new_shipping_postcode] = useState('');
    const [new_shipping_memo, set_new_shipping_memo] = useState('');
    const [new_personal_code, set_new_personal_code] = useState('');
    const [new_shipping_country, set_new_shipping_country] = useState('KR');

    const change_address_field = (key, name, value) => {
        var draft = address;
        // if(draft.length) {
        draft[key][name] = value;
        // }

        set_address(Object.assign([], draft));
        console.log(address);
    };

    const PostcodeComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        // console.log(data);
        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        if (!address_last_KEY || address_last_KEY == address_KEY) {
            set_new_shipping_address1(data.roadAddressEnglish);
            set_new_shipping_postcode(data.zonecode);
        } else {
            change_address_field(isPostOpen, 'shipping_address1', data.roadAddressEnglish);
            change_address_field(isPostOpen, 'shipping_postcode', data.zonecode);
        }

        setIsPostOpen(null);
    };

    // 주문처리
    const DoOrder = (e) => {
        if (!cart_items.length) {
            toast.error('상품이 비어있습니다.');
        } else {
            const schema = {
                billing_name: Joi.string()
                    .required()
                    .error(() => ({ message: '주문자명을 입력해주세요.' })),
                billing_email: Joi.string()
                    .required()
                    .email()
                    .error(() => ({ message: '이메일주소를 입력해주세요.' })),
                billing_phone: Joi.string()
                    .required()
                    .error(() => ({ message: '휴대폰 번호를 입력해주세요.' })),
                shipping_country: Joi.string()
                    .required()
                    .error(() => ({ message: '국가를 선택해주세요.' })),
                shipping_postcode: Joi.string()
                    .required()
                    .error(() => ({ message: '우편번호를 입력해주세요.' })),
                shipping_address1: Joi.string()
                    .required()
                    .error(() => ({ message: '주소를 입력해주세요.' })),
                shipping_address2: Joi.string()
                    .required()
                    .error(() => ({ message: '상세주소를 입력해주세요.' })),
                term_check: Joi.bool()
                    .required()
                    .error(() => ({ message: '이용약관에 동의해주세요.' })),
                shipping_notice_check: Joi.bool()
                    .required()
                    .error(() => ({ message: '배송지연 안내를 읽고 체크해주세요.' })),
            };
            const { error, value } = Joi.validate(
                {
                    billing_name: billing_name,
                    billing_email: billing_email,
                    billing_phone: billing_phone,
                    shipping_country:
                        !address_last_KEY || address_KEY == address_last_KEY
                            ? new_shipping_address1
                            : address[address_KEY]['shipping_country'],
                    shipping_postcode:
                        !address_last_KEY || address_KEY == address_last_KEY
                            ? new_shipping_postcode
                            : address[address_KEY]['shipping_postcode'],
                    shipping_address1:
                        !address_last_KEY || address_KEY == address_last_KEY
                            ? new_shipping_address1
                            : address[address_KEY]['shipping_address1'],
                    shipping_address2:
                        !address_last_KEY || address_KEY == address_last_KEY
                            ? new_shipping_address2
                            : address[address_KEY]['shipping_address2'],
                    term_check: term_check,
                    shipping_notice_check: shipping_notice_check,
                },
                schema
            );

            console.log({
                order_items: cart.cart_items,
                point: cart.point,
                coupon_code: cart.coupon,
                billing_name: billing_name,
                billing_email: billing_email,
                billing_phone: billing_phone,
                address_KEY: address_KEY,
                new_address:
                    !address_last_KEY || address_KEY == address_last_KEY
                        ? {
                              shipping_address1: new_shipping_address1,
                              shipping_address2: new_shipping_address2,
                              shipping_postcode: new_shipping_postcode,
                              shipping_country: new_shipping_country,
                              shipping_memo: new_shipping_memo,
                              personal_code: new_personal_code,
                          }
                        : address[address_KEY],
                payment_method: selectedPaymentMethod,
            });
            if (error) {
                toast.error(error.details[0].message);
            } else {
                set_disabled(1);
                Dispatch(SetLoading(1));
                OrderPost({
                    order_items: cart.cart_items,
                    point: cart.point,
                    subtotal: cart.subtotal,
                    coupon_code: cart.coupon,
                    billing_name: billing_name,
                    billing_email: billing_email,
                    billing_phone: billing_phone,
                    address_KEY: address_KEY,
                    new_address:
                        !address_last_KEY || address_KEY == address_last_KEY
                            ? {
                                  shipping_address1: new_shipping_address1,
                                  shipping_address2: new_shipping_address2,
                                  shipping_postcode: new_shipping_postcode,
                                  shipping_country: new_shipping_country,
                                  shipping_memo: new_shipping_memo,
                                  personal_code: new_personal_code,
                              }
                            : address[address_KEY],
                    payment_method: selectedPaymentMethod,
                    is_vr_discount: cart.vr_discount,
                })
                    .then((response) => {
                        if (response.data.code == '200') {
                            Dispatch(ClearCart());
                            localStorage.removeItem('persistantState');
                            toast.success('주문이 완료되었습니다.');
                            History.push('/order/view/' + response.data.body.order_ID);
                            // openAuthPayment(response.data.body.order_ID);
                        } else {
                            toast.error(response.data.body.message);
                            set_disabled(0);
                        }
                        Dispatch(SetLoading(0));
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error('서버 통신 오류');
                        Dispatch(SetLoading(0));
                        set_disabled(0);
                    });
            }
        }
    };

    const [authPayment, setAuthPayment] = useState(0);
    const [iframeLoad, setIframeLoad] = useState(0);
    const openAuthPayment = (order_ID) => {
        setAuthPayment(order_ID);
    };
    const closeAuthPayment = (order_ID) => {
        setAuthPayment(0);
        alert('결제가 취소되었습니다.');
        window.location.reload();
    };

    const pg_callback = (e) => {
        console.log(e, '포스트메세지 받음');
        if (e.data.messageId === 'success') {
            // 성공 포스트메세지 받은경우
            console.log('/order/view/' + e.data.params.order_id, '이동');

            Dispatch(ClearCart());
            localStorage.removeItem('persistantState');
            toast.success('주문이 완료되었습니다.');
            History.push('/order/view/' + e.data.params.order_id);
        } else if (e.data.messageId === 'fail') {
            alert('결제가 취소되었습니다.');
            window.location.reload();
        }
    };
    useEffect(() => {
        window.addEventListener('message', pg_callback);
        return () => window.addEventListener('message', pg_callback);
    }, []);

    useEffect(() => {
        if (!cart_items.length) {
            toast.error('장바구니가 비어있습니다.');
            History.goBack();
        }

        if (cart.is_point_order) {
            if (parseInt(cart.total) > parseInt(me.point)) {
                toast.error('포인트가 부족합니다.');
                Dispatch(ClearCart());
                History.goBack();
            } else {
                if (!cart.point) {
                    Dispatch(EarnPoint(parseInt(cart.total)));
                }
            }
        }
        if (!address) {
            GetAddress().then((response) => {
                if (response.data.code == '200') {
                    set_address(Object.assign([], response.data.body.addresses));
                    set_address_last_KEY(response.data.body.addresses.length);
                    set_test(address ? address[address_KEY].shipping_country : '');
                } else {
                }
            });
        }
    }, []);

    const [test, set_test] = useState();

    var key = 0;

    return (
        <MainContainer type="small">
            <PostCodeWrapper isOpen={isPostOpen != null}>
                {isPostOpen !== null ? <DaumPostcode onComplete={PostcodeComplete} /> : ''}
                <div onClick={(e) => setIsPostOpen(null)} className="overlay"></div>
            </PostCodeWrapper>

            <Wrapper>
                <Title>주문 정보</Title>
                <OrderInfoWrapper>
                    {cart_items
                        ? cart_items.map((item, key) => (
                              <OrderInfo>
                                  <ProductImage url={item.product_image} />
                                  <ProductDetail>
                                      <Name>
                                          <span>{item.product_name}</span>
                                      </Name>
                                      <Price>{parseInt(item.price).toLocaleString('en')}원</Price>
                                      <Amount>
                                          <div>
                                              <img
                                                  onClick={minusCount}
                                                  data-key={key}
                                                  src={process.env.PUBLIC_URL + '/img/minus.png'}
                                                  alt=""
                                              />
                                              <span>{item.qty}</span>
                                              <img
                                                  onClick={plusCount}
                                                  data-key={key}
                                                  src={process.env.PUBLIC_URL + '/img/plus.png'}
                                                  alt=""
                                              />
                                          </div>
                                      </Amount>
                                  </ProductDetail>
                                  <Delete onClick={RemoveCartItem} data-key={key}>
                                      <span>삭제</span>
                                  </Delete>
                              </OrderInfo>
                          ))
                        : ''}
                </OrderInfoWrapper>
                <SubTitle>주문자 정보</SubTitle>
                <InputSection>
                    <input
                        type="text"
                        onChange={(e) => set_billing_name(e.target.value)}
                        className="block"
                        placeholder="이름(입금자명)"
                    />
                    <input
                        type="text"
                        value={billing_email}
                        onChange={(e) => set_billing_email(e.target.value)}
                        placeholder="이메일 주소"
                    />
                    <input
                        type="text"
                        value={billing_phone}
                        onChange={(e) => set_billing_phone(e.target.value)}
                        placeholder="전화번호"
                    />
                </InputSection>
                <SubTitle>배송지 정보{isPostOpen}</SubTitle>
                {address
                    ? address.map((address_item, key) => (
                          <>
                              <PostBasic onCheck={address_KEY == key ? address_KEY : ''}>
                                  <label for="post_check" onClick={(e) => set_address_KEY(key)}>
                                      {address_KEY == key ? onCheck_icon : check_icon}
                                  </label>

                                  <TogglePen
                                      onClick={(e) => (revise == key ? set_revise(null) : set_revise(key))}
                                      isopen={revise == key}
                                  >
                                      <img src={process.env.PUBLIC_URL + '/img/post_pen.png'} alt="" />
                                  </TogglePen>
                                  <UserName>
                                      <p>{address_item.shipping_address1 ? address_item.shipping_address1 : ''}</p>
                                      {/* {address_KEY == key ? (<span>기본배송지</span>) : ''} */}
                                  </UserName>
                                  <UserPost>
                                      <span>
                                          {address_item.shipping_address2 ? address_item.shipping_address2 : ''}
                                      </span>
                                      <span>{address_item.personal_code ? address_item.personal_code : ''}</span>
                                      <span>{address_item.shipping_country ? address_item.shipping_country : ''}</span>
                                  </UserPost>
                              </PostBasic>
                              {/* 편집영역 */}
                              <UserInfor isopen={revise === key}>
                                  <SelectStyle>
                                      <select
                                          placeholder="국가선택"
                                          onChange={(e) =>
                                              change_address_field(key, 'shipping_country', e.target.value)
                                          }
                                      >
                                          {country_list
                                              ? country_list.map((val, key) => (
                                                    <option
                                                        selected={
                                                            address_item.shipping_country == val.code ? 'selected' : ''
                                                        }
                                                        value={val.code}
                                                    >
                                                        {val.title}
                                                    </option>
                                                ))
                                              : ''}
                                      </select>
                                  </SelectStyle>
                                  <InputSection>
                                      {address_item.shipping_country == 'KR' ? (
                                          <>
                                              <InputWrapper>
                                                  <input
                                                      className="block "
                                                      onClick={(e) => setIsPostOpen(key)}
                                                      readOnly={true}
                                                      value={
                                                          address_item.shipping_postcode
                                                              ? address_item.shipping_postcode
                                                              : ''
                                                      }
                                                      type="text"
                                                      placeholder="우편번호"
                                                  />
                                                  <button onClick={(e) => setIsPostOpen(key)} className="coupon">
                                                      우편번호 찾기
                                                  </button>
                                              </InputWrapper>
                                              <input
                                                  type="text"
                                                  class=""
                                                  onClick={(e) => setIsPostOpen(key)}
                                                  readOnly={true}
                                                  value={
                                                      address_item.shipping_address1
                                                          ? address_item.shipping_address1
                                                          : ''
                                                  }
                                                  placeholder="주소"
                                              />
                                          </>
                                      ) : (
                                          <>
                                              <InputWrapper>
                                                  <input
                                                      className="block "
                                                      onChange={(e) =>
                                                          change_address_field(key, 'shipping_postcode', e.target.value)
                                                      }
                                                      value={
                                                          address_item.shipping_postcode
                                                              ? address_item.shipping_postcode
                                                              : ''
                                                      }
                                                      type="text"
                                                      placeholder="우편번호"
                                                  />
                                              </InputWrapper>
                                              <input
                                                  type="text"
                                                  class=""
                                                  onChange={(e) =>
                                                      change_address_field(key, 'shipping_address1', e.target.value)
                                                  }
                                                  value={
                                                      address_item.shipping_address1
                                                          ? address_item.shipping_address1
                                                          : ''
                                                  }
                                                  placeholder="주소"
                                              />
                                          </>
                                      )}

                                      <input
                                          type="text"
                                          ref={shipping_address1_el}
                                          value={address_item.shipping_address2 ? address_item.shipping_address2 : ''}
                                          onChange={(e) =>
                                              change_address_field(key, 'shipping_address2', e.target.value)
                                          }
                                          placeholder="상세주소 (영어로 작성해주세요.)"
                                      />
                                      <input
                                          type="text"
                                          value={address_item.shipping_memo}
                                          onChange={(e) => change_address_field(key, 'shipping_memo', e.target.value)}
                                          placeholder="배송메모"
                                      />
                                      <input
                                          type="text"
                                          value={address_item.personal_code ? address_item.personal_code : ''}
                                          onChange={(e) => change_address_field(key, 'personal_code', e.target.value)}
                                          placeholder="개인통관번호 (선택사항)"
                                      />
                                      <EditDone
                                          onClick={(e) => {
                                              set_revise(null);
                                              set_address_KEY(key);
                                          }}
                                          isopen={revise}
                                      >
                                          <button>편집 완료</button>
                                      </EditDone>
                                  </InputSection>
                              </UserInfor>
                          </>
                      ))
                    : ''}
                {/* 신규 배송지 */}
                <PostNewBasic onCheck={address_KEY == address_last_KEY ? address_KEY : ''}>
                    <label for="check" onClick={(e) => set_address_KEY(address_last_KEY)}>
                        {address_KEY == address_last_KEY ? onCheck_icon : check_icon}
                    </label>
                    <ToggleNewPen>
                        <img src={process.env.PUBLIC_URL + '/img/post_pen.png'} alt="" />
                    </ToggleNewPen>
                    <PostNew>
                        <p>새로운 주소로 배송</p>
                    </PostNew>
                </PostNewBasic>
                {address_KEY == address_last_KEY ? (
                    <>
                        <SelectStyle>
                            <select
                                placeholder="국가선택"
                                value={new_shipping_country}
                                onChange={(e) => set_new_shipping_country(e.target.value)}
                            >
                                {country_list
                                    ? country_list.map((val, key) => <option value={val.code}>{val.title}</option>)
                                    : ''}
                            </select>
                        </SelectStyle>
                        <InputSection>
                            {new_shipping_country == 'KR' ? (
                                <>
                                    <InputWrapper>
                                        <input
                                            className="block on_postcode"
                                            onClick={(e) => setIsPostOpen(true)}
                                            readOnly={true}
                                            value={new_shipping_postcode ? new_shipping_postcode : ''}
                                            type="text"
                                            placeholder="우편번호"
                                        />
                                        <button onClick={(e) => setIsPostOpen(true)} className="coupon">
                                            우편번호 찾기
                                        </button>
                                    </InputWrapper>
                                    <input
                                        type="text"
                                        class="on_postcode"
                                        onClick={(e) => setIsPostOpen(true)}
                                        readOnly={true}
                                        value={new_shipping_address1 ? new_shipping_address1 : ''}
                                        placeholder="주소"
                                    />
                                </>
                            ) : (
                                <>
                                    <InputWrapper>
                                        <input
                                            className="block "
                                            onChange={(e) => set_new_shipping_postcode(e.target.value)}
                                            value={new_shipping_postcode ? new_shipping_postcode : ''}
                                            type="text"
                                            placeholder="우편번호"
                                        />
                                    </InputWrapper>
                                    <input
                                        type="text"
                                        class=""
                                        onChange={(e) => set_new_shipping_address1(e.target.value)}
                                        value={new_shipping_address1 ? new_shipping_address1 : ''}
                                        placeholder="주소"
                                    />
                                </>
                            )}

                            <input
                                type="text"
                                ref={shipping_address1_el}
                                value={new_shipping_address2 ? new_shipping_address2 : ''}
                                onChange={(e) => set_new_shipping_address2(e.target.value)}
                                placeholder="상세주소 (영어로 작성해주세요.)"
                            />
                            <input
                                type="text"
                                value={new_shipping_memo}
                                onChange={(e) => set_new_shipping_memo(e.target.value)}
                                placeholder="배송메모"
                            />
                            <input
                                type="text"
                                value={new_personal_code ? new_personal_code : ''}
                                onChange={(e) => set_new_personal_code(e.target.value)}
                                placeholder="개인통관번호 (선택사항)"
                            />
                        </InputSection>
                    </>
                ) : (
                    ''
                )}
                {!cart.is_point_order ? (
                    <>
                        <SubTitle>쿠폰/포인트</SubTitle>
                        <InputSection>
                            <InputWrapper>
                                <input
                                    className="input-coupon"
                                    type="text"
                                    value={coupon_code}
                                    onChange={(e) => set_coupon_code(e.target.value)}
                                    placeholder="쿠폰 코드를 입력해주세요"
                                />
                                <button onClick={UseCoupon_} className="coupon">
                                    쿠폰 적용
                                </button>
                                {cart.coupon ? (
                                    <a href="javascript:;" onClick={RemoveCoupon_} className="coupon_remove">
                                        적용 취소
                                    </a>
                                ) : (
                                    ''
                                )}
                                {/* <span>보유중인 포인트가 없습니다.</span> */}
                            </InputWrapper>
                            <InputWrapper>
                                <input
                                    className="input-point"
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value)) {
                                            set_use_point(e.target.value);
                                        }
                                    }}
                                    value={use_point}
                                    type="text"
                                    placeholder="0"
                                />
                                <button onClick={UsePoint} className="point">
                                    사용
                                </button>
                                {me &&
                                    (me.point ? (
                                        <span>
                                            <strong>{me.point - cart.point}P</strong> 사용 가능
                                        </span>
                                    ) : (
                                        <span>보유중인 포인트가 없습니다.</span>
                                    ))}
                            </InputWrapper>
                        </InputSection>
                    </>
                ) : (
                    ''
                )}
                <Payment>
                    <PaymentRow>
                        <span>총 주문금액</span>
                        <span>
                            {parseInt(cart.subtotal).toLocaleString('en')}
                            {!cart.is_point_order ? '원' : '포인트'}
                        </span>
                    </PaymentRow>
                    {!cart.is_point_order ? (
                        <PaymentRow>
                            <span>쿠폰</span>
                            <spann>
                                <strong>{cart.coupon ? '[' + cart.coupon + ']' : ''}</strong>{' '}
                                {(cart.coupon_total ? '-' : '') + parseInt(cart.coupon_total).toLocaleString('en')}
                                {!cart.is_point_order ? '원' : '포인트'}
                            </spann>
                        </PaymentRow>
                    ) : (
                        ''
                    )}
                    <PaymentRow>
                        <span>포인트</span>
                        <spann>
                            -{parseInt(cart.point).toLocaleString('en')} {!cart.is_point_order ? '원' : '포인트'}
                        </spann>
                    </PaymentRow>
                    <PaymentRow>
                        <span>배송비</span>
                        <span>
                            {(address && address[address_KEY]
                                ? address[address_KEY].shipping_country
                                : new_shipping_country) == 'KR' ||
                            (address && address[address_KEY]
                                ? address[address_KEY].shipping_country
                                : new_shipping_country) == 'JP'
                                ? '무료배송'
                                : '30,000원'}
                        </span>
                    </PaymentRow>
                    <PaymentRow className="last-row">
                        <span>총 결제금액</span>
                        <span className="color">
                            {parseInt(
                                cart.total +
                                    ((address && address[address_KEY]
                                        ? address[address_KEY].shipping_country
                                        : new_shipping_country) != 'KR' &&
                                    (address && address[address_KEY]
                                        ? address[address_KEY].shipping_country
                                        : new_shipping_country) != 'JP'
                                        ? 30000
                                        : 0)
                            ).toLocaleString('en')}
                            원
                        </span>
                    </PaymentRow>
                </Payment>
                <PaymentMethod>
                    <PaymentMethodTitle>결제수단</PaymentMethodTitle>
                    <PaymentMethodRow className="last-row">
                        {/* <PaymentMethodItem
              onCheck={selectedPaymentMethod == 'bacs' ? '1' : ''}
            >
              <label onClick={e => selectPaymentMethod('bacs')}>
                {selectedPaymentMethod == 'bacs' ? onCheck_icon : check_icon}
              </label>
              <PaymentMethodItemName>
                <p>무통장입금</p>
              </PaymentMethodItemName>
            </PaymentMethodItem> */}
                        <PaymentMethodItem onCheck={selectedPaymentMethod == 'paykiwoom' ? '1' : ''}>
                            <label for="post_check" onClick={(e) => selectPaymentMethod('paykiwoom')}>
                                {selectedPaymentMethod == 'paykiwoom' ? onCheck_icon : check_icon}
                            </label>
                            <PaymentMethodItemName>
                                <p>가상계좌</p>
                                {/* <p>카카오페이</p> */}
                            </PaymentMethodItemName>
                        </PaymentMethodItem>
                    </PaymentMethodRow>
                </PaymentMethod>
                <AgreementSection>
                    <Agreement onClick={Toggle} className="active">
                        <span>
                            <input type="checkbox" id="term" onChange={(e) => set_term_check(e.target.checked)} />
                            <label for="term">{checkIcon}</label>
                            본인은 웹사이트 이용 약관을 동의합니다.
                            <ArrowDown className="svg" src={process.env.PUBLIC_URL + '/img/arrow-down.png'} />
                        </span>
                        <AgreementDetail onClick={[]}>
                            해외 의약품 개인수입 대행 특성상 주문하신 상품은 해외에서 고객님께 직배송 해드리며 재고를
                            보유하지 않고 있습니다.
                            <br />
                            주문을 받으면 즉시 현지에서 상품이 구매된 후 직발송 처리 하고있습니다.
                            <br />
                            현지에서 상품이 구매된 후 상품 배송이 시작되면 취소 및 환불은 할 수 없습니다.
                            <br /> 제품 수령 후 문제가 있을 시 수령일 기준 7일 안으로 연락주세요. 의약품 특성상 7일 경과
                            후 대응이 불가능합니다.
                        </AgreementDetail>
                    </Agreement>
                    <Agreement onClick={Toggle} className="active">
                        <span>
                            <input
                                type="checkbox"
                                id="shipping_notice"
                                onChange={(e) => set_shipping_notice_check(e.target.checked)}
                            />
                            <label for="shipping_notice">{checkIcon}</label>
                            배송 지연을 이해하며 동의합니다.
                            <ArrowDown className="svg" src={process.env.PUBLIC_URL + '/img/arrow-down.png'} />
                        </span>
                        <AgreementDetail onClick={[]}>
                            생산 및 유통 이슈로 배송지연이 발생하고 있습니다.
                            <br />
                            발송처 별 수령 기간안내 (주문일 기준)
                            <br />
                            싱가포르 : 2주 ~ 4주 사이입니다.
                            <br />
                            홍콩 : 2주 ~ 4주 사이입니다.
                            <br />
                            인도 : 2주 ~ 4주 사이입니다.
                            <br />
                            현지 사정에 따라 안내 된 예정일 보다 연장 될 수 있습니다.
                            <br />
                            배송 지연으로 인한 주문 취소는 할 수 없습니다.
                            <br />
                            상품이 리뉴얼 되어 배송될 수 있습니다.
                        </AgreementDetail>
                    </Agreement>
                </AgreementSection>
                {/* <AgreementSection>
          <Agreement>
            <span style={{ fontWeight: 'bold', color: 'red' }}>
              30분내 입금하지 않을시, 자동으로 주문이 취소되며, 재주문 해주셔야
              합니다
            </span>
          </Agreement>
        </AgreementSection> */}
                <ConfirmButton
                    disabled={disabled}
                    onClick={disabled ? (e) => console.log('주문처리중입니다.') : DoOrder}
                >
                    주문확정
                    <LoadingDot disabled={disabled} class="loading dot">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </LoadingDot>
                </ConfirmButton>
                {authPayment ? (
                    <AuthPaymentLayer>
                        <div className="container">
                            <iframe
                                src={'https://api-sakuraherb.com/vr-payment/?order_ID=' + authPayment}
                                frameborder="0"
                                onLoad={(e) => setIframeLoad(1)}
                            ></iframe>
                            {!iframeLoad ? (
                                <div className="iframe-loading">
                                    <LoadingRound /> <p>결제창 로딩중..</p>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="overlay" onClick={(e) => closeAuthPayment()}></div>
                    </AuthPaymentLayer>
                ) : (
                    ''
                )}
            </Wrapper>
        </MainContainer>
    );
};

export default Order;
