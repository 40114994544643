import styled from 'styled-components';

export const Wrapper = styled.div`
    max-height: 550px;
    margin-bottom:20px;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar{
        display: none;
    }
    @media ${props => props.theme.mobile}{
        padding: 0 20px;
        
    }
`;
export const PopupContainer = styled.div`
    padding: 0 30px;
    @media ${props => props.theme.mobile}{
        padding: 0 0px;
    }
`;

export const Title = styled.div`
    border-bottom: 1px solid #000;
    padding: 30px 0px 20px 0;
    h2{
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        color: #000;
    }
    @media ${props => props.theme.mobile}{
        padding: 30px 0 9px 0;
        margin: 0 20px;
        h2{
            font-size: 16px;
        }
    }
`;

export const Information = styled.div`
	margin-bottom: 50px;
	position: relative;
	button{
		position: absolute;
		top: 74%;
		right: 25px;
		width: 150px;
		transform: translateY(-50%);
		font-size: 16px;
		font-weight: bold;
		height: 56px;
		line-height: 56px;
		margin-bottom: 0;
		border: none;
		background-color: #6a36ff;
		color: #000;
		& > div{
			margin-bottom: 0;
		}
	}
	select{
		position: relative;
		border: none;
		background-color: #212428;
		padding-left: 20px;
		width: 100%;
		height: 58px;
		color: #fff;
		line-height: 58px;
		background-size: 14px 14px;
		background-repeat: no-repeat;
    	background-position: calc(100% - 20px) center;
		background-image: url('/img/select_arrow.svg');
	}
    input {
        display:none;
    }
	.load{
		svg{
			vertical-align: sub;
		}
	}
	.load_file{
		/* display: none; */
	}
	@media ${props => props.theme.mobile}{
		margin-bottom: 20px;
		button{
			top: 67%;
			right: 0;
			width: 100px;
			height: 54px;
			line-height: 54px;
			font-size: 12px;
		}
		select{
			height: 50px;
			line-height: 50px;
			background-size: 12px 12px;
		}
	}
`;
export const Write = styled.div`
    margin: 30px 0px 0px 0px;
    input {
        width:100%;
        position: relative;
        display: block;
        border-radius: 10px;
        border: 1px solid #f0f0f0;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.71;
        padding: 20px;
        ::placeholder{
            font-size: 14px;
            color: #666;
        }
    }
    @media ${props=>props.theme.mobile} {
        margin: 20px 0px 0px 0px;
        input {
            padding:20px;
            font-size:13px;
            line-height: 1.85;
            ::placeholder{
                font-size:13px;
                color: #666;
            }
        }
        textarea {
            padding: 15px;
            height:125px;
            font-size:13px;
            ::placeholder{
                font-size:13px;
                color: #666;
            }
        }
    }
`;

export const ReviewGallery = styled.div`
    overflow-x: scroll;
    overflow-y: visible;
    padding: 20px 0px 0px;
    width: 100%;
    @media ${props => props.theme.mobile}{
        padding: 20px 0 0 0;
    }
`;

export const GalleryList = styled.div`
    width: auto;
    max-width: 2200px;
    margin: 0px -5px;
    padding-bottom: 10px;
    .box{
        display: inline-block;
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 2px;
        vertical-align: top;
        margin-left:5px;
        :first-child {
            border: 1px dashed #9a9a9e;
        }
        a {
            cursor:pointer;
            display:none;
            position: absolute;
            top: 0px;
            right: 0px;
            transform:translate(50%,-50%);
            content:"";
            width: 18px;
            height: 18px;
            background-image: url("/img/close_button.svg");
			background-size: contain;
			background-color: #fff;
			border-radius: 50%;
			border: 1px solid #111;
        }
        :hover{
            a {
                display:block;
            }
        }
    }
    @media ${props => props.theme.mobile}{
    }
`;

export const Upload = styled.div`
    display:block;
    text-align: center;
    transform: translateY(50%);
    input {
        display:none;
    }
    cursor: pointer;
    p{
        font-size: 11px;
        font-weight: bold;
        color: #9a9a9e;
        span{
            color: #00D1FC;
        }
    }
`;

export const Gallery = styled.div`
    ${props => props.url ? `background: url("${props.url}");` : ''};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 2px;
    width: auto;
    height: 100%;
    cursor: pointer;
`;

export const UploadButton = styled.div`
    width: 100%;
    height: auto;
    padding: 17px 0px;
    margin-top: 50px;
    display: block;
    position: relative;
    border-radius: 200px;
    background-color: #f5f5f5;;
    font-size: 15px;
    font-weight: 500;
    text-align: center; 
    color: #111;
    cursor: pointer;
    @media ${props => props.theme.mobile}{
        display: none;
    }
`;


export const Name = styled.div`
	margin-bottom: 28px;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
    margin-top:20px;
	color: #000;
	span{
		font-size: 26px;
		color: #6a36ff;
	}
	small {
        color:red;
		font-size: 12px;
		font-weight: 300;
		margin-left: 0px;
	}
	@media ${props => props.theme.mobile}{
		font-size: 12px;
		line-height: 16px;
	}
`;
export const Example = styled.div`
    margin-top:40px;
    background:#f7f7f7;
    padding:20px;
    p {
        font-size: 12px;
    }
`;
export const Load = styled.div`
	margin-bottom: 12px;
	color: #fff;
	label {
		vertical-align: middle;
        cursor:pointer;
		padding-right: 30px;
		background: #fff;
		border: 1px solid #eee;
		color: #000;
		border-radius: 5px;
		padding: 14px 10px;
		margin-right: 30px;
		font-size: 13px;
        transition:.5s;
        :hover {
            background:#eee;
        }
	}
	.demension {
	}
	svg {
		padding: 0 5px;
	}
`;



export const ImageArea = styled.div`
    margin-top: 20px;
	/* margin-top: 20px; */
	${props => props.row ? `
		min-height: 120px;
	` : ''}
`;

export const ExampleImage = styled.div`
	width: 300px;
	height: 350px;
	${props => props.url ? `
		border: 1px solid #eee;
	` : `border: 1px solid #eee;`};
	background-image: url(${props => props.url ? props.url : ''});
    background-color:#fff;
	background-size: 80%;
	background-repeat: no-repeat;
	background-position: top center;
	border-radius: 5px;
`;
export const CiImage = styled.div`
	width: 100px;
	height: 100px;
	${props => props.url ? `
		border: 1px solid #eee;
	` : `border: 1px solid #eee;`};
	background-image: url(${props => props.url ? props.url : ''});
	background-color: #f7f7f7;
	background-size: 80%;
	background-repeat: no-repeat;
	background-position: top center;
	border-radius: 5px;
`;

export const ButtonWrapper = styled.div`
    padding: 30px 0;
    margin: 0 -5px;
    @media ${props => props.theme.mobile}{
        padding: 0 0;
        margin: 0 0;
        position: absolute;
        display: block;
        bottom: 0px;
        width: 100%;
    }
    @media(max-width:360px){
        bottom: -30px;
    }
    @media(max-width:330px){
        bottom: -40px;
    }
`;

export const CancelButton = styled.div`
    width: 50%;
    display: inline-block;
    padding: 0 5px;
    box-sizing: border-box;
    a{
        width: 100%;
        background-color: #aaa;
        color: #fff;
        transition: .4s;
        :hover{
            opacity: 0.6;
        }
    }
    @media ${props => props.theme.mobile}{
        padding: 0 0;
        a{
            font-size: 13px;
            border-radius: 0;
            height: 50px;
            line-height: 48px;
        }
    }
`;

export const WriteButton = styled.div`
    width: 50%;
    display: inline-block;
    padding: 0 5px;
    box-sizing: border-box;
    a{
        width: 100%;
        transition: .4s;
        :hover{
            opacity: 0.6;
        }
    }
    @media ${props => props.theme.mobile}{
        padding: 0 0;
        a{
            font-size: 13px;
            border-radius: 0;
            height: 50px;
            line-height: 48px;
        }
    }
`;

export const Scope = styled.div`
    width: 100%;
    label{
        display: inline-block;
        width: 20%;
        height: 40px;
        line-height: 37px;
        text-align: center;
        box-sizing: border-box;
        border: 1px solid #eee;
        cursor: pointer;
        transition: .4s;
        :hover{
            opacity: 0.6;
        }
    }
    label:nth-child(2) {
        border-radius: 5px 0 0 5px;
    }
    label:last-child {
        border-radius: 0 5px 5px 0;
    }
    input[type=radio]{
        display: none !important;
    }
    
    svg {
        margin-right: 5px;
    }
    input[type=radio]:checked + label {
        color: #00D1FC;
        border: 1px solid #00D1FC;
        transition: all ease 0.4s;   
        :hover{
            opacity: 1;
        }   
        svg{
            path {
                transition: all ease 0.4s;      
                fill: #00D1FC;
            }
            rect {
                transition: all ease 0.4s;      
                stroke:#00D1FC;
                fill:  #00D1FC; 
            }
        }
    } 
    @media ${props => props.theme.mobile}{
        label{
            font-size: 12px;
        }
        svg{
            margin-right: 2px;
            vertical-align: middle;
        }
    }
`;
