export default [
{code: "KR", title: '대한민국'},
{code: "JP", title: '일본'},
{code: "CN", title: '중국'},
{code: "US", title: 'United States (US)'},
{code: "GB", title: 'United Kingdom (UK)'},
{code: "AS", title: 'American Samoa'},
{code: "BJ", title: 'Benin'},
{code: "BQ", title: 'Bonaire, Saint Eustatius and Saba'},
{code: "DK", title: 'Denmark'},
{code: "DJ", title: 'Djibouti'},
{code: "DM", title: 'Dominica'},
{code: "DO", title: 'Dominican Republic'},
{code: "FK", title: 'Falkland Islands'},
{code: "TF", title: 'French Southern Territories'},
{code: "GA", title: 'Gabon'},
{code: "GM", title: 'Gambia'},
{code: "GE", title: 'Georgia'},
{code: "DE", title: 'Germany'},
{code: "GH", title: 'Ghana'},
{code: "GI", title: 'Gibraltar'},
{code: "GR", title: 'Greece'},
{code: "GL", title: 'Greenland'},
{code: "GD", title: 'Grenada'},
{code: "GP", title: 'Guadeloupe'},
{code: "GU", title: 'Guam'},
{code: "GT", title: 'Guatemala'},
{code: "GG", title: 'Guernsey'},
{code: "GN", title: 'Guinea'},
{code: "GW", title: 'Guinea-Bissau'},
{code: "GY", title: 'Guyana'},
{code: "HT", title: 'Haiti'},
{code: "HM", title: 'Heard Island and McDonald Islands'},
{code: "HN", title: 'Honduras'},
{code: "HK", title: 'Hong Kong'},
{code: "HU", title: 'Hungary'},
{code: "IS", title: 'Iceland'},
{code: "IN", title: 'India'},
{code: "ID", title: 'Indonesia'},
{code: "IR", title: 'Iran'},
{code: "IQ", title: 'Iraq'},
{code: "IM", title: 'Isle of Man'},
{code: "IL", title: 'Israel'},
{code: "IT", title: 'Italy'},
{code: "CI", title: 'Ivory Coast'},
{code: "JM", title: 'Jamaica'},
{code: "JE", title: 'Jersey'},
{code: "JO", title: 'Jordan'},
{code: "KZ", title: 'Kazakhstan'},
{code: "KE", title: 'Kenya'},
{code: "KI", title: 'Kiribati'},
{code: "KW", title: 'Kuwait'},
{code: "KG", title: 'Kyrgyzstan'},
{code: "LA", title: 'Laos'},
{code: "LV", title: 'Latvia'},
{code: "LB", title: 'Lebanon'},
{code: "LS", title: 'Lesotho'},
{code: "LR", title: 'Liberia'},
{code: "LY", title: 'Libya'},
{code: "LI", title: 'Liechtenstein'},
{code: "LT", title: 'Lithuania'},
{code: "LU", title: 'Luxembourg'},
{code: "MO", title: 'Macao'},
{code: "MG", title: 'Madagascar'},
{code: "MW", title: 'Malawi'},
{code: "MY", title: 'Malaysia'},
{code: "MV", title: 'Maldives'},
{code: "ML", title: 'Mali'},
{code: "MT", title: 'Malta'},
{code: "MH", title: 'Marshall Islands'},
{code: "MQ", title: 'Martinique'},
{code: "MR", title: 'Mauritania'},
{code: "MU", title: 'Mauritius'},
{code: "YT", title: 'Mayotte'},
{code: "MX", title: 'Mexico'},
{code: "FM", title: 'Micronesia'},
{code: "MD", title: 'Moldova'},
{code: "MC", title: 'Monaco'},
{code: "MN", title: 'Mongolia'},
{code: "ME", title: 'Montenegro'},
{code: "MS", title: 'Montserrat'},
{code: "MA", title: 'Morocco'},
{code: "MZ", title: 'Mozambique'},
{code: "MM", title: 'Myanmar'},
{code: "NA", title: 'Namibia'},
{code: "NR", title: 'Nauru'},
{code: "NP", title: 'Nepal'},
{code: "NL", title: 'Netherlands'},
{code: "NC", title: 'New Caledonia'},
{code: "NZ", title: 'New Zealand'},
{code: "NI", title: 'Nicaragua'},
{code: "NE", title: 'Niger'},
{code: "NG", title: 'Nigeria'},
{code: "NU", title: 'Niue'},
{code: "NF", title: 'Norfolk Island'},
{code: "MP", title: 'Northern Mariana Islands'},
{code: "NO", title: 'Norway'},
{code: "OM", title: 'Oman'},
{code: "PK", title: 'Pakistan'},
{code: "PS", title: 'Palestinian Territory'},
{code: "PA", title: 'Panama'},
{code: "PG", title: 'Papua New Guinea'},
{code: "PY", title: 'Paraguay'},
{code: "PE", title: 'Peru'},
{code: "PH", title: 'Philippines'},
{code: "PN", title: 'Pitcairn'},
{code: "PL", title: 'Poland'},
{code: "PT", title: 'Portugal'},
{code: "PR", title: 'Puerto Rico'},
{code: "QA", title: 'Qatar'},
{code: "RE", title: 'Reunion'},
{code: "RO", title: 'Romania'},
{code: "RU", title: 'Russia'},
{code: "RW", title: 'Rwanda'},
{code: "ST", title: 'São Tomé and Príncipe'},
{code: "BL", title: 'Saint Barthélemy'},
{code: "SH", title: 'Saint Helena'},
{code: "KN", title: 'Saint Kitts and Nevis'},
{code: "LC", title: 'Saint Lucia'},
{code: "SX", title: 'Saint Martin (Dutch part)'},
{code: "MF", title: 'Saint Martin (French part)'},
{code: "PM", title: 'Saint Pierre and Miquelon'},
{code: "VC", title: 'Saint Vincent and the Grenadines'},
{code: "SM", title: 'San Marino'},
{code: "SA", title: 'Saudi Arabia'},
{code: "SN", title: 'Senegal'},
{code: "RS", title: 'Serbia'},
{code: "SC", title: 'Seychelles'},
{code: "SL", title: 'Sierra Leone'},
{code: "SG", title: 'Singapore'},
{code: "SK", title: 'Slovakia'},
{code: "SI", title: 'Slovenia'},
{code: "SB", title: 'Solomon Islands'},
{code: "SO", title: 'Somalia'},
{code: "ZA", title: 'South Africa'},
{code: "GS", title: 'South Georgia/Sandwich Islands'},
{code: "SS", title: 'South Sudan'},
{code: "ES", title: 'Spain'},
{code: "LK", title: 'Sri Lanka'},
{code: "SD", title: 'Sudan'},
{code: "SR", title: 'Suriname'},
{code: "SJ", title: 'Svalbard and Jan Mayen'},
{code: "SZ", title: 'Swaziland'},
{code: "SE", title: 'Sweden'},
{code: "CH", title: 'Switzerland'},
{code: "SY", title: 'Syria'},
{code: "TW", title: 'Taiwan'},
{code: "TJ", title: 'Tajikistan'},
{code: "TZ", title: 'Tanzania'},
{code: "TH", title: 'Thailand'},
{code: "TL", title: 'Timor-Leste'},
{code: "TG", title: 'Togo'},
{code: "TK", title: 'Tokelau'},
{code: "TO", title: 'Tonga'},
{code: "TT", title: 'Trinidad and Tobago'},
{code: "TN", title: 'Tunisia'},
{code: "TR", title: 'Turkey'},
{code: "TM", title: 'Turkmenistan'},
{code: "TC", title: 'Turks and Caicos Islands'},
{code: "TV", title: 'Tuvalu'},
{code: "UG", title: 'Uganda'},
{code: "UA", title: 'Ukraine'},
{code: "AE", title: 'United Arab Emirates'},
{code: "UY", title: 'Uruguay'},
{code: "UZ", title: 'Uzbekistan'},
{code: "VU", title: 'Vanuatu'},
{code: "VA", title: 'Vatican'},
{code: "VE", title: 'Venezuela'},
{code: "VN", title: 'Vietnam'},
{code: "WF", title: 'Wallis and Futuna'},
{code: "EH", title: 'Western Sahara'},
{code: "YE", title: 'Yemen'},
{code: "ZM", title: 'Zambia'},
{code: "ZW", title: 'Zimbabwe'},
{code: "AX", title: 'Åland Islands'},
{code: "AQ", title: '남극'},
{code: "UM", title: '미국령 군소 제도'},
{code: "BH", title: '바레인'},
{code: "BB", title: '바베이도스'},
{code: "BS", title: '바하마'},
{code: "BD", title: '방글라데시'},
{code: "BM", title: '버뮤다'},
{code: "VI", title: '버진아일랜드(미국령)'},
{code: "VG", title: '버진아일랜드(영국령)'},
{code: "BE", title: '벨기에'},
{code: "BY", title: '벨라루스'},
{code: "BZ", title: '벨리즈'},
{code: "BA", title: '보스니아 &amp; 헤르체고비나'},
{code: "BW", title: '보츠와나'},
{code: "BO", title: '볼리비아'},
{code: "BI", title: '부룬디'},
{code: "BF", title: '부르키나 파소'},
{code: "BV", title: '부베섬'},
{code: "BT", title: '부탄'},
{code: "MK", title: '북마케도니아'},
{code: "BG", title: '불가리아'},
{code: "BR", title: '브라질'},
{code: "BN", title: '브루나이'},
{code: "WS", title: '사모아'},
{code: "AW", title: '아루바'},
{code: "AM", title: '아르메니아'},
{code: "AR", title: '아르헨티나'},
{code: "IE", title: '아일랜드'},
{code: "AZ", title: '아제르바이잔'},
{code: "AF", title: '아프가니스탄'},
{code: "AD", title: '안도라'},
{code: "AL", title: '알바니아'},
{code: "DZ", title: '알제리'},
{code: "AO", title: '앙골라'},
{code: "AG", title: '앤티가 바부다'},
{code: "AI", title: '앵귈라'},
{code: "ER", title: '에리트레아'},
{code: "EE", title: '에스토니아'},
{code: "EC", title: '에콰도르'},
{code: "ET", title: '에티오피아'},
{code: "SV", title: '엘살바도르'},
{code: "IO", title: '영국령 인도양 지역'},
{code: "AT", title: '오스트리아'},
{code: "EG", title: '이집트'},
{code: "GQ", title: '적도 기니'},
{code: "CF", title: '중앙아프리카공화국'},
{code: "TD", title: '차드'},
{code: "CZ", title: '체코 공화국'},
{code: "CL", title: '칠레'},
{code: "CM", title: '카메룬'},
{code: "CV", title: '카보베르데'},
{code: "KH", title: '캄보디아'},
{code: "CA", title: '캐나다'},
{code: "KY", title: '케이먼제도'},
{code: "KM", title: '코모로'},
{code: "CR", title: '코스타리카'},
{code: "CC", title: '코코스 제도'},
{code: "CO", title: '콜롬비아'},
{code: "CG", title: '콩고 (브라자빌)'},
{code: "CD", title: '콩고 (킨샤사)'},
{code: "CU", title: '쿠바'},
{code: "CK", title: '쿡 제도'},
{code: "CW", title: '퀴라소'},
{code: "HR", title: '크로아티아'},
{code: "CX", title: '크리스마스 아일랜드'},
{code: "CY", title: '키프로스'},
{code: "PW", title: '팔라우'},
{code: "FO", title: '패로 제도'},
{code: "FR", title: '프랑스'},
{code: "GF", title: '프랑스령 기아나'},
{code: "PF", title: '프랑스령 폴리네시아'},
{code: "FJ", title: '피지'},
{code: "FI", title: '핀란드'},
{code: "AU", title: '호주'},
];