import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {toast} from 'react-toastify';
import LoadingRound from '../../../../components/LoadingRound';
import { NoticeGetPost } from '../../../../api/notice';
import { 
    CommentGetPosts,
    CommentInsert,
    CommentUpdate,
    CommentDelete
} from '../../../../api/comment';
import {
    Wrapper,
    TopSection,
    Title,
    Date,
    Content,
    Comment,
    CommentWrite,
    Total,
    CommentListWrapper,
    CommentList,
    Avartar,
    Detail,
    Editor,
    UserID,
    CommentDate,
    CommentEdit,
    CommentRm,
    CommentContent,
    MoreButton
} from './styled';


const NoticeSingle = () => {

    const params = useParams();
    const [notice, set_notice] = useState('');
    const [comment, set_comment] = useState('');
    const [each_comment, set_each_comment] = useState('');

    const [new_comment, set_new_comment] = useState('');

    useEffect(() => {
        if(!notice){
            NoticeGetPost({
                notice_ID: params.ID
            })
            .then(response => {
                if(response.data.code == 200){
                    set_notice(response.data.body);
                    console.log(response.data.body);
                }
            })
            .catch(error => console.log(error));
        } 
        // if(!comment){
        //     CommentGetPosts({
        //         post_ID: params.ID,
        //         posts_per_page: 5,
        //         paged: 1
        //     })
        //     .then(response => {
        //         if(response.data.code == 200){
        //             set_comment(response.data.body);
        //             set_each_comment(response.data.body.comments);
        //             console.log(response.data.body);
        //         }
        //     })
        //     .catch(error => console.log(error));
        // } 
    });

    // const InsertCmt = () => {
    //     CommentInsert({
    //         post_ID: params.ID,
    //         comment_content: new_comment
    //     })
    //     .then(response => {
    //         if(response.data.code == 200){  
    //             toast.success('댓글 등록 완료!');
    //         }
    //     })
    //     .catch(error => console.log(error));
    // }
    // const UpdateCmt = () => {
    //     CommentUpdate({
    //         comment_ID: 'comment_ID',
    //         comment_content: 'comment_content'
    //     })
    //     .then(response => {
    //         if(response.data.code == 200){
    //             toast.success('댓글 수정 완료!');
    //         }
    //     })
    //     .catch(error => console.log(error));
    // }
    // const DeleteCmt = () => {
    //     CommentDelete({
    //         comment_ID: 'comment_ID'
    //     })
    //     .then(response => {
    //         if(response.data.code == 200){
    //             toast.success('댓글 삭제 완료!');
    //         }
    //     })
    //     .catch(error => console.log(error));
    // }

    return (
        <Wrapper>
            <TopSection>
                <Title dangerouslySetInnerHTML={{__html:notice.post_title ? notice.post_title : ''}}></Title>
                <Date>
                    {notice.post_date ? notice.post_date : ''}
                </Date>
            </TopSection>
            {notice.post_content ? (
                <Content dangerouslySetInnerHTML={{
                        __html: notice.post_content
                    }}/>
            ) : (
                <Content>
                    <LoadingRound/>
                </Content>
            )}
            
            {/* <Comment>
                <CommentWrite>
                    <input type="text" onChange={(e) => set_new_comment(e.target.value)} placeholder="댓글을 달아주세요." />
                    <button onClick={InsertCmt}>
                        등록
                    </button>
                </CommentWrite>
                <Total>
                    <strong>총 </strong><span>{comment.count ? comment.count : '5'}</span>개의 댓글이 있습니다.
                </Total>
                <CommentListWrapper>
                    {each_comment ? each_comment.map((item, key) => {
                            return (
                                <CommentList>
                                    <Avartar />
                                    <Detail>
                                        <UserID>
                                            {item.author_name ? item.author_name : ''}
                                        </UserID>
                                        <CommentDate>
                                            {item.comment_date ? item.comment_date : ''}
                                        </CommentDate>
                                        <Editor>
                                            <CommentEdit onClick={UpdateCmt}>
                                                수정
                                            </CommentEdit>
                                            <CommentRm onClick={DeleteCmt}>
                                                삭제
                                            </CommentRm>
                                        </Editor>
                                        <CommentContent>
                                            {item.comment_content ? item.comment_content : ''}
                                        </CommentContent>
                                    </Detail>
                                </CommentList>
                            )
                        }) : <>
                            로딩                   
                        </>
                    }
                    <CommentList>
                        <Avartar />
                        <Detail>
                            <UserID>
                                stopthink
                            </UserID>
                            <CommentDate>
                                2020-10-02 12:38
                            </CommentDate>
                            <Editor>
                                <CommentEdit>
                                    수정
                                </CommentEdit>
                                <CommentRm>
                                    삭제
                                </CommentRm>
                            </Editor>
                            <CommentContent>
                                모두 힘들겠지만 좀만더 힘내시고 극복합시다 화이팅!모두 힘들겠지만 좀만더 힘내시고 극복합시다 화이팅!
                            </CommentContent>
                        </Detail>
                    </CommentList>
                    <CommentList>
                        <Avartar />
                    </CommentList>
                    <CommentList>
                        <Avartar />
                    </CommentList>
                    <CommentList>
                        <Avartar />
                    </CommentList>
                </CommentListWrapper>
                <MoreButton>댓글 더보기</MoreButton>
            </Comment> */}
        </Wrapper>
    );
};

export default NoticeSingle;